import React, { useState } from "react";
import { connect } from "react-redux";
import {
  applyFilter,
  clearFilters,
  setEmptyList,
  setFilterType,
} from "../../redux/actions/session-activity";
import {
  Label,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { X } from "react-feather";
import DateTimePicker from "react-datetime-picker";
import "../../assets/css/locationSelectHeader.scss";
import "../../assets/css/infoModal.scss";
const InfoModal = (props) => {
  const {
    sessions,
    applyFilter,
    filtered_data,
    filters,
    clearFilters,
    setEmptyList,
    filterType,
    setFilterType,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [startDateTime, setStartDateTime] = useState(new Date());
  const [endDateTime, setEndDateTime] = useState(new Date());

  const getSessionInTimeFrame = (sessions) => {
    const filteredList = sessions.filter((item) => {
      const time = new Date(item.created_at);
      return (
        time.getTime() >= startDateTime.getTime() &&
        time.getTime() <= endDateTime.getTime()
      );
    });
    return filteredList;
  };

  const updateSessions = () => {
    setFilterType("time");
    if (filters && filters?.length > 0) {
      const filteredList = getSessionInTimeFrame(filtered_data);
      applyFilter(filteredList, filters);
    } else {
      const filteredList = getSessionInTimeFrame(sessions);
      filteredList.length === 0 && setEmptyList(true);
      applyFilter(filteredList, filteredList);
    }
    toggle();
  };

  const onViewAllClick = () => {
    setFilterType("");
    clearFilters();
    setEmptyList(false);
  };

  return (
    <>
      <Button
        color="primary"
        onClick={toggle}
        className="mb-1"
        style={{ border: "1px solid #444" }}
        size="md"
      >
        <span className="ml-1 mr-1 d-md-inline"> Select Custom Time Frame</span>
      </Button>
      {filterType === "time" && (
        <Button
          color="primary"
          onClick={onViewAllClick}
          className="ml-1 mb-1"
          style={{ border: "1px solid #444" }}
          size="md"
        >
          <span className="ml-1 mr-1 d-md-inline">View All</span>
        </Button>
      )}

      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="modal-dialog-centered d-sm-margin-0 p-0"
      >
        <div className="modal-header display-flex full-width flex-space-between">
          <h5 className="modal-title">Enter Date And Time</h5>
          <X size={20} onClick={toggle} />
        </div>
        <ModalBody>
          <AvForm>
            <Row>
              <Col md="12" sm="12">
                <AvGroup>
                  <Label>Start Time</Label>
                  <DateTimePicker
                    className="date-time-picker"
                    maxDate={new Date()}
                    onChange={setStartDateTime}
                    value={startDateTime}
                  />
                   {startDateTime === null && <p className="error-message">Please select start date.</p>}
                </AvGroup>
               
              </Col>

              <Col md="12" sm="12">
                <AvGroup>
                  <Label>End Time</Label>
                  <DateTimePicker
                    className="date-time-picker"
                    minDate={startDateTime}
                    maxDate={new Date()}
                    onChange={setEndDateTime}
                    value={endDateTime}
                    required={true}
                  />
                  {endDateTime === null && <p className="error-message">Please select end date.</p>}
                </AvGroup>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
        <ModalFooter>
          <div className="d-none d-sm-flex flex-space-between full-width">
            <div></div>
            <div>
              <Button color="dark" onClick={toggle}>
                Cancel
              </Button>{" "}
              <Button
                disabled={startDateTime === null || endDateTime === null}
                color="primary"
                onClick={updateSessions}
              >
                Save
              </Button>{" "}
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

function mapStateToProps(state) {
  const {
    sessionActivity: {
      allSessionActivity: {
        data: sessions,
        filters,
        filtered_data,
        filterType,
      },
    },
  } = state;
  return { sessions, filters, filtered_data, filterType };
}

export default connect(mapStateToProps, {
  applyFilter,
  clearFilters,
  setEmptyList,
  setFilterType,
})(InfoModal);
