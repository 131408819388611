import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import TimeHelper from "../../helpers/TimeHelper";
import ViewHelper from "../../helpers/ViewHelper";
import Checkbox from "../@vuexy/checkbox/CheckboxesVuexy";
import { Check, Edit2 } from "react-feather";
import { Button } from "reactstrap";
import ImageHelper from "../../helpers/ImageHelper";


const ListRiderHistory = (props) => {


  const columns = [
    {
      name: 'ID',
      selector: 'id',
      sortable: true,
    },{
      name: 'Order ID',
      selector: 'order_id',
      sortable: true,
    },{
      name: 'Rider ID',
      selector: 'rider_id',
      sortable: true,
      minWidth: '100px',
    }, {
      name: 'Rider Email',
      selector: 'User.email',
      sortable: true,
      minWidth: '300px',
      format: (val) => {
        return val.User && val.User.email ? val.User.email : val.User.facebook_email;
      }
    },
    {
      name: 'Restaurant',
      selector: 'Location.name',
      minWidth: '200px',
      sortable: true,
      format: (val) => {
        return val.Location && val.Location.name ? val.Location.name : 'Not Available';
      }
    }, {
      name: 'Amount',
      selector: 'amount',
      minWidth: '100px',
      sortable: true,
    }, {
      name: 'Delivery Amount',
      selector: 'delivery_amount',
      minWidth: '200px',
      sortable: true,
    }, {
      name: 'Cash Status',
      selector: 'cash_collected_status',
      minWidth: '200px',
      sortable: true,
      format: (val) => {
        return val.cash_collected_status == 1 ? 'Collected' : 'Not Collected';
      }
    }
    , {
      name: 'Date',
      selector: 'created_at',
      minWidth: '200px',
      sortable: true,
      format: (val) => {
        return val.created_at ? TimeHelper.toFormattedDateTimeDB(val.created_at) : 'Not available';
      }
    }
  ];

  const renderRiders = (riders, handleChange) => {
    if (!riders || riders.length === 0)
      return <h1>No App riders found.</h1>;
    const tableData = {
      columns: columns,
      data: riders,
      exportHeaders: true,
      print: false,
    };
    return <DataTableExtensions
      {...tableData}
    ><DataTable
        noHeader
        pagination
        paginationRowsPerPageOptions={[25, 50, 100]}
        paginationPerPage={50}
        selectableRows
        selectableRowsComponent={Checkbox}
        selectableRowsComponentProps={{
          color: "primary",
          icon: <Check className="vx-icon" size={12} />,
          label: "",
          size: "sm"
        }}
        onSelectedRowsChange={handleChange}
        
      /></DataTableExtensions>;
  }
  return renderRiders(props.riders, props.handleChange);

}

export default ListRiderHistory;
