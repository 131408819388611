import React, { Component } from 'react';
import { GoogleApiWrapper } from 'google-maps-react';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import CONSTANTS from '../../../constants';

import OrderService from '../../../services/OrderService';

import TimeHelper from '../../../helpers/TimeHelper';

import '../../../assets/css/map.scss';

import RideMap from './RideMap';

class RiderTrackModal extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showMap: false,
			riderLocation: null,
			estimatedDeliveryTime: null,
			lastUpdated: null,
			fetchingLocation: false,
		};
	}

	componentDidUpdate(prevProps) {
		if(this.props.orderId !== prevProps.orderId || (this.props.showModal && !prevProps.showModal)) {
			if(this.timeoutFunc) {
				clearTimeout(this.timeoutFunc)
			}
			this.fetchRiderLocation();
		}
	}

	componentWillUnmount() {
		if(this.timeoutFunc) {
			clearTimeout(this.timeoutFunc)
			this.timeoutFunc = null;
		}
	}

	async fetchRiderLocation() {
		try {
			if(this.state.fetchingLocation) {
				return;
			}
			let needUpdate = !this.state.lastUpdated;
			if(!needUpdate) {
				const date = new Date().getTime();
				const lastUpdateDate = new Date(this.state.lastUpdated * 1000).getTime();
				if(date - lastUpdateDate > 30) {
					needUpdate = true;
				}
			}
			if(this.props.orderId && needUpdate) {
				this.setState({ fetchingLocation: true });
				const riderDetails = await OrderService.getRideDetails(this.props.locationId, this.props.orderId);
				if(riderDetails.updatedAt) {
					this.setState({ riderLocation: riderDetails.riderLocation, lastUpdated: riderDetails.updatedAt });
				}
				this.setState({ fetchingLocation: false });
			}
		} catch(e) {
			console.log(e);
		}
		this.timeoutFunc = setTimeout(this.fetchRiderLocation.bind(this), 5 * 1000);
	}

	toggleMap() {
		this.setState({ showMap: !this.state.showMap });
	}

	render() {
		const closeBtn = <button className="close" onClick={this.props.toggleModal}>&times;</button>;
		const {
			props: {
				deliveryLocation,
				riderLocation,
				estimatedDeliveryTime,
				restaurantLocation,
				lastUpdated,
			}, state: {
				riderLocation: stateRiderLocation,
				lastUpdated: stateLastUpdated,
		}} = this;
		const rLoc = stateRiderLocation || riderLocation;
		const lastU = stateLastUpdated || lastUpdated;
		return (
			<React.Fragment>
				<Modal isOpen={this.props.showModal} toggle={this.props.toggleModal} className='my-map-modal'>
					{/* {closeBtn} */}
					<div className='modal-header no-border'>
						<h5 className='modal-title text-bold'>Track Rider</h5>
						<div onClick={this.props.toggleModal}><FontAwesomeIcon icon={faTimes} /></div>
					</div>
					<ModalBody>
						<div className='map-container'>
							<div className='map'>
								{ this.props.showModal && <RideMap
									defaultLocation={deliveryLocation}
									riderLocation={rLoc}
									restaurantLocation={restaurantLocation}
								/> }
							</div>
						</div>
						{ estimatedDeliveryTime ? <p className="ml-2 mt-2">Estimated Delivery Time: <strong>{TimeHelper.toFormattedDateTime(estimatedDeliveryTime)}</strong></p> : null }
						{ lastU ? <p className="ml-2 mt-2">Location last updated: <strong>{TimeHelper.toFormattedDateTime(new Date(lastU * 1000))}</strong></p> : null }
					</ModalBody>
					<ModalFooter className="no-border">
						<div className="container-fluid">
							<div className="row">
								<Button className="col-lg-12" color="primary" onClick={this.props.toggleModal}>Done</Button>
							</div>
						</div>
					</ModalFooter>
				</Modal>
			</React.Fragment>
		);
	}
}

export default GoogleApiWrapper({
		apiKey: CONSTANTS.GOOGLE_MAPS_KEY,
})(RiderTrackModal)