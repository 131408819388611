import React, {Component} from "react";
import {connect} from "react-redux";

import { getLocation } from "../../redux/actions/locations";
import { getItemsStats } from "../../redux/actions/analytics";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import SelectLocation from "../shared/SelectLocation";
import LocationSelectHeader from "../shared/LocationSelectHeader";
import TimeHelper from '../../helpers/TimeHelper';

import DataTableComponent from "../shared/DataTableComponent";
import CONSTANTS from "../../constants";
import ViewHelper from "../../helpers/ViewHelper";
import LocationService from "../../services/LocationService";

class ItemsList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectLocation: false,
      location: {
        loc_id: 0,
        name: 'All Venues',
        Currency: {
          currency_code: 'PKR'
        },
        decimal_places: 2,
      },
      loading: false,
      filters: {
        startDate: TimeHelper.getFirstDayOf2018(),
        endDate: TimeHelper.getCurrentDate(),
        orderBy: 'total_sales',
        order: 'desc'
      },
      columns: [
      //   {
      //   name: "Item ID",
      //   selector: "menu_id",
      //   sortable: false
      // },
        {
        name: "Name",
        selector: "name",
        sortable: false
      }, {
        name: "Category",
        selector: "category_name",
        sortable: false
      }, {
        name: "Units Sold",
        selector: "total_items_sold",
        sortable: true,
        format: (val) => {
          return `${val.total_items_sold.toLocaleString( undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0} )}`
        },
      }, {
        name: "Total Sales",
        selector: "total_sales",
        sortable: true,
        format: (val) => {
          return ViewHelper.toCurrency(val.total_sales, this.state.location.Currency.currency_code, this.state.location.decimal_places);
        },
      }, {
        name: "Rating",
        selector: "rating",
        sortable: true,
        format: (val) => {
          return `${val.rating.toLocaleString( undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 } )}`
        },
      }, {
        name: "Total Reviews",
        selector: "total_reviews",
        sortable: true,
        format: (val) => {
          return `${val.total_reviews.toLocaleString( undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0} )}`
        },
      }],
    }
  }

  async componentDidMount() {
    const location = LocationService.getLocation(CONSTANTS.CACHE_KEY_PREFIX_ANALYTICS);
    if(location) {
      await this.refreshData(location.loc_id);
      this.setState({loading: false });
    } else {
      this.setState({loading: false, selectLocation: true})
    }
  }

  async refreshData(loc_id) {
    this.setState({ loading: true });
    let location;
    if(loc_id !== 0) {
      await this.props.getLocation(loc_id);
      location = this.props.location;
    }
    else {
      location = {
        loc_id: 0,
        name: 'All Venues',
        Currency: {
          currency_code: 'PKR'
        },
        decimal_places: 2,
      }
    }
    await this.getStats(loc_id);
    this.setState({ loading: false, location: JSON.parse(JSON.stringify(location)) });
  }

  async getStats(loc_id) {
    await this.props.getItemsStats(loc_id, this.state.filters);
  }

  onBlur() {
    if( this.state.location )
      this.setState({selectLocation: !this.state.selectLocation});
  }

  toggleLocation() {
    this.setState({selectLocation: !this.state.selectLocation});
  }

  async onSelect(location) {
    this.setState({loading: true});
    LocationService.setLocation(location, CONSTANTS.CACHE_KEY_PREFIX_ANALYTICS);
    await this.refreshData(location.loc_id);
    this.setState({selectLocation: false, loading: false});
  }

  filterUpdated(date) {
    const { state: {
      filters,
      location
    }} = this;
    filters.startDate = TimeHelper.toFormattedDate(date[0], CONSTANTS.DATES.FORMAT.DB_DATE);
    if(date.length > 1)
      filters.endDate = TimeHelper.toFormattedDate(date[1], CONSTANTS.DATES.FORMAT.DB_DATE);
    else
      filters.endDate = TimeHelper.getCurrentDate();

    this.setState({ filters: filters }, () => {
      this.getStats(location.loc_id);
    });
  }

  render() {
    const { props: {
      stats
    }} = this;

    const { state: {
      selectLocation,
      loading,
      columns,
      filters,
      location: stateLocation
    }} = this;

    if( loading ) return <SpinnerComponent />;

    const locationName = stateLocation ? stateLocation.name : '';

    if ( selectLocation )
      return <SelectLocation showAll={true} onBack={this.toggleLocation.bind(this)} onSelect={this.onSelect.bind(this)} />;

    return (
      <div className='location'>
        <div className='mb-50'>
          <LocationSelectHeader text={locationName} onIconClick={this.toggleLocation.bind(this)}/>
        </div>
        { !loading && stats ? <DataTableComponent dateFilterUpdated={this.filterUpdated.bind(this)} showDateFilter={true} title={'Top Items'} data={stats} columns={columns} startDate={filters.startDate} endDate={filters.endDate} /> : null }
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { analytics: {
    itemsStats: {
      data: statsData, loading: statsLoading
    }
  }, locations: {
    location: {
      data: locationData, loading: locationLoading
    }
  }} = state;

  return { stats: statsData, location: locationData, loading: statsLoading || locationLoading };
}

export default connect(mapStateToProps, { getLocation, getItemsStats })(ItemsList);