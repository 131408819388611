import { combineReducers } from 'redux'
import { dummyItem,allModifiers } from "./MenuReducer";


const menuReducer = combineReducers({
  dummyItem,
  allModifiers,
});

export default menuReducer;
