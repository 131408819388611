import React, { Component } from 'react';
import SpinnerComponent from '../@vuexy/spinner/Fallback-spinner';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import CONSTANTS from '../../constants';
import SmsCheckoutForm from './SmsCheckoutForm';
import ParamHelper from '../../helpers/ParamHelper';

class SmsPayments extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  render() {
    if (this.state.loading) return <SpinnerComponent />;
    const plan_id = ParamHelper.getPlanId(this.props);
    const stripePromise = loadStripe(CONSTANTS.STRIPE_PUBLIC_KEY);
    return (
      <Elements stripe={stripePromise}>
        <ElementsConsumer>
          {({ stripe, elements }) => (
            <SmsCheckoutForm stripe={stripe} elements={elements} plan_id={plan_id} />
          )}
        </ElementsConsumer>
      </Elements>
    );
  }
}

export default SmsPayments;