import React from 'react';

export default ({input, label, type, meta: { touched, error, warning }, helpText}) => {
  return (
    <div className='form-group'>
      <label htmlFor={input.name}>{label}</label>
      <textarea rows={10} className={`form-control ${input.className ? input.className : ''}`} id={input.name} {...input} />
      { helpText && <small className='form-text text-muted'>{helpText}</small> }
      { touched && ((error && <small className='form-text text-danger'>{label} is {error}</small>) ||
        (warning && <small className='form-text text-muted'>{warning}</small>))}
    </div>
  );
}
