import React from "react";
import DataTable from "react-data-table-component";
import TimeHelper from "../../../helpers/TimeHelper";
import ViewHelper from "../../../helpers/ViewHelper";
import Checkbox from "../../@vuexy/checkbox/CheckboxesVuexy";
import { Check, Edit2 } from "react-feather";
import { Button } from "reactstrap";


const RolesList = (props) => {

  const editStaff = (id) => {
    props.editStaff(id);
  }


  const columns = [
    {
      name: 'ID',
      selector: 'id',
      width: '6%',
    },
    {
      name: 'Staff ID',
      selector: 'User.staff_id',
      format: (val) => {
        return val.User ? val.User.staff_id : 'Not Available';
      },
      width: '8%',
    }, {
      name: 'Name',
      selector: 'User.first_name',
      width: '12%',
      format: (val) => {
        return val.User ? val.User.first_name + ' ' + val.User.last_name : 'Not Available';
      }
    },
    {
      name: 'Email',
      selector: 'User.email',
      width: '23%',
      format: (val) => {
        return val.User ? val.User.email : 'Not Available';
      }
    }, {
      name: 'Location Name',
      selector: 'Location.name',
      width: '16%',
      format: (val) => {
        return val.Location ? val.Location.name : 'Not Available';
      }
    }, {
      name: 'Role Level',
      selector: 'StaffGroup',
      width: '18%',
      format: (val) => {
        return val.StaffGroup && val.StaffGroup.staff_group_name ?
          <>
            <div className='row'>
              <div className='col-lg-12'>
                <a className='btn btn-primary btn-sm' style={{ 'margin-top': '5px' }}>{val.StaffGroup.staff_group_name}</a>
              </div>
            </div>
          </>
          : 'No Role';
      }
    }, {
      name: 'Date Added',
      selector: 'date_added',
      format: (val) => {
        return val.date_added ? val.date_added : 'Not available';
      }
    }
  ];

  const renderStaff = (staff, handleChange) => {
    if (!staff || staff.length === 0)
      return <h1>No App Location Roles found.</h1>;

    return <DataTable
      noHeader
      pagination
      paginationRowsPerPageOptions={[25, 50, 100]}
      paginationPerPage={50}
      selectableRows
      selectableRowsComponent={Checkbox}
      selectableRowsComponentProps={{
        color: "primary",
        icon: <Check className="vx-icon" size={12} />,
        label: "",
        size: "sm"
      }}
      onSelectedRowsChange={handleChange}
      columns={columns}
      data={staff}

    />;
  }
  return renderStaff(props.staff, props.handleChange);

}

export default RolesList;
