import React from 'react';
import * as Icon from 'react-feather';

const adminNavigationConfig = [
  {
    id: 'live-orders',
    title: 'Live Orders',
    type: 'item',
    icon: <Icon.Package size={20} />,
    permissions: ['admin', 'editor'],
    navLink: '/'
  }, {
    id: 'order-types',
    title: 'Order Status',
    type: 'item',
    icon: <Icon.File size={20} />,
    permissions: ['admin', 'editor'],
    navLink: '/locations/order_types',
    filterBase: '/locations/order_types',
  }, {
    id: 'menu-manager',
    title: 'Menu',
    type: 'item',
    icon: <Icon.List size={20} />,
    permissions: ['admin', 'editor'],
    navLink: '/locations/menu'
  }, {
    id: 'analytics',
    title: 'Analytics',
    type: 'collapse',
    icon: <Icon.BarChart2 size={16} />,
    children: [
      {
        id: 'overall-stats',
        title: 'Overall Stats',
        type: 'item',
        icon: <Icon.Activity size={20} />,
        permissions: ['admin', 'editor'],
        navLink: '/locations/overall_stats',
      },
      {
        id: 'customer-stats',
        title: 'Customer Stats',
        type: 'item',
        icon: <Icon.User size={20} />,
        permissions: ['admin', 'editor'],
        navLink: '/locations/customer_stats',
      },
      {
        id: 'item-stats',
        title: 'Item Stats',
        type: 'item',
        icon: <Icon.List size={20} />,
        permissions: ['admin', 'editor'],
        navLink: '/locations/item_stats',
      }
    ]
  }, {
    id: 'promotions',
    title: 'Promotions',
    type: 'item',
    icon: <Icon.Monitor size={20} />,
    permissions: ['admin', 'editor'],
    navLink: '/locations/promotions'
  },
  {
    id: 'sms-marketing',
    title: 'Sms Marketing',
    type: 'collapse',
    icon: <Icon.Mail size={20} />,
    children: [
      {
        id: 'sms-campaigns',
        title: 'SMS Campaigns',
        type: 'item',
        icon: <Icon.MessageSquare size={20} />,
        permissions: ['admin', 'editor'],
        navLink: '/locations/sms/campaigns'
      },
      {
        id: 'sms-plans',
        title: 'Purchase SMS Bundle',
        type: 'item',
        icon: <Icon.ShoppingBag size={20} />,
        permissions: ['admin', 'editor'],
        navLink: '/locations/sms/plans'
      },
      {
        id: 'create-sms-plans',
        title: 'Create SMS Bundle',
        type: 'item',
        icon: <Icon.Archive size={20} />,
        permissions: ['admin', 'editor'],
        navLink: '/locations/sms/admin/plans'
      }
    ]
  }, {
    id: 'restaurant-settings',
    title: 'Restaurant Settings',
    type: 'collapse',
    icon: <Icon.Home size={20} />,
    children: [
      {
        id: 'outlets',
        title: 'Outlets',
        type: 'item',
        icon: <Icon.ShoppingBag size={20} />,
        permissions: ['admin', 'editor'],
        navLink: '/locations/outlets'
      }, {
        id: 'branding',
        title: 'Branding',
        type: 'item',
        icon: <Icon.Layout size={20} />,
        permissions: ['admin'],
        navLink: '/locations/branding',
        filterBase: '/locations/branding'
      }, {
        id: 'tables',
        title: 'Tables',
        type: 'item',
        icon: <Icon.Tablet size={20} />,
        permissions: ['admin'],
        navLink: '/tables'
      }
    ]
  }, {
    id: 'settings',
    title: 'Settings',
    type: 'collapse',
    icon: <Icon.Settings size={20} />,
    children: [
      {
        id: 'restaurants',
        title: 'Restaurants',
        type: 'item',
        icon: <Icon.ShoppingBag size={20} />,
        permissions: ['admin', 'editor'],
        navLink: '/locations'
      }, {
        id: 'payment-processor',
        title: 'Payment Settings',
        type: 'item',
        icon: <Icon.CreditCard size={20} />,
        permissions: ['admin', 'editor'],
        navLink: '/locations/payment_processor',
        filterBase: '/locations/payment_processor'
      }, {
        id: 'currencies',
        title: 'Currencies',
        type: 'item',
        icon: <Icon.DollarSign size={20} />,
        permissions: ['admin'],
        navLink: '/currencies'
      }, {
        id: 'rider-services',
        title: 'Rider Services',
        type: 'item',
        icon: <Icon.User size={20} />,
        permissions: ['admin'],
        navLink: '/locations/rider_services',
        filterBase: '/locations/rider_services'
      }, {
        id: 'notification-settings',
        title: 'Notification Settings',
        type: 'item',
        icon: <Icon.Settings size={20} />,
        navLink: '/locations/settings/notification'
      }
    ]
  }, {
    id: 'riders-and-delivery',
    title: 'Riders & Delivery',
    type: 'collapse',
    icon: <Icon.Truck size={20} />,
    children: [
      {
        id: 'rider-compensation',
        title: 'Rider Compensation',
        type: 'item',
        icon: <Icon.CreditCard size={20} />,
        permissions: ['admin', 'editor'],
        navLink: '/locations/rider_compensation',
      },
      {
        id: 'rider',
        title: 'Riders',
        type: 'item',
        icon: <Icon.CreditCard size={20} />,
        permissions: ['admin', 'editor'],
        navLink: '/riders',
      },
      {
        id: 'rider-history',
        title: 'Delivery Ledger',
        type: 'item',
        icon: <Icon.CreditCard size={20} />,
        permissions: ['admin', 'editor'],
        navLink: '/riders/history',
      },
      {
        id: 'rider-payments',
        title: 'Settlements Ledger',
        type: 'item',
        icon: <Icon.CreditCard size={20} />,
        permissions: ['admin', 'editor'],
        navLink: '/riders/payments',
      }
    ]
  }, {
    id: 'appdata',
    title: 'App Data',
    type: 'collapse',
    icon: <Icon.Box size={20} />,
    children: [
      {
        id: 'feedback',
        title: 'Feedbacks',
        type: 'item',
        icon: <Icon.Inbox size={20} />,
        permissions: ['admin'],
        navLink: '/feedbacks'
      }, {
        id: 'reviews',
        title: 'Reviews',
        type: 'item',
        icon: <Icon.Star size={20} />,
        permissions: ['admin'],
        navLink: '/reviews'
      }, {
        id: 'users',
        title: 'Users',
        type: 'item',
        icon: <Icon.Users size={20} />,
        permissions: ['admin'],
        navLink: '/appdata/users'
      }, {
        id: 'reports',
        title: 'Reports',
        type: 'item',
        icon: <Icon.FileText size={20} />,
        permissions: ['admin'],
        navLink: '/appdata/reports'
      },
      {
        id: 'session-activity',
        title: 'Session Activity',
        type: 'item',
        icon: <Icon.Activity size={20} />,
        permissions: ['admin'],
        navLink: '/session-activity'
      },
    ]
  }, {
    id: 'accounts',
    title: 'Accounts & Roles',
    type: 'collapse',
    icon: <Icon.Lock size={20} />,
    children: [
      {
        id: 'Accounts',
        title: 'Accounts',
        type: 'item',
        icon: <Icon.Users size={20} />,
        permissions: ['admin'],
        navLink: '/staff'
      }, {
        id: 'Roles',
        title: 'Roles',
        type: 'item',
        icon: <Icon.LogIn size={20} />,
        permissions: ['admin'],
        navLink: '/roles'
      }
    ]
  }, {
    id: 'divider'
  }, {
    id: 'logout',
    title: 'Logout',
    type: 'item',
    icon: <Icon.Power size={20} />,
    permissions: ['admin', 'editor'],
    navLink: '/logout',
  }
];
export default adminNavigationConfig;
