import React, { Component } from 'react';
import { connect } from 'react-redux';
import SpinnerComponent from '../@vuexy/spinner/Fallback-spinner';
import LocationForm from './LocationForm';
import DeliveryAreas from './DeliveryAreas';
import LocationOrderTypes from './LocationOrderTypes';
import LocationTimings from './LocationTimings';
import LocationSelectHeader from "../shared/LocationSelectHeader";
import ErrorHandler from '../shared/ErrorHandler';
import ParamHelper from '../../helpers/ParamHelper';
import { getLocation, updateTimings, updateLocation } from '../../redux/actions/locations';
import classnames from "classnames";
import { serialize } from "php-serialize";
import SelectLocation from "../shared/SelectLocation";
import LocationService from "../../services/LocationService";
import CONSTANTS from '../../constants';

import {
	Card,
	CardHeader, CardBody,
	CardTitle, TabContent,
	TabPane, Nav, NavItem,
	NavLink
} from 'reactstrap';


class Location extends Component {

	constructor(props) {
		super(props);
		this.state = {
			activeTab: '1',
			selectLocation: false,
		}
	}

	async componentDidMount() {
		await this.props.getLocation(ParamHelper.getLocationId(this.props));
		if (this.props.location) {
			this.setState({ loading: false, location: JSON.parse(JSON.stringify(this.props.location)), isOutlet: this.props.location.is_outlet });
		} else {
			this.setState({ loading: false, selectLocation: true })
		}
	}

	async refreshData(loc_id) {
    let location;
    if(loc_id !== 0 && ( !this.state.location || loc_id !== this.state.location.loc_id)) {
      await this.props.getLocation(loc_id);
      location = this.props.location;
    } else {
      location = this.state.location;
    }
    this.setState({ loading: false, location: JSON.parse(JSON.stringify(location)) });
  }


	async toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			await this.setState({ activeTab: tab })
		}
	}

	updateLocationsTimings(options, special_events) {
		const formData = new FormData();
		formData.append('options', serialize(options));
		formData.append('special_events', JSON.stringify(special_events));
		this.props.updateTimings(ParamHelper.getLocationId(this.props), formData);
	}

	async updateAreas(options) {
		const location = { ...this.props.location, options: serialize(options) };
		const formData = new FormData();
		formData.append('locationItem', JSON.stringify(location));
		formData.loc_id = this.props.location.loc_id;
		await this.props.updateLocation(formData);
	}

	toggleLocation() {
		this.setState({ selectLocation: !this.state.selectLocation });
	}

	onBlur() {
		if (this.state.location)
			this.setState({ selectLocation: !this.state.selectLocation });
	}

	async onSelect(location) {
		this.setState({ loading: true });
		await LocationService.setLocation(location);
		await this.refreshData(location.loc_id);
		this.setState({ selectLocation: false, loading: false });
		this.props.history.push(`${CONSTANTS.BASE_URL}locations/${location.loc_id}`);
	}

	render() {
		const { location, loading } = this.props;
		const { state: {
			selectLocation
		} } = this;

		// TODO: Implement proper error handling
		if (location && location.loc_id) {
			const locationName = location ? location.name : '';

			if (selectLocation)
				return <SelectLocation onBack={this.toggleLocation.bind(this)} onSelect={this.onSelect.bind(this)} />;

			return (
				<div className='location'>
					<LocationSelectHeader className='mb-1' text={locationName} onIconClick={this.toggleLocation.bind(this)} />
					<TabContent className='mt-2'>
						<TabPane>
							<Nav tabs className="nav-justified mb-0" >
								<NavItem>
									<NavLink
										className={classnames({
											active: this.state.activeTab === "1"
										})}
										onClick={() => {
											this.toggleTab("1")
										}}
									>
										Basic
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										className={classnames({
											active: this.state.activeTab === "2"
										})}
										onClick={() => {
											this.toggleTab("2")
										}}
									>
										Order Types
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										className={classnames({
											active: this.state.activeTab === "3"
										})}
										onClick={() => {
											this.toggleTab("3")
										}}
									>
										Timings
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										className={classnames({
											active: this.state.activeTab === "4"
										})}
										onClick={() => {
											this.toggleTab("4")
										}}
									>
										Delivery Areas
									</NavLink>
								</NavItem>
							</Nav>
							<TabContent className="py-50" activeTab={this.state.activeTab}>
								<TabPane className="background-transparent" tabId="1">
									<LocationForm />
								</TabPane>
								<TabPane className="background-transparent" tabId="2">
									<LocationOrderTypes location={location} />
								</TabPane>
								<TabPane className="background-transparent" tabId="3">
									{location && <LocationTimings options={location.options} updateOptions={this.updateLocationsTimings.bind(this)} special_events={location.SpecialEvents} />}
								</TabPane>
								<TabPane className="background-transparent" tabId="4">
									{
										location && <DeliveryAreas location={location} updateAreas={this.updateAreas.bind(this)} />
									}
								</TabPane>
							</TabContent>
						</TabPane>
					</TabContent>
				</div>
			);
		}
		if (loading) return <SpinnerComponent />;
		return <ErrorHandler />;
	}
}

function mapStateToProps(state) {
	const { location } = state.locations;
	return { location: location.data, loading: location.loading };
}

export default connect(mapStateToProps, { getLocation, updateTimings, updateLocation })(Location);