import React, {useEffect, useState} from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
  TabContent, TabPane, Nav, NavItem, NavLink,
  Input, Col, Media,
} from "reactstrap";

import Radio from "../../@vuexy/radio/RadioVuexy";

import {ClipLoader} from "react-spinners";
import Select from "react-select";
import {Image, Plus, X} from "react-feather";
import ImageHelper from "../../../helpers/ImageHelper";
import * as classnames from "classnames";
import MenuModifiersList from "../MenuModifiersList";
import ConfirmationModal from "./ConfirmationModal";
import GlobalModifiersFormModal from "./GlobalModifiersFormModal";
import MenuService from "../../../services/MenuService";

const MenuItemModal = (props) => {

  const saveAction = () => {
    if(isOpen) {
      submitItem();
    }
  };

  const keydownHandler = (e) => {
    if(e.keyCode===13 && e.ctrlKey) saveAction();
  };

  useEffect(()=> {
    document.addEventListener('keydown', keydownHandler);
    return () => document.removeEventListener('keydown', keydownHandler);
  });

  const dummyItem = {
    menu_name: '',
    menu_description: '',
    menu_status: 1,
    menu_price: '',
    menu_photo: '',
    menu_category_id: null,
    stock_qty: 0,
    minimum_qty: 0,
    subtract_stock: 0,
    mealtime_id: 0,
		pre_rating: 4.0,
    review_photo: '',
    ratable: 1,
    MenuOptions: [],
  }

  let {
    isOpen,
    toggleModal,
    selectedCategory,
    categories,
    deleteMenu,
    saveItem,
    deleting,
    location,
  } = props;

  let itemProp = props.item ? props.item : JSON.parse(JSON.stringify(dummyItem));

  const [item, setItem] = useState(itemProp);
  const [saving, setSaving] = useState(false);
  const [nameError, setError] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [tab, setTab] = useState(1);
  const [prevOpenState, setPrevOpenState] = useState(isOpen);
  const [confirmationDialog, setConfirmationDialog] = useState({
    show: false,
    acceptCallback: () => {},
    rejectCallback: () => {},
    title: '',
  });

  const [globalModifiersDialog, setGlobalModifiersDialog] = useState(false);

  const toggleGlobalModifierModal = (changeTab= false) => {
    setGlobalModifiersDialog(!globalModifiersDialog);
    if(changeTab)
      toggle(2);
  }

  const getSelectedModifiers = () => {
    return item.MenuOptions.map(mo => mo.option_id);
  }

  const showConfirmationDialog = (accept, reject, title) => {
    const defaultState = {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
      title: '',
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        accept();
        setConfirmationDialog(defaultState);
      },
      rejectCallback: () => {
        setConfirmationDialog(defaultState);
        if( reject )
          reject();
      },
      title: title,
    }
    setConfirmationDialog(confirmationDialog);
  }


  let inputRef = React.createRef();
  let fileRef = React.createRef();

  const toggle = sTab => {
    if (tab !== sTab) {
      setTab(sTab);
    }
  }

  const updateItem = (key, val) => {
    const cat = JSON.parse(JSON.stringify(item));
    cat[key] = val;
    if(key === 'img' && val === null)
      cat.deletePhoto = true;
    setItem(cat);
  }

  const handleChange = (e) => {
    if(e.target.files.length > 0) {
      let reader = new FileReader();
      reader.onload = (ev) => {
        const itemProp = JSON.parse(JSON.stringify(item));
        itemProp.img = ev.target.result;
        itemProp.deletePhoto = false;
        setItem(itemProp);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const delMenu = () => {
    if(saving || deleting) return;
    deleteMenu(item);
  }

  useEffect(() => {
    let itemProp = props.item ? props.item : JSON.parse(JSON.stringify(dummyItem));
    itemProp.menu_category = selectedCategory;
    itemProp.img = ImageHelper.getImageURL(itemProp.menu_photo,'?func=crop&w=120&h=80');
    isOpen = props.isOpen;
    setSaving(false);
    deleting = props.deleting;
    setError(false);
    if(prevOpenState !== isOpen) {
      setPrevOpenState(isOpen);
      setTab(1);
    }
    setItem(itemProp);
    setPriceError(false);
  }, [props]);

  const submitItem = () => {
    if(saving || deleting) return;
    if(item.menu_name === '') {
      setError(true);
      return;
    }
    if(item.menu_price === '' || item.menu_price == 0 || isNaN(item.menu_price)) {
      setPriceError(true);
      return;
    }
    setSaving(true);
    saveItem(item);
  }

	const fetchGlobalModifiersForItem = async () => {
		const modifiers = await MenuService.getModifiers(item.loc_id, item.menu_id);
		const it = JSON.parse(JSON.stringify(item));
		it.MenuOptions = modifiers;
		setItem(it);
	}

  const categoryOptions = categories.map( cat => ({value: cat.id, label: cat.name}) );

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={() => toggleModal()}
        onOpened={() => {
          if(props.item)
            return;
          setTimeout(() => inputRef.current.focus(), 1);
        }}
        className="modal-dialog-centered d-sm-margin-0 p-0"
      >
        <div className='modal-header display-flex full-width flex-space-between'>
          <h5 className='modal-title'>{ props.item ? props.item.menu_name : 'New Item' }</h5>
          <X size={20} onClick={toggleModal} />
        </div>
        <ModalBody>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({
                  active: tab === 1
                }, 'content-dark-bg-color')}
                onClick={() => {
                  toggle(1)
                }}
              >
                Details
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: tab === 2
                }, 'content-dark-bg-color')}
                onClick={() => {
                  toggle(2)
                }}
              >
                Modifiers
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={tab}>
            <TabPane tabId={1} className='content-dark-bg-color'>
              <FormGroup className='row'>
                <Col md={9}>
                  <Label for="name">Item Name:</Label>
                  <Input
                    innerRef={ inputRef }
                    type="text"
                    id="name"
                    value={item.menu_name}
                    placeholder="Enter Item Name"
                    invalid={nameError}
                    onChange={(e) => updateItem('menu_name', e.target.value)}
                  />
                </Col>
                <Col md={3}>
                  <Label for="price">Price:</Label>
                  <Input
                    type="text"
                    id="price"
                    invalid={priceError}
                    value={item.menu_price}
                    placeholder="Enter Price"
                    onChange={(e) => updateItem('menu_price', e.target.value)}
                  />
                </Col>
              </FormGroup>
              <FormGroup>
                <Label for="email">Item Description:</Label>
                <Input
                  type="textarea"
                  id="name"
                  value={item.menu_description}
                  placeholder="Enter Item Description"
                  onChange={(e) => updateItem('menu_description', e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="photoDiv">Image:</Label>
                <div tabIndex="0" id="photoDiv" className='display-flex flex-column border border-2 border-light cursor-pointer' onKeyUp={(e) => e.keyCode === 13 && !e.ctrlKey ? fileRef.current.click() : null} onClick={() => fileRef.current.click()} style={{ position: 'relative', height: 120, width: 150}}>
                  <div style={{ textAlign: '-webkit-center' }}>
                    { item.img ?
                      <div>
                        <div style={{ position: 'absolute', top: -10, right: -10 }}>
                          <Button.Ripple onClick={() => {
                            updateItem('img', null);
                            fileRef.current.value = null;
                          }} style={{ width: 20, height: 20 }} className="m-0 p-0" color="danger">
                            <X size={14} />
                          </Button.Ripple>
                        </div>
                        <div style={{ position: 'absolute', bottom: 3 }} className='full-width'>
                          <Button.Ripple className='p-25' color="primary" onClick={() => fileRef.current.click()}>
                            <span className='font-small-1'>Change Photo</span>
                          </Button.Ripple>
                        </div>
                        <Media
                          object
                          src={item.img}
                          alt="image"
                          style={{ maxWidth: 148, maxHeight: 118 }}
                          className='align-self-center'
                        />
                      </div> :
                      <div className='align-self-center' style={{ padding: 9, height: 50, width: 50, }}>
                        <Image size={32} className='align-self-center' />
                      </div>
                    }
                  </div>
                  { item.img ? null :
                    <React.Fragment>
                      <span className='font-small-2 primary align-self-center'>Click to upload a file</span>
                      <span className='font-small-2 color- align-self-center align-text-center'>PNG, JPG, IMG <br /> up to 10mb</span>
                    </React.Fragment>
                  }
                </div>
                <input
                  type="file"
                  id="fileInput"
                  name="fileInput"
                  onChange={handleChange}
                  ref={fileRef}
                  style={{ display: 'none' }}
                />
              </FormGroup>
              <FormGroup>
                <Label for="category">Category:</Label>
                <Select
                  styles={ {
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? 'var(--primary)' : state.isFocused ? '#7367f099' : '#262c49',
                    }),
                  } }
                  className="React no-pointer-events"
                  classNamePrefix="select"
                  defaultValue={item.menu_category}
                  name="color"
                  options={categoryOptions}
                  onChange={(e) => updateItem('menu_category', e)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="status">Status:</Label>
                <div id='status' onChange={(e) => updateItem('menu_status', parseInt(e.target.value))}>
                  <div className="d-inline-block mr-1">
                    <Radio label="Enabled" checked={item.menu_status === 1} name="status" value={1} />
                  </div>
                  <div className="d-inline-block mr-1">
                    <Radio label="Disabled" name="status" checked={item.menu_status === 0} value={0} />
                  </div>
                  <div className="d-inline-block mr-1">
                    <Radio label="Out of Stock" name="status" checked={item.menu_status === 2} value={2} />
                  </div>
                </div>
              </FormGroup>
              <FormGroup className='row'>
								<Col md={6} sm={9}>
									<Label for="time">Ratings:</Label>
									<div id='time' onChange={(e) => updateItem('ratable', parseInt(e.target.value)) }>
										<div className="d-inline-block mr-1">
											<Radio label="Enabled" checked={item.ratable} name="ratable" value={1} />
										</div>
										<div className="d-inline-block mr-1">
											<Radio label="Disabled" checked={!item.ratable} name="ratable" value={0} />
										</div>
									</div>
								</Col>
								<Col md={6} sm={3}>
                  <Label for="price">Pre Rating:</Label>
                  <Input
                    type="text"
                    id="pre_rating"
                    invalid={priceError}
                    value={item.pre_rating}
                    placeholder="Pre-Rating"
                    onChange={(e) => {
											const val = e.target.value;
											if(val !== "" && isNaN(val))
												return;
											const value = val !== "" ? parseFloat(val) : val;
											if(value !== "" && (value > 5 || value < 1))
												return;
											updateItem('pre_rating', e.target.value)
										}}
                  />
                </Col>
              </FormGroup>
              <Button outline className="font-weight-bold custom-padding disabled-2-border" color={ "secondary" } size="md" onClick={toggleGlobalModifierModal.bind(this)}>
                <Plus className="mx-50" size={14} /> <span className="ml-50 mr-50">Add Modifier</span>
              </Button>
            </TabPane>
            <TabPane tabId={2} className='content-dark-bg-color'>
              <MenuModifiersList showConfirmationDialog={showConfirmationDialog} modifiers={item.MenuOptions} updateModifiers={updateItem.bind(this, 'MenuOptions')} />
              <Button outline className="font-weight-bold custom-padding disabled-2-border" color={ "secondary" } size="md" onClick={toggleGlobalModifierModal.bind(this)}>
                <Plus className="mx-50" size={14} /> <span className="ml-50 mr-50">Add Modifier</span>
              </Button>
            </TabPane>
          </TabContent>
        </ModalBody>
        <ModalFooter>
          <div className='d-none d-sm-flex flex-space-between full-width'>
            <div>
              { props.item && !props.item.is_dummy_item && <Button color='bg-darken-3' disabled={saving || deleting} onClick={ delMenu }>
                { deleting ? <ClipLoader size={15} /> : <span className='danger'> Delete </span> }
              </Button> } {" "}
            </div>
            <div>
              <Button color="dark" disabled={saving || deleting} onClick={ () => {
                if(saving) return;
                toggleModal();
              } }>
                Cancel
              </Button>{" "}
              <Button color="primary" disabled={saving || deleting} onClick={ submitItem }>
                { saving ? <ClipLoader size={15} /> : "Save" }
              </Button>{" "}
            </div>
          </div>
          <div className='d-sm-none col-12'>
            <Button className='col-12' color="primary" disabled={saving || deleting} onClick={ submitItem }>
              { saving ? <ClipLoader size={15} /> : "Save" }
            </Button>{" "}
            <Button className='col-12 my-50' color="dark" disabled={saving || deleting} onClick={ () => {
              if(saving || deleting) return;
              toggleModal();
            } }>
              Cancel
            </Button>{" "}
            { props.item && <Button className='col-12' color="darken-1" disabled={saving || deleting} onClick={ delMenu }>
              { deleting ? <ClipLoader size={15} /> : <span className='danger'> Delete </span> }
            </Button> }
          </div>
          <p className='d-none d-sm-inline col-12 align-text-right font-small-1 pr-0'>
            Hit Ctrl + Enter to Save
          </p>
        </ModalFooter>
      </Modal>
      <GlobalModifiersFormModal fetchGlobalModifiersForItem={fetchGlobalModifiersForItem.bind(this)} isOpen={globalModifiersDialog} selectedModifiers={getSelectedModifiers()} toggleModal={toggleGlobalModifierModal.bind(this)} showConfirmationDialog={showConfirmationDialog.bind(this)} menu_id={item.menu_id} title={item.menu_name} loc_id={location?.loc_id} isDummyItem={item.is_dummy_item} />
      <ConfirmationModal title={confirmationDialog.title} isOpen={confirmationDialog.show} accept={confirmationDialog.acceptCallback.bind(this)} reject={confirmationDialog.rejectCallback.bind(this)} />
    </React.Fragment>
  );
}
export default MenuItemModal;