import { combineReducers } from 'redux'
import { allSmsCampaigns, campaign } from './CampaignsReducer';
import { customerStats } from './CampaignCustomerStatsReducer';


const campaignsReducer = combineReducers({
    allSmsCampaigns,
    campaign,
    customerStats
});

export default campaignsReducer;