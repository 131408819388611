import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';
import { Button, Col, Row } from 'reactstrap';
import { ArrowLeft } from 'react-feather';

import ViewHelper from '../../helpers/ViewHelper';
import LocationService from '../../services/LocationService';
import CONSTANTS from '../../constants';

import '../../assets/css/selectLocation.scss';

class SelectLocation extends Component {
  constructor(props) {
		super(props);
    this.state = { searchTerm: '', userLocations: [] };
  }

  async componentDidMount() {
    let locations = await LocationService.getLocations('');
    this.setState({ userLocations: locations });
    if(locations.length === 1) {
      this.onChange({ location: locations[0] });
    }
  }

  handleInputChange = (searchTerm) => {
    this.setState({ searchTerm });
    return searchTerm;
  };

  async loadOptions(term, callback) {
    if(term.length < 3) return callback([]);
    try {
      const locations = await LocationService.getLocations(term, this.props.showOutlets);
      callback(ViewHelper.transformedLocations(locations));
    } catch (e) {
     console.log(e);
     alert('Could not load locations');
    }
  }

  onChange(selected) {
    if(selected) {
      if(this.props.onSelect) this.props.onSelect(selected.location);
      else this.onSelect(selected.location);
    }
  }

  onBlur() {
    if(this.props.onBlur) {
      this.props.onBlur();
    }
  }

  goBack() {
    if(this.props.onBack) {
      console.log("Go Back");
      this.props.onBack();
    }
  }

  onSelect(location) {
    LocationService.setLocation(location);
    this.redirectToURL(location);
  }

  redirectToURL(location) {
    this.props.history.push(`${CONSTANTS.BASE_URL}locations/${location.loc_id}/order_types`);
  }

  render (){
    const { props: {
      hideBack = false,
      showAll = false
    }} = this;
    return (
      <React.Fragment>
        <div className='d-flex flex-row'>
          { !hideBack && <ArrowLeft className="primary font-weight-bold align-self-auto mr-1" size='40' onClick={this.goBack.bind(this)} /> }
          <div className='position-relative has-icon-left full-width'>
            <AsyncSelect
              placeholder='Please enter restaurant name'
              autoFocus
              isClearable
              cacheOptions
              onBlur={this.onBlur.bind(this)}
              onChange={this.onChange.bind(this)}
              loadOptions={this.loadOptions.bind(this)}
              onInputChange={this.handleInputChange.bind(this)}
              noOptionsMessage={() => 'No restaurants present.'}
              styles={{
                control: (provided) => { return {...provided, borderRadius: '1.5rem' }},
                dropdownIndicator: (provided) => { return {...provided, display: 'none' }},
                indicatorSeparator: (provided) => { return {...provided, display: 'none' }}
              }}
            />
          </div>
        </div>
        <div>
          <Row style={{margin: 10}}>
            { showAll && <Button.Ripple style={{margin: 5}} outline color="primary" key={0} onClick={() => this.onChange({location: {
              loc_id: 0
              }})}> All </Button.Ripple> }
            { this.state.userLocations.map(location => <Button.Ripple style={{margin: 5}} outline color="primary" key={location.loc_id} onClick={() => { this.onChange({location}) }}> { location.name } </Button.Ripple> ) }
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default SelectLocation;
