import React from "react";
import DataTable from "react-data-table-component";
import {Button} from "reactstrap";
import {Edit2, Pause, Play, Send, ShieldOff, Trash} from "react-feather";

const PromotionList = (props) => {

  const toggleItem = async (id, state) => {
    props.togglePromotion(id, state);
  }

  const editItem = (id) => {
    props.editPromotion(id);
  }

  const columns = [
    {
      name: 'Name',
      selector: 'name',
    }, {
      name: 'Code',
      selector: 'code',
    }, {
      name: 'Automatic',
      selector: 'automatic',
      format: ({automatic}) => {
        return `${automatic ? 'Yes' : 'No'}`
      }
    }, {
      name: 'Total Orders',
      selector: 'total_orders',
      format: ({total_orders}) => {
        return total_orders ? total_orders : 0
      }
    }, {
      name: 'Total Discount',
      selector: 'total_discount',
      format: ({total_discount}) => {
        return total_discount ? total_discount : 0
      }
    }, {
      name: 'Start Date',
      selector: 'start_date',
      format: ({start_date}) => {
        return `${start_date.split('T')[0]}`
      }
    }, {
      name: 'End Date',
      selector: 'end_date',
      format: ({end_date}) => {
        return `${end_date.split('T')[0]}`
      }
    }, {
      name: 'Actions',
      selector: 'id',
      compact: true,
      wrap: true,
      format: ({id, active, end_date}) => {
        end_date = Date.parse(`${end_date.split('T')[0]}T23:59:59`);
        return (
          <>
            { end_date < Date.now() ?
              <Button outline className='btn-icon' color={'warning'} disabled={true}>
                <ShieldOff color='var(--warning)' className='m-0 p-0' size={20} />
              </Button>
              :
              <Button outline className='btn-icon' color={active ? 'success' : 'light'}
                      onClick={() => toggleItem(id, active)}>{active ?
                <Play color='var(--success)' className='m-0 p-0' size={20}/> :
                <Pause color='var(--light)' className='m-0 p-0' size={20}/>}
              </Button>
            }
            <Button outline className='btn-icon ml-25' color='primary' onClick={() => editItem(id)}><Edit2 color='var(--primary)' className='ml-0 p-0' size={20} /></Button>
          </>
        );
      }
    }
  ];

  const renderPromotions = (promos) => {
    if(!promos || promos.length === 0)
      return null;

    return <DataTable
      columns={columns}
      data={promos}
      noHeader
    />;
  }
  return renderPromotions(props.promotions);

}

export default PromotionList;