import React, { Component } from 'react';
import { Map, Circle, Marker, Polyline } from 'google-maps-react';
import CONSTANTS from '../../../constants';

import currentLocImage from '../../../assets/img/marker.png';
import restaurantImg from '../../../assets/img/restaurant.png';
import dBoyImg from '../../../assets/img/dboy.png';

export default class RideMap extends Component {
	constructor(props) {
    super(props);
    this.mapRef = React.createRef();
  }

	setBounds(destination, start) {
		const bounds = new window.google.maps.LatLngBounds();
		bounds.extend(destination);
		bounds.extend(start);
		// if(this.mapRef.current) {
		// 	this.mapRef.current.map.fitBounds(bounds);
		// 	this.mapRef.current.map.panBy(0, 0);
		// }
	}

	// componentDidUpdate(prevProps) {
	// 	if(JSON.stringify(this.props.defaultLocation) !== JSON.stringify(prevProps.defaultLocation) || JSON.stringify(this.props.riderLocation) !== JSON.stringify(prevProps.riderLocation)) {
	// 		const destination = this.props.defaultLocation || `${CONSTANTS.ORDERS.VARIABLES.DEFAULT_LOCATION.lat},${CONSTANTS.ORDERS.VARIABLES.DEFAULT_LOCATION.lng}`;
	// 		const riderLocation = this.props.riderLocation;
	// 		const location = riderLocation || this.props.restaurantLocation;
	// 		const end = {lat: Number(destination.split(',')[0]), lng: Number(destination.split(',')[1])}
	// 		const start = {lat: Number(location.split(',')[0]), lng: Number(location.split(',')[1])}
	// 		this.setBounds(end, start);
	// 	}
	// }

  render () {
    const destination = this.props.defaultLocation || `${CONSTANTS.ORDERS.VARIABLES.DEFAULT_LOCATION.lat},${CONSTANTS.ORDERS.VARIABLES.DEFAULT_LOCATION.lng}`;
		const {
			riderLocation,
			restaurantLocation,
		} = this.props;
		const location = riderLocation || restaurantLocation;
		const end = {lat: Number(destination.split(',')[0]), lng: Number(destination.split(',')[1])}
		const start = {lat: Number(location.split(',')[0]), lng: Number(location.split(',')[1])}
		const restaurant = {lat: Number(restaurantLocation.split(',')[0]), lng: Number(restaurantLocation.split(',')[1])}
    return [
      <Map
				ref={this.mapRef}
        mapTypeControl={false}
        fullscreenControl={false}
        streetViewControl={false}
        containerStyle={{ position: 'relative' }}
        google={window.google}
        key='map'
        initialCenter={start}
        center={start}
        zoom={15}
				panControl={false}
				zoomControl={false}
				scaleControl={false}
        gestureHandling={'greedy'}>
					<Marker
						name={'Rider location'}
						position={start}
						icon={{
							url: dBoyImg,
							anchor: new window.google.maps.Point(25, 25),
							scaledSize: new window.google.maps.Size(50, 50)
						}}
					/>
					<Marker
						name={'Your location'}
						position={end}
						icon={{
							url: currentLocImage,
							anchor: new window.google.maps.Point(25, 25),
							scaledSize: new window.google.maps.Size(50, 50)
						}}
					/>
					<Marker
						name={'Restaurant location'}
						position={restaurant}
						icon={{
							url: restaurantImg,
							anchor: new window.google.maps.Point(25, 25),
							scaledSize: new window.google.maps.Size(50, 50)
						}}
					/>
      </Map>
    ];
  }
}