import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import SpinnerComponent from '../@vuexy/spinner/Fallback-spinner';
import { initializeNewLocation } from '../../redux/actions/locations';
import CONSTANTS from '../../constants';
import OutletForm from './OutletForm';

class NewOutlet extends Component {

  componentDidMount() {
    this.props.initializeNewLocation();
  }

  redirectToOutlets() {
    this.props.history.replace(`${CONSTANTS.BASE_URL}locations/outlets`);
  }

  render() {
    const { location, loading } = this.props;
    if(!location || !location.loc_id) {
      return (
        <div className='location'>
          <Card>
            <CardBody>
              <OutletForm onSuccess={this.redirectToOutlets.bind(this)}/>
            </CardBody>
          </Card>
        </div>
      );
    }
    if(loading) return <SpinnerComponent />;
    return '';
  }
}

function mapStateToProps(state) {
  const { location } = state.locations;
  return { location: location.data, loading: location.loading };
}

export default connect(mapStateToProps, { initializeNewLocation })(NewOutlet);
