import React, { useEffect, useState } from 'react';
import { Button, Col, FormGroup, Input, Label, Row, Media } from 'reactstrap';
import NumericInput from 'react-numeric-input';

import Select from 'react-select';
import { Check, Plus, Trash, Image, X } from 'react-feather';
import Checkbox from '../../@vuexy/checkbox/CheckboxesVuexy';
import { defaultStyle } from '../numberInputStyles/InputStyles';
import Radio from '../../@vuexy/radio/RadioVuexy';
import MathHelper from '../../../helpers/MathHelper';
import ImageHelper from "../../../helpers/ImageHelper";

import '../../../assets/scss/plugins/forms/switch/react-toggle.scss';
import 'react-toggle/style.css';
import Toggle from "react-toggle";

const ItemModifierForm = (props) => {

  const formatOptionForDropdown = (optionValues) => {
    return optionValues.map(ov => ({ value: ov.option_value_id, label: ov.value }));
  }

  const getMenuItem = (selected, optionValues) => {
    let selectedObj = optionValues.filter(ov => ov.option_value_id === selected.value)[0];
    return {
      menu_option_id: item.menu_option_id,
      option_value_id: selectedObj.option_value_id,
      option_id: selectedObj.option_id,
      menu_id: item.menu_id,
      new_price: selectedObj.price,
      quantity: 0,
      subtract_stock: 0,
      OptionValue: selectedObj,
      isNew: true,
      menu_option_value_id: MathHelper.getRndInteger()
    };
  }

  let {
    item: propItem,
    updateModifier,
    showConfirmationDialog
  } = props;

  const typeOptions = [{
    value: 'radio',
    label: 'Single'
  }, {
    value: 'select',
    label: 'Selector'
  }, {
    value: 'checkbox',
    label: 'Checkbox'
  }];

  const options = {
    radio: 'Single',
    select: 'Selector',
    checkbox: 'Checkbox',
  }

  propItem.type = { value: propItem.Option.display_type, label: options[propItem.Option.display_type] };

  const [item, setItem] = useState(propItem);
  const [optionAddToggle, setToggleAdd] = useState(false);

  useEffect(() => {
    let {
      item: propItem,
    } = props;
    propItem.type = { value: propItem.Option.display_type, label: options[propItem.Option.display_type] };
    setItem(props.item);
  }, [props]);

  const updateItem = (option, val) => {
    let itemCopy = item;
    let optionParts = option.split('.');
    let obj = itemCopy;
    for (let i = 0; i < optionParts.length - 1; i++) {
      if (isNaN(optionParts[i]))
        obj = obj[optionParts[i]];
      else
        obj = obj[parseInt(optionParts[i])];
    }

    if (option === 'default_value_id' && val === obj['default_value_id'])
      val = 0;

    if ((option === 'min' || option === 'max') && (val > 100 || val < 0))
      return;

    const opt = optionParts[optionParts.length - 1];
    if (opt === 'new_price' && val !== '' && isNaN(val))
      return;

    if (opt === 'new_price' && val === '')
      val = null;

    obj[opt] = val;
    updateModifier(itemCopy);
  }

  const deleteItem = (index) => {
    showConfirmationDialog(() => {
      console.log('deleting');
      let itemCopy = item;
      itemCopy.MenuOptionValues.splice(index, 1);
      updateModifier(itemCopy);
    }, () => {
      console.log("Don't delete");
    }, 'Are you sure you want to remove this Option?');
  }

  const toggleOptionAdd = () => {
    console.log(optionAddToggle);
    setToggleAdd(!optionAddToggle);
  }

  const addSelectedItem = (selected) => {
    if (selected === null)
      return;
    const newItem = getMenuItem(selected, item.Option.OptionValues);
    item.MenuOptionValues.push(newItem);
    updateModifier(item);
    toggleOptionAdd();
  }

  const renderMenuOptionValues = (optionValues, options_layout, default_id) => {
    return optionValues.map((option, index) => {
      let image = ImageHelper.getImageURL(option.OptionValue == null ? '' : option.OptionValue.img, '?func=crop&w=120&h=80');
      if (!option.OptionValue)
        return null;
      return (
        options_layout == 1 ?
          <React.Fragment>
            <Col xs={6} className='mb-25'>
              <Input
                type="text"
                id="optionName"
                value={option.OptionValue.value}
                placeholder="Option"
                readOnly
              />
            </Col>
            <Col xs={3} className='mb-25'>
              <Input
                type="text"
                id="optionPrice"
                value={option.new_price === null ? '' : option.new_price}
                placeholder="Price"
                onChange={(e) => updateItem(`MenuOptionValues.${index}.new_price`, e.target.value)}
              />
            </Col>
            <Col xs={3} className='mb-25 display-flex align-items-center'>
              <Button
                className="btn-icon overflow-visible p-0 m-0"
                color="flat-danger"
                tabindex='-1'
                onClick={(e) => {
                  e.currentTarget.blur();
                  deleteItem(index);
                }}
              >
                <Trash size={16} />
              </Button>
              <Checkbox
                tabindex='1'
                color='primary'
                icon={<Check className='vx-icon' size={16} />}
                defaultChecked={parseInt(option.menu_option_value_id) === default_id}
                checked={parseInt(option.menu_option_value_id) === default_id}
                className='ml-75 p-0'
                value={option.menu_option_value_id}
                onChange={(e) => updateItem(`default_value_id`, e.target.value)}
              />
            </Col>
          </React.Fragment>
          :
          <React.Fragment key={option.option_value_id} >
            <Col xs={4} className='mb-25'>
              <FormGroup>
                <Label for="photoDiv">Image:</Label>
                <div tabIndex="0" id="photoDiv" className='display-flex flex-column border border-2 border-light cursor-pointer' style={{ position: 'relative', height: 100, width: 130 }}>
                  <div style={{ textAlign: '-webkit-center' }}>
                    {image ?
                      <div>
                        <Media
                          object
                          src={image}
                          alt="image"
                          style={{ maxWidth: 128, maxHeight: 118, width: 98, height: 98 }}
                          className='align-self-center'
                        />
                      </div> :
                      <div className='justify-content-center align-self-center' style={{ padding: 9, height: 50, width: 50, }}>
                        <Image size={32} className='align-self-center' />
                      </div>
                    }
                  </div>
                  {image ? null :
                    <React.Fragment>
                      <span className='font-small-2 primary align-self-center'>No Image Available</span>
                    </React.Fragment>
                  }
                </div>
              </FormGroup>
            </Col>
            <Col sm={6} md={6} lg={8} className='mb-25'>
              <Row>
                <Col xs={8} className='mb-25'>
                  <FormGroup>
                    <Label >Option:</Label>
                    <Input
                      type="text"
                      id="optionName"
                      value={option.OptionValue.value}
                      placeholder="Option"
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col xs={4} className='mb-25'>
                  <FormGroup>
                    <Label >Price:</Label>
                    <Input
                      type="text"
                      id="optionPrice"
                      value={option.new_price === null ? '' : option.new_price}
                      placeholder="Price"
                      onChange={(e) => updateItem(`MenuOptionValues.${index}.new_price`, e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                {/* <Col xs={6} className='mb-25 ml-1' >
                  <Row>
                    <Toggle
                      name={'active'}
                      data-id={0}
                      defaultChecked={option.status}
                      className='switch-danger'
                      onChange={(e) => {
                        e.stopPropagation();
                        statusItem(index);
                      }}
                    />
                    <p style={{ marginBottom: '0px', marginTop: '2px', marginLeft: '5px' }}>{option.status ? 'Enabled' : 'Disabled'}</p>
                  </Row>
                </Col> */}
                <Col xs={12} className='mb-25 display-flex align-items-center'>
                  <Button
                    className="btn-icon overflow-visible p-0 m-0"
                    color="flat-danger"
                    tabindex='-1'
                    onClick={(e) => {
                      e.currentTarget.blur();
                      deleteItem(index);
                    }}
                  >
                    <Trash size={16} />
                  </Button>
                  <Checkbox
                    tabindex='1'
                    color='primary'
                    icon={<Check className='vx-icon' size={16} />}
                    defaultChecked={parseInt(option.menu_option_value_id) === default_id}
                    checked={parseInt(option.menu_option_value_id) === default_id}
                    className='ml-75 p-0'
                    value={option.menu_option_value_id}
                    onChange={(e) => updateItem(`default_value_id`, e.target.value)}
                  />
                </Col>
              </Row>
            </Col>
          </React.Fragment >
      );
    });
  }

  const renderMenuExtras = (item) => {
    if (item.type.label === options.radio) {
      return (
        <React.Fragment>
          <Col xs={12} className='my-75 mb-0'>
            <div id='status' onChange={(e) => updateItem('required', parseInt(e.target.value))}>
              <div className="d-inline-block mr-1">
                <Radio label='Mandatory' checked={item.required === 1} name={`required-${item.menu_option_id}`} value={1} />
              </div>
              <div className="d-inline-block mr-1">
                <Radio label='Optional' name={`required-${item.menu_option_id}`} checked={item.required === 0} value={0} />
              </div>
            </div>
          </Col>
        </React.Fragment>
      )
    } else {
      return (
        <Row>
          <Col xs={6}>
            <FormGroup className='custom-number-input-style'>
              <Label for='min'>Min Selections:</Label>
              <NumericInput
                id='min'
                min={0}
                max={100}
                value={item.min}
                mobile
                style={defaultStyle}
                onChange={(e) => updateItem('min', e)}
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup className='custom-number-input-style'>
              <Label for='max'>Max Selections:</Label>
              <NumericInput
                id='max'
                min={0}
                max={100}
                value={item.max}
                mobile
                style={defaultStyle}
                onChange={(e) => updateItem('max', e)}
              />
            </FormGroup>
          </Col>
        </Row>
      )
    }
  }

  return (
    <div>

      <FormGroup className='row'>
        {item.options_layout == 1 &&
          <>
            <Col xs={6}>
              <Label>Options</Label>
            </Col>
            <Col xs={3}>
              <Label>Price</Label>
            </Col>
            <Col xs={3}>
              <Label>Actions</Label>
            </Col>
          </>
        }
        {renderMenuOptionValues(item.MenuOptionValues, item.Option.options_layout, parseInt(item.default_value_id))}
      </FormGroup>
      <div className='row mb-1'>
        {optionAddToggle ?
          <React.Fragment>
            <Col xs={6}>
              <Select
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected ? 'var(--primary)' : state.isFocused ? '#7367f099' : '#262c49',
                  }),
                }}
                className="React"
                classNamePrefix="select"
                name="color"
                options={formatOptionForDropdown(item.Option.OptionValues)}
                onChange={(e) => addSelectedItem(e)}
                onBlur={toggleOptionAdd}
                autoFocus={true}
                defaultMenuIsOpen
              />
            </Col>
          </React.Fragment> :
          <Col xs={12}>
            <Button outline className='font-weight-bold custom-padding disabled-2-border' color={'secondary'} size='md' onClick={toggleOptionAdd}>
              <Plus className="mx-50" size={14} /> <span className='ml-50 mr-50'>Add More Option</span>
            </Button>
          </Col>
        }
      </div>
      {renderMenuExtras(item)}
    </div>
  )
}

export default ItemModifierForm;