import React, { Component } from 'react';
import { connect } from 'react-redux';
import CONSTANTS from '../../constants';
import { getSmsPlans, subscribePlan, getSubscribedSmsPlans } from '../../redux/actions/sms';
import PricingCards from '../smsplans/PricingCards';
import PricingHeader from '../smsplans/PricingHeader';
import '../../assets/scss/pages/page-pricing.scss';

class SmsPlans extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectLocation: false,
      location: null,
      loading: true,
    };
  }

  async componentDidMount() {
    await this.props.getSubscribedSmsPlans();
    await this.props.getSmsPlans();
  }

  subscribe(loc_id, plan_id) {
    this.props.history.push(`${CONSTANTS.BASE_URL}locations/sms/plans/payment/${plan_id}`);
  }

  render() {
    const { props: {
      smsplans,
      smsplan
    } } = this;
    const { state: {
      loading
    } } = this;
    //if( loading ) return <SpinnerComponent />;
    return (
      <div id='pricing-table'>

        {smsplans !== null ? (
          <>
            <PricingHeader data={smsplans} wallet={smsplans.staff} />
            <PricingCards data={smsplans.subscriptions} subscribe={this.subscribe.bind(this)} subscribedPlan={smsplan} />
          </>
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { sms: {
    allSmsPlans: {
      data: smsplans, loading: plansLoading
    },
    smsPlan: {
      data: smsplan
    }
  } } = state;

  return { smsplans, smsplan, loading: plansLoading };
}

export default connect(mapStateToProps, { getSmsPlans, subscribePlan, getSubscribedSmsPlans })(SmsPlans);
