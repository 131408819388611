import React from 'react';
import { AvForm, AvInput, AvGroup, AvFeedback, } from 'availity-reactstrap-validation';
import {
  Label,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button
} from 'reactstrap';
import LocationService from '../../services/LocationService';
import CONSTANTS from '../../constants';
import { connect } from 'react-redux';
import SpinnerComponent from '../@vuexy/spinner/Fallback-spinner';
import SmsService from '../../services/SmsService';
import ConfirmationModal from '../shared/forms/ConfirmationModal';
import { getSmsPlans, subscribePlan, getSubscribedSmsPlans, saveSmsPlans } from '../../redux/actions/sms';
import Toggle from 'react-toggle';
import { getCurrencies } from '../../redux/actions/configurations';
import ViewHelper from '../../helpers/ViewHelper';
import { reduxForm,Field } from 'redux-form';
import ValidationHelper from '../../helpers/ValidationHelper';
import Select from '../shared/fields/Select';


class SmsPlanForm extends React.Component {
  state = {
    activeStep: 0,
    isOpenSelect: false,
    plan: {
      id: '',
      name: '',
      description: '',
      quantity: 0,
      plan_amount: '',
      currency: 0,
      is_popular: 0
    },
    location: {
      loc_id: 0,
      name: 'All Venues',
      Currency: {
        currency_code: 'PKR'
      },
      decimal_places: 2,
    },
    loading: false,
    saving: false,
    errorSaving: false,
    errorWallet: false,
    users: [],
    isOpen: false,
    confirmationDialog: {
      show: false,
      acceptCallback: () => { },
      rejectCallback: () => { },
      title: 'Wallet',
      header: 'Wallet'
    }
  }

  async getPlanFromId(id) {
    let plan = await SmsService.getSmsPlan(id);
    return plan;
  }

  async componentDidMount() {
    let editMode = false;
    let planById = this.state.plan;
    this.props.getCurrencies();
    await this.getSmsPlans();
    if (window.location.pathname.indexOf('edit') !== -1) {
      const parts = window.location.pathname.split('/');
      planById = await this.getPlanFromId(parts[parts.length - 1]);
      planById.id = parts[parts.length - 1];
      const { plan } = this.state;
      plan.id = planById.id;
      plan.name = planById.SmsPlan.name;
      plan.description = planById.SmsPlan.description;
      plan.quantity = planById.quantity;
      plan.plan_amount = planById.plan_amount;
      plan.is_popular = planById.is_popular;
      plan.currency = planById.currency;
      await this.setState({ plan });
      await this.setState({ editMode });
    }
    this.setState({ loading: false });
  }

  async getSmsPlans() {
    return this.props.getSmsPlans();
  }

  async getStats(loc_id) {
    await this.props.getCustomersStats(loc_id, this.state.filters);
  }

  onBlur() {
    this.filterUpdated();
  }

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  async onSelect(location) {
    this.setState({ loading: true });
    LocationService.setLocation(location, CONSTANTS.CACHE_KEY_PREFIX_ANALYTICS);
    await this.refreshData(location.loc_id);
    this.setState({ selectLocation: false, loading: false });
  }

  toggleItemsModal() {
    this.setState({ isMenuItemModalOpen: !this.state.isMenuItemModalOpen });
  }

  async updateValue(value, type) {
    if (!isNaN(value) && value)
      value = parseInt(value);
    let plan = JSON.parse(JSON.stringify(this.state.plan));
    let obj = plan;
    let parts = type.split('.');
    const n = parts.length - 1;
    for (let i = 0; i < n; i++)
      obj = obj[parts[i]];
    obj[parts[n]] = value;
    await this.setState({ plan });
  }

  async setOpen(open) {
    await this.setState({ isOpen: open });
  }

  async setOpenSelect() {
    await this.setState({ isOpenSelect: !this.state.isOpenSelect });
  }

  async savePlan() {
    const {
      state: {
        plan
      }
    } = this;
    await this.props.saveSmsPlans(plan);
    await this.props.history.replace('/locations/sms/admin/plans');
  }

  handleStepChange(activeStep) {
    this.setState({ activeStep });
  }

  showConfirmationDialog(accept, reject, title, header) {
    const defaultState = {
      show: false,
      acceptCallback: () => { },
      rejectCallback: () => { },
      title: '',
      header: ''
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        accept();
        this.setState({ confirmationDialog: defaultState });
      },
      rejectCallback: () => {
        this.setState({ confirmationDialog: defaultState });
        if (reject)
          reject();
      },
      title: title,
      header: header
    }
    this.setState({ confirmationDialog });
  }

  onConditionChange = (selectedOption) => {
    this.updateValue(selectedOption.value, 'plan_time',);
  }

  render() {
    const { props: {
      loading,
      currencies,
    } } = this;

    const { state: {
      plan,
      errorSaving,
      confirmationDialog,
    } } = this;
    const { name, description, plan_amount, quantity, is_popular } = plan;
    let currencyOptions = currencies.data ? ViewHelper.toOptions(currencies.data, { idColumn: 'currency_id', valueColumns: ['currency_code', 'currency_name'], joiner: ' - ' }, { allowEmpty: true }):'';

    if (loading) return <SpinnerComponent />;
    return (

      <div className='location'>
        {currencies.data &&
          <Card>
            <CardHeader className='flex-column align-items-start'>
              {errorSaving ? <span className='danger'>Unable to save sms plan.</span> : null}
            </CardHeader>
            <CardBody>
              <AvForm >
                <Row>
                  <Col md='12' sm='12'>
                    <AvGroup>
                      <Label>Name</Label>
                      <AvInput name='name' type='text' value={name} required onChange={(e) => this.updateValue(e.target.value, 'name')} />
                      <AvFeedback>Please enter plan name.</AvFeedback>
                    </AvGroup>
                  </Col>
                  <Col md='12' sm='12'>
                    <AvGroup>
                      <Label> Description </Label>
                      <AvInput name='description' required type='text' value={description} onChange={e => this.updateValue(e.target.value, 'description')} />
                      <AvFeedback>Please enter plan description.</AvFeedback>
                    </AvGroup>
                  </Col>
                  <Col md='12' sm='12'>
                    <AvGroup>
                      <Field name='currency' component={Select} label='Currency' validate={ValidationHelper.required} options={currencyOptions}  onChange={e => this.updateValue(e.target.value, 'currency')}/>
                      <AvFeedback>Please enter plan description.</AvFeedback>
                    </AvGroup>
                  </Col>
                  <Col className='col-6'>
                    <AvGroup>
                      <Label> Units </Label>
                      <AvInput name='quantity' required type='number' min='1' value={quantity} onChange={e => this.updateValue(e.target.value, 'quantity')} />
                      <AvFeedback>Please enter quantity of sms.</AvFeedback>
                    </AvGroup>
                  </Col>
                  <Col className='col-6'>
                    <AvGroup>
                      <Label> Amount </Label>
                      <AvInput name='plan_amount' required type='number' min='1' value={plan_amount} onChange={e => this.updateValue(e.target.value, 'plan_amount')} />
                      <AvFeedback>Please enter amount of sms plan.</AvFeedback>
                    </AvGroup>
                  </Col>

                  <Col md='12' sm='12'>
                    <Row className='mx-50 my-1'>
                      <h5>Is Popular</h5>
                      <Toggle
                        name={'is_popular'}
                        defaultChecked={is_popular}
                        className='switch-danger ml-2 align-self-baseline'
                        onChange={this.updateValue.bind(this, is_popular === 0 ? 1 : 0, 'is_popular')}
                      />
                    </Row>
                  </Col>
                </Row>
                <Button color='primary' size='md'  onClick={() => this.savePlan()}>
                  <span className='ml-1 mr-1 d-md-inline '>Submit</span>
                </Button>
              </AvForm>
            </CardBody>
          </Card>}
        <ConfirmationModal header={confirmationDialog.header} title={confirmationDialog.title} isOpen={confirmationDialog.show} reject={confirmationDialog.rejectCallback.bind(this)} rejectTitle={'OK'} />
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { sms: {
    allSmsPlans: {
      data: smsplans, loading: plansLoading
    },
    smsPlan: {
      data: smsplan
    }
  }, configurations: {
    currencies: currencies
  } } = state;

  return { smsplans, smsplan, currencies, loading: plansLoading };
}

export default connect(mapStateToProps, { getCurrencies, getSmsPlans, subscribePlan, getSubscribedSmsPlans, saveSmsPlans })(reduxForm({ form: 'smsplanform' })(SmsPlanForm));
