import React from "react";
import DataTable from "react-data-table-component";
import {Button} from "reactstrap";
import {Edit2, Pause, Play, Send, ShieldOff, Trash} from "react-feather";

const ListSmsPlans = (props) => {

  const editItem = (id) => {
    props.editSmsPlan(id);
  }

	const delItem = (id) => {
    props.delSmsPlan(id);
  }

  const columns = [
    {
      name: 'Name',
      selector: 'SmsPlan.name',
    }, {
      name: 'Description',
      selector: 'SmsPlan.description',
    }, {
      name: 'Duration',
      selector: 'plan_duration',
    }, {
      name: 'Time',
      selector: 'plan_time',
    } , {
      name: 'Quantity',
      selector: 'quantity',
    }, {
      name: 'Amount',
      selector: 'plan_amount'
    }, {
      name: 'Actions',
      selector: 'id',
      compact: true,
      wrap: true,
      format: ({id}) => {
        return (
          <>
						<Button outline className='btn-icon ml-25' color='danger' onClick={() => delItem(id)}><Trash className='ml-0 p-0' size={20} /></Button>
            <Button outline className='btn-icon ml-25' color='primary' onClick={() => editItem(id)}><Edit2 color='var(--primary)' className='ml-0 p-0' size={20} /></Button>
          </>
        );
      }
    }
  ];

  const renderSmsPlans = (plans) => {
    if(!plans || plans.length === 0)
      return null;

    return <DataTable
      columns={columns}
      data={plans}
      noHeader
    />;
  }
  return renderSmsPlans(props.plans);

}

export default ListSmsPlans;