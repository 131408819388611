import CONSTANTS from '../constants';
import LoginService from './LoginService';
import { unserialize, serialize } from "php-serialize";
import { customFetch as fetch } from '../helpers/FetchHelper';

export default {
  getLocation,
  setLocation,
  getLocations,
  getLocationFromServer,
  updateLocation,
  createLocation,
  getTotalLocationsCount,
  getOutletsForLocation,
  updateLocationFormData,
  enableOutlets,
  checkSlug,
  updateLocationTimings,
  getAllLocations,
	updateLocationFormData,
	enableOutlets,
	checkSlug,
	getDeliveryProcessorInfo,
	getDeliveriesForLocation,
};

async function getDeliveriesForLocation(locationId, type = null) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.DELIVERIES(locationId)}${type !== null ? `?type=${type}` : ''}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if (response) return response;
  throw new Error('Could not fetch outlets');
}

async function checkSlug(slug) {
  const { token } = LoginService.getToken();
  const url = `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.CHECK_SLUG}?name=${slug}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  if (response.status !== 200)
    return false;
  return true;
}

function getLocation(key = `${CONSTANTS.CACHE_KEY_PREFIX}_location`) {
  let location = localStorage.getItem(key);
  if (location) return JSON.parse(location);
  return null;
}

function setLocation(location, key = `${CONSTANTS.CACHE_KEY_PREFIX}_location`) {
  localStorage.setItem(key, JSON.stringify(location));
}

function setTotalLocationsCount(count) {
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_location_count`, count);
}

function getTotalLocationsCount() {
  let count = localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_location_count`);
  if (count === null)
    return 5;
  return count;
}

async function getLocations(searchTerm, showOutlets = null) {
  const { token } = LoginService.getToken();
  const url = showOutlets === null ? `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.DEFAULT}?name=${searchTerm}` : `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.DEFAULT}?name=${searchTerm}&is_outlet=${showOutlets}`;
  let response = await fetch(url, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if (searchTerm === '') {
    setTotalLocationsCount(response.length);
  }
  if (response) return response;

  throw new Error('Could not fetch locations');
}

async function getAllLocations(showOutlets = null) {
  const { token } = LoginService.getToken();
  const url = showOutlets === null ? `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.ALL_LOCATIONS}` : `${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.ALL_LOCATIONS}?is_outlet=${showOutlets}`;
  let response = await fetch(url, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  
  if (response) return response;

  throw new Error('Could not fetch locations');
}

async function getOutletsForLocation(locationId) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.OUTLETS(locationId)}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if (response) return response;
  throw new Error('Could not fetch outlets');
}

async function getLocationFromServer(locationId) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.DEFAULT}/${locationId}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  
  response = await response.json();
  if (response) {
    response.options = response.options ? unserialize(response.options) : response.options;
    // if (response.SpecialEvents.length > 0) {
    //   for (let i = 0; i < response.SpecialEvents.length; i++) {
    //     response.SpecialEvents[i].event_timings = response.SpecialEvents[i].event_timings ? await JSON.parse(response.SpecialEvents[i].event_timings) : response.SpecialEvents[i].event_timings;
    //     response.SpecialEvents[i].isServer = 1
    //   }
    // }
    if (response.is_outlet && response.OutletStockStatuses.length > 0) {
      response.OutletStocks = _convertArrayToMap(response.OutletStockStatuses);
    }
    return response;
  }

  throw new Error('Could not fetch location');
}

async function getDeliveryProcessorInfo(locationId) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.GET_DELIVERY_PROCESSORS(locationId)}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if (response) {
    return response;
  }
  throw new Error('Could not fetch location');
}

async function updateLocation(locationData) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.DEFAULT}/${locationData.loc_id}`, {
    body: JSON.stringify(locationData),
    method: 'PATCH',
    headers: { 'Accept': CONSTANTS.REQUEST_HEADERS.Accept, 'X-ACCESS-TOKEN': token, 'Content-Type': 'application/json' }
  });
  response = await response.json();
  if (response) {
    return response;
  }
  throw new Error('Could not save location');
}

async function updateLocationTimings(loc_id, options) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.UPDATE_TIMINGS(loc_id)}`, {
    body: options,
    method: 'PATCH',
    headers: { 'Accept': CONSTANTS.REQUEST_HEADERS.Accept, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if (response) {
    response.options = response.options ? unserialize(response.options) : response.options;
    if (response.SpecialEvents.length > 0) {
      for (let i = 0; i < response.SpecialEvents.length; i++) {
        response.SpecialEvents[i].event_timings = response.SpecialEvents[i].event_timings ? await JSON.parse(response.SpecialEvents[i].event_timings) : response.SpecialEvents[i].event_timings;
        response.SpecialEvents[i].isServer = 1
      }
    }
    if (response.is_outlet && response.OutletStockStatuses.length > 0) {
      response.OutletStocks = _convertArrayToMap(response.OutletStockStatuses);
    }
    return response;
  }
  throw new Error('Could not update timings');
}

async function updateLocationFormData(locationData) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.DEFAULT}/${locationData.loc_id}`, {
    body: locationData,
    method: 'PATCH',
    headers: { 'Accept': CONSTANTS.REQUEST_HEADERS.Accept, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if (response) {
    response.options = response.options ? unserialize(response.options) : response.options;
    return response;
  }
  throw new Error('Could not save location');
}

async function enableOutlets(body) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.ENABLE_OUTLETS}`, {
    body: JSON.stringify(body),
    method: 'POST',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if (response) return response;
  throw new Error('Could not enable outlets for location');
}

async function createLocation(locationData) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.LOCATIONS.DEFAULT}`, {
    body: locationData,
    method: 'POST',
    headers: { 'Accept': CONSTANTS.REQUEST_HEADERS.Accept, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if (response) {
    response.options = response.options ? unserialize(response.options) : response.options;
    return response;
  }
  throw new Error('Could not create new location');
}

function _convertArrayToMap(outletStock) {
  const stock = {};
  for (let i = 0; i < outletStock.length; i++) {
    const st = outletStock[i];
    stock[st.menu_id] = st;
  }
  return stock;
}