import CONSTANTS from '../constants';
import { customFetch as fetch } from '../helpers/FetchHelper';
import _, { find } from 'lodash';

export default {
  getToken,
  setLoginData,
  removeLoginData,
  login,
  setSmsWallet,
  getSmsWallet,
  getRole,
  getLoggedUser,
  setRoleAndEmail,
};

function setLoginData(tokenData, role) {
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_token`, tokenData.token);
  let roles = [];
  role.map((item) => {
    roles.push(item.StaffGroup.staff_group_name);
  })
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_role`, roles);
  localStorage.setItem(`DashColor`, 'dark');
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_login_user`, tokenData.staff.staff_email);
  this.setSmsWallet(tokenData.sms_wallet);
}

function setRoleAndEmail(role, email) {
  const findValue = (arr, value) => {
    return _.find(arr, (elem) => {
      return elem.StaffGroup.staff_group_id === value;
    });
  }
  let roles = [];
  let definedRoles = [11, 13, 16];
  for (let i = 0; i < definedRoles.length; i++) {
    if(findValue(role,definedRoles[i])){
      let res = findValue(role,definedRoles[i]);
      roles.push(res.StaffGroup.staff_group_name);
      break;
    }
  }
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_role`, roles);
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_login_user`, email);
}

function setSmsWallet(wallet) {
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_sms_wallet`, wallet);
}

function removeLoginData() {
  localStorage.removeItem(`${CONSTANTS.CACHE_KEY_PREFIX}_token`);
  localStorage.removeItem(`${CONSTANTS.CACHE_KEY_PREFIX}_role`);
  localStorage.removeItem(`${CONSTANTS.CACHE_KEY_PREFIX}_location`);
  localStorage.removeItem(`${CONSTANTS.CACHE_KEY_PREFIX}_location_count`);
}

function getToken() {
  let token = localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_token`);
  if (token) return { token };
  return null;
}

function getRole() {
  let role = localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_role`);
  if (role) return { role };
  return null;
}

function getSmsWallet() {
  let wallet = localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_sms_wallet`);
  if (wallet) return { wallet };
  return null;
}

async function login(email, password) {
  let data = { email, password };
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.LOGIN}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: CONSTANTS.REQUEST_HEADERS
  });
  response = await response.json();
  if (response && response.token) return { token: response.token, sms_wallet: response.sms_wallet, role: response.role, staff: response.staff };
  throw new Error(`Could not login: ${response}`);
}

async function getLoggedUser() {
  let { token } = this.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}/session_user`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  this.setRoleAndEmail(response.role, response.staff.staff_email);
}
