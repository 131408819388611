import React, { Component } from 'react';
import { connect } from 'react-redux';
import SpinnerComponent from '../../@vuexy/spinner/Fallback-spinner';
import { getAllSubscriptions } from '../../../redux/actions/sms';
import '../../../assets/scss/pages/page-pricing.scss';
import ListAllSubscriptions from './ListAllSubscriptions';

class AllSmsSubscriptions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectLocation: false,
      location: null,
    };
  }

  async componentDidMount() {
    await this.props.getAllSubscriptions();
  }

  render() {
    const { props: {
      subscriptions,
      loading
    } } = this;
    if (loading) return <SpinnerComponent />;
    return (
      <>
        <div id='pricing-table'>
          {!loading && subscriptions ? <ListAllSubscriptions subscriptions={subscriptions} /> : null}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { sms: {
    allSubscriptions: {
      data: subscriptions, loading: subscriptionsLoading
    }
  } } = state;

  return { subscriptions, loading: subscriptionsLoading };
}

export default connect(mapStateToProps, { getAllSubscriptions })(AllSmsSubscriptions);