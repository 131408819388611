import React, { Component } from 'react'
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  FormGroup,
  Col,
  Input,
  Button
} from 'reactstrap';

class PandagoIntegrationForm extends Component {
  render() {
    let { pandagoProcessor, onChange, onSave } = this.props;
    return(
      <Card className='card-action'>
        <CardHeader>
          <CardTitle>PandaGo</CardTitle>
        </CardHeader>
        <CardBody>
          <FormGroup row>
            <Col md='4'><span>Client ID</span></Col>
            <Col md='8'>
              <Input
                name='client_id'
                placeholder='Client ID'
                value={pandagoProcessor.client_id}
                onChange={onChange}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col md='4'><span>Key ID</span></Col>
            <Col md='8'>
              <Input
                name='key_id'
                placeholder='Key ID'
                value={pandagoProcessor.key_id}
                onChange={onChange}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col md='4'><span>Scope</span></Col>
            <Col md='8'>
              <Input
                name='scope'
                placeholder='Scope'
                value={pandagoProcessor.scope}
                onChange={onChange}
              />
            </Col>
          </FormGroup>
					<FormGroup row>
            <Col md='4'><span>Delivery Radius (In KMs)</span></Col>
            <Col md='8'>
              <Input
                name='delivery_radius'
                placeholder='delivery_radius'
                value={pandagoProcessor.delivery_radius}
                onChange={onChange}
              />
            </Col>
          </FormGroup>
          <Button color='primary' type='submit' className='mr-1 mb-1' onClick={onSave}>Save</Button>
        </CardBody>
      </Card>
    );
  }
}
export default PandagoIntegrationForm;
