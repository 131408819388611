import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Card, CardBody, Button, Input, CardHeader } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import CONSTANTS from '../../constants';
import {
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement,
} from '@stripe/react-stripe-js';
import CardSection from './CardSection';
import LocationService from '../../services/LocationService';
import { subscribePlan, getCards } from '../../redux/actions/sms';
import SweetAlert from 'react-bootstrap-sweetalert';

class SmsCheckoutForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			activeTab: '1',
			active: '1',
			selectLocation: false,
			location: null,
			loading: false,
			successAlert: false,
			errorAlert: false,
			errorMsg: '',
			selectedCard: ''
		};
		this.getToken = this.getToken.bind(this);
	}

	async componentDidMount() {
		await this.props.getCards();
	}

	async updateValue(value, type) {
		await this.setState({ [type]: value });
	}

	async getToken() {
		this.setState({ loading: true })
		const { stripe, elements } = this.props;
		if (stripe && elements) {
			const card = elements.getElement(
				CardNumberElement,
				CardExpiryElement,
				CardCvcElement
			);
			const { error, paymentMethod } = await stripe.createPaymentMethod({
				type: 'card',
				card: card,
			});
			if (!error) {
				this.processPayment(paymentMethod);
			} else {
				await this.setState({ loading: false, errorMsg: error.message })
				this.handleAlert('errorAlert', true);
			}
		}
	}

	async processPayment(paymentMethod) {
		try {
			const { id } = paymentMethod;
			await this.props.subscribePlan(this.props.plan_id, id);
			await this.setState({ loading: false });
			this.handleAlert('successAlert', true);
		} catch (error) {
			await this.setState({ loading: false, errorMsg: 'Payment Failed.' })
			this.handleAlert('errorAlert', true);
		}
	}

	goBack() {
		this.props.history.push(`${CONSTANTS.BASE_URL}locations/sms/plans`);
	}
	handleAlert = (state, value) => {
		this.setState({ [state]: value })
	}

	handleSetCheck = async (state, value) => {
		await this.setState({ [state]: value })
	}

	async selectedCardPayment() {
		this.setState({ loading: true })
		if (this.state.selectedCard) {
			this.processPayment({ id: this.state.selectedCard });
		} else {
			await this.setState({ loading: false })
			await this.setState({ errorMsg: 'Please select one card atleast for your payment.' });
			this.handleAlert('errorAlert', true);
		}
	}


	render() {
		const { state: {
			loading
		} } = this;

		const { props: {
			cards
		} } = this;

		return (
			<>
				<SweetAlert error title='Error'
					show={this.state.errorAlert}
					onConfirm={() => this.handleAlert('errorAlert', false)}
				>
					<p className='sweet-alert-text'>{this.state.errorMsg}</p>
				</SweetAlert>
				<SweetAlert success title='Success'
					show={this.state.successAlert}
					onConfirm={() => this.goBack()}
				>
					<p className='sweet-alert-text'>Payment successfully completed.</p>
				</SweetAlert>
				<Card>
					<CardHeader>Via existing cards payment</CardHeader>
					<CardBody>
						{
							cards && cards.map((row) => {
								let isChecked = this.state.selectedCard === row.id ? true : false;
								return (
									<Card>
										<CardBody>
											<Input id={row.id} type='checkbox' name='checkbox' checked={isChecked} onChange={this.handleSetCheck.bind(this, 'selectedCard', row.id)} /><h2 className='f1'>**** **** **** {row.card.last4}</h2>
										</CardBody>
									</Card>
								);
							})
						}
						{cards &&
							<Button className='full-width' onClick={() => this.selectedCardPayment()}>
								Submit
							</Button>
						}
						{!cards &&
							<h1>No Cards Found.</h1>
						}
					</CardBody>
				</Card>
				{/* {loading && <SpinnerComponent />} */}
				<Card>
					<CardHeader>Via new card payment</CardHeader>
					<CardBody>
						<AvForm onSubmit={this.getToken}>
							<CardSection />
							<Button className='full-width' >
								Submit
							</Button>
						</AvForm>
					</CardBody>
				</Card>
			</>
		);
	}
}
function mapStateToProps(state) {
	const { sms: {
		allSmsPlans: {
			data: smsplans, loading: plansLoading
		},
		smsPlan: {
			data: smsplan
		},
		allCards: {
			data: cards
		}
	} } = state;

	return { smsplans, smsplan, cards, loading: plansLoading };
}

export default connect(mapStateToProps, { subscribePlan, getCards })(withRouter(SmsCheckoutForm));
