import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import customizer from './customizer';
import locations from './locations/';
import orders from './orders/';
import configurations from './configurations';
import analytics from './analytics';
import users from './users';
import categories from './categories';
import options from './options';
import promotions from './promotions';
import campaigns from './campaigns';
import sms from './sms';
import feedback from './feedback';
import currency from './currency';
import reviews from './reviews';
import tables from './tables';
import staff from './staff';
import roles from './roles';
import menu from './menus';
import riders from './riders';
import sessionActivity from './session-activity';
import notificationReducer from './notifications'

const rootReducer = combineReducers({
  customizer,
  orders,
  configurations,
  locations,
  users,
  analytics,
  categories,
  options,
  promotions,
  campaigns,
  sms,
  feedback,
  currency,
  reviews,
  tables,
  staff,
  roles,
  form,
  menu,
  riders,
  sessionActivity,
  notificationReducer,
});

export default rootReducer;
