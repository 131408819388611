import React, {useEffect, useState} from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
	Input,
	Label,
	FormGroup,
} from "reactstrap";
import LocationService from "../../services/LocationService";

const MasterOutletModal = (props) => {

	const [name, setName] = useState('');
	const [slug, setSlug] = useState('');
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

  let {
    isOpen,
		toggleModal: reject,
  } = props;

  useEffect(() => {
    isOpen = props.isOpen;
  }, [props]);

	const onSubmit = async () => {
		if(name === '') {
			setError('Please enter name');
			return;
		}

		if(slug === '') {
			setError('Please enter slug');
			return;
		}
		
		setIsLoading(true);
		const isSlugAvailable = await LocationService.checkSlug(slug);
		if(!isSlugAvailable) {
			setIsLoading(false);
			setError('Sorry, this slug is taken. Please enter another slug');
			return;
		}
		props.enableOutlets(name, slug);
	}

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={reject}
        className="modal-dialog-centered"
      >
        <ModalHeader>
          Master Venue Details
        </ModalHeader>
        <ModalBody>
					<FormGroup className='form-label-group'>
						<Input
							name={'name'}
							placeholder={'Name'}
							type='text'
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
						<Label for="name">Name</Label>
					</FormGroup>
					<FormGroup className='form-label-group'>
						<Input
							name={'slug'}
							placeholder={'Slug'}
							type='text'
							value={slug}
							onChange={(e) => setSlug(e.target.value)}
						/>
						<Label for="slug">Slug</Label>
					</FormGroup>
					<span className='danger'>{error ? error : ''}</span>
        </ModalBody>
        <ModalFooter>
          <Button disabled={isLoading} color="dark" onClick={ reject }>
            Cancel
          </Button>{" "}
          <Button disabled={isLoading} color="danger" onClick={ onSubmit }>
            Save
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}
export default MasterOutletModal;