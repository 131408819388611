import React, { Component } from 'react';
import { connect } from 'react-redux';
import SpinnerComponent from '../@vuexy/spinner/Fallback-spinner';
import ErrorHandler from '../shared/ErrorHandler';
import { deleteRiderPayment, getAllRidersPayments } from '../../redux/actions/riders';
import '../../assets/scss/pages/page-pricing.scss';
import ListRidersPayments from './ListRiderPayments';
import { Button } from 'reactstrap';
import ConfirmationModal from '../shared/forms/ConfirmationModal';
import LocationService from '../../services/LocationService';
import SelectLocation from "../shared/SelectLocation";
import LocationSelectHeader from '../shared/LocationSelectHeader';

class RidersPayments extends Component {

	constructor(props) {
		super(props);
		this.state = {
			riders: '',
			location: null,
			selectLocation: false,
			payments: [],
			errorDialog: {
				show: false,
				rejectCallback: () => { },
				title: 'App Users',
				header: 'App Users'
			},
			confirmationDialog: {
				show: false,
				acceptCallback: () => { },
				rejectCallback: () => { },
				title: 'App Users',
				header: 'App Users'
			}
		}
	}

	async componentDidMount() {
		this.setState({ loading: true });
		try {
			const location = LocationService.getLocation();
			if (location) {
				await this.refreshData(location.loc_id);
				this.setState({ loading: false });
			} else {
				this.setState({ loading: false, selectLocation: true })
			}
		} catch (e) {
			console.log(e);
			this.setState({ loading: false, error: true });
		}
	}
	async refreshData(loc_id, updateLocation = true) {
		let loc = this.state.location;
		if (updateLocation)
			loc = await LocationService.getLocationFromServer(loc_id);
		await this.props.getAllRidersPayments(loc.loc_id);
		const location = {
			loc_id: loc.loc_id,
			location_status: loc.location_status,
			name: loc.name
		};
		this.setState({ location });
	}

	handleChange = (state) => {
		let payments = [];
		for (let i = 0; i < state.selectedRows.length; i++) {
			payments.push(state.selectedRows[i].id);
		}
		this.setState({ payments: payments });
	};


	showConfirmationDialog(accept, reject, title, header) {
		const defaultState = {
			show: false,
			acceptCallback: () => { },
			rejectCallback: () => { },
			title: '',
			header: ''
		};

		const confirmationDialog = {
			show: true,
			acceptCallback: async () => {
				if (accept)
					accept();
				this.setState({ confirmationDialog: defaultState });

			},
			rejectCallback: () => {
				this.setState({ confirmationDialog: defaultState });
				if (reject)
					reject();
			},
			title: title,
			header: header
		}
		this.setState({ confirmationDialog });
	}

	showErrorDialog(reject, title, header) {
		const defaultState = {
			show: false,
			rejectCallback: () => { },
			title: '',
			header: ''
		};

		const errorDialog = {
			show: true,
			rejectCallback: () => {
				this.setState({ errorDialog: defaultState });
				if (reject)
					reject();
			},
			title: title,
			header: header
		}
		this.setState({ errorDialog });
	}


	toggleLocation() {
		this.setState({ selectLocation: !this.state.selectLocation });
	}

	async onSelect(location) {
		this.setState({ loading: true });
		LocationService.setLocation(location);
		await this.refreshData(location.loc_id);
		this.setState({ selectLocation: false, loading: false });
	}

	changeLocation() {
		this.setState({ selectLocation: true })
	}

	async updateStatus() {
		if (this.state.payments.length > 0) {
			this.showConfirmationDialog(async () => {
				let deletePayments = {
					payments: this.state.payments
				}
				await this.props.deleteRiderPayment(this.state.location.loc_id, deletePayments)
			}, null, 'Are you sure you want to delete of these Payments?', 'Permission');
		} else {
			this.showErrorDialog(null, 'Please select atleast one payment for delete.', 'Warning');
		}
	}

	addNewPayment() {
		this.props.history.push('payments/new');
	}

	render() {
		const { props: {
			payments,
		} } = this;
		const { state: {
			confirmationDialog,
			errorDialog,
			selectLocation,
			location,
			loading
		} } = this;

		const locationName = location ? location.name : '';

		if (selectLocation)
			return <SelectLocation onBack={this.toggleLocation.bind(this)} onSelect={this.onSelect.bind(this)} />;

		if (loading) return <SpinnerComponent />;
		return (
			<>
				<div className='display-block mb-1'>
					<LocationSelectHeader text={locationName} onIconClick={this.changeLocation.bind(this)} />
				</div>
				<div id='pricing-table'>
					{/* <Button className='mb-1' color='primary' size='md' onClick={() => this.addNewPayment()}>
						<span className='ml-1 mr-1 d-md-inline'>Add new payment</span>
					</Button> */}
					<Button className='ml-1 mb-1' color='danger' size='md' onClick={() => this.updateStatus()}>
						<span className='ml-1 mr-1 d-md-inline'> Delete </span>
					</Button>
					{!loading && payments ? <ListRidersPayments payments={payments} handleChange={this.handleChange.bind(this)} /> : null}
				</div>
				<ConfirmationModal header={confirmationDialog.header} title={confirmationDialog.title} isOpen={confirmationDialog.show} reject={confirmationDialog.rejectCallback.bind(this)} rejectTitle={'Cancel'} accept={confirmationDialog.acceptCallback.bind(this)} />
				<ConfirmationModal header={errorDialog.header} title={errorDialog.title} isOpen={errorDialog.show} reject={errorDialog.rejectCallback.bind(this)} rejectTitle={'OK'} />
			</>
		);
		return <ErrorHandler />;
	}
}

function mapStateToProps(state) {
	console.log(state);
	const { riders: {
		allpayments: {
			data: payments, loading: loading
		}
	} } = state;

	return { payments, loading: loading };
}

export default connect(mapStateToProps, { deleteRiderPayment, getAllRidersPayments })(RidersPayments);