import CONSTANTS from '../../../constants'
import LocationService from '../../../services/LocationService';
import OrderService from '../../../services/OrderService';

export function getLocation(locationId) {
  return async dispatch => {
    try {
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location: null, loading: true });
      let location = await LocationService.getLocationFromServer(locationId);
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location: null, loading: false });
    }
  };
}

export function getAllLocations() {
  return async dispatch => {
    try {
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location: null, loading: true });
      let location = await LocationService.getAllLocations();
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location: null, loading: false });
    }
  };
}

export function getOutletsFor(locationId) {
  return async dispatch => {
    try {
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET_OUTLETS, outlets: null, loading: true });
      let outlets = await LocationService.getOutletsForLocation(locationId);
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET_OUTLETS, outlets, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location: null, loading: false });
    }
  };
}

export function getDeliveriesFor(locationId, type=null) {
  return async dispatch => {
    try {
      dispatch({type: CONSTANTS.ACTIONS.LOCATIONS.GET_DELIVERIES, deliveries: null, loading: true });
      let deliveries = await LocationService.getDeliveriesForLocation(locationId, type);
      dispatch({type: CONSTANTS.ACTIONS.LOCATIONS.GET_DELIVERIES, deliveries, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.LOCATIONS.GET_DELIVERIES, deliveries: null, loading: false });
    }
  };
}

export function updateLocation(locationData) {
  return async dispatch => {
    try {
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location: null, loading: true });
      let location = await LocationService.updateLocationFormData(locationData);
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location: null, loading: false });
    }
  };
}

export function updateTimings(loc_id, options) {
  return async dispatch => {
    try {
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location: null, loading: true });
      let location = await LocationService.updateLocationTimings(loc_id, options);
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location: null, loading: false });
    }
  };
}

export function createLocation(locationData) {
  return async dispatch => {
    try {
      let location = await LocationService.createLocation(locationData);
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location: location, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location: null, loading: false });
    }
  };
}

export function initializeNewLocation() {
  return async dispatch => {
    dispatch({ type: CONSTANTS.ACTIONS.LOCATIONS.GET, location: {}, loading: false });
  };
}

export function getDeliveryProcessorInfo(locationId, orderId) {
	return async dispatch => {
    try {
      dispatch({type: CONSTANTS.ACTIONS.LOCATIONS.GET_DELIVERY_PROCESSORS, processors: null, loading: true });
      let processors = await OrderService.getDeliveryProcessorInfo(locationId, orderId);
      dispatch({type: CONSTANTS.ACTIONS.LOCATIONS.GET_DELIVERY_PROCESSORS, processors, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.LOCATIONS.GET_DELIVERY_PROCESSORS, processors: null, loading: false });
    }
  };
}