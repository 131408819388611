import React, {Component} from "react";
import {connect} from "react-redux";
import ErrorHandler from "../shared/ErrorHandler";
import CONSTANTS from "../../constants";
import LocationService from "../../services/LocationService";
import { getSmsPlans,subscribePlan,getSubscribedSmsPlans,delSmsPlans } from "../../redux/actions/sms";
import {Plus} from "react-feather";
import {Button} from "reactstrap";
import ConfirmationModal from "../shared/forms/ConfirmationModal";
import ListSmsPlans from "./ListSmsPlans";


class AdminSmsPlans extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectLocation: false,
      location: null,
      loading: false,
      confirmationDialog: {
        show: false,
        acceptCallback: () => {},
        rejectCallback: () => {},
        title: '',
      },
    };
  }

  async componentDidMount() {
    const location = LocationService.getLocation(`${CONSTANTS.CACHE_KEY_PREFIX}_location`);
    if(location) {
      await this.props.getSubscribedSmsPlans(location.loc_id);
      await this.props.getSmsPlans();
    }
  }



  async refreshData(loc_id) {
    let location;
    if(loc_id !== 0 && ( !this.state.location || loc_id !== this.state.location.loc_id)) {
      await this.props.getLocation(loc_id);
      location = this.props.location;
    } else {
      location = this.state.location;
    }
    this.setState({ loading: false, location: JSON.parse(JSON.stringify(location)) });
  }


  onBlur() {
    if( this.state.location )
      this.setState({selectLocation: !this.state.selectLocation});
  }

  toggleLocation() {
    this.setState({selectLocation: !this.state.selectLocation});
  }

  async onSelect(location) {
    this.setState({loading: true});
    LocationService.setLocation(location, `${CONSTANTS.CACHE_KEY_PREFIX}_location`);
    await this.refreshData(location.loc_id);
    this.setState({selectLocation: false, loading: false});
  }

  async toggleSmsPlan(id, state) {
    let nextState = 'activate';
    if(state)
      nextState = 'deactivate';
    const title = `Are you sure you want to ${nextState} this promotion?`;
    this.showConfirmationDialog(async () => {
    const { state: {
      location: {
        loc_id
      }
    }} = this;
    await this.props.togglePromotion(loc_id, id);
    await this.refreshData(loc_id);
    }, null, title );
  }

  editSmsPlan(id) {
    this.props.history.push(`/locations/sms/admin/plans/edit/${id}`);
  }

  async delSmsPlan(id){
    await this.props.delSmsPlans(id);
    const location = LocationService.getLocation(`${CONSTANTS.CACHE_KEY_PREFIX}_location`);
    if(location) {
      await this.props.getSubscribedSmsPlans(location.loc_id);
      await this.props.getSmsPlans();
    }
  }

  showConfirmationDialog(accept, reject, title) {
    const defaultState = {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
      title: '',
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        accept();
        this.setState({confirmationDialog: defaultState});
      },
      rejectCallback: () => {
        this.setState({confirmationDialog: defaultState});
        if( reject )
          reject();
      },
      title: title,
    }
    this.setState({confirmationDialog});
  }

  render() {
    const { props: {
      smsplans,
    }} = this;
    const { state: {
      confirmationDialog,
      loading
    }} = this;
    //if( loading ) return <SpinnerComponent />;

    return (
      <div className='location'>
        <div className='display-flex flex-row flex-space-between mb-1 align-items-center float-right'>
          <Button outline className="font-weight-bold custom-padding disabled-2-border btn-sm-font" color={ "secondary" } size="md" onClick={() => this.props.history.push('/locations/sms/admin/plans/new')}>
            <Plus size={20} /> <span className="ml-1 mr-1 d-md-inline">Add New Package</span>
          </Button>
        </div>
        { !loading && smsplans ? <ListSmsPlans plans={smsplans} toggleSmsPlan={this.toggleSmsPlan.bind(this)} editSmsPlan={this.editSmsPlan.bind(this)} delSmsPlan={this.delSmsPlan.bind(this)} /> : null }
        <ConfirmationModal title={confirmationDialog.title} isOpen={confirmationDialog.show} accept={confirmationDialog.acceptCallback.bind(this)} reject={confirmationDialog.rejectCallback.bind(this)} />
      </div>
    );
    return <ErrorHandler />;
  }
}

function mapStateToProps(state) {
    const { sms: {
        allSmsPlans: {
          data: smsplans , loading: plansLoading
        },
        smsPlan: {
          data: smsplan 
        }
      }} = state;
    
     return { smsplans,smsplan, loading: plansLoading };
}

export default connect(mapStateToProps, { getSmsPlans, subscribePlan, getSubscribedSmsPlans, delSmsPlans })(AdminSmsPlans);