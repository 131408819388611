import CONSTANTS from '../../../constants';
import OrderService from '../../../services/OrderService';

export function getLiveOrders(params, initialize= false) {
  return async dispatch => {
    try {
      if(initialize) dispatch({type: CONSTANTS.ACTIONS.ORDERS.LIST,  orders: null, loading: true });
      let orders = await OrderService.getLiveOrders(params);
      dispatch({type: CONSTANTS.ACTIONS.ORDERS.LIST,  orders: orders, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.ORDERS.LIST,  orders: null, loading: false });
    }
  };
}

export function getOrder(locationId, orderId, initialize = true) {
  return async dispatch => {
    try {
      if(initialize) dispatch({type: CONSTANTS.ACTIONS.ORDERS.GET,  order: null, loading: true });
      let order = await OrderService.getOrder(locationId, orderId);
      dispatch({type: CONSTANTS.ACTIONS.ORDERS.GET,  order: order, loading: false });
    }
    catch (error) {
      console.log(error);
      alert('Could not get order');
      dispatch({type: CONSTANTS.ACTIONS.ORDERS.GET,  order: null, loading: false });
    }
  };
}

export function markAsSeen(order, statuses, initialize= false) {
  return async dispatch => {
    try {
      if(initialize) dispatch({type: CONSTANTS.ACTIONS.ORDERS.GET,  order: null, loading: true });
      let seenStatus = statuses.find((status) => status.status_name === CONSTANTS.VARIABLES.ORDERS.STATUSES.SEEN);
      let updatedOrder = await OrderService.updateStatus(order.location_id, order.order_id, seenStatus.status_id);
      dispatch({type: CONSTANTS.ACTIONS.ORDERS.GET,  order: updatedOrder, loading: false });
    }
    catch (error) {
      console.log(error);
      alert('Could not get order');
      dispatch({type: CONSTANTS.ACTIONS.ORDERS.GET,  order: null, loading: false });
    }
  };
}
