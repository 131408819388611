import React, { Component } from 'react';
import {Card, CardBody, CardHeader, Row, Button} from "reactstrap";

export default class NetworkErrorHandler extends Component {
	
	constructor(props) {
    super(props);
  }

	componentDidMount() {
		this.retryFunc = setTimeout(this.onRetry.bind(this), 5000);
	}

  componentWillUnmount() {
		if(this.retryFunc) {
			clearTimeout(this.retryFunc);
			this.retryFunc = null;
		}
	}

	onRetry() {
		if(this.props.retry) {
			this.props.retry();
		}
		if(this.retryFunc) {
			clearTimeout(this.retryFunc);
			this.retryFunc = setTimeout(this.onRetry.bind(this), 5000);
		}
	}

  render() {
		return (
			<Row>
				<Card className={'col-md-12'}>
					<CardHeader className='font-large-1'>OOPS! Something Unexpected happened</CardHeader>
					<CardBody className='font-medium-1'>
						Please refresh the page and try again.
						If problem persists please contact us @ developer@plugd.app.
						<div className="mt-2">
							<Button color="primary" onClick={this.onRetry.bind(this)}>
								Reload now
							</Button>
						</div>
					</CardBody>
				</Card>
			</Row>
		);
  }
}
