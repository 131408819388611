import CONSTANTS from '../../../constants';
import RidersService from '../../../services/RidersService';


export function getAllRidersByLocation(locationId) {
  return async dispatch => {
    try {
      dispatch({ type: CONSTANTS.ACTIONS.RIDERS.ALL_RIDERS, riders: [], loading: true });
      let riders = await RidersService.getAllRiders(locationId);
      dispatch({ type: CONSTANTS.ACTIONS.RIDERS.ALL_RIDERS, riders, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.RIDERS.ALL_RIDERS, riders: [], loading: false });
    }
  };
}

export function getAllRidersHistory(locationId) {
  return async dispatch => {
    try {
      dispatch({ type: CONSTANTS.ACTIONS.RIDERS.ALL_RIDERS_HISTORY, riders: [], loading: true });
      let riders = await RidersService.getAllRidersHistory(locationId);
      dispatch({ type: CONSTANTS.ACTIONS.RIDERS.ALL_RIDERS_HISTORY, riders, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.RIDERS.ALL_RIDERS_HISTORY, riders: [], loading: false });
    }
  };
}

export function getAllRidersPayments(locationId) {
  return async dispatch => {
    try {
      dispatch({ type: CONSTANTS.ACTIONS.RIDERS.ALL_RIDERS_PAYMENTS, payments: [], loading: true });
      let payments = await RidersService.getAllRidersPaymentHistory(locationId);
      dispatch({ type: CONSTANTS.ACTIONS.RIDERS.ALL_RIDERS_PAYMENTS, payments, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.RIDERS.ALL_RIDERS_PAYMENTS, payments: [], loading: false });
    }
  };
}

export function createRiderPayment(locationId, payment) {
  return async dispatch => {
    try {
      dispatch({ type: CONSTANTS.ACTIONS.RIDERS.PAYMENT, payments: [], loading: true });
      let payments = await RidersService.createPayment(locationId, payment);
      dispatch({ type: CONSTANTS.ACTIONS.RIDERS.PAYMENT, payments, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.RIDERS.PAYMENT, payments: [], loading: false });
    }
  };
}

export function deleteRiderPayment(locationId, payment) {
  return async dispatch => {
    try {
      dispatch({ type: CONSTANTS.ACTIONS.RIDERS.ALL_RIDERS_PAYMENTS, payments: [], loading: true });
      let payments = await RidersService.deletePayments(locationId, payment);
      dispatch({ type: CONSTANTS.ACTIONS.RIDERS.ALL_RIDERS_PAYMENTS, payments, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.RIDERS.ALL_RIDERS_PAYMENTS, payments: [], loading: false });
    }
  };
}


