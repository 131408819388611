import React from 'react';
import CONSTANTS from '../../constants';
import LocationService from '../../services/LocationService';
import { Row, Col, Card, CardBody, CardText, Badge, ListGroup, Button } from 'reactstrap'
const location = LocationService.getLocation(`${CONSTANTS.CACHE_KEY_PREFIX}_location`);
const PricingCards = ({ data, subscribe, subscribedPlan }) => {
  const renderPricingCards = () => {
    return data && data.map((item, index) => {
      return (
        <Col key={index} md='4' xs='12'>
          <Card
            className='text-center basic-pricing'
          >
            <CardBody>
              {true === true ? (
                <div className='pricing-badge text-right'>
                  <Badge color='light-primary' pill>
                    {item && item.is_popular === 1 ? 'Popular' : ''}
                  </Badge>
                </div>
              ) : null}
              <h3>{item.SmsPlan.name}</h3>
              <CardText>{item.SmsPlan.description}</CardText>
              <div className='annual-plan'>
                <div className='plan-price mt-2'>
                  <span className={`pricing-basic-value font-weight-bolder text-primary`}>
                    {item.Currency.currency_symbol}&nbsp;{item.plan_amount}
                  </span>
                </div>
              </div>
              <ListGroup tag='ul' className='list-group-circle text-left mb-2'>
                <li className='list-group-item'>Quantity: {item.quantity} Units</li>
              </ListGroup>

              <span className='f2'><small>Local deduction =  1 unit / No</small></span><br></br>

              <Button.Ripple
                className='mt-2'
                color={'primary'}
                block
                onClick={subscribe.bind(this, location ? location.loc_id : 0, item.id)}
              >
                {'Buy Now'}
              </Button.Ripple>
            </CardBody>
          </Card>
        </Col>
      )
    })
  }

  return (
    <Row className='pricing-card'>
      <Col className='mx-auto' sm={{ offset: 2, size: 10 }} lg={{ offset: 2, size: 10 }} md='12'>
        <Row>{renderPricingCards()}</Row>
      </Col>
    </Row>
  )
}

export default PricingCards
