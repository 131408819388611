import CONSTANTS from '../../../constants'
import SmsService from '../../../services/SmsService';

export function getSmsPlansForAdmin(initialize = false) {
	return async dispatch => {
		try {
			if (initialize) dispatch({ type: CONSTANTS.ACTIONS.SMS.ALL_SMS_PLANS, data: null, loading: true });
			let smsPlans = await SmsService.getSmsPlansForAdmin();
			dispatch({ type: CONSTANTS.ACTIONS.SMS.ALL_SMS_PLANS, data: smsPlans, loading: false });
		}
		catch (error) {
			console.log(error);
			dispatch({ type: CONSTANTS.ACTIONS.SMS.ALL_SMS_PLANS, data: null, loading: false });
		}
	};
}

export function getSmsPlans(initialize = false) {
	return async dispatch => {
		try {
			if (initialize) dispatch({ type: CONSTANTS.ACTIONS.SMS.ALL_SMS_PLANS, data: null, loading: true });
			let smsPlans = await SmsService.getSmsPlans();
			dispatch({ type: CONSTANTS.ACTIONS.SMS.ALL_SMS_PLANS, data: smsPlans, loading: false });
		}
		catch (error) {
			console.log(error);
			dispatch({ type: CONSTANTS.ACTIONS.SMS.ALL_SMS_PLANS, data: null, loading: false });
		}
	};
}

export function saveSmsPlans(plan, initialize = false) {
	return async dispatch => {
		try {
			if (initialize) dispatch({ type: CONSTANTS.ACTIONS.SMS.SUBSCRIBE_SMS_PLAN, data: null, loading: true });
			let smsPlans = await SmsService.saveSmsPlan(plan);
			dispatch({ type: CONSTANTS.ACTIONS.SMS.SUBSCRIBE_SMS_PLAN, data: smsPlans, loading: false });
		}
		catch (error) {
			console.log(error);
			dispatch({ type: CONSTANTS.ACTIONS.SMS.SUBSCRIBE_SMS_PLAN, data: null, loading: false });
		}
	};
}

export function delSmsPlans(plan_id, initialize = false) {
	return async dispatch => {
		try {
			if (initialize) dispatch({ type: CONSTANTS.ACTIONS.SMS.SUBSCRIBE_SMS_PLAN, data: null, loading: true });
			let smsPlans = await SmsService.delPlan(plan_id);
			dispatch({ type: CONSTANTS.ACTIONS.SMS.SUBSCRIBE_SMS_PLAN, data: smsPlans, loading: false });
		} catch (error) {
			console.log(error);
			dispatch({ type: CONSTANTS.ACTIONS.SMS.SUBSCRIBE_SMS_PLAN, data: null, loading: false });
		}
	};
}

export function getSubscribedSmsPlans(initialize = false) {
	return async dispatch => {
		try {
			if (initialize) dispatch({ type: CONSTANTS.ACTIONS.SMS.SUBSCRIBE_SMS_PLAN, data: null, loading: true });
			let smsplans = await SmsService.getSubscribedSmsPlans();
			dispatch({ type: CONSTANTS.ACTIONS.SMS.SUBSCRIBE_SMS_PLAN, data: smsplans, loading: false });
		}
		catch (error) {
			console.log(error);
			dispatch({ type: CONSTANTS.ACTIONS.SMS.SUBSCRIBE_SMS_PLAN, data: null, loading: false });
		}
	};
}

export function getAllSubscriptions( initialize = false) {
	return async dispatch => {
		try {
			if (initialize) dispatch({ type: CONSTANTS.ACTIONS.SMS.SUBSCRIPTIONS, data: null, loading: true });
			let smsplans = await SmsService.getAllSmsSubscriptions();
			dispatch({ type: CONSTANTS.ACTIONS.SMS.SUBSCRIPTIONS, data: smsplans, loading: false });
		}
		catch (error) {
			console.log(error);
			dispatch({ type: CONSTANTS.ACTIONS.SMS.SUBSCRIPTIONS, data: null, loading: false });
		}
	};
}

export function getSubscriptions( initialize = false) {
	return async dispatch => {
		try {
			if (initialize) dispatch({ type: CONSTANTS.ACTIONS.SMS.SUBSCRIPTIONS, data: null, loading: true });
			let smsplans = await SmsService.getSmsSubscriptions();
			dispatch({ type: CONSTANTS.ACTIONS.SMS.SUBSCRIPTIONS, data: smsplans, loading: false });
		}
		catch (error) {
			console.log(error);
			dispatch({ type: CONSTANTS.ACTIONS.SMS.SUBSCRIPTIONS, data: null, loading: false });
		}
	};
}

export function subscribePlan( plan_id, payment_id, initialize = false) {
	return async dispatch => {
		try {
			if (initialize) dispatch({ type: CONSTANTS.ACTIONS.SMS.SUBSCRIBE_SMS_PLAN, data: null, loading: true });
			let comp = await SmsService.subscribePlan(plan_id, payment_id);
			dispatch({ type: CONSTANTS.ACTIONS.SMS.SUBSCRIBE_SMS_PLAN, data: comp, loading: false });
		}
		catch (error) {
			console.log(error);
			dispatch({ type: CONSTANTS.ACTIONS.SMS.SUBSCRIBE_SMS_PLAN, data: null, loading: false });
		}
	};
}
export function getCards(initialize = false) {
	return async dispatch => {
		try {
			if (initialize) dispatch({ type: CONSTANTS.ACTIONS.SMS.CARDS, data: null, loading: true });
			let comp = await SmsService.getCards();
			dispatch({ type: CONSTANTS.ACTIONS.SMS.CARDS, data: comp, loading: false });
		}
		catch (error) {
			console.log(error);
			dispatch({ type: CONSTANTS.ACTIONS.SMS.CARDS, data: null, loading: false });
		}
	};
}