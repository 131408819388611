import CONSTANTS from '../../../constants';
import PromotionService from '../../../services/PromotionService';

export function getPromotions(loc_id, initialize= false) {
  return async dispatch => {
    try {
      if(initialize) dispatch({type: CONSTANTS.ACTIONS.PROMOTIONS.ALL_PROMOTIONS,  data: null, loading: true });
      let promotions = await PromotionService.getPromotions(loc_id);
      dispatch({type: CONSTANTS.ACTIONS.PROMOTIONS.ALL_PROMOTIONS, data: promotions, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.PROMOTIONS.ALL_PROMOTIONS,  data: null, loading: false });
    }
  };
}

export function savePromotion(loc_id, promotion, initialize= false) {
  return async dispatch => {
    try {
      if(initialize) dispatch({type: CONSTANTS.ACTIONS.PROMOTIONS.GET_PROMOTION,  data: null, loading: true });
      let prom = await PromotionService.savePromotion(loc_id, promotion);
      dispatch({type: CONSTANTS.ACTIONS.PROMOTIONS.GET_PROMOTION, data: prom, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.PROMOTIONS.GET_PROMOTION, data: null, loading: false });
    }
  };
}

export function updatePromotion(loc_id, promotion, initialize= false) {
  return async dispatch => {
    try {
      if(initialize) dispatch({type: CONSTANTS.ACTIONS.PROMOTIONS.GET_PROMOTION,  data: null, loading: true });
      let prom = await PromotionService.updatePromotion(loc_id, promotion);
      dispatch({type: CONSTANTS.ACTIONS.PROMOTIONS.GET_PROMOTION, data: prom, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.PROMOTIONS.GET_PROMOTION, data: null, loading: false });
    }
  };
}

export function togglePromotion(loc_id, promotionId, initialize= false) {
  return async dispatch => {
    try {
      if(initialize) dispatch({type: CONSTANTS.ACTIONS.PROMOTIONS.GET_PROMOTION,  data: null, loading: true });
      let prom = await PromotionService.togglePromotion(loc_id, promotionId);
      dispatch({type: CONSTANTS.ACTIONS.PROMOTIONS.GET_PROMOTION, data: prom, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.PROMOTIONS.GET_PROMOTION,  data: null, loading: false });
    }
  };
}