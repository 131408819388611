import CONSTANTS from '../../../constants';
import FeedbackService from '../../../services/FeedbackService';


export function getAllFeedbacks() {
  return async dispatch => {
    try {
      dispatch({type: CONSTANTS.ACTIONS.FEEDBACKS.ALL_FEEDBACKS, feedbacks: [], loading: true });
      let feedbacks = await FeedbackService.getAllFeedbacks();
      dispatch({type: CONSTANTS.ACTIONS.FEEDBACKS.ALL_FEEDBACKS, feedbacks, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.FEEDBACKS.ALL_FEEDBACKS, feedbacks: [], loading: false });
    }
  };
}

