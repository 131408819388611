import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import SpinnerComponent from '../@vuexy/spinner/Fallback-spinner';
import PaymentProcessorForm from '../shared/forms/PaymentProcessorForm';
import LocationSelectHeader from '../shared/LocationSelectHeader';
import LocationService from '../../services/LocationService';
import PaymentProcessorService from '../../services/PaymentProcessorService';
import CONSTANTS from '../../constants';
import SelectLocation from "../shared/SelectLocation";

class PaymentProcessor extends Component {

  constructor(props) {
    super(props);
    this.state = { location: null, paymentProcessor: null, loading: false, error: false };
  }

  getDefaultPaymentProcessor() {
    return {
      name: CONSTANTS.VARIABLES.PAYMENTS.DEFAULT,
      merchant_account: '',
      api_key: '',
      origin_key: '',
      secret: '',
      env: '',
      selectLocation: false,
    }
  }


  async componentDidMount() {
    this.setState({ loading: true });
    try {
      const location = LocationService.getLocation();
      if(location) {
        await this.refreshData(location.loc_id);
        this.setState({loading: false });
      } else {
        this.setState({loading: false, selectLocation: true})
      }
    } catch (e) {
      console.log(e);
      this.setState({ loading: false, error: true });
    }
  }

  async refreshData(loc_id) {
    let paymentProcessor = await PaymentProcessorService.getPaymentProcessor(loc_id);
    if(!paymentProcessor) paymentProcessor = this.getDefaultPaymentProcessor();
    this.setState({ paymentProcessor, location: loc_id });
  }

  onChange(event) {
    let paymentProcessor = {...this.state.paymentProcessor};
    paymentProcessor[event.target.name] = event.target.value;
    this.setState({ paymentProcessor });
  }

  async onSave() {
    this.setState({ loading: true });
    try {
      let { paymentProcessor } = this.state;
      paymentProcessor = await PaymentProcessorService.updatePaymentProcessor(this.state.location, paymentProcessor);
      this.setState({ paymentProcessor, loading: false });
    } catch (e) {
      console.log(e);
      this.setState({ loading: false, error: true });
    }
  }

  changeLocation() {
    this.setState({ selectLocation: true })
  }

  async onSelect(location) {
    this.setState({loading: true});
    LocationService.setLocation(location);
    await this.refreshData(location.loc_id);
    this.setState({selectLocation: false, loading: false});
  }

  toggleLocation() {
    this.setState({selectLocation: !this.state.selectLocation});
  }

  renderPaymentProcessor(paymentProcessor) {
    return (
      <Col lg='6' md='12'>
        <PaymentProcessorForm
          paymentProcessor={paymentProcessor}
          onChange={this.onChange.bind(this)}
          onSave={this.onSave.bind(this)} />
      </Col>
    );
  }

  render() {
    const { state: {
      paymentProcessor,
      loading,
      error,
      selectLocation
    }} = this;

    const location = LocationService.getLocation();
    const locationName = location ? location.name : '';

    // TODO: Implement proper error handling
    if(loading) return <SpinnerComponent />;
    else if(selectLocation) return <SelectLocation onBack={this.toggleLocation.bind(this)} onSelect={this.onSelect.bind(this)} />;
    else if(paymentProcessor)
      return (
        <div className='payment-processor'>
          <LocationSelectHeader text={locationName} onIconClick={this.changeLocation.bind(this)} />
          <Row className='mt-3'>
            {this.renderPaymentProcessor(paymentProcessor)}
          </Row>
        </div>
      );
    return '';  
  }
}

export default PaymentProcessor;
