import React, {Component, useMemo} from "react";
import ErrorHandler from "../shared/ErrorHandler";
import {
  Label,
  Row,
  Col
} from "reactstrap";
import { AvInput, AvGroup, AvFeedback,AvForm } from "availity-reactstrap-validation";
import {
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

class CardSection extends Component {

  useOptions() {
    const CARD_ELEMENT_OPTIONS = ({
      iconStyle: 'solid',
      hidePostalCode: true,
      style: {
        base: {
          iconColor: '#6b6e6f',
          color: '#6b6e6f',
          fontSize: '16px',
          fontFamily: 'Open Sans, sans-serif',
          fontSmoothing: 'antialiased',
          '::placeholder': {
            color: '#6b6e6f',
          },
        },
        invalid: {
          color: '#6b6e6f',
          ':focus': {
            color: '#6b6e6f',
          },
        },
      },
    });
    return CARD_ELEMENT_OPTIONS;
  };

  render() {
    const options = this.useOptions();
    return (
      <Row>
        <Col md='12' sm='12'>
          <AvGroup className='form-group'>
            <Label>Card Number</Label>
            <CardNumberElement options={options} className='form-control' />
          </AvGroup>
        </Col>
        <Col className='col-6'>
          <AvGroup>
            <Label> (MM/YY) </Label>
            <CardExpiryElement options={options} className='form-control' />
          </AvGroup>
        </Col>

        <Col className='col-6'>
          <AvGroup>
            <Label> CVC </Label>
            <CardCvcElement options={options} className='form-control' />
          </AvGroup>
        </Col>
      </Row>
    );
  }
}


export default CardSection;