import React, {Component} from "react";
import {connect} from "react-redux";

import {getLocation, getDeliveriesFor} from "../../redux/actions/locations";

import CONSTANTS from "../../constants";
import LocationService from "../../services/LocationService";

import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import SelectLocation from "../shared/SelectLocation";
import LocationSelectHeader from "../shared/LocationSelectHeader";

import DeliveryList from "./DeliveryList";

class Deliveries extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectLocation: false,
      location: null,
      loading: true,
    };
  }

  async componentDidMount() {
    const location = LocationService.getLocation(`${CONSTANTS.CACHE_KEY_PREFIX}_location`);
    if(location) {
      await this.refreshData(location.loc_id);
    } else {
      this.setState({loading: false, selectLocation: true})
    }
  }

  async refreshData(loc_id) {
    let location;
    if(loc_id !== 0 && ( !this.state.location || loc_id !== this.state.location.loc_id)) {
      await this.getDeliveries(loc_id);
      await this.props.getLocation(loc_id);
      location = this.props.location;
    } else {
      location = this.state.location;
    }
    this.setState({ loading: false, location: JSON.parse(JSON.stringify(location)) });
  }

  async getDeliveries(loc_id, type = null) {
    return this.props.getDeliveriesFor(loc_id, type);
  }

  onBlur() {
    if( this.state.location )
      this.setState({selectLocation: !this.state.selectLocation});
  }

  toggleLocation() {
    this.setState({selectLocation: !this.state.selectLocation});
  }

  async onSelect(location) {
    this.setState({loading: true});
    LocationService.setLocation(location, `${CONSTANTS.CACHE_KEY_PREFIX}_location`);
    await this.refreshData(location.loc_id);
    this.setState({selectLocation: false, loading: false});
  }

  render() {
    const { props: {
      deliveries = [],
    }} = this;

    const { state: {
      selectLocation,
      loading,
			location: stateLocation
    }} = this;

    if( loading ) return <SpinnerComponent />;

    const locationName = stateLocation ? stateLocation.name : '';

    if ( selectLocation )
      return <SelectLocation onBack={this.toggleLocation.bind(this)} onSelect={this.onSelect.bind(this)} showOutlets={true} />;

    return (
      <div className='location'>
        <div className='display-flex flex-row flex-space-between mb-1 align-items-center'>
          <LocationSelectHeader text={locationName} onIconClick={this.toggleLocation.bind(this)} />
        </div>
        { <DeliveryList filterUpdated={this.getDeliveries.bind(this, stateLocation.loc_id)} location={stateLocation} deliveries={deliveries} /> }
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { locations: {
    location: {
      data: locationData, loading: locationLoading
    },
    deliveries: {
      data: deliveries, loading: deliveriesLoading
    }
  }} = state;

  return { deliveries, location: locationData, loading: (locationLoading || deliveriesLoading) };
}

export default connect(mapStateToProps, { getLocation, getDeliveriesFor })(Deliveries);