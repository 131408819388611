import React, {useEffect, useState} from 'react';
import {
  Button,
  ListGroup,
  ListGroupItem,
  Media
} from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ViewHelper from '../../helpers/ViewHelper';
import {
  Star,
  StopCircle,
  Edit2,
  Play,
  Pause,
  Image,
  ShieldOff,
  Check
} from "react-feather";

import Checkbox from '../@vuexy/checkbox/CheckboxesVuexy';
import ImageHelper from "../../helpers/ImageHelper";
import ParamHelper from "../../helpers/ParamHelper";

// get data from array
const getItems = ( listItems, location ) =>
  listItems.map(k => {
    let content = <span> {ViewHelper.toCurrency(k.menu_price, location.Currency.currency_code, location.decimal_places)}
      { k.overall_rating ? <span> <StopCircle size={2} fill='white' className='' /> <Star fill='gold' color='gold' size={10} className='align-baseline' /> { ViewHelper.toDecimal(k.overall_rating, 1) } </span> : null}
    </span>;
    return {
      id: `${k.menu_id}`,
      img: ImageHelper.getImageURL(k.menu_photo,'?func=crop&w=50&h=50'),
      name: k.menu_name,
      content: content,
      status: k.menu_status,
      ...k
    };
  })

const DnDListView = (props) => {

  const [items, setItems] = useState([]);

  const {
    hideEditableButtons,
    updateSelectedModifiers,
    selectedIds,
    isDragDisabled: dragDisableProp,
    categorySelected,
    toggleStatus,
    updateMenuPriority,
    location,
    menu,
		isOutlet,
  } = props;

	const isDragDisabled = dragDisableProp || isOutlet;

  useEffect(() => {
    const itemsCopy = getItems(menu, location);
    setItems(itemsCopy);
  }, [menu])

  const onDragEnd = result => {
    if (!result.destination) {
      return
    }
    const itemsCopy = ViewHelper.reorder(
      items,
      result.source.index,
      result.destination.index,
      true
    );
    setItems(itemsCopy);
    updateMenuPriority(location.loc_id, ParamHelper.getMenusForBulkUpdate(itemsCopy));
  }
  return (
    <ListGroup id='list-group-dnd'>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable'>
          {(provided, snapshot) => (
            <div ref={provided.innerRef}>
              { items.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id}
                  index={index}
                  isDragDisabled={isDragDisabled}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className='drag-wrapper'
                    >
                      <ListGroupItem className='no-border-bottom-radius no-border-top-radius' disabled={categorySelected}>
                        <div className='display-flex flex-row flex-space-between'>
                          <div>
                            <Media>
                              <Media left tag='div'>
                                {item.img ?
                                  <Media
                                    object
                                    src={item.img}
                                    className='rounded-circle mr-2'
                                    style={{ objectFit: 'cover' }}
                                    alt="image"
                                    height="50"
                                    width="50"
                                  /> :
                                  <div className='rounded-circle mr-2' style={{ padding: 9, height: 50, width: 50, backgroundColor: '#ffffff15', }}>
                                    <Image size={32} className='align-self-center' />
                                  </div>
                                }
                              </Media>
                              <Media body>
                                <p className='mt-0'>{item.name}</p>
                                {item.content}
                              </Media>
                            </Media>
                          </div>
                          { !hideEditableButtons &&
                          <div className='align-self-center display-flex'>
                            <Button outline
                                    className={item.status === 1 ? 'round custom-padding mr-1 success-1-border' : item.status === 0 ? 'round custom-padding mr-1 disabled-1-border' : 'round custom-padding mr-1 warning-1-border' }
                                    color={ item.status === 1 ? 'success' : item.status === 0 ? 'secondary' : 'warning' }
                                    size='md'
                                    onClick={ () => toggleStatus(item) }
                            >
                              { item.status === 1 ?
                                <span className='success'>
                                  <Play size={15} />
                                  <span className='success d-none d-sm-inline'> Enabled </span>
                                </span>
                                : item.status === 0 ?
                                <span className='light'>
                                  <Pause size={15} />
                                  <span className='light d-none d-sm-inline'> Disabled </span>
                                </span>
                                  : <span className='warning'>
                                  <ShieldOff size={15} />
                                  <span className='warning d-none d-sm-inline'> Out of Stock </span>
                                </span> }
                            </Button>
                            { !isOutlet && <span tag='small' className='text-bold-500 cursor-pointer mr-1'>
                              <Edit2 size={25} onClick={(e) => {
                                e.stopPropagation();
                                props.toggleMenuModal(item);
                              }} />
                            </span> }
                          </div> }
                          { !isOutlet && updateSelectedModifiers && <Checkbox
                            color='primary'
                            icon={<Check className='vx-icon' size={16} />}
                            defaultChecked={ selectedIds.indexOf( parseInt(item.id) ) > -1 || categorySelected }
                            checked={ selectedIds.indexOf( parseInt(item.id) ) > -1 || categorySelected }
                            value={item.id}
                            onClick={(e) => {
                              e.stopPropagation();
                              updateSelectedModifiers(e.target.value);
                            }}
                          /> }
                        </div>
                      </ListGroupItem>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </ListGroup>
  );
}

export default DnDListView;