import React, {useEffect, useState} from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
  Input, Col, Row,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import Radio from "../../@vuexy/radio/RadioVuexy";
import TimeHelper from "../../../helpers/TimeHelper";
import CONSTANTS from "../../../constants";
import {ClipLoader} from "react-spinners";
import {X} from "react-feather";

const CategoryModal = (props) => {

  const saveAction = () => {
    if(isOpen) {
      submitCategory();
    }
  };

  const keydownHandler = (e) => {
    if(e.keyCode===13 && e.ctrlKey) saveAction();
  };

  useEffect(()=> {
    document.addEventListener('keydown', keydownHandler);
    return () => document.removeEventListener('keydown', keydownHandler);
  });

  const dummyCategory = {
    name: '',
    status: 1,
    available_time: 0,
    description: '',
    parent_id: 0,
    image: '',
    local_from_time: TimeHelper.toFormattedTime(Date.now(), CONSTANTS.TIMES.FORMAT.DB),
    local_to_time: TimeHelper.toFormattedTime(Date.now(), CONSTANTS.TIMES.FORMAT.DB),
  }

  let {
    isOpen,
    toggleModal,
    deleteCategory,
    saveCategory,
    deleting
  } = props;

  let cat = props.category ? props.category : JSON.parse(JSON.stringify(dummyCategory));

  const [category, setCategory] = useState(cat);
  const [saving, setSaving] = useState(false);
  const [nameError, setError] = useState(false);

  let inputRef = React.createRef();

  const updateCategory = (key, val) => {
    const cat = JSON.parse(JSON.stringify(category));
    cat[key] = val;
    setCategory(cat);
  }

  useEffect(() => {
    let cat = props.category ? props.category : JSON.parse(JSON.stringify(dummyCategory));
    isOpen = props.isOpen;
    setSaving(false);
    deleting = props.deleting;
    setCategory(cat);
  }, [props]);

  const submitCategory = () => {
    if(saving || deleting) return;
    if(category.name === '') {
      setError(true);
      return;
    }
    setSaving(true);
    saveCategory(category);
  }

  const delCategory = () => {
    if(saving || deleting) return;
    deleteCategory(category);
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={toggleModal}
        onOpened={() => {
          if(props.category)
            return;
          setTimeout(() => inputRef.current.focus(), 1);
        }}
        className="modal-dialog-centered d-sm-margin-0 p-0"
      >
        <div className='modal-header display-flex full-width flex-space-between'>
          <h5 className='modal-title'>{ props.category ? 'Edit Category' : 'New Category' }</h5>
          <X size={20} onClick={toggleModal} />
        </div>
        <ModalBody>
          <FormGroup>
            <Label for="email">Category Name:</Label>
            <Input
              innerRef={ inputRef }
              type="text"
              id="name"
              value={category.name}
              placeholder="Enter Category Name"
              invalid={nameError}
              onChange={(e) => updateCategory('name', e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="status">Status:</Label>
            <div id='status' onChange={(e) => updateCategory('status', parseInt(e.target.value))}>
              <div className="d-inline-block mr-1">
                <Radio label="Enabled" checked={category.status === 1} name="status" value={1} />
              </div>
              <div className="d-inline-block mr-1">
                <Radio label="Disabled" name="status" checked={category.status === 0} value={0} />
              </div>
            </div>
          </FormGroup>
          <FormGroup>
            <Label for="time">Available Time:</Label>
            <div id='time' onChange={(e) => updateCategory('available_time', e.target.value) }>
              <div className="d-inline-block mr-1">
                <Radio label="Anytime" checked={category.available_time == 0} name="time" value={0} />
              </div>
              <div className="d-inline-block mr-1">
                <Radio label="Specific Times" checked={category.available_time == 1} name="time"  value={1} />
              </div>
            </div>
          </FormGroup>
          { category.available_time != 0 &&
          <FormGroup>
            <Row>
              <Col md={6}>
                <Label for='times'>Available from</Label>
                <Flatpickr
                  className="form-control"
                  options={{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: "H:i",
                    defaultDate: category.local_from_time
                  }}
                  onChange={date => updateCategory('local_from_time', TimeHelper.toFormattedTime(date[0], CONSTANTS.TIMES.FORMAT.DB))}
                />
              </Col>
              <Col md={6}>
                <Label for='times'>Available till</Label>
                <Flatpickr
                  className="form-control"
                  options={{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: "H:i",
                    defaultDate: category.local_to_time
                  }}
                  onChange={ date => updateCategory('local_to_time', TimeHelper.toFormattedTime(date[0], CONSTANTS.TIMES.FORMAT.DB))}
                />
              </Col>
            </Row>
          </FormGroup> }
        </ModalBody>
        <ModalFooter>
          <div className='d-none d-sm-flex flex-space-between full-width'>
            <div>
              { props.category && <Button color="darken-1" disabled={saving || deleting} onClick={ delCategory }>
                { deleting ? <ClipLoader size={15} /> : <span className='danger'> Delete </span> }
              </Button> } {" "}
            </div>
            <div>
              <Button color="dark" disabled={saving || deleting} onClick={ () => {
                if(saving || deleting) return;
                toggleModal();
              } }>
                Cancel
              </Button>{" "}
              <Button color="primary" disabled={saving || deleting} onClick={ submitCategory }>
                { saving ? <ClipLoader size={15} /> : "Save" }
              </Button>{" "}
            </div>
          </div>
          <div className='d-sm-none col-12'>
            <Button className='col-12' color="primary" disabled={saving || deleting} onClick={ submitCategory }>
              { saving ? <ClipLoader size={15} /> : "Save" }
            </Button>{" "}
            <Button className='col-12 my-50' color="dark" disabled={saving || deleting} onClick={ () => {
              if(saving || deleting) return;
              toggleModal();
            } }>
              Cancel
            </Button>{" "}
            { props.category && <Button className='col-12' color="darken-1" disabled={saving || deleting} onClick={ delCategory }>
              { deleting ? <ClipLoader size={15} /> : <span className='danger'> Delete </span> }
            </Button> }
          </div>
          <p className='d-none d-sm-inline col-12 align-text-right font-small-1 pr-0'>
            Hit Ctrl + Enter to Save
          </p>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}
export default CategoryModal;