import CONSTANTS from '../../../constants';
import UserService from '../../../services/UserService';

export function getRiders(loc_id) {
  return async dispatch => {
    try {
      dispatch({type: CONSTANTS.ACTIONS.USERS.GET_RIDERS, riders: [], loading: true });
      let riders = await UserService.getRiders(loc_id);
      dispatch({type: CONSTANTS.ACTIONS.USERS.GET_RIDERS, riders, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.USERS.GET_RIDERS, riders: [], loading: false });
    }
  };
}
export function getAllUsers() {
  return async dispatch => {
    try {
      dispatch({type: CONSTANTS.ACTIONS.USERS.ALL_USERS, users: [], loading: true });
      let users = await UserService.getAllUsers();
      dispatch({type: CONSTANTS.ACTIONS.USERS.ALL_USERS, users, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.USERS.ALL_USERS, users: [], loading: false });
    }
  };
}
export function updateBanStatus(data) {
  return async dispatch => {
    try {
      dispatch({type: CONSTANTS.ACTIONS.USERS.ALL_USERS, users: [], loading: true });
      let users = await UserService.updateBanStatus(data);
      dispatch({type: CONSTANTS.ACTIONS.USERS.ALL_USERS, users, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.USERS.ALL_USERS, users: [], loading: false });
    }
  };
}
export function getAllReports() {
  return async dispatch => {
    try {
      dispatch({type: CONSTANTS.ACTIONS.USERS.ALL_REPORTS, reports: [], loading: true });
      let reports = await UserService.getAllReports();
      dispatch({type: CONSTANTS.ACTIONS.USERS.ALL_REPORTS, reports, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.USERS.ALL_REPORTS, reports: [], loading: false });
    }
  };
}

export function deleteReport(data) {
  return async dispatch => {
    try {
      dispatch({type: CONSTANTS.ACTIONS.USERS.ALL_REPORTS, reports: [], loading: true });
      let reports = await UserService.deleteReports(data);
      dispatch({type: CONSTANTS.ACTIONS.USERS.ALL_REPORTS, reports, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.USERS.ALL_REPORTS, reports: [], loading: false });
    }
  };
}