import React from 'react';
import { Button } from 'reactstrap';
import { Edit2 } from 'react-feather';
import LocationService from '../../services/LocationService';
import '../../assets/css/locationSelectHeader.scss';

const LocationSelectHeader = (props) => {
  const count = LocationService.getTotalLocationsCount();
  const handleClick = () => {
    if( count > 1 && props.onIconClick)
      props.onIconClick();
  }
  return (
    <div>
      <Button outline className='round font-weight-bold success-2-border custom-padding' color='success' size='md' onClick={ handleClick }>
        {props.text} { count > 1 ? <Edit2 size={15} /> : null }
      </Button>
    </div>
  );
};

export default LocationSelectHeader;
