import CONSTANTS from '../constants';
import LoginService from './LoginService';
import { customFetch as fetch } from '../helpers/FetchHelper';

export default {
  getLiveOrders,
  getOrder,
  updateStatus,
  addRiders,
  cancelRiderRequest,
	getDeliveryProcessorInfo,
	getRideDetails,
};

async function getRideDetails(locationId, orderId) {
	let { token } = LoginService.getToken();
  console.log(fetch);
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.GET_RIDER_LOCATION(locationId, orderId)}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
	if(+response.status === 200) {
		return response.json();
	}
  
  return null;
}

async function getLiveOrders(params) {
  let { token } = LoginService.getToken();
  console.log(fetch);
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.LIST}`, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if(response) return response;
  throw new Error(`Could not get live orders: ${response}`);
}

async function getOrder(locationId, orderId) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.DEFAULT(locationId)}/${orderId}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if(response) return response;
  throw new Error(`Could not get live order: ${response}`);
}

async function updateStatus(locationId, orderId, statusId, reasonToCancel) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.DEFAULT(locationId)}/${orderId}`, {
    method: 'PUT',
    body: JSON.stringify({ status_id: statusId, status_history: { comment: reasonToCancel } }),
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });

  response = await response.json();
  if(response) return response;
  throw new Error(`Could not update order`);
}

async function getDeliveryProcessorInfo(locationId, orderId) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.GET_DELIVERY_PROCESSORS(locationId, orderId)}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if(response) {
    return response;
  }
  throw new Error('Could not fetch location');
}

async function addRiders(locationId, orderId, body) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.ADD_RIDERS(locationId, orderId)}`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if(response) return response;
  throw new Error(`Could not add riders`);
}

async function cancelRiderRequest(locationId, orderId) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.ORDERS.CANCEL_RIDER_REQUEST(locationId, orderId)}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if(response) return response;
  throw new Error(`Could not add riders`);
}