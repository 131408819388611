const colors = ['#F99665', '#FFC65D', '#7BC8A4', '#4CC3D9', '#93648D', '#434343', '#F16765', '#FFC65D', '#7BC8A4', '#4CC3D9', '#93648D', '#424242', '#F16785', '#FFC85D', '#7BC8A4', '#4CC3D9', '#93648D', '#414141', '#F16715', '#FFC65D'];
class MapsHelper {
	constructor(map, deliveryAreas, events, center) {
		this.map = map;
		this.events = events;
		this.deliveryAreas = [];
		this.centerLatLng = { lat: +center.lat, lng: +center.lng };
		this.clearMapAreas(this.deliveryAreas);
		if (!window.google.maps.Polygon.prototype.getBounds) {
			window.google.maps.Polygon.prototype.getBounds = function () {
				var bounds = new window.google.maps.LatLngBounds();
				var paths = this.getPaths();
				var path;
				for (var i = 0; i < paths.getLength(); i++) {
					path = paths.getAt(i);
					for (var ii = 0; ii < path.getLength(); ii++) {
						bounds.extend(path.getAt(ii));
					}
				}
				return bounds;
			}
		}
		this.createSavedArea(deliveryAreas);
	}

	static getColorsByIndex(index) {
		return (colors[index - 1] == undefined) ? '#F16745' : colors[index - 1]
	}

	redrawAreas(deliveryAreas) {
		this.clearMapAreas(this.deliveryAreas);
		this.deliveryAreas = [];
		this.createSavedArea(deliveryAreas);
	}

	deleteMapArea(deliveryArea) {
		for (let i = this.deliveryAreas.length - 1; i >= 0; i--) {
			if (this.deliveryAreas[i].row == deliveryArea.row) {
				this.toggleVisibleMapArea(deliveryArea);
				this.deliveryAreas.splice(i, 1);
			}
		}
	}

	clearMapAreas(deliveryAreas) {
		deliveryAreas.forEach((area) => {
			area.setMap(null);
			// this.deleteMapArea(area);
		});
	}

	createSavedArea(deliveryAreas) {
		let savedAreas = deliveryAreas;
		savedAreas.sort((a,b) =>  a.isEdit - b.isEdit);
		savedAreas.forEach((area) => {
			let shapeArea, circleArea, shape, decodedPath, center;
			if (area.type === 'circle') {
				const circle = JSON.parse(area.circle);
				circleArea = this.drawCircleArea(area.row, circle[0].center, circle[1].radius, area.isEdit);
			} else {
				decodedPath = JSON.parse(area.vertices);
				shapeArea = this.drawShapeArea(area.row, decodedPath, area.isEdit);
			}

			// if (area.type == 'circle') {
			// 	this.toggleVisibleMapArea(circleArea, 'circle');
			// 	// setDeliveryAreaEvents(circleArea);
			// } else {
			// 	this.toggleVisibleMapArea(shapeArea, 'shape');
			// 	// setDeliveryAreaEvents(shapeArea);
			// }
		});
		// this.resizeMap();
	}

	createDeliveryArea(row) {
		let circleArea, shapeArea, radius = 1000, ne, sw, scale = 0.15, windowWidth, windowHeight,
			widthMargin, heightMargin, top, bottom, left, right;
		circleArea = this.drawCircleArea(row, this.centerLatLng, radius);
		ne = circleArea.getBounds().getNorthEast();
		sw = circleArea.getBounds().getSouthWest();
		scale = 0.15;
		windowWidth = ne.lng() - sw.lng();
		windowHeight = ne.lat() - sw.lat();
		widthMargin = windowWidth * scale;
		heightMargin = windowHeight * scale;
		top = ne.lat() - heightMargin;
		bottom = sw.lat() + heightMargin;
		left = sw.lng() + widthMargin;
		right = ne.lng() - widthMargin;
		const shape = [
			new window.google.maps.LatLng(top, right),
			new window.google.maps.LatLng(bottom, right),
			new window.google.maps.LatLng(bottom, left),
			new window.google.maps.LatLng(top, left)
		];

		shapeArea = this.drawShapeArea(row, shape);
		this.toggleVisibleMapArea(shapeArea, 'shape');
		// resizeMap();
		return shapeArea;
	}

	toggleVisibleMapArea(deliveryArea, type) {
	
		this.deliveryAreas.forEach(function (area) {
			if (area.row == deliveryArea.row) {
				area.setOptions({ visible: false });
				if (type != undefined && area.type == type) {
					area.setOptions({ visible: true });
				}
			}
		});
	}

	resizeMap() {
		let allAreasBounds = [];
		if (!this.deliveryAreas.length) {
			return;
		}
		allAreasBounds = new window.google.maps.LatLngBounds();
		this.deliveryAreas.forEach(function (area) {
			var bounds = area.getBounds();
			allAreasBounds.extend(bounds);
		});
		this.map.fitBounds(allAreasBounds);
	}

	drawCircleArea(row, center, radius, status) {
		let options, circleArea, color = (colors[row - 1] == undefined) ? '#F16745' : colors[row - 1];
		options = this.defaultAreaOptions(status);
		options.strokeColor = color;
		options.fillColor = color;
		options.center = center;
		options.radius = radius;
		options.map = this.map;
		circleArea = new window.google.maps.Circle(options);
		this.addMapArea(circleArea);

		circleArea.row = row;
		circleArea.name = 'Area ' + row;
		circleArea.color = color;
		circleArea.type = 'circle';

		return circleArea;
	}

	drawShapeArea(row, shape, status) {
		var options, shapeArea,
			color = (colors[row - 1] == undefined) ? '#F16745' : colors[row - 1];

		options = this.defaultAreaOptions(status);
		options.paths = shape;
		options.strokeColor = color;
		options.fillColor = color;
		shapeArea = new window.google.maps.Polygon(options);
		shapeArea.setMap(this.map);
		this.addMapArea(shapeArea);

		shapeArea.div = '#delivery-area' + row;
		shapeArea.row = row;
		shapeArea.name = 'Area ' + row;
		shapeArea.color = color;
		shapeArea.type = 'shape';
		return shapeArea;
	}

	defaultAreaOptions(status) {
		if(status){
			return {
				visible: true,
				draggable: status,
				editable: status,
				strokeOpacity: 0.8,
				strokeWeight: 4,
				fillOpacity: 0.5
			};
		}else{
			return {
				visible: true,
				draggable: status,
				editable: status,
				strokeOpacity: 0.8,
				strokeWeight: 3,
				fillOpacity: 0.15
			};
		}
		
	}

	addMapArea(deliveryArea) {
		this.deliveryAreas.push(deliveryArea);
		this.setMapAreaEvents(deliveryArea);
	}

	setMapAreaEvents(deliveryArea) {
		window.google.maps.event.addDomListener(deliveryArea, 'click', () => {
			this.events['toggleMapArea'](deliveryArea);
		});

		window.google.maps.event.addDomListener(deliveryArea, 'mouseover', () => {
			this.events['toggleHoverMapArea'](deliveryArea);
		});

		window.google.maps.event.addDomListener(deliveryArea, 'mouseout', () => {
			this.events['toggleHoverMapArea'](deliveryArea);
		});

		window.google.maps.event.addDomListener(deliveryArea, 'mouseup', () => {
			this.events['updatedDeliveryArea'](deliveryArea);
		});

		window.google.maps.event.addDomListener(deliveryArea, 'radius_changed', () => {
			this.events['updatedDeliveryArea'](deliveryArea);
		});

	}
}

export default MapsHelper;