import React, { Component } from 'react';
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label
} from 'reactstrap';
import { Mail, Lock, Check } from 'react-feather';
import Checkbox from '../@vuexy/checkbox/CheckboxesVuexy';
import LoginService from '../../services/LoginService';
import CONSTANTS from '../../constants';

import loginImg from '../../assets/img/pages/merged.png'
import '../../assets/scss/pages/authentication.scss'

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email: '',
      password: '',
      isTermsCheck: false
    }
  }

  componentDidMount() {
    if(this.props.location.state && this.props.location.state.from) localStorage.setItem('returnURL', this.props.location.state.from);
    if(this.props.location.state && this.props.location.state.data) localStorage.setItem('returnData', JSON.stringify(this.props.location.state.data));
    if(LoginService.getToken()) this.redirectToReturnURL();
  }

  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  login() {
    if(this.state.isTermsCheck){
      this.setState({ loading: true }, async () => {
        try {
          let tokenData = await LoginService.login(this.state.email, this.state.password);
          LoginService.setLoginData(tokenData,tokenData.role);
          this.redirectToReturnURL();
        } catch (e) {
          this.setState({ loading: false });
          alert('Failed login attempt. Please enter correct username or password.');
        }
      });
    }else{
      alert('Please select Terms & Condition to proceed login.');
    }
  }

  returnURL() {
    let url = localStorage.getItem('returnURL') || `${CONSTANTS.BASE_URL}`;
    localStorage.removeItem('returnURL');
    return url;
  }

  returnData() {
    let data = localStorage.getItem('returnData') || null;
    localStorage.removeItem('returnData');
    if(data) return JSON.parse(data);
  }

  redirectToReturnURL() {
    this.props.history.push(this.returnURL(), this.returnData());
  }

  async onChangeTerms(){
    await this.setState({ isTermsCheck : !this.state.isTermsCheck })
  }

  render() {
    return (
      <Row className='m-0 justify-content-center'>
        <Col
          sm='8'
          xl='7'
          lg='10'
          md='8'
          className='d-flex justify-content-center'
        >
          <Card className='bg-authentication login-card rounded-0 mb-0 w-100'>
            <Row className='m-0'>
              <Col
                lg='6'
                className='d-lg-block text-center align-self-center px-1 py-0'
              >
                <div style={{display: 'flex', flexFlow: 'row', alignItems: 'center', height: '300px'}}>
                  <img width={'100%'} height={'auto'} src={loginImg} alt='loginImg' />
                </div>
              </Col>
              <Col lg='6' md='12' className='p-0'>
                <Card className='rounded-0 mb-0 px-2'>
                  <CardBody>
                    <h4>Plugd Admin Panel</h4>
                    <p>Manage all your restaurant orders at one place</p>
                    <Form onSubmit={e => e.preventDefault()}>
                      <FormGroup className='form-label-group position-relative has-icon-left'>
                        <Input
                          type='email'
                          placeholder='Email'
                          name='email'
                          value={this.state.email}
                          onChange={this.handleInputChange.bind(this)}
                        />
                        <div className='form-control-position'>
                          <Mail size={15} />
                        </div>
                        <Label>Email</Label>
                      </FormGroup>
                      <FormGroup className='form-label-group position-relative has-icon-left'>
                        <Input
                          type='password'
                          name='password'
                          placeholder='Password'
                          value={this.state.password}
                          onChange={this.handleInputChange.bind(this)}
                        />
                        <div className='form-control-position'>
                          <Lock size={15} />
                        </div>
                        <Label>Password</Label>
                      </FormGroup>
                      <FormGroup className='d-flex align-items-center'>
                        <Checkbox
                          color='primary'
                          icon={<Check className='vx-icon' size={16} />}
                          onClick={()=> this.onChangeTerms()}
                          required
                        />
                        <label>I agree to <a href='/terms' target="_blank">Terms & Conditions</a>.</label>
                      </FormGroup>
                      <div className='d-flex justify-content-between'>
                        <Button.Ripple color='primary' type='submit' onClick={this.login.bind(this)}>
                          {this.state.loading && this.renderLoader()}
                          {!this.state.loading && 'Login'}
                        </Button.Ripple>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    );
  }

  renderLoader() {
    return (
      <div className='text-sm spinner-border text-primary' role='status'>
        <span className='sr-only'>Loading...</span>
      </div>
    );
  }
}

export default Login;
