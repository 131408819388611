import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch } from 'react-router-dom';
import { store } from './redux/storeConfig/store'
import Layout from './helpers/context/Layout'
import PrivateRoute from './components/shared/PrivateRoute';
import PublicRoute from './components/shared/PublicRoute';
import Login from './components/sessions/Login';
import Logout from './components/sessions/Logout';
import LiveOrders from './components/orders/LiveOrders';
import Order from './components/orders/Order';
import OrderTypes from './components/locations/OrderTypes';
import PaymentProcessor from './components/locations/PaymentProcessor';
import Locations from './components/locations/Locations';
import AllLocations from './components/locations/AllLocations';
import Location from './components/locations/Location';
import NewLocation from './components/locations/NewLocation';
import SelectLocation from './components/shared/SelectLocation';
import RiderCompensation from "./components/locations/RiderCompensation";
import OverallStats from "./components/analytics/OverallStats";
import CustomerStats from "./components/analytics/CustomersList";
import ItemsStats from "./components/analytics/ItemsList";

import * as serviceWorker from './serviceWorker';
import CONSTANTS from './constants';

import "./components/@vuexy/rippleButton/RippleButton";
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'prismjs/themes/prism-tomorrow.css';
import './index.scss';

import Menu from "./components/menus/Menu";
import ScrollToTop from "./components/shared/ScrollToTop";
import Promotions from "./components/promotions/Promotions";
import PromotionForm from "./components/promotions/PromotionForm";
import Outlets from "./components/outlets/Outlets";
import NewOutlet from "./components/outlets/NewOutlet";
import Outlet from "./components/outlets/Outlet";
import Branding from "./components/branding/Branding";
import SmsCampaigns from "./components/campaigns/SmsCampaigns";
import SmsCampaignsForm from "./components/campaigns/SmsCampaignsForm";
import SmsPlans from "./components/smsplans/SmsPlans";
import SmsPayments from "./components/smsplans/SmsPayments";

import Terms from "./components/terms/Terms";
import AdminSmsPlans from "./components/smsplans/AdminSmsPlans";
import SmsPlanForm from "./components/smsplans/SmsPlanForm";
import AppUsers from "./components/appdata/users/AppUsers";
import AppReports from "./components/appdata/reports/AppReports";
import Feedback from "./components/feedback/Feedback";
import Currency from "./components/currencies/Currency";
import CurrencyForm from "./components/currencies/CurrencyForm";
import Reviews from "./components/reviews/Reviews";
import RiderHistory from "./components/rider/RiderHistory";
import Riders from "./components/rider/Riders";
import RidersPayments from "./components/rider/RiderPayments";
import RidersPaymentsForm from "./components/rider/PaymentForm";
import Tables from "./components/tables/Tables";
import TablesForm from "./components/tables/TablesForm";
import Staff from "./components/staff/Staff";
import StaffForm from "./components/staff/StaffForm";
import Roles from "./components/roles/Roles";
import RolesForm from "./components/roles/RolesForm";
import AssignRole from "./components/staff/rolesassign/RoleAssign";
import AssignRoleForm from "./components/staff/rolesassign/RoleAssignForm";

import RiderServices from './components/locations/RiderServices';
import Deliveries from './components/deliveries/Deliveries';
import SmsSubscriptions from "./components/smsplans/SmsSubscriptions";
import AllSmsSubscriptions from "./components/smsplans/admin/AllSmsSubscriptions";
import Modifiers from "./components/modifiers/Modifiers";
import SessionActivity from './components/session-activity/SessionActivity';
import ToastifyNotification from "./components/notifications/toastify-noatification";

ReactDOM.render(
  <Provider store={store}>
    <Layout>
      <BrowserRouter>
        <ScrollToTop />
        <div className='app-container'>
          <Switch>
            <PublicRoute fullLayout exact path={`${CONSTANTS.BASE_URL}login`} component={Login} />
            <PublicRoute fullLayout exact path={`${CONSTANTS.BASE_URL}terms`} component={Terms} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}logout`} component={Logout} pageTitle={'Logout'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}`} component={LiveOrders} pageTitle={'Live Orders'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}locations/:location_id/orders/:order_id`} component={Order} pageTitle={'Order Details'} goBackURL={'home'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}select_location`} component={SelectLocation} pageTitle={'Select Restaurant'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}appdata/users`} component={AppUsers} pageTitle={'App Users'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}appdata/reports`} component={AppReports} pageTitle={'App Reports'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}feedbacks`} component={Feedback} pageTitle={'App Feedbacks'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}currencies`} component={Currency} pageTitle={'Currencies'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}currencies/new`} component={CurrencyForm} pageTitle={'Currency form'} goBackURL={'currencies'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}currencies/edit/:currency_id`} component={CurrencyForm} pageTitle={'Currency form'} goBackURL={'currencies'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}reviews`} component={Reviews} pageTitle={'Venue Reviews'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}riders`} component={Riders} pageTitle={'Riders'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}riders/history`} component={RiderHistory} pageTitle={'Delivery Ledger'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}riders/payments`} component={RidersPayments} pageTitle={'Settlements Ledger'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}riders/payments/new`} component={RidersPaymentsForm} pageTitle={'Add new Payment'} goBackURL={'riders/payments'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}tables`} component={Tables} pageTitle={'Tables'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}locations/settings/notification`} component={ToastifyNotification} pageTitle={'Notification Settings'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}tables/new`} component={TablesForm} goBackURL={'tables'} pageTitle={'Add New Tables'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}tables/edit/:table_id`} component={TablesForm} goBackURL={'tables'} pageTitle={'Edit Tables'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}staff`} component={Staff} pageTitle={'Manager Accounts'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}staff/assign/:id`} component={AssignRole} goBackURL={'staff'} pageTitle={'Assign Role'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}staff/assign/new/:id`} component={AssignRoleForm} goBackURL={'staff'} pageTitle={'Assign Role'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}staff/new`} component={StaffForm} goBackURL={'staff'} pageTitle={'Staff New'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}staff/edit/:id`} component={StaffForm} pageTitle={'Edit Staff'} goBackURL={'staff'} withConfirmation={true} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}roles`} component={Roles} pageTitle={'Roles'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}roles/new`} component={RolesForm} pageTitle={'Roles form'} goBackURL={'roles'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}roles/edit/:id`} component={RolesForm} pageTitle={'Edit Role'} goBackURL={'roles'} withConfirmation={true} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}locations/order_types`} component={OrderTypes} pageTitle={'Order Types'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}locations/rider_compensation`} component={RiderCompensation} pageTitle={'Rider Compensation'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}locations/overall_stats`} component={OverallStats} pageTitle={'Overall Stats'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}locations/customer_stats`} component={CustomerStats} pageTitle={'Customer Stats'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}locations/item_stats`} component={ItemsStats} pageTitle={'Item Stats'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}locations/modifiers`} component={Modifiers} pageTitle={'Modifiers'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}locations/menu`} component={Menu} pageTitle={'Menu'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}locations/outlets`} component={Outlets} pageTitle={'Outlets'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}locations/deliveries`} component={Deliveries} pageTitle={'Deliveries'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}locations/outlets/new`} component={NewOutlet} pageTitle={'New Outlet'} goBackURL={'locations/outlets'} withConfirmation={true} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}locations/outlets/:location_id`} component={Outlet} pageTitle={'Edit Outlet'} goBackURL={'locations/outlets'} withConfirmation={true} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}locations/branding`} component={Branding} pageTitle={'Branding'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}locations/promotions`} component={Promotions} pageTitle={'Promotions'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}locations/promotions/new`} component={PromotionForm} pageTitle={'Create new Promotion'} goBackURL={'locations/promotions'} withConfirmation={true} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}locations/promotions/edit/:id`} component={PromotionForm} pageTitle={'Edit Promotion'} goBackURL={'locations/promotions'} withConfirmation={true} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}session-activity`} component={SessionActivity} pageTitle={'Session Activity'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}locations/sms/campaigns`} component={SmsCampaigns} pageTitle={'SMS Campaigns'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}locations/sms/campaigns/new`} component={SmsCampaignsForm} pageTitle={'Create new campaign'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}locations/sms/campaigns/edit/:id`} component={SmsCampaignsForm} pageTitle={'Edit Campaign'} goBackURL={'locations/sms/campaigns'} withConfirmation={true} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}locations/sms/plans`} component={SmsPlans} pageTitle={'SMS Plans'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}locations/sms/subscriptions`} component={SmsSubscriptions} pageTitle={'SMS Subscriptions'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}locations/sms/plans/payment/:id`} component={SmsPayments} pageTitle={'SMS Plan Payments'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}locations/sms/admin/plans`} component={AdminSmsPlans} pageTitle={'SMS Plans'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}locations/sms/admin/plans/new`} component={SmsPlanForm} pageTitle={'SMS Plans'} goBackURL={'locations/sms/admin/plans'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}locations/sms/admin/plans/edit/:id`} component={SmsPlanForm} pageTitle={'Edit Sms Plan'} goBackURL={'locations/sms/admin/plans'} withConfirmation={true} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}locations/sms/admin/subscriptions`} component={AllSmsSubscriptions} pageTitle={'SMS Subscriptions'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}locations/payment_processor`} component={PaymentProcessor} pageTitle={'Payment Settings'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}locations/rider_services`} component={RiderServices} pageTitle={'Rider Services'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}locations`} component={Locations} pageTitle={'Select Restaurant'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}locations/all`} component={AllLocations} pageTitle={'Restaurants'} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}locations/new`} component={NewLocation} pageTitle={'New Restaurant'} goBackURL={'locations'} withConfirmation={true} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}locations/:location_id`} component={Location} pageTitle={'Restaurant Details'} goBackURL={'locations'} withConfirmation={true} />
          </Switch>
        </div>
      </BrowserRouter>
    </Layout>
  </Provider>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
