import React, { Component } from 'react';
import {Card, Input, Row} from 'reactstrap';
import SpinnerComponent from '../@vuexy/spinner/Fallback-spinner';
import LocationSelectHeader from '../shared/LocationSelectHeader';
import LocationService from '../../services/LocationService';
import OrderTypeService from '../../services/OrderTypeService';
import OrderTypeHelper from '../../helpers/OrderTypeHelper';

import '../../assets/scss/plugins/forms/switch/react-toggle.scss';
import 'react-toggle/style.css';
import SelectLocation from "../shared/SelectLocation";
import Toggle from "react-toggle";

class OrderTypes extends Component {

  constructor(props) {
    super(props);
    this.state = { orderTypes: null, selectLocation: false, location: null }
  }

  async componentDidMount() {
    this.setState({ loading: true });
    try {
      const location = LocationService.getLocation();
      if(location) {
        await this.refreshData(location.loc_id);
        this.setState({loading: false });
      } else {
        this.setState({loading: false, selectLocation: true})
      }
    } catch (e) {
      console.log(e);
      this.setState({ loading: false, error: true });
    }
  }

  async refreshData(loc_id) {
    const loc = await LocationService.getLocationFromServer(loc_id);
    const location = {
      loc_id: loc.loc_id,
      location_status: loc.location_status,
      name: loc.name
    };
    const orderTypes = await OrderTypeService.getOrderTypes(loc_id);
    this.setState({ orderTypes, location });
  }

  changeLocation() {
    this.setState({ selectLocation: true })
  }

  async onSelect(location) {
    this.setState({loading: true});
    LocationService.setLocation(location);
    await this.refreshData(location.loc_id);
    this.setState({selectLocation: false, loading: false});
  }

  onMinimumOrderChange(event) {
    let orderTypeId = parseInt(event.target.getAttribute('data-id'), 10);
    let orderTypes = [...this.state.orderTypes];
    orderTypes.forEach((orderType) => {
      if(orderType.id === orderTypeId) orderType[event.target.name] = event.target.value;
    });
    this.setState({ orderTypes });
  }

  onValueChange(isToggle, event) {
    let orderTypeId = parseInt(event.target.getAttribute('data-id'), 10);
    let orderTypes = [...this.state.orderTypes];
    let orderType = orderTypes.filter( o => o.id === orderTypeId)[0];
    if(isToggle)
      orderType[event.target.name] = !orderType[event.target.name];
    else
      orderType[event.target.name] = event.target.value;
    this.setState({ orderTypes });
    return this.updateOrderType(orderType);
  }

  saveOrderType(orderTypeId) {
    let orderTypes = [...this.state.orderTypes];
    orderTypes.forEach((orderType) => {
      if(orderType.id === orderTypeId) {
        orderType.loading = true;
        return this.updateOrderType(orderType);
      }
    });
    this.setState({ orderTypes });
  }

  async updateOrderType(orderType) {
    try {
      await OrderTypeService.updateOrderType(orderType);
    } catch (e) {
      console.log(e);
      alert('Something went wrong. Please refresh the page and try again.');
    }
  }

  renderOrderTypes() {
    return <Card className='full-width'>
      <div className='display-flex flex-row flex-space-between my-50 mx-50'>
        <div className='flex-3'>
        </div>
        <h6 className='flex-2'>
          Status
        </h6>
        <h6 className='flex-2'>
          Prepayment
        </h6>
        <h6 className='d-sm-none d-none d-md-block flex-2'>
          Minimum Order
        </h6>
      </div>
      { this.state.orderTypes.map((orderType, index) => {
        if(orderType.loading) return <SpinnerComponent size={20} key={orderType.id} />;
        return (
          <div className='display-flex flex-row flex-space-between my-1 mx-50'>
            <h5 className='flex-3'>
              {orderType.label}
            </h5>
            <div className='flex-2'>
              <Toggle
                name={'active'}
                data-id={orderType.id}
                defaultChecked={orderType['active']}
                className='switch-danger'
                onChange={this.onValueChange.bind(this, true)}
              />
              <label className='vertical-align-super font-small-3 ml-25'>{orderType['active'] ? 'Active' : 'Inactive'}</label>
            </div>
            <div className='flex-2'>
              <Toggle
                name={'pre_payment'}
                data-id={orderType.id}
                defaultChecked={orderType['pre_payment']}
                className='switch-danger'
                onChange={this.onValueChange.bind(this, true)}
              />
              <label className='vertical-align-super font-small-3 ml-25'>{orderType['pre_payment'] ? 'Mandatory' : 'Optional'}</label>
            </div>
            <div className='d-sm-none d-none d-md-block flex-2'>
              { OrderTypeHelper.isDelivery(this.state.orderTypes, orderType.id) ?
              <Input
                data-id={orderType.id}
                name={`minimum_order`}
                placeholder={'Minimum Order'}
                type='number'
                value={orderType.minimum_order}
                onChange={this.onValueChange.bind(this, false)}
              /> : null }
            </div>
          </div>
        );
      }) }
    </Card>
  }

  toggleLocation() {
    this.setState({selectLocation: !this.state.selectLocation});
  }

  async onResStatusToggle(event) {
    this.setState({ loading: true });
    const {
      state: {
        location: loc
      }
    } = this;
    let location = JSON.parse(JSON.stringify(loc));
    location.location_status = event.target.checked;
    await LocationService.updateLocation(location);
    this.setState({ loading: false, location });
  }

  render() {
    const { state: {
      orderTypes,
      loading,
      selectLocation,
      location
    }} = this;
    
    const locationName = location ? location.name : '';

    // TODO: Implement proper error handling
    if( selectLocation )
      return <SelectLocation onBack={this.toggleLocation.bind(this)} onSelect={this.onSelect.bind(this)} />;

    if(orderTypes && orderTypes.length > 0)
      return (
        <div className='order-types'>
          <LocationSelectHeader text={locationName} onIconClick={this.changeLocation.bind(this)} />
          <Row className='mx-50 my-1'>
            <h2>Restaurant Status</h2>
            <Toggle
              name={'res_status'}
              defaultChecked={location.location_status}
              className='switch-danger ml-2 align-self-baseline'
              onChange={this.onResStatusToggle.bind(this)}
            />
          </Row>
          <div className='mt-1'>
            {this.renderOrderTypes()}
          </div>
        </div>
      );
    if(loading) return <SpinnerComponent />;
    return '';
  }
}

export default OrderTypes;
