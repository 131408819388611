import React, {useEffect, useState} from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {ClipLoader} from "react-spinners";
import {X} from "react-feather";
import CollapsibleCardComponent from "../shared/CollapsibleCardComponent";
import DnDListView from "../shared/DnDListView";

const CategoryItemSelectionModal = (props) => {

  const getItemCatMap = (cats) => {
    const map = {};
    for( let i=0; i<cats.length; i++) {
      const menu = cats[i].Menus;
      for(let j=0; j<menu.length; j++) {
        map[menu[j].menu_id] = cats[i].category_id;
      }
    }
    return map;
  }

  const saveAction = () => {
    console.log(JSON.stringify(selectedItems));
    console.log(JSON.stringify(selectedCategories));
    setSaving(true);
    updatePromotionItem(selectedItems, selectedCategories);
    setSaving(false);
    toggleModal();
  };

  const keydownHandler = (e) => {
    if(e.keyCode===13 && e.ctrlKey) saveAction();
  };

  const toggleCollapse = collapseID => {
    let index = collapseItems.indexOf(collapseID);
    if (index >= 0) {
      let items = collapseItems.splice(0);
      items.splice(index, 1);
      setCollapseItems(items);
    } else {
      let items = collapseItems.splice(0);
      items.push(collapseID);
      setCollapseItems(items);
    }
  }

  const updateSelectedModifiers = (type, itemId) => {
    itemId = parseInt(itemId);
    if(type == 0) {
      const selectedCats = selectedCategories.splice(0);
      const index = selectedCats.indexOf(itemId);
      if(index === -1) {
        selectedCats.push(itemId);
        const sItems = [];
        for( let i=0; i<selectedItems.length; i++ ) {
          if(itemCatMap[selectedItems[i]] == itemId)
            continue;
          sItems.push(selectedItems[i]);
        }
        setSelectedItems(sItems);
      }
      else
        selectedCats.splice(index, 1);
      setSelectedCategories(selectedCats);
    } else if(type == 1) {
      const selectedIds = selectedItems.splice(0);
      const index = selectedIds.indexOf(itemId);
      if(index === -1)
        selectedIds.push(itemId);
      else
        selectedIds.splice(index, 1);
      setSelectedItems(selectedIds);
    }
  }

  useEffect(()=> {
    document.addEventListener('keydown', keydownHandler);
    return () => document.removeEventListener('keydown', keydownHandler);
  });

  let {
    isOpen,
    toggleModal,
    items: propItems,
    selectedItems: selectItemObj,
    location,
    updatePromotionItem,
  } = props;

  const [saving, setSaving] = useState(false );
  const [collapseItems, setCollapseItems] = useState([] );
  const [items, setItems] = useState(propItems.map( item => ({id: item.category_id, title: item.name, ...item})));

  const [selectedCategories, setSelectedCategories] = useState(selectItemObj.filter(item => item.item_type == 0).map(item => item.item));
  const [selectedItems, setSelectedItems] = useState(selectItemObj.filter(item => item.item_type == 1).map(item => item.item));

  const [itemCatMap, setItemCatMap] = useState( getItemCatMap(propItems) );

  useEffect(() => {
    isOpen = props.isOpen;
    selectItemObj = props.selectedItems;
    setSelectedCategories(selectItemObj.filter(item => item.item_type == 0).map(item => item.item));
    setSelectedItems(selectItemObj.filter(item => item.item_type == 1).map(item => item.item));
  }, [props]);

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={saveAction}
        className='modal-dialog-centered d-sm-margin-0 p-0'
      >
        <div className='modal-header display-flex full-width flex-space-between'>
          <h5 className='modal-title'>{ 'Add Items to Promotion' }</h5>
          <X size={20} onClick={saveAction} />
        </div>
        <ModalBody>
          <div>
            { items && items.map(item => (
            <div className='no-padding no-border'>
              <CollapsibleCardComponent bodyColor='content-dark-bg-color' isPromotion={true} updateSelectedModifiers={ updateSelectedModifiers.bind(this, 0) } selectedIds={selectedCategories} item={item} toggleCollapse={toggleCollapse.bind(this)} isOpen={ collapseItems.indexOf(item.id) >= 0 } >
                <DnDListView isDragDisabled={true} menu={item.Menus} location={location} hideEditableButtons={true} updateSelectedModifiers={ updateSelectedModifiers.bind(this, 1) } selectedIds={selectedItems} categorySelected={selectedCategories.indexOf(item.id) > -1} />
              </CollapsibleCardComponent>
            </div>
            ))}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className='d-none d-sm-flex flex-space-between full-width'>
            <div>
              <Button color='dark' disabled={saving} onClick={ () => {
                if(saving) return;
                toggleModal();
              } }>
                Cancel
              </Button>{' '}
              <Button color='primary' disabled={saving} onClick={ saveAction }>
                { saving ? <ClipLoader size={15} /> : 'Save' }
              </Button>{' '}
            </div>
          </div>
          <div className='d-sm-none col-12'>
            <Button className='col-12' color="primary" disabled={saving} onClick={ saveAction }>
              { saving ? <ClipLoader size={15} /> : "Save" }
            </Button>{' '}
            <Button className='col-12 my-50' color="dark" disabled={saving} onClick={ () => {
              if(saving) return;
              toggleModal();
            } }>
              Cancel
            </Button>{' '}
          </div>
          <p className='d-none d-sm-inline col-12 align-text-right font-small-1 pr-0'>
            Hit Ctrl + Enter to Save
          </p>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}
export default CategoryItemSelectionModal;