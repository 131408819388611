import React from "react";
import DataTable from "react-data-table-component";
import TimeHelper from "../../helpers/TimeHelper";
import ViewHelper from "../../helpers/ViewHelper";
import Checkbox from "../@vuexy/checkbox/CheckboxesVuexy";
import { Check, Edit2 } from "react-feather";
import { Button } from "reactstrap";
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';


const ListCurrency = (props) => {

  const editCurrency = (id) => {
    props.editCurrency(id);
  }

  const columns = [
    {
      name: 'Edit',
      selector: 'currency_id',
      format: (val) => {
        return <Button outline className='btn-icon' color='primary' onClick={() => editCurrency(val.currency_id)}><Edit2 color='var(--primary)' className='ml-0 p-0' size={10} /></Button>

      }
    }, {
      name: 'ID',
      selector: 'currency_id',
      sortable: true,
    }, {
      name: 'Name',
      selector: 'currency_name',
      sortable: true,
      format: (val) => {
        return val.currency_name ? val.currency_name : 'Not Available';
      }
    },
    {
      name: 'Code',
      selector: 'currency_code',
      sortable: true,
      format: (val) => {
        return val.currency_code ? val.currency_code : 'Not Available';
      }
    }, {
      name: 'Country',
      selector: 'Country.country_name',
      sortable: true,
      format: (val) => {
        return val.Country && val.Country.country_name ? val.Country.country_name : 'Not Available';
      }
    }, {
      name: 'Rate',
      selector: 'currency_rate',
      sortable: true,
    }
    , {
      name: 'Symbol',
      selector: 'currency_symbol',
      sortable: true,
      format: (val) => {
        return val.currency_symbol ? val.currency_symbol : 'Not Available';
      }
    }, {
      name: 'Status',
      selector: 'currency_status',
      sortable: true,
      format: (val) => {
        return val.currency_status == 0 ? 'Disabled' : 'Enabled';
      }
    }
  ];

  const renderCurrencies = (currencies, handleChange) => {
    if (!currencies || currencies.length === 0)
      return <h1>No App user found.</h1>;

    const tableData = {
      columns: columns,
      data: currencies,
      print: false,
      export: false,
    };
    return <DataTableExtensions
      exportHeaders
      {...tableData}
    >

       <DataTable
        noHeader
        pagination
        paginationRowsPerPageOptions={[25, 50, 100]}
        paginationPerPage={50}
        selectableRows
        selectableRowsComponent={Checkbox}
        selectableRowsComponentProps={{
          color: "primary",
          icon: <Check className="vx-icon" size={12} />,
          label: "",
          size: "sm"
        }}
        onSelectedRowsChange={handleChange}
        columns={columns}
        data={currencies}
      />
      </DataTableExtensions>
      ;
  }
      return renderCurrencies(props.currencies, props.handleChange);

}

      export default ListCurrency;
