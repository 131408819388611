import React, {Component} from "react";
import {connect} from "react-redux";
import {Plus} from "react-feather";
import {Button} from "reactstrap";

import {getLocation, getOutletsFor} from "../../redux/actions/locations";
import CONSTANTS from "../../constants";
import LocationService from "../../services/LocationService";

import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import SelectLocation from "../shared/SelectLocation";
import LocationSelectHeader from "../shared/LocationSelectHeader";
import ConfirmationModal from "../shared/forms/ConfirmationModal";

import OutletList from "./OutletList";
import MasterOutletModal from "./MasterOutletModal";

class Outlets extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectLocation: false,
      location: null,
      loading: true,
			masterOutletModalOpen: false,
      confirmationDialog: {
        show: false,
        acceptCallback: () => {},
        rejectCallback: () => {},
        title: '',
      },
    };
  }

  async componentDidMount() {
    const location = LocationService.getLocation(`${CONSTANTS.CACHE_KEY_PREFIX}_location`);
    if(location) {
      await this.refreshData(location.loc_id);
    } else {
      this.setState({loading: false, selectLocation: true})
    }
  }

  async refreshData(loc_id) {
    let location;
    if(loc_id !== 0 && ( !this.state.location || loc_id !== this.state.location.loc_id)) {
      await this.getOutlets(loc_id)
      await this.props.getLocation(loc_id);
      location = this.props.location;
    } else {
      location = this.state.location;
    }
    this.setState({ loading: false, location: JSON.parse(JSON.stringify(location)) });
  }

  async getOutlets(loc_id) {
    return this.props.getOutletsFor(loc_id);
  }

  onBlur() {
    if( this.state.location )
      this.setState({selectLocation: !this.state.selectLocation});
  }

  toggleLocation() {
    this.setState({selectLocation: !this.state.selectLocation});
  }

  editOutlet(loc_id, isOutlet) {
    if(isOutlet) {
			//return this.props.history.push(`${CONSTANTS.BASE_URL}locations/outlets/${loc_id}`);
      return this.props.history.push(`${CONSTANTS.BASE_URL}locations/${loc_id}`, { goBackUrl: 'locations/outlets' });
		}
		return this.props.history.push(`${CONSTANTS.BASE_URL}locations/${loc_id}`, { goBackUrl: 'locations/outlets' });
  }

  async onSelect(location) {
    this.setState({loading: true});
    LocationService.setLocation(location, `${CONSTANTS.CACHE_KEY_PREFIX}_location`);
    await this.refreshData(location.loc_id);
    this.setState({selectLocation: false, loading: false});
  }

	async addNewOutlet() {
		if(this.state.location.is_master_venue) {
			this.props.history.push('/locations/outlets/new');
		} else {
			this.toggleMasterOutletModal();	
		}
	}

	async enableOutlets(name, slug) {
		const location = await LocationService.enableOutlets({
			name,
			slug,
			loc_id: this.state.location.loc_id,
		});
		await LocationService.getLocations('');
		await LocationService.setLocation(location, `${CONSTANTS.CACHE_KEY_PREFIX}_location`);
		await this.toggleMasterOutletModal();
		this.props.history.push('/locations/outlets/new');
	}

	toggleMasterOutletModal() {
		return this.setState({ masterOutletModalOpen: !this.state.masterOutletModalOpen });
	}

  render() {
    const { props: {
      outlets = [],
    }} = this;

    const { state: {
      selectLocation,
      confirmationDialog,
      loading,
			masterOutletModalOpen,
      location: stateLocation
    }} = this;

    if( loading ) return <SpinnerComponent />;

    const locationName = stateLocation ? stateLocation.name : '';

    if ( selectLocation )
      return <SelectLocation onBack={this.toggleLocation.bind(this)} onSelect={this.onSelect.bind(this)} showOutlets={false} />;

    return (
      <div className='location'>
        <div className='display-flex flex-row flex-space-between mb-1 align-items-center'>
          <LocationSelectHeader text={locationName} onIconClick={this.toggleLocation.bind(this)} />
          { !stateLocation.is_outlet && <Button outline className="font-weight-bold custom-padding disabled-2-border btn-sm-font" color={ "secondary" } size="md" onClick={this.addNewOutlet.bind(this)}>
            <Plus size={20} /> <span className="ml-1 mr-1 d-md-inline">Add New Outlet</span>
          </Button> }
        </div>
        { !loading && outlets ? <OutletList location={stateLocation} outlets={outlets} editOutlet={this.editOutlet.bind(this)} /> : null }
        <ConfirmationModal title={confirmationDialog.title} isOpen={confirmationDialog.show} accept={confirmationDialog.acceptCallback.bind(this)} reject={confirmationDialog.rejectCallback.bind(this)} />
				<MasterOutletModal isOpen={masterOutletModalOpen} toggleModal={this.toggleMasterOutletModal.bind(this)} enableOutlets={this.enableOutlets.bind(this)} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { locations: {
    location: {
      data: locationData, loading: locationLoading
    },
    outlets: {
      data: outlets, loading: outletsLoading
    }
  }} = state;

  return { outlets, location: locationData, loading: (locationLoading || outletsLoading) };
}

export default connect(mapStateToProps, { getLocation, getOutletsFor })(Outlets);