import React, {Component} from 'react';
import {Button, Modal, ModalBody, ModalHeader, ModalFooter} from 'reactstrap';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import chroma from "chroma-js";

import Radio from '../@vuexy/radio/RadioVuexy';
import CONSTANTS from '../../constants';

import OrderService from '../../services/OrderService';
import {connect} from "react-redux";
import { getDeliveryProcessorInfo } from "../../redux/actions/locations";
import TimeHelper from "../../helpers/TimeHelper";

import '../../assets/css/deliveryStatus.scss';

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color)
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? data.color
          : isFocused
            ? color.alpha(0.1).css()
            : null,
      color: isDisabled
        ? "#ccc"
        : isSelected
          ? chroma.contrast(color, "white") > 2
            ? "white"
            : "black"
          : data.color,
      cursor: isDisabled ? "not-allowed" : "default",
      ":active": {
        ...styles[":active"],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : color.alpha(0.3).css())
      }
    }
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css()
    }
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ":hover": {
      backgroundColor: data.color,
      color: "white"
    }
  })
}
class DeliveryStatus extends Component {

  constructor() {
    super();
    this.state = {
      loading: false,
      isModalOpen: false,
      deliveryAction: CONSTANTS.VARIABLES.ORDERS.DELIVERY_ACTIONS.NOT_ASSIGNED,
      riders: [],
      selectedRiders: [],
      savingRiders: false,
      showCancelRequest: false,
      loadingCancelRequest: false,
      order: {},
			showErrorAddingRiders: false,
			riderAddErrorMessage: '',
			radioDeliveryAction: CONSTANTS.VARIABLES.ORDERS.DELIVERY_ACTIONS.NOT_ASSIGNED,
    }
  }

  async componentDidMount() {
    let { props: {
      order
    }} = this;
		let deliveryAction = parseInt(order.delivery_action);
    let { showCancelRequest } = this.state;
    if([CONSTANTS.VARIABLES.ORDERS.DELIVERY_ACTIONS.NOT_ASSIGNED, CONSTANTS.VARIABLES.ORDERS.DELIVERY_ACTIONS.CANCELLED].indexOf(deliveryAction) === -1) {
      showCancelRequest = true;
    }
    this.setState({ order: order, deliveryAction, showCancelRequest });
  }

  async componentWillReceiveProps(nextProps) {
    const { order } = nextProps;
    const state = {};
    if(order.OrderDelivery)
      state['order'] = order;

    state['deliveryAction'] = parseInt(order.delivery_action);
    if([CONSTANTS.VARIABLES.ORDERS.DELIVERY_ACTIONS.NOT_ASSIGNED, CONSTANTS.VARIABLES.ORDERS.DELIVERY_ACTIONS.CANCELLED].indexOf(state['deliveryAction']) === -1) {
      state['showCancelRequest'] = true;
    }
    this.setState(state);
  }

	getRiderName(history) {
		let name = history.delivery_status_type === 1 ? 'PandaGo' : history.User.Staff.staff_name;
		if (name === '')
			name = history.User.email;
		return name;
	}

	getRiderNumber({OrderDelivery: orderDelivery}) {
		if(orderDelivery && orderDelivery.type === 1) {
			const thirdPartyData = orderDelivery.third_party_data ? JSON.parse(orderDelivery.third_party_data) : { driver: {} };
			return thirdPartyData.driver ? thirdPartyData.driver.phone_number : null;
		}
		return null;
	}

  getLatestStatusText() {
    if(this.state.isModalOpen) {
      let {order} = this.state;
      if (!order.OrderDelivery)
        return null;

      let length = order.OrderDelivery.StatusHistories.length;
      let history = order.OrderDelivery.StatusHistories[length - 1];

      const name = this.getRiderName(history);

      let status = history.OrderStatus;
      let status_text = `${status.status_name} by ${name}`;
      if (status.status_id === CONSTANTS.VARIABLES.ORDERS.DELIVERY_ACTIONS.SPECIFIC_RIDERS) {
        status_text += `${status.status_name} requested by ${name}`;
			}

			if (status.status_id === CONSTANTS.VARIABLES.ORDERS.DELIVERY_ACTIONS.THIRD_PARTY) {
        status_text = `PandaGo requested by ${name}`;
			}
			if (status.status_id === CONSTANTS.VARIABLES.ORDERS.DELIVERY_ACTIONS.ON_THE_WAY) {
        status_text = 'On the way to customer';
			}

      return (
        <div className='f3'>
          <strong style={{color: status.status_color}}>{status_text}</strong>
          <div className='f3'>{TimeHelper.toFormattedDateTime(history.date_added)}</div>
          {history.comment && <div className='f3 font-italic text-muted'>{history.comment}</div>}
        </div>
      );
    }
    return null;
  }

  async fetchRiders() {
    await this.props.getDeliveryProcessorInfo(this.state.order.Location.loc_id, this.state.order.order_id);
    let riders = this.props.riders.map( rider => {
      let name = rider.first_name + ' ' + rider.last_name;
      if(name === ' ') {
        name = rider.Staff.staff_name;
        if(name === '') {
          name = rider.Staff.staff_email;
        }
      }
      return { value: rider.user_id, label: name, user_id: rider.user_id, color: '#fff' };
    } );
    let selectedRiders = riders.filter( rider => {
      let filtered = this.state.order.OrderVisibilities.filter(ro => ro.rider_id === rider.user_id);
      return filtered.length > 0;
    });
		const state = {
			riders,
			selectedRiders,
		}
		if(this.props.thirdPartyMessage) {
			state.showErrorAddingRiders = true;
			state.riderAddErrorMessage = this.props.thirdPartyMessage;
		}
    this.setState(state);
  }

  async handleRadioActionChange(e) {
		await this.setState({ radioDeliveryAction: parseInt(e.target.value),deliveryAction: parseInt(e.target.value), showErrorAddingRiders: false });
  }

  handleDriverSelectionChange(e) {
    this.setState({ selectedRiders: e });
  }

  toggleModal() {
    this.setState({isModalOpen: !this.state.isModalOpen}, async () => {
    	this.setState({loading: true, showErrorAddingRiders: false});
			await this.fetchRiders();
			this.setState({loading: false});
    });
  }

  async saveAction() {
		if([CONSTANTS.VARIABLES.ORDERS.DELIVERY_ACTIONS.BROADCAST, CONSTANTS.VARIABLES.ORDERS.DELIVERY_ACTIONS.SPECIFIC_RIDERS, CONSTANTS.VARIABLES.ORDERS.DELIVERY_ACTIONS.THIRD_PARTY].indexOf(this.state.radioDeliveryAction) === -1) {
			return this.setState({ loading: false, savingRiders: false, showErrorAddingRiders: true, riderAddErrorMessage: 'Please select atleast one option' });
		}
    let stateChanges = { loading: false, isModalOpen: false, savingRiders: false };
    if( !this.state.loadingCancelRequest ) {
      const data = { deliveryAction: this.state.radioDeliveryAction, riders: this.state.selectedRiders.map(rider => rider.user_id) };
      this.setState({ loading: true, savingRiders: true });
      const response = await OrderService.addRiders(this.state.order.location_id, this.state.order.order_id, data);
			this.props.onOrderUpdate();
			if(response.status !== 200) {
				return this.setState({ loading: false, savingRiders: false, showErrorAddingRiders: true, riderAddErrorMessage: response.message ? response.message : 'Unable to add riders' });
			}
      stateChanges.showCancelRequest = true;
    }
    this.setState(stateChanges);
  }

  async cancelDeliveryRequest() {
    await this.setState({ loading: true, loadingCancelRequest: true });
    const response = await OrderService.cancelRiderRequest(this.state.order.location_id, this.state.order.order_id);
    
		if(response.status !== 200) {
			await this.setState({ loading: false, loadingCancelRequest: false, showErrorAddingRiders: true, riderAddErrorMessage: response.message });
		}
    await this.setState({ loading: false, isModalOpen: true, loadingCancelRequest: false, showCancelRequest: false, savingRiders: false, deliveryAction: CONSTANTS.VARIABLES.ORDERS.DELIVERY_ACTIONS.NOT_ASSIGNED, radioDeliveryAction: CONSTANTS.VARIABLES.ORDERS.DELIVERY_ACTIONS.NOT_ASSIGNED,});
		return this.props.onOrderUpdate();
  }

	getActionButtons({showCancelRequest, loadingCancelRequest, savingRiders, order, loading}) {
		const number = this.getRiderNumber(order);
		if(showCancelRequest) {
			if(loadingCancelRequest) {
				return <Button color='primary'>
					<ClipLoader size={15} />
				</Button>
			}
			const buttons = [];
			buttons.push(<Button color='danger' onClick={this.cancelDeliveryRequest.bind(this)}>
				Cancel Rider Request
			</Button>);
			if(number) {
				buttons.push(<a className='btn btn-primary float-right' href={`tel:${number}`}>
					Call Rider
				</a>);
			}
			return buttons;
		} else if(savingRiders) {
			return <Button color='primary'>
				<ClipLoader size={15} />
			</Button>
		}
		return <Button disabled={loading} color='primary' onClick={this.saveAction.bind(this)}>
			Done
		</Button>
	}

  render() {
    let { order, showActionText, deliveryAction, radioDeliveryAction } = this.state;
    let text = showActionText && deliveryAction === CONSTANTS.VARIABLES.ORDERS.DELIVERY_ACTIONS.NOT_ASSIGNED ? CONSTANTS.VARIABLES.ORDERS.DELIVERY_ACTION_TEXT[deliveryAction + "_action"] : CONSTANTS.VARIABLES.ORDERS.DELIVERY_ACTION_TEXT[deliveryAction];
    let conditionalComp = this.state.loading ? <ClipLoader size={15} /> : <Button className={'delivery-action-button'} color={ CONSTANTS.VARIABLES.ORDERS.DELIVERY_ACTION_TEXT_COLORS[deliveryAction] } size={'small'} onClick={this.toggleModal.bind(this)}>{text}</Button>;
    return [
      conditionalComp,
      <Modal
        isOpen={ this.state.isModalOpen }
        toggle={this.toggleModal.bind(this)}
        className='modal-dialog-centered'>
        <ModalHeader toggle={this.toggleModal.bind(this)}>
          Assign Delivery Job to Riders
        </ModalHeader>
        <ModalBody>
					{ this.state.loading ? <ClipLoader size={25} /> 
          : this.state.showCancelRequest ? this.getLatestStatusText() : (
						<React.Fragment>
								{ this.state.riders.length > 0 && <>
									<Radio
										label='Broadcast'
										color='primary'
										defaultChecked={ radioDeliveryAction === CONSTANTS.VARIABLES.ORDERS.DELIVERY_ACTIONS.BROADCAST }
										checked={ radioDeliveryAction === CONSTANTS.VARIABLES.ORDERS.DELIVERY_ACTIONS.BROADCAST }
										value={ CONSTANTS.VARIABLES.ORDERS.DELIVERY_ACTIONS.BROADCAST }
										onChange={ this.handleRadioActionChange.bind(this) }
										name='deliveryAction'
										className='py-50'
									/>
									<Radio
										label='Specific Riders'
										color='primary'
										defaultChecked={ parseInt(radioDeliveryAction) === CONSTANTS.VARIABLES.ORDERS.DELIVERY_ACTIONS.SPECIFIC_RIDERS }
										checked={ parseInt(radioDeliveryAction) === CONSTANTS.VARIABLES.ORDERS.DELIVERY_ACTIONS.SPECIFIC_RIDERS }
										value={CONSTANTS.VARIABLES.ORDERS.DELIVERY_ACTIONS.SPECIFIC_RIDERS}
										name='deliveryAction'
										className='py-50'
										onChange={this.handleRadioActionChange.bind(this)}
									/>
									{ parseInt(deliveryAction) === CONSTANTS.VARIABLES.ORDERS.DELIVERY_ACTIONS.SPECIFIC_RIDERS ? (
										<div className={'form-group'}>
											<h5 className='text-bold-600 my-1'>Select Riders</h5>
											<Select
												defaultValue={this.state.selectedRiders}
												isMulti
												name='colors'
												options={this.state.riders}
												styles={colourStyles}
												className='React'
												classNamePrefix='select'
												onChange={this.handleDriverSelectionChange.bind(this)}
											/>
										</div>
									) : null }
									</> }
								{ this.props.thirdPartyEnabled && <Radio
									label='PandaGo'
									color='primary'
									defaultChecked={ parseInt(radioDeliveryAction) === CONSTANTS.VARIABLES.ORDERS.DELIVERY_ACTIONS.THIRD_PARTY }
									checked={ parseInt(radioDeliveryAction) === CONSTANTS.VARIABLES.ORDERS.DELIVERY_ACTIONS.THIRD_PARTY }
									value={CONSTANTS.VARIABLES.ORDERS.DELIVERY_ACTIONS.THIRD_PARTY}
									name='deliveryAction'
									className='py-50'
									onChange={this.handleRadioActionChange.bind(this)}
								/> }
								{ !this.props.thirdPartyEnabled && this.state.riders.length === 0 && <p>
									No Rider has been added in the system.
								</p> }
            </React.Fragment> )}
						{ this.state.showErrorAddingRiders && <p style={{color: 'red'}}>{this.state.riderAddErrorMessage}</p>}
        </ModalBody>
        <ModalFooter>
					{this.getActionButtons(this.state)}
        </ModalFooter>
      </Modal>
    ];
  }
}

function mapStateToProps(state) {
	const { 
		locations: {
			deliveryProcessors: {
				data: deliveryData, loading: dataLoading
			}
  }} = state;
  const riders = dataLoading ? null : deliveryData.riders;
	const thirdPartyEnabled = dataLoading ? null : deliveryData.thirdPartyEnabled;
	const thirdPartyMessage = dataLoading ? null : deliveryData.thirdPartyMessage;
  return { loading: dataLoading, riders: riders, thirdPartyEnabled, thirdPartyMessage };
}

export default connect(mapStateToProps, { getDeliveryProcessorInfo })(DeliveryStatus);