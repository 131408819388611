import CONSTANTS from '../constants';

export default class OrderTypeHelper {
  static getOrderType(orderTypes, orderType) {
    if(orderTypes.length === 0) return { order_type: orderType }; // When order type is already present
    return orderTypes.find((ot) => ot.id === orderType);
  }

  static isDelivery(orderTypes, orderTypeId) {
    let orderType = this.getOrderType(orderTypes, orderTypeId);
    return orderType && orderType.order_type === CONSTANTS.VARIABLES.ORDER_TYPES.DELIVERY;
  }

  static isDineIn(orderTypes, orderTypeId) {
    let orderType = this.getOrderType(orderTypes, orderTypeId);
    return orderType && orderType.order_type === CONSTANTS.VARIABLES.ORDER_TYPES.DINE_IN;
  }

  static isPickup(orderTypes, orderTypeId) {
    let orderType = this.getOrderType(orderTypes, orderTypeId);
    return orderType && orderType.order_type === CONSTANTS.VARIABLES.ORDER_TYPES.PICK_UP;
  }

  static isCurbsidePickup(orderTypes, orderTypeId) {
    let orderType = this.getOrderType(orderTypes, orderTypeId);
    return orderType && orderType.order_type === CONSTANTS.VARIABLES.ORDER_TYPES.CURBSIDE_PICKUP;
  }
}
