import React from "react";
import { AvInput } from "availity-reactstrap-validation";
import {
  Label,
  FormGroup,
  Button,
  Input,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import LocationService from "../../../services/LocationService";
import CONSTANTS from "../../../constants";
import { connect } from "react-redux";
import SpinnerComponent from "../../@vuexy/spinner/Fallback-spinner";
import StaffService from "../../../services/StaffService";
import RolesService from "../../../services/RolesService";

import ConfirmationModal from "../../shared/forms/ConfirmationModal";
import { getAllLocations } from "../../../redux/actions/locations";
import { getRoles, getStaff, assign } from "../../../redux/actions/staff";
import Toggle from "react-toggle";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import _ from "lodash";
import ViewHelper from "../../../helpers/ViewHelper";

import "../../../assets/scss/plugins/forms/react-select/_react-select.scss";
import { X } from "react-feather";
import { ClipLoader } from "react-spinners";

class RiderAssignModal extends React.Component {
  state = {
    loading: false,
    saving: false,
    users: [],
    isOpen: false,
    isAdmin: false,
    searchTerm: "",
    error: "",
  };

  async componentDidMount() {
    this.setState({ saving: false });
  }

  onConditionChange = (selectedOption) => {
    this.updateValue(selectedOption.value, "plan_time");
  };

  handleInputChange = (searchTerm) => {
    this.setState({ searchTerm });
    return searchTerm;
  };

  onChange(selected) {
    if (selected) {
      if (this.props.onSelect) this.props.onSelect(selected.location);
      else this.onSelect(selected.location);
    }
  }

  onChangeRole(selected) {
    if (selected) {
      this.onSelectRole(selected.value);
    }
  }

  onBlur() {
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  }

  async saveRider() {
    await this.setState({ saving: true });
    await this.props.save();
  }

  async loadOptionsEmail(term, callback) {
    if (term.length < 3) return callback([]);
    try {
      const staffs = await StaffService.getStaffByEmail(term);
      callback(ViewHelper.transformedStaff(staffs));
    } catch (e) {
      console.log(e);
      alert("Could not load staffs");
    }
  }
  onChangeEmail(selected) {
    if (selected) {
      this.props.setUser(selected);
    }
  }

  handleEmailInputChange = (searchTerm) => {
    this.setState({ searchEmailTerm: searchTerm, error: "" });
    return searchTerm;
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ saving: false, error: this.props.error });
    }
  }

  render() {
    const {
      props: { isOpen, toggleModal },
    } = this;

    const {
      state: { loading, error, saving },
    } = this;

    if (loading) return <SpinnerComponent />;
    return (
      <React.Fragment>
        <Modal
          isOpen={isOpen}
          toggle={toggleModal}
          className="modal-dialog-centered d-sm-margin-0 p-0"
        >
          <div className="modal-header display-flex full-width flex-space-between">
            <h5 className="modal-title">Assign new rider</h5>
            <X size={20} onClick={toggleModal} />
          </div>
          <ModalBody>
            {error ? <span className="danger">{error}</span> : null}
            <FormGroup>
              <Label> Rider Email </Label>
              <AsyncSelect
                placeholder="Please enter email"
                autoFocus
                isClearable
                cacheOptions
                onBlur={this.onBlur.bind(this)}
                onChange={this.onChangeEmail.bind(this)}
                loadOptions={this.loadOptionsEmail.bind(this)}
                onInputChange={this.handleEmailInputChange.bind(this)}
                noOptionsMessage={() => "No email presents."}
                className="React"
                classNamePrefix="select"
                styles={{
                  control: (provided) => {
                    return { ...provided, borderRadius: "0.3rem" };
                  },
                  dropdownIndicator: (provided) => {
                    return { ...provided, display: "none" };
                  },
                  indicatorSeparator: (provided) => {
                    return { ...provided, display: "none", color: "#fff" };
                  },
                  input: (provided) => {
                    return { ...provided, color: "#fff" };
                  },
                }}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <div className="d-none d-sm-flex flex-space-between full-width">
              <div>
                <Button
                  color="dark"
                  onClick={() => {
                    toggleModal();
                  }}
                >
                  Cancel
                </Button>{" "}
                <Button color="primary" onClick={() => this.saveRider()}>
                  {saving ? <ClipLoader size={15} /> : "Save"}
                </Button>
              </div>
            </div>

            <p className="d-none d-sm-inline col-12 align-text-right font-small-1 pr-0">
              Hit Ctrl + Enter to Save
            </p>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default RiderAssignModal;
