import React from 'react';
import VerticalLayout from '../../layouts/VerticalLayout';
import FullLayout from '../../layouts/FullpageLayout';
import HorizontalLayout from '../../layouts/HorizontalLayout';
import themeConfig from '../../configs/themeConfig';
import styled from "styled-components";
import { isAndroid, isIOS } from "react-device-detect";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { toast, ToastContainer } from "react-toastify";
import SocketService from "../../services/SocketService";
import CONSTANTS from "../../constants";
import { Col, Row } from "react-bootstrap";
import SoundIcon from "../../assets/img/static/notification_soundIcon.svg";
import CloseNotificationIcon from '../../assets/img/static/notification_closeIcon.svg';
import ReactHowler from 'react-howler'
import { getLiveOrders } from "../../redux/actions/orders";
import { connect } from "react-redux";
import { setCount, setMode, setOneCard, setOnPlay, setVolume } from "../../redux/actions/notifications";

let audio;

toast.configure()

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const layouts = {
	vertical: VerticalLayout,
	full: FullLayout,
	horizontal: HorizontalLayout
};

const ContextLayout = React.createContext();

class Layout extends React.Component {
	constructor(props) {
		super(props);
		this.toastId = React.createRef();
		// this.callNotificationBtnRef = React.createRef();
		this.state = {
			activeLayout: themeConfig.layout,
			width: window.innerWidth,
			lastLayout: null,
			direction: themeConfig.direction,
			pathname: window.location.pathname,
			connection: window.navigator.onLine,
			openOnlineNotification: false,
			notificationMsg: '',
			notificationSeverity: '',
			isSilent: false,
			audioRepeat: false,
			count: 1,
			howler_sound_loop: false,
		};
	}

	dismissNotification() {
		//window.Howler.mute(true);
		this.props.setOnPlay(false)
		this.props.setOneCard(false)
		this.props.setCount(1)
		toast.dismiss(this.toastId.current);

	}

	handleRouter() {
		// window.location.href = "/";
	}

	mute() {
		this.props.setOnPlay(false)
		this.props.setOneCard(false)
		this.props.setCount(1)
		toast.dismiss(this.toastId.current);
	}

	updatePathname() {
		this.setState({ pathname: window.location.pathname });
	}

	updateWidth() {
		this.setState({ width: window.innerWidth });
	};

	handleWindowResize() {
		this.updateWidth();
		if (this.state.activeLayout === 'horizontal' && this.state.width <= 1199)
			this.setState({ activeLayout: 'vertical', lastLayout: 'horizontal' });

		if (this.state.lastLayout === 'horizontal' && this.state.width >= 1199)
			this.setState({ activeLayout: 'horizontal', lastLayout: 'vertical' });
	};


	async updateOnlineStatus() {
		this.setState({
			openOnlineNotification: true,
			notificationMsg: 'You in online mode',
			notificationSeverity: 'success'
		});
	}

	updateOfflineStatus() {
		this.mute();
		this.setState({
			openOnlineNotification: true,
			notificationMsg: 'Your in offline mode',
			notificationSeverity: 'warning'
		});
	}

	componentDidMount() {
		if (!this.props.volume) {
			this.props.setVolume('notification_medium.wav')
			this.props.setMode('Single')
		}
		this.props.setOnPlay(false)
		this.props.setOneCard(false)
		this.props.setCount(1)

		window.addEventListener('online', this.updateOnlineStatus.bind(this));
		window.addEventListener('offline', this.updateOfflineStatus.bind(this));
		window.addEventListener('beforeunload', this.mute.bind(this));

		if (window !== undefined) window.addEventListener('resize', this.handleWindowResize.bind(this));
		this.handleDirUpdate();
		if (this.state.activeLayout === 'horizontal' && this.state.width <= 1199)
			this.setState({ activeLayout: 'vertical' });
		else if (themeConfig.layout === 'horizontal' && this.state.width >= 1200)
			this.setState({ activeLayout: 'horizontal' });
		else
			this.setState({ activeLayout: 'vertical' });
		SocketService.listenTo(this.socketEvents());
	};

	async componentWillUnmount() {
		await this.mute();
		// SocketService.turnOff(this.socketEvents());
	}

	socketEvents() {
		return [
			{ key: 'connect', callback: () => console.log('Socket sound connection was established') },
			{ key: 'disconnect', callback: () => console.log('Socket sound connection was disconnected') },
			{ key: 'reconnect', callback: this.callToastifyNotification.bind(this) },
			{ key: CONSTANTS.VARIABLES.ORDERS.EVENTS.NEW, callback: this.callToastifyNotification.bind(this) },
			{ key: CONSTANTS.VARIABLES.ORDERS.EVENTS.BILL_REQUESTED, callback: this.callToastifyNotification.bind(this) },
			{ key: CONSTANTS.VARIABLES.ORDERS.EVENTS.UPDATE, callback: this.callToastifyNotification.bind(this) },
		];
	}

	callToastifyNotification(data) {
		console.log('data on notification', data)

		let filter = {
			filter: 'live_orders',
			filterKeyName: "Live Orders",
			page: 0,
			sort: "DESC"
		}

		this.props.getLiveOrders(filter, false).then(() => {
			let order = this.props.orders && this.props.orders.find((p) => p.order_id === parseInt(data))
			if (order && order.status_id === 11) {
				if (isAndroid) {

				} else if (isIOS) {

				} else {
					if (this.props.handleOneCard === false) {
						if (this.props.mode !== "Silent" && !this.props.howler_sound_play) {
							this.props.setOnPlay(true)
							this.props.setOneCard(true)
							this.props.setCount(1)
						}

						// setTimeout(()=>{
						//     if (this.props.mode !== "Repeated") {
						//         this.setState({
						//             howler_sound_play: false,
						//         });
						//         toast.dismiss(this.toastId.current);
						//     }
						// }, 10000)

						this.toastId.current = toast(
							<Row style={{ zIndex: "1020" }}>
								<Col onClick={this.handleRouter.bind(this)}
									className='Toastify__toast Toastify__toast-body toast-text'>
									You have {this.props.count} new
									order!</Col>
								<Col>
									<button onClick={this.dismissNotification.bind(this)}
										className='toast-button toast-button1'><img
											src={CloseNotificationIcon} /></button>
								</Col>
								<Col>
									<button onClick={this.mute.bind(this)} className='toast-button toast-button2'><img
										src={SoundIcon} /></button>
								</Col>
							</Row>,

							{
								containerId: "notification", className: 'custom-toast', closeOnClick: false, onClose: props => {
									this.setState({
										...this.state,
										howler_sound_loop: this.props.mode === "Repeated" ? true : false,
									}, () => {
										this.props.setOnPlay(false)
										this.props.setOneCard(false)
										this.props.setCount(1)
									});
								}, autoClose: false,
							})
						this.props.setOneCard(true)
						this.props.setCount(this.props.count + 1)
					} else {
						if (this.toastId.current === null) {
							this.toastId.current = toast(
								<Row style={{ zIndex: "1020" }}>
									<Col onClick={this.handleRouter.bind(this)}
										className='Toastify__toast Toastify__toast-body toast-text'>
										You have {this.props.count} new
										order!</Col>
									<Col>
										<button onClick={this.dismissNotification.bind(this)}
											className='toast-button toast-button1'><img
												src={CloseNotificationIcon} /></button>
									</Col>
									<Col>
										<button onClick={this.mute.bind(this)} className='toast-button toast-button2'><img
											src={SoundIcon} /></button>
									</Col>
								</Row>,

								{
									containerId: "notification", className: 'custom-toast', closeOnClick: false, onClose: props => {
										this.setState({
											...this.state,
											howler_sound_loop: this.props.mode === "Repeated" ? true : false,
										}, () => {
											this.props.setOnPlay(false)
											this.props.setOneCard(false)
											this.props.setCount(1)
										});
									}, autoClose: false,
								})
						} else {
							toast.update(this.toastId.current, {
								render:
									<Row style={{ zIndex: "1020" }}>
										<Col onClick={this.handleRouter.bind(this)}
											className='Toastify__toast Toastify__toast-body toast-text'> You
											have {this.props.count} new
											orders!</Col>
										<Col>
											<button onClick={this.dismissNotification.bind(this)}
												className='toast-button toast-button1'><img
													src={CloseNotificationIcon} />
											</button>
										</Col>
										<Col>
											<button onClick={this.mute.bind(this)} className='toast-button toast-button2'><img
												src={SoundIcon} />
											</button>
										</Col>
									</Row>,
								autoClose: false, onClose: props => {
									this.setState({
										howler_sound_loop: this.props.mode === "Repeated" ? true : false,
									}, () => {
										this.props.setOnPlay(false)
										this.props.setOneCard(false)
										this.props.setCount(1)
									});
								}
							});
						}
						this.props.setOneCard(true)
						this.props.setCount(this.props.count + 1)
					}
				}
			}
		});
	}

	handleNotificationClose() {
		this.setState({
			openOnlineNotification: false
		})
	}

	componentDidUpdate() {
		this.handleDirUpdate()
	}

	handleDirUpdate() {
		let dir = this.state.direction;
		if (dir === 'rtl')
			document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
		else document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr')
	};

	render() {
		const { children } = this.props;
		return (
			<StyledLayout path={window.location.pathname}>
				{/*<button onClick={this.callToastifyNotification.bind(this)}>Notify</button>*/}
				<ReactHowler
					src={`../../${this.props.volume}`}
					playing={this.props.howler_sound_play}
					loop={this.props.mode === 'Repeated' ? true : false}
				/>
				<ToastContainer
					bodyStyle={{ zIndex: "1050" }}
					position="top-right"
					hideProgressBar={true}
					newestOnTop={false}
					closeButton={false}
					rtl={false}
					pauseOnFocusLoss
					draggable={false}
					pauseOnHover
					className="foo" style={{ paddingTop: "64px" }}
				/>
				<Snackbar
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					open={this.state.openOnlineNotification}
					autoHideDuration={6000}
					onClose={this.handleNotificationClose.bind(this)}

				><Alert onClose={this.handleNotificationClose.bind(this)}
					severity={this.state.notificationSeverity}>
						{this.state.notificationMsg}
					</Alert></Snackbar>
				<ContextLayout.Provider
					value={{
						state: this.state,
						fullLayout: layouts['full'],
						verticalLayout: layouts['vertical'],
						horizontalLayout: layouts['horizontal'],
						switchLayout: layout => {
							this.setState({ activeLayout: layout })
						},
						switchDir: dir => {
							this.setState({ direction: dir })
						}
					}}
				>
					{children}
				</ContextLayout.Provider>
			</StyledLayout>
		);

	}
}

const StyledLayout = styled.div`
  .setting-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 14px;
    color: #ffffff;
    margin-bottom: 40px;
  }

  /*Notification Toast Popup*/

  .Toastify__toast-container {
    @media (max-width: 477px) {
      padding-top: 81px !important;
    }
  }

  .Toastify__toast-container--top-right {
    right: -23px;
    @media (max-width: 501px) {
      right: -38px;
    }
  }

  .custom-toast {
    color: white;
    background: #7065EB;
    height: 36px;
    width: 260px;
  }

  .toast-button {
    float: right;
    cursor: pointer;
    background: none;
    border: none;
  }

  .Toastify__toast {
    position: absolute !important;
    min-height: 36px;
    box-sizing: border-box;
    /* margin-bottom: 0rem; */
    margin: 0px;
    padding: 0px;
    border-radius: 5px;
    padding-left: 10px !important;
    padding-right: 10px !important;
    box-shadow: 0 1px 0px 0 rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%);
    //right: 18px;
    @media (max-width: 477px) {
      right: 18px;
    }
    z-index: 10;
  }

  .Toastify__toast-body {
    margin: auto 0 !important;
    flex: 1 1 auto;
    padding: 6px !important;
    display: flex;
    align-items: start !important;
    z-index: 10;
    position: relative;
  }

  .toast-button1 {
    padding-top: 10px;
    position: absolute;
    right: -124px;
    top: -9px;
    z-index: 10;
  }

  .toast-button2 {
    padding-right: 20px;
    position: relative;
    z-index: 10;
    /*padding-top: 9px;*/
  }

  .toast-button2-hide {
    opacity: 0;
  }

  .toast-text {
    font-size: 12px !important;
    font-weight: 600;
    padding-top: 4px !important;
    padding-left: 10px !important;
    @media (max-width: 477px) {
      padding-left: 28px !important;
    }
  }
`;

function mapStateToProps(state) {
	const { orders } = state.orders;
	let orderRows, totalCount;
	if (orders.data) {
		orderRows = orders.data.rows;
		totalCount = orders.data.count;
	}
	return {
		orders: orderRows,
		totalCount,
		locationLoc: state.locations ? state.locations?.location?.data : null,
		volume: state.notificationReducer ? state.notificationReducer.volume : 'notification_medium.wav',
		mode: state.notificationReducer ? state.notificationReducer.mode : 'Single',
		count: state.notificationReducer ? state.notificationReducer.count : 1,
		handleOneCard: state.notificationReducer ? state.notificationReducer.handleOneCard : false,
		howler_sound_play: state.notificationReducer ? state.notificationReducer.howler_sound_play : false,
	};
}

export default connect(mapStateToProps, { getLiveOrders, setVolume, setMode, setCount, setOneCard, setOnPlay })(Layout);

export { ContextLayout };
