import CONSTANTS from '../../../constants';

export function allSmsPlans(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.SMS.ALL_SMS_PLANS) {
    return { data: action.data, loading: action.loading };
  }
  return state;
}
export function smsPlan(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.SMS.SUBSCRIBE_SMS_PLAN) {
    return { data: action.data, loading: action.loading };
  }
  return state;
}
export function allCards(state = { data: null, loading: true }, action) {

  if (action.type === CONSTANTS.ACTIONS.SMS.CARDS) {
    return { data: action.data.data, loading: action.loading };
  }
  return state;
}
export function allSubscriptions(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.SMS.SUBSCRIPTIONS) {
    return { data: action.data, loading: action.loading };
  }
  return state;
}