import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import TimeHelper from "../../helpers/TimeHelper";
import ViewHelper from "../../helpers/ViewHelper";
import Checkbox from "../@vuexy/checkbox/CheckboxesVuexy";
import { Check, Edit2 } from "react-feather";
import { Button } from "reactstrap";
import ImageHelper from "../../helpers/ImageHelper";


const ListReviews = (props) => {

  const editCurrency = (id) => {
    props.editCurrency(id);
  }

  const columns = [
    {
      name: 'ID',
      selector: 'review_id',
      sortable: true,
    }, {
      name: 'User Email',
      selector: 'User.email',
      sortable: true,
      minWidth: '300px',
      format: (val) => {
        return val.User && val.User.email ? val.User.email : 'Not Available';
      }
    },
    {
      name: 'Restaurant',
      selector: 'Location.name',
      minWidth: '200px',
      sortable: true,
      format: (val) => {
        return val.Location && val.Location.name ? val.Location.name : 'Not Available';
      }
    }, {
      name: 'Menu Item',
      selector: 'Menu.menu_name',
      minWidth: '300px',
      sortable: true,
      format: (val) => {
        return val.Menu && val.Menu.menu_name ? val.Menu.menu_name : 'Not Available';
      }
    }, {
      name: 'Review Comment',
      selector: 'review_comment',
      minWidth: '200px',
      sortable: true,
      format: (val) => {
        return val.review_comment ? val.review_comment : 'Not Available';
      }
    }
    , {
      name: 'Rating',
      selector: 'rating',
      sortable: true,
      format: (val) => {
        return val.rating ? val.rating : 'Not Available';
      }
    }, {
      name: 'Date',
      selector: 'review_datetime',
      minWidth: '200px',
      sortable: true,
      format: (val) => {
        return val.review_datetime ? TimeHelper.toFormattedDateTimeDB(val.review_datetime) : 'Not available';
      }
    }, {
      name: 'Image',
      minWidth: '200px',
      selector: 'review_photo',
      sortable: true,
      format: (val) => {
        return val.review_photo ? <img src={ImageHelper.getImageURL(val.review_photo)} /> : 'Not available';
      }
    }
  ];

  const renderReviews = (reviews, handleChange) => {
    if (!reviews || reviews.length === 0)
      return <h1>No App reviews found.</h1>;
    const tableData = {
      columns: columns,
      data: reviews,
      exportHeaders: true,
      print: false,
    };
    return <DataTableExtensions
      {...tableData}
    ><DataTable
        noHeader
        pagination
        paginationRowsPerPageOptions={[25, 50, 100]}
        paginationPerPage={50}
        selectableRows
        selectableRowsComponent={Checkbox}
        selectableRowsComponentProps={{
          color: "primary",
          icon: <Check className="vx-icon" size={12} />,
          label: "",
          size: "sm"
        }}
        onSelectedRowsChange={handleChange}
        
      /></DataTableExtensions>;
  }
  return renderReviews(props.reviews, props.handleChange);

}

export default ListReviews;
