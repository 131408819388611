import CONSTANTS from '../../../constants';
import TableService from '../../../services/TableService';

export function getAllTables(locationId) {
  return async dispatch => {
    try {
      dispatch({ type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES, tables: [], loading: true });
      let tables = await TableService.getAllTables(locationId);
      dispatch({ type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES, tables, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES, tables: [], loading: false });
    }
  };
}

export function createTable(locationId, table) {
  return async dispatch => {
    try {
      dispatch({ type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES, tables: [], loading: true });
      let tables = await TableService.createTable(locationId, table);
      dispatch({ type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES, tables, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES, tables: [], loading: false });
    }
  };
}

export function changeTableStatus(locationId, data) {
  return async dispatch => {
    try {
      dispatch({ type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES, tables: [], loading: true });
      let tables = await TableService.changeTableStatus(locationId, data);
      dispatch({ type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES, tables, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES, tables: [], loading: false });
    }
  };
}

export function reGenerateQr(locationId, data) {
  return async dispatch => {
    try {
      dispatch({ type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES, tables: [], loading: true });
      let tables = await TableService.generateQr(locationId, data);
      dispatch({ type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES, tables, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.TABLES.ALL_TABLES, tables: [], loading: false });
    }
  };
}