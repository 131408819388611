import CONSTANTS from '../constants';
import LoginService from './LoginService';
import { customFetch as fetch } from '../helpers/FetchHelper';

export default {
  getDeliveryProcessor,
  updateDeliveryProcessor
};

async function getDeliveryProcessor(locationId) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.DELIVERY_PROCESSORS.DEFAULT(locationId)}/`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  let status = response.status;
  response = await response.json();
  if(status === 200) return response;
  throw new Error('Could not get payment processor');
}

async function updateDeliveryProcessor(locationId, processorData) {
  let { token } = LoginService.getToken();

  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.DELIVERY_PROCESSORS.DEFAULT(locationId)}/${processorData.id}`, {
    method: 'POST',
    body: JSON.stringify(processorData),
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if(response) return response;
  throw new Error('Could not update payment processor');
}
