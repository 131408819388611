import React from 'react';
import { Button } from 'reactstrap';

const PricingHeader = ({ data,wallet }) => {
  if (data.length === 0)
    return (<h1>No Plans Found.</h1>);

  return (
    <div className='text-center'>
      <Button outline className='font-weight-bold custom-padding disabled-2-border btn-sm-font' color={'secondary'} size='md' >
        <span className='ml-1 mr-1 d-md-inline'>SMS In Wallet : {wallet ? wallet.wallet : 0} Units</span>
      </Button>
      <h1 className='mt-5'>Sms Plans</h1>
      <p className='mb-2 pb-75'>
        All plans include advanced sms and features to boost your restaurant marketing. Choose the best plan to fit your needs.
      </p>
    </div>
  )
}

export default PricingHeader
