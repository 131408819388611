import { combineReducers } from 'redux'
import { order } from './OrderReducer';
import { orders } from './OrdersReducer';

const ordersReducer = combineReducers({
  order,
  orders
});

export default ordersReducer;
