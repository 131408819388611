import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import SpinnerComponent from '../@vuexy/spinner/Fallback-spinner';
import PandagoIntegrationForm from '../thirdPartyServices/PandagoIntegrationForm';
import LocationSelectHeader from '../shared/LocationSelectHeader';
import LocationService from '../../services/LocationService';
import DeliveryProcessorService from '../../services/DeliveryProcessorService';
import CONSTANTS from '../../constants';
import SelectLocation from "../shared/SelectLocation";

class RiderServices extends Component {

  constructor(props) {
    super(props);
    this.state = { location: null, deliveryProcessor: null, loading: true, error: false };
  }

  getDefaultDeliveryProcessor() {
    return {
			id: 1,
      processor_type: CONSTANTS.VARIABLES.DELIVERY.DEFAULT,
      client_id: '',
      key_id: '',
      scope: '',
      selectLocation: false,
			delivery_radius: 4.0,
    }
  }


  async componentDidMount() {
    this.setState({ loading: true });
    try {
      const location = LocationService.getLocation();
      if(location) {
        await this.refreshData(location.loc_id);
        this.setState({loading: false });
      } else {
        this.setState({loading: false, selectLocation: true})
      }
    } catch (e) {
      console.log(e);
      this.setState({ loading: false, error: true });
    }
  }

  async refreshData(loc_id) {
    let deliveryProcessor = await DeliveryProcessorService.getDeliveryProcessor(loc_id);
    if(!deliveryProcessor) deliveryProcessor = this.getDefaultDeliveryProcessor();
    this.setState({ deliveryProcessor, location: loc_id });
  }

  onChange(event) {
    const deliveryProcessor = {...this.state.deliveryProcessor};
		const {
			target: {
				name,
				value,
			}
		} = event;
		
		if(name === 'delivery_radius' && value !== '' && !+value) {
			return;
		}
		deliveryProcessor[name] = value;
    this.setState({ deliveryProcessor });
  }

  async onSave() {
    this.setState({ loading: true });
    try {
      let { deliveryProcessor, location } = this.state;
			deliveryProcessor = await DeliveryProcessorService.updateDeliveryProcessor(location, deliveryProcessor);
      this.setState({ deliveryProcessor, loading: false });
    } catch (e) {
      console.log(e);
      this.setState({ loading: false, error: true });
    }
  }

  changeLocation() {
    this.setState({ selectLocation: true })
  }

  async onSelect(location) {
    this.setState({loading: true});
    LocationService.setLocation(location);
    await this.refreshData(location.loc_id);
    this.setState({selectLocation: false, loading: false});
  }

  toggleLocation() {
    this.setState({selectLocation: !this.state.selectLocation});
  }

  renderDeliveryProcessor(deliveryProcessor) {
    return (
      <Col lg='6' md='12'>
        <PandagoIntegrationForm
          pandagoProcessor={deliveryProcessor}
          onChange={this.onChange.bind(this)}
          onSave={this.onSave.bind(this)} />
      </Col>
    );
  }

  render() {
    const { state: {
      deliveryProcessor,
      loading,
      error,
      selectLocation
    }} = this;

    const location = LocationService.getLocation();
    const locationName = location ? location.name : '';

    // TODO: Implement proper error handling
    if(selectLocation) return <SelectLocation onBack={this.toggleLocation.bind(this)} onSelect={this.onSelect.bind(this)} />;
    else if(deliveryProcessor)
      return (
        <div className='payment-processor'>
          <LocationSelectHeader text={locationName} onIconClick={this.changeLocation.bind(this)} />
          <Row className='mt-3'>
            {this.renderDeliveryProcessor(deliveryProcessor)}
          </Row>
        </div>
      );
		return <SpinnerComponent />;
    return '';
  }
}

export default RiderServices;