import React from 'react';
import Wizard from '../@vuexy/wizard/WizardComponent';
import { AvInput, AvGroup, AvFeedback, } from 'availity-reactstrap-validation';
import { parsePhoneNumber } from 'react-phone-number-input';
import { countries } from 'country-data';
import {
  Label,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Button
} from 'reactstrap';
import _, { filter } from 'lodash';
import Flatpickr from 'react-flatpickr';
import LocationService from '../../services/LocationService';
import CONSTANTS from '../../constants';
import TimeHelper from '../../helpers/TimeHelper';
import { connect } from 'react-redux';
import { getLocation } from '../../redux/actions/locations';
import { saveCampaign, getSmsCampaigns } from '../../redux/actions/campaigns';
import LocationSelectHeader from '../shared/LocationSelectHeader';
import SpinnerComponent from '../@vuexy/spinner/Fallback-spinner';
import ViewHelper from '../../helpers/ViewHelper';
import { getCustomersStats } from '../../redux/actions/campaigns';
import DataTableComponent from '../shared/DataTableComponent';
import SelectLocation from '../shared/SelectLocation';
import Select from 'react-select';
import CampaignService from '../../services/CampaignService';
import { Plus, Trash } from 'react-feather';
import ConfirmationModal from '../shared/forms/ConfirmationModal';

const filterConditions = [
  { value: 'less', label: 'Less than' },
  { value: 'equals', label: 'Equals' },
  { value: 'greater', label: 'Greater than' },
  { value: 'isblank', label: 'Is Blank' }
]
const filterConditionsFirstLast = [
  { value: 'between', label: 'Between' },
  { value: 'not between', label: 'Not Between' },
]
const dateOptions = [
  { value: 'lastweek', label: 'Last week(7 days)' },
  { value: 'lastmonth', label: 'Last month(30 days)' },
  { value: 'last3months', label: 'Last 3 months' },
  { value: 'last6months', label: 'Last 6 months' },
  { value: 'lastyear', label: 'Last year' },
  { value: 'alltime', label: 'All time' },
  { value: 'custom', label: 'custom' }
]

class SmsCampaignsForm extends React.Component {
  state = {
    activeStep: 0,
    isOpenSelect: false,
    isInternational: false,
    campaign: {
      id: '',
      loc_id: '',
      name: '',
      sms: '',
      timestamp: new Date(),
      sms_limit: 0,
      users: '',
      sms_wallet: 0,
    },
    selectLocation: false,
    location: {
      loc_id: 0,
      name: 'All Venues',
      Currency: {
        currency_code: 'PKR'
      },
      decimal_places: 2,
    },
    loading: false,
    filters: [],
    dummyFilter: {
      startDate: TimeHelper.getFirstDayOf2018(),
      endDate: TimeHelper.getCurrentDate(),
      orderBy: 'total_sales',
      order: 'desc',
      filter_name: 'all',
      filter_type: 'all',
      filter_value: '',
      filter_condition: ''
    },
    columns: [
      {
        name: 'Customer ID',
        selector: 'customer_id',
        sortable: false
      },
      {
        name: 'Name',
        selector: 'customer_name',
        sortable: false
      }, {
        name: 'Last Order',
        selector: 'last_order',
        sortable: true,
      }, {
        name: 'Orders',
        selector: 'total_orders',
        sortable: true,
        format: (val) => {
          return `${val.total_orders.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`
        },
      }, {
        name: 'Sales',
        selector: 'total_sales',
        sortable: true,
        format: (val) => {
          return ViewHelper.toCurrency(val.total_sales, this.state.location.Currency.currency_code, this.state.location.decimal_places);
        },
      }, {
        name: 'Phone',
        selector: 'phone_number',
        sortable: false
      }, {
        name: 'Address',
        selector: 'address',
        visible: false
      }, {
        name: 'Country',
        selector: 'country',
        visible: false
      }, {
        name: 'Email',
        selector: 'email',
        sortable: false
      }],
    saving: false,
    errorSaving: false,
    errorWallet: false,
    users: [],
    isOpen: false,
    confirmationDialog: {
      show: false,
      acceptCallback: () => { },
      rejectCallback: () => { },
      title: 'Wallet',
      header: 'Wallet'
    },
    errorDialog: {
      show: false,
      rejectCallback: () => { },
      title: 'Wallet',
      header: 'Wallet'
    }
  }

  async getCampaignFromId(loc_id, id) {
    let campaign = await CampaignService.getCampaign(loc_id, id);
    campaign.users = (campaign.SmsCampaignUsers.map(user => user.user_id)).toString();
    return campaign;
  }

  async componentDidMount() {
    const location = LocationService.getLocation();
    if (location) {
      let editMode = false;
      let campaign = this.state.campaign;
      await this.getSmsCampaigns(location.loc_id);
      if (window.location.pathname.indexOf('edit') !== -1) {
        const parts = window.location.pathname.split('/');
        campaign = await this.getCampaignFromId(location.loc_id, parts[parts.length - 1]);
        campaign.id = parts[parts.length - 1];
        editMode = true;
        await this.setState({ campaign, editMode });
      }
      await this.refreshData(location.loc_id);
      this.setState({ loading: false });
    } else {
      this.props.history.replace('/locations/sms/campaigns');
    }
  }

  async getSmsCampaigns(loc_id) {
    return this.props.getSmsCampaigns(loc_id);
  }
  async refreshData(loc_id) {
    this.setState({ loading: true });
    let location;
    if (loc_id !== 0) {
      await this.props.getLocation(loc_id);
      location = this.props.location;
    }
    else {
      location = {
        loc_id: 0,
        name: 'All Venues',
        Currency: {
          currency_code: 'PKR'
        },
        decimal_places: 2,
      }
    }
    let filters = JSON.parse(JSON.stringify(this.state.filters));
    filters.push(JSON.parse(JSON.stringify(this.state.dummyFilter)));
    this.setState({ filters });
    await this.getStats(loc_id);
    this.setState({ loading: false, location: JSON.parse(JSON.stringify(location)) });
  }

  async getStats(loc_id) {
    await this.props.getCustomersStats(loc_id, this.state.filters);
  }

  onBlur() {
    this.filterUpdated();
  }

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  async onSelect(location) {
    this.setState({ loading: true });
    LocationService.setLocation(location, CONSTANTS.CACHE_KEY_PREFIX_ANALYTICS);
    await this.refreshData(location.loc_id);
    this.setState({ selectLocation: false, loading: false });
  }

  filterUpdated() {
    const { state: {
      filters,
      location
    } } = this;
    this.setState({ filters: filters }, () => {
      this.getStats(location.loc_id);
    });
  }

  toggleItemsModal() {
    this.setState({ isMenuItemModalOpen: !this.state.isMenuItemModalOpen });
  }

  async updateValue(value, type) {
    if (!isNaN(value) && value)
      value = parseInt(value);
    let campaign = JSON.parse(JSON.stringify(this.state.campaign));
    let obj = campaign;
    let parts = type.split('.');
    const n = parts.length - 1;
    for (let i = 0; i < n; i++)
      obj = obj[parts[i]];
    obj[parts[n]] = value;
    await this.setState({ campaign });
  }

  async updateFilterValue(value, type) {
    if (!isNaN(value) && value)
      value = parseInt(value);
    let dummyFilter = JSON.parse(JSON.stringify(this.state.dummyFilter));
    let obj = dummyFilter;
    let parts = type.split('.');
    const n = parts.length - 1;
    for (let i = 0; i < n; i++)
      obj = obj[parts[i]];
    obj[parts[n]] = value;
    await this.setState({ dummyFilter });
  }

  onTypeChange = (selectedOption) => {
  };

  onConditionChange = (selectedOption, i) => {
    this.updateFilters(selectedOption.value, 'filter_condition', i);
  }

  async updateFilterState(selectedOption, selectedlbl) {
    await this.updateFilterValue(selectedlbl, 'filter_name');
    await this.updateFilterValue(selectedOption, 'filter_type');
  }

  async updateFilters(value, type, index) {
    let filters = JSON.parse(JSON.stringify(this.state.filters));
    filters[index][type] = value;
    await this.setState({ filters });
    this.filterUpdated();
  }

  handleChange = (state) => {
    this.setState({ users: state.selectedRows });
  };

  async addNewFilter(selectedOption, selectedlbl) {
    await this.updateFilterState(selectedOption, selectedlbl);
    let filters = JSON.parse(JSON.stringify(this.state.filters));
    filters.push(JSON.parse(JSON.stringify(this.state.dummyFilter)));
    await this.setState({ filters });
  }

  async deleteFilter(index) {
    let filters = JSON.parse(JSON.stringify(this.state.filters));
    filters.splice(index, 1);
    await this.setState({ filters });
    this.filterUpdated();
  }

  async setOpen(open) {
    await this.setState({ isOpen: open });
  }

  onDateChange = (selectedOption, i) => {
    const allTimeValue = TimeHelper.getFirstDayOf2018();
    const last7Days = TimeHelper.getDaysBeforeToday(7);
    const last30Days = TimeHelper.getDaysBeforeToday(30);
    const last3months = TimeHelper.getDaysBeforeToday(90);
    const last6months = TimeHelper.getDaysBeforeToday(365 / 2);
    const lastYear = TimeHelper.getDaysBeforeToday(365);
    switch (selectedOption.value) {
      case 'lastweek': return this.updateFilters(last7Days, 'startDate', i);
      case 'lastmonth': return this.updateFilters(last30Days, 'startDate', i);
      case 'last3months': return this.updateFilters(last3months, 'startDate', i);
      case 'last6months': return this.updateFilters(last6months, 'startDate', i);
      case 'lastyear': return this.updateFilters(lastYear, 'startDate', i);
      case 'alltime': return this.updateFilters(allTimeValue, 'startDate', i);
      case 'custom': return this.setOpen(!this.state.isOpen);

      default: return this.updateFilters(allTimeValue, 'startDate', i);
    }
  }

  async setOpenSelect() {
    await this.setState({ isOpenSelect: !this.state.isOpenSelect });
  }

  renderFilters(filters) {
    if (filters && filters.length > 0) {
      return filters.map((d, i) => (
        <>
          {d.filter_type !== 'all' &&
            <Row>
              <Col className='col-10 col-md-3'>
                <Label>Filter Name</Label>
                <AvInput name='name' type='text' value={d.filter_name} disabled />
              </Col>
              <Col className={(d.filter_type === 'no_of_orders' || d.filter_type === 'total_spend') ? `col-12 col-md-3` : `col-12 col-md-4`}>
                <AvGroup>
                  <Label>Condition</Label>
                  {
                    (d.filter_type === 'no_of_orders' || d.filter_type === 'total_spend') ?
                      <Select
                        options={filterConditions}
                        className='React'
                        classNamePrefix='select'
                        onChange={(e) => this.onConditionChange(e, i)}
                      /> :
                      <Select
                        options={filterConditionsFirstLast}
                        className='React'
                        classNamePrefix='select'
                        onChange={(e) => this.onConditionChange(e, i)}
                      />
                  }
                </AvGroup>
              </Col>
              {d.filter_type === 'no_of_orders' &&
                <>
                  <Col className='col-10 col-md-2'>
                    <Label>Input Value</Label>
                    <AvInput name={`${d.filter_type}_${i}`} type='text'
                      onChange={(e) => this.updateFilters(e.target.value, 'filter_value', i)} />
                  </Col>
                </>
              }
              {d.filter_type === 'total_spend' &&
                <>
                  <Col className='col-10 col-md-2'>
                    <Label>Input Value</Label>
                    <AvInput name={`${d.filter_type}_${i}`} type='text'
                      onChange={(e) => this.updateFilters(e.target.value, 'filter_value', i)} />
                  </Col>
                </>
              }
              <Col className={(d.filter_type === 'no_of_orders' || d.filter_type === 'total_spend') ? `col-12 col-md-3` : `col-12 col-md-4`}>
                <AvGroup>
                  <Label>Date</Label>
                  {!this.state.isOpen &&
                    <Select
                      options={dateOptions}
                      className='React'
                      classNamePrefix='select'
                      defaultValue={{ label: 'All time', value: 'alltime' }}
                      onChange={(e) => this.onDateChange(e, i)}
                    />
                  }
                  {this.state.isOpen &&
                    <Flatpickr
                      id='date-interval'
                      className='form-control align-text-center'
                      options={{
                        mode: 'range',
                        defaultDate: [this.state.dummyFilter.startDate, this.state.dummyFilter.endDate]
                      }}
                      onChange={(d) => {
                        if (d.length <= 1)
                          return;
                        this.updateFilters(d[0], 'startDate', i);
                        this.updateFilters(d[1], 'endDate', i);
                        this.setOpen(!this.state.isOpen);
                      }}
                    />
                  }
                </AvGroup>
              </Col>
              <Col md='1' sm='4' xs='4' className='d-md-block d-none d-sm-none m-auto align-text-center'>
                <Trash size={20} className='btn-flat-danger' onClick={(e) => {
                  e.stopPropagation();
                  this.deleteFilter(i);
                }} />
              </Col>
              <div className='d-sm-block d-block d-none d-md-none my-50 full-width'></div>
            </Row>
          }
        </>
      ));
    }
    return null;
  }

  _getStepsContentForCampaign(campaign) {
    const {
      name,
      sms,
      timestamp,
      sms_limit,
      users,
    } = campaign;
    
    const {
      props: {
        location,
        stats
      } } = this;

    const { state: {
      loading,
      columns,
      filters,
    } } = this;

    const steps = [
      {
        title: 1,
        content: <Row>
          <Col md='12' sm='12'>
            <AvGroup>
              <Label>Name</Label>
              <AvInput name='name' type='text' value={name} required onChange={(e) => this.updateValue(e.target.value, 'name')} />
              <AvFeedback>Please enter campaign name.</AvFeedback>
            </AvGroup>
          </Col>
          <Col md='12' sm='12'>
            <AvGroup>
              <Label> SMS </Label>
              <AvInput name='sms' rows={3} required type='textarea' value={sms} onChange={e => this.updateValue(e.target.value, 'sms')} />
              <AvFeedback>Please enter your message.</AvFeedback>
            </AvGroup>
          </Col>
          <Col className='col-6'>
            <AvGroup>
              <Label> Start Date&Time </Label>
              <Flatpickr
                id='date-interval'
                className='form-control'
                options={{
                  defaultDate: timestamp
                }}
                required
                data-enable-time
                value={timestamp}
                onChange={(d) => {
                  this.updateValue(TimeHelper.toFormattedDate(d[0], CONSTANTS.DATE_TIMES.FORMAT.DB), 'timestamp')
                }}
              />
              <AvFeedback>Please select campaign start time.</AvFeedback>
            </AvGroup>
          </Col>
          <Col className='col-6'>
            <AvGroup>
              <Label> Upper limit of sms </Label>
              <AvInput name='sms_limit' type='number' value={sms_limit} render min='0' max='1000' onChange={e => this.updateValue(e.target.value, 'sms_limit')} />
              <AvFeedback>Please enter quantity between 1-1000.</AvFeedback>
            </AvGroup>
          </Col>
        </Row>
      }, {
        title: 2,
        content: <Row>
          <Col md='12' sm='12'>
            {this.renderFilters(filters)}
          </Col>
          <Col className='col-10 col-md-6 col'>
            <Button outline className='font-weight-bold custom-padding disabled-2-border my-1 mt-2' color={'secondary'} size='md' onClick={() => this.setOpenSelect()}>
              <Plus className='mx-2' size={20} /> <span className='ml-1 mr-5'>Add Filter</span>
            </Button>
            <div left>
              {this.state.isOpenSelect &&
                <div className='col-md-3 col-lg-3 col-xl-6 col-6 no-padding filter-container-left'>
                  <Button.Ripple outline
                    onClick={() => {
                      this.addNewFilter('no_of_orders', 'No of orders');
                      this.setOpenSelect();
                    }} className='square full-width'>
                    No of orders
                  </Button.Ripple>
                  <Button.Ripple outline
                    onClick={() => {
                      this.addNewFilter('total_spend', 'Total spend');
                      this.setOpenSelect();
                    }} className='square full-width'>
                    Total spend
                  </Button.Ripple>
                  <Button.Ripple outline
                    onClick={() => {
                      this.addNewFilter('first_order', 'First order');
                      this.setOpenSelect();
                    }} className='square full-width'>
                    First order
                  </Button.Ripple>
                  <Button.Ripple outline
                    onClick={() => {
                      this.addNewFilter('last_order', 'Last order');
                      this.setOpenSelect();
                    }} className='square full-width'>
                    Last order
                  </Button.Ripple>
                </div>}
            </div>
          </Col>
          <Col md='12' sm='12'>
            <div className='location'>
              {!loading && stats ? <DataTableComponent dateFilterUpdated={this.filterUpdated.bind(this)} showDateFilter={true} title={'Customers'} data={stats} columns={columns} startDate={filters.startDate} endDate={filters.endDate} isCheckBox={true} handleChange={this.handleChange.bind(this)} users={users} /> : null}
            </div>
          </Col>
        </Row>
      }
    ];
    return steps;
  }

  async saveCampaign() {
    let sms_wallet = this.props.campaigns.staff.wallet;
    if (this.state.users.length > this.state.campaign.sms_limit) {
      this.showErrorDialog(null, 'Unable to save campaign. No of users is greater than from sms limit.', 'New Campaign');
      return;
    }
    if (this.state.users.length === 0) {
      this.showErrorDialog(null, 'Unable to save campaign. Please select at least one user.', 'New Campaign');
      return
    }
    const location = LocationService.getLocation();
    if (!location.country) {
      this.showErrorDialog(null, 'Unable to save campaign. Your restaurant has no country attached to it.', 'New Campaign');
      return;
    }
    if (this.state.users.length <= sms_wallet) {
      let users = [];
      let isInternational = false;
      for (let i = 0; i < this.state.users.length; i++) {
        if (!this.state.users[i].phone_number) {
          continue;
        }
        const countryCode = parsePhoneNumber(this.state.users[i].phone_number).country;
        const countryName = countries[countryCode].name;
        if (location.country.toLowerCase() !== countryName.toLowerCase()) {
          isInternational = true;
        } else {
          users.push(this.state.users[i].customer_id);
        }
      }
      this.updateValue(this.props.location.loc_id, 'loc_id');
      this.updateValue(users.toString(), 'users');
      await this.setState({ saving: true });
      const {
        state: {
          campaign
        }
      } = this;
      if (isInternational && users.length === 0) {
        this.showErrorDialog(this.cancelSaving.bind(this), 'International numbers exists.Please select at least one local number.', 'Warning');
      } else if (isInternational) {
        this.showConfirmationDialog(async () => {
          await this.processSaveCampaign(campaign)
        }, this.cancelSaving.bind(this), 'International numbers exist. We will not send messages to International numbers.', 'Warning');
      } else {
        this.showConfirmationDialog(async () => {
          await this.processSaveCampaign(campaign)
        }, this.cancelSaving.bind(this), 'you have selected ' + users.length + ' users and only ' + users.length + ' units will deduct for this campaign.Would you like to process this campaign?', 'Campaign');
      }
    } else {
      this.showErrorDialog(this.cancelSaving.bind(this), 'Unable to save campaign. Wallet amount is low against no of users.', 'Wallet');
    }
  }

  async processSaveCampaign(campaign) {
    await this.props.saveCampaign(this.props.location.loc_id, campaign);
    if (this.props.campaign)
      await this.props.history.replace('/locations/sms/campaigns');
    else
      await this.setState({ errorSaving: true, saving: false })
  }

  async cancelSaving() {
    await this.setState({ saving: false });
  }

  handleStepChange(activeStep) {
    this.setState({ activeStep });
  }

  showConfirmationDialog(accept, reject, title, header) {
    const defaultState = {
      show: false,
      acceptCallback: () => { },
      rejectCallback: () => { },
      title: '',
      header: ''
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        if (accept)
          accept();
        this.setState({ confirmationDialog: defaultState });

      },
      rejectCallback: () => {
        this.setState({ confirmationDialog: defaultState });
        if (reject)
          reject();
      },
      title: title,
      header: header
    }
    this.setState({ confirmationDialog });
  }

  showErrorDialog(reject, title, header) {
    const defaultState = {
      show: false,
      rejectCallback: () => { },
      title: '',
      header: ''
    };

    const errorDialog = {
      show: true,
      rejectCallback: () => {
        this.setState({ errorDialog: defaultState });
        if (reject)
          reject();
      },
      title: title,
      header: header
    }
    this.setState({ errorDialog });
  }

  render() {
    const { props: {
      loading,
      campaigns
    } } = this;

    const { state: {
      selectLocation,
      campaign,
      saving,
      errorSaving,
      activeStep,
      location: stateLocation,
      filters,
      errorDialog,
      confirmationDialog,
    } } = this;
    const titles = ['Enter the Basic Details', 'Select Users'];
    let sms_wallet = campaigns ? campaigns.staff.wallet : 0;
    if (loading) return <SpinnerComponent />;
    const locationName = stateLocation ? stateLocation.name : '';
    if (selectLocation)
      return <SelectLocation onBack={this.toggleLocation.bind(this)} onSelect={this.onSelect.bind(this)} />;
    const steps = this._getStepsContentForCampaign(campaign, filters);
    return (
      <div className='location'>
        <div className='display-flex flex-row justify-content-between mb-1 align-items-center'>
          <LocationSelectHeader text={locationName} onIconClick={this.toggleLocation.bind(this)} />
          <Button outline className='font-weight-bold custom-padding-btn disabled-2-border btn-sm-font' color={'secondary'} size='md' >
            <span className='d-md-inline'>SMS In Wallet : {sms_wallet} Units</span>
          </Button>
        </div>
        <Card>
          <CardHeader className='flex-column align-items-start'>
            <CardTitle>{`${titles[activeStep]}`}</CardTitle>
            {errorSaving ? <span className='danger'>Unable to save campaign. Your code must be unique.</span> : null}
          </CardHeader>
          <CardBody>
            <Wizard
              validate
              steps={steps}
              saving={saving}
              activeStep={activeStep}
              handleStepChange={this.handleStepChange.bind(this)}
              onFinish={this.saveCampaign.bind(this)}
            />
          </CardBody>
        </Card>
        <ConfirmationModal header={confirmationDialog.header} title={confirmationDialog.title} isOpen={confirmationDialog.show} reject={confirmationDialog.rejectCallback.bind(this)} rejectTitle={'Cancel'} accept={confirmationDialog.acceptCallback.bind(this)} />
        <ConfirmationModal header={errorDialog.header} title={errorDialog.title} isOpen={errorDialog.show} reject={errorDialog.rejectCallback.bind(this)} rejectTitle={'OK'} />

      </div>
    )
  }
}

function mapStateToProps(state) {
  const { analytics: {
    customerStats: {
      data: statsData, loading: statsLoading
    }
  },
    campaigns: {
      allSmsCampaigns: {
        data: campaigns
      }
    },
    locations: {
      location: {
        data: locationData, loading: locationLoading
      }
    }, campaigns: {
      campaign: {
        data: campaign
      }
    } } = state;

  return { campaigns, campaign, stats: statsData, location: locationData, loading: statsLoading || locationLoading };
}

export default connect(mapStateToProps, { getSmsCampaigns, saveCampaign, getLocation, getCustomersStats })(SmsCampaignsForm);
