import React from 'react';
import {
  Card,
  CardBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import classnames from 'classnames';
import SmsCampaignInbox from './SmsCampaignInbox';

const SmsCampaignsList = (props) => {

  const renderCampaigns = (campaigns, editCampaign, cancelCampaign, deleteCampaign) => {
    if (!campaigns || campaigns.length === 0)
      return null;

    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <TabContent activeTab={props.activeTab}>
              <TabPane tabId='1'>
                <Nav tabs className='nav-justified'>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: props.active === '1'
                      })}
                      onClick={() => {
                        props.toggle('1')
                      }}
                    >
                      Now
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: props.active === '2'
                      })}
                      onClick={() => {
                        props.toggle('2')
                      }}
                    >
                      Schedule
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent className='py-50' activeTab={props.active}>
                  <TabPane tabId='1'>
                    <SmsCampaignInbox data={campaigns.before} editCampaign={editCampaign} cancelCampaign={cancelCampaign} deleteCampaign={deleteCampaign} />
                  </TabPane>
                  <TabPane tabId='2'>
                    <SmsCampaignInbox data={campaigns.after} editCampaign={editCampaign} cancelCampaign={cancelCampaign} deleteCampaign={deleteCampaign} />
                  </TabPane>
                </TabContent>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
  return renderCampaigns(props.campaigns, props.editCampaign, props.cancelCampaign, props.deleteCampaign);
}

export default SmsCampaignsList;