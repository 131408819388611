import React, { Component } from 'react';
import { connect } from 'react-redux';
import SpinnerComponent from '../../@vuexy/spinner/Fallback-spinner';
import ErrorHandler from '../../shared/ErrorHandler';
import { getStaff, changeStaffStatus, deleteAssignRole } from '../../../redux/actions/staff';
import '../../../assets/scss/pages/page-pricing.scss';
import RolesList from './RolesList';
import { Button } from 'reactstrap';
import ConfirmationModal from '../../shared/forms/ConfirmationModal';


class RoleAssign extends Component {

	constructor(props) {
		super(props);
		this.state = {
			locations: '',
			errorDialog: {
				show: false,
				rejectCallback: () => { },
				title: 'App Users',
				header: 'App Users'
			},
			confirmationDialog: {
				show: false,
				acceptCallback: () => { },
				rejectCallback: () => { },
				title: 'App Users',
				header: 'App Users'
			}
		}
	}

	async componentDidMount() {
		const parts = window.location.pathname.split('/');
		await this.props.getStaff(parts[parts.length - 1]);
	}

	handleChange = (state) => {
		let locations = [];
		for (let i = 0; i < state.selectedRows.length; i++) {
			locations.push(state.selectedRows[i].id);
		}
		this.setState({ locations: locations });
	};

	async updateStatus() {
		if (this.state.locations.length > 0) {
			this.showConfirmationDialog(async () => {
				const parts = window.location.pathname.split('/');
				let deleteRoles = {
					staff_id: parts[parts.length - 1],
					locations: this.state.locations
				}
				await this.props.deleteAssignRole(deleteRoles)
			}, null, 'Are you sure you want to delete of this permissions?', 'Permission');
		} else {
			this.showErrorDialog(null, 'Please select atleast one permission for delete.', 'Warning');
		}
	}

	showConfirmationDialog(accept, reject, title, header) {
		const defaultState = {
			show: false,
			acceptCallback: () => { },
			rejectCallback: () => { },
			title: '',
			header: ''
		};

		const confirmationDialog = {
			show: true,
			acceptCallback: async () => {
				if (accept)
					accept();
				this.setState({ confirmationDialog: defaultState });

			},
			rejectCallback: () => {
				this.setState({ confirmationDialog: defaultState });
				if (reject)
					reject();
			},
			title: title,
			header: header
		}
		this.setState({ confirmationDialog });
	}

	showErrorDialog(reject, title, header) {
		const defaultState = {
			show: false,
			rejectCallback: () => { },
			title: '',
			header: ''
		};

		const errorDialog = {
			show: true,
			rejectCallback: () => {
				this.setState({ errorDialog: defaultState });
				if (reject)
					reject();
			},
			title: title,
			header: header
		}
		this.setState({ errorDialog });
	}


	assignRole() {
		const parts = window.location.pathname.split('/');
		this.props.history.push('/staff/assign/new/' + parts[parts.length - 1]);
	}

	render() {
		const { props: {
			staff,
			loading
		} } = this;
		const { state: {
			confirmationDialog,
			errorDialog,
		} } = this;
		if (loading) return <SpinnerComponent />;
		console.log(staff.StaffLocations);
		return (
			<>
				<div id='pricing-table'>
					<Button className='mb-1' color='primary' size='md' onClick={() => this.assignRole()}>
						<span className='ml-1 mr-1 d-md-inline '>New</span>
					</Button>
					<Button className='ml-1 mb-1' color='danger' size='md' onClick={() => this.updateStatus()}>
						<span className='ml-1 mr-1 d-md-inline '> Delete </span>
					</Button>
					{!loading && staff ? <RolesList staff={staff.StaffLocations} handleChange={this.handleChange.bind(this)} /> : null}
				</div>
				<ConfirmationModal header={confirmationDialog.header} title={confirmationDialog.title} isOpen={confirmationDialog.show} reject={confirmationDialog.rejectCallback.bind(this)} rejectTitle={'Cancel'} accept={confirmationDialog.acceptCallback.bind(this)} />
				<ConfirmationModal header={errorDialog.header} title={errorDialog.title} isOpen={errorDialog.show} reject={errorDialog.rejectCallback.bind(this)} rejectTitle={'OK'} />
			</>
		);
		return <ErrorHandler />;
	}
}

function mapStateToProps(state) {
	const { staff: {
		staff: {
			data: staff, loading: loading
		}
	} } = state;

	return { staff, loading: loading };
}

export default connect(mapStateToProps, { getStaff, changeStaffStatus, deleteAssignRole })(RoleAssign);