import React, { Component } from "react";
import { connect } from "react-redux";

import { getLocation } from "../../redux/actions/locations";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import SelectLocation from "../shared/SelectLocation";
import LocationSelectHeader from "../shared/LocationSelectHeader";

import CONSTANTS from "../../constants";
import LocationService from "../../services/LocationService";
import { togglePromotion, getPromotions } from "../../redux/actions/promotions";
import PromotionList from "./PromotionList";
import { Plus } from "react-feather";
import { Button } from "reactstrap";
import ConfirmationModal from "../shared/forms/ConfirmationModal";

class Promotions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectLocation: false,
      location: null,
      loading: true,
      confirmationDialog: {
        show: false,
        acceptCallback: () => { },
        rejectCallback: () => { },
        title: '',
      },
    };
  }

  async componentDidMount() {
    const location = LocationService.getLocation(`${CONSTANTS.CACHE_KEY_PREFIX}_location`);
    if (location) {
      await this.refreshData(location.loc_id);
    } else {
      this.setState({ loading: false, selectLocation: true })
    }
  }

  async refreshData(loc_id) {
    let location;
    if (loc_id !== 0 && (!this.state.location || loc_id !== this.state.location.loc_id)) {
      await this.props.getLocation(loc_id);
      location = this.props.location;
    } else {
      location = this.state.location;
    }
    await this.getPromotions(loc_id);
    this.setState({ loading: false, location: JSON.parse(JSON.stringify(location)) });
  }

  async getPromotions(loc_id) {
    return this.props.getPromotions(loc_id);
  }

  onBlur() {
    if (this.state.location)
      this.setState({ selectLocation: !this.state.selectLocation });
  }

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  async onSelect(location) {
    this.setState({ loading: true });
    LocationService.setLocation(location, `${CONSTANTS.CACHE_KEY_PREFIX}_location`);
    await this.refreshData(location.loc_id);
    this.setState({ selectLocation: false, loading: false });
  }

  async togglePromotion(id, state) {
    let nextState = 'activate';
    if (state)
      nextState = 'deactivate';
    const title = `Are you sure you want to ${nextState} this promotion?`;
    this.showConfirmationDialog(async () => {
      const { state: {
        location: {
          loc_id
        }
      } } = this;
      await this.props.togglePromotion(loc_id, id);
      await this.refreshData(loc_id);
    }, null, title);
  }

  editPromotion(id) {
    this.props.history.push(`/locations/promotions/edit/${id}`);
  }

  showConfirmationDialog(accept, reject, title) {
    const defaultState = {
      show: false,
      acceptCallback: () => { },
      rejectCallback: () => { },
      title: '',
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        accept();
        this.setState({ confirmationDialog: defaultState });
      },
      rejectCallback: () => {
        this.setState({ confirmationDialog: defaultState });
        if (reject)
          reject();
      },
      title: title,
    }
    this.setState({ confirmationDialog });
  }

  render() {
    const { props: {
      promotions,
    } } = this;

    const { state: {
      selectLocation,
      confirmationDialog,
      loading,
      location: stateLocation
    } } = this;

    if (loading) return <SpinnerComponent />;

    const locationName = stateLocation ? stateLocation.name : '';

    if (selectLocation)
      return <SelectLocation onBack={this.toggleLocation.bind(this)} onSelect={this.onSelect.bind(this)} showOutlets={false} />;

    return (
      <div className='location'>
         <div className='display-flex flex-row flex-space-between mb-1 align-items-center'>
          <LocationSelectHeader text={locationName} onIconClick={this.toggleLocation.bind(this)} />
          { !stateLocation.is_outlet && <Button outline className="font-weight-bold custom-padding disabled-2-border btn-sm-font" color={ "secondary" } size="md" onClick={() => this.props.history.push('/locations/promotions/new')}>
            <Plus size={20} /> <span className="ml-1 mr-1 d-md-inline d-none">Add New Promotion</span>
          </Button> }
        </div>
    
        <br></br>
        {!loading && promotions ? <PromotionList promotions={promotions} togglePromotion={this.togglePromotion.bind(this)} editPromotion={this.editPromotion.bind(this)} /> : null}
        <ConfirmationModal title={confirmationDialog.title} isOpen={confirmationDialog.show} accept={confirmationDialog.acceptCallback.bind(this)} reject={confirmationDialog.rejectCallback.bind(this)} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { promotions: {
    allPromotions: {
      data: promotions, loading: promotionsLoading
    }
  }, locations: {
    location: {
      data: locationData, loading: locationLoading
    }
  } } = state;

  return { promotions, location: locationData, loading: promotionsLoading || locationLoading };
}

export default connect(mapStateToProps, { getLocation, getPromotions, togglePromotion })(Promotions);