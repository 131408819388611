import React from "react";
import DataTable from "react-data-table-component";
import {Button} from "reactstrap";
import {Edit2,} from "react-feather";

const OutletList = (props) => {

  const edit = (id, isOutlet) => {
		return props.editOutlet(id, isOutlet);
  }

  const columns = (isMaster) => [
    {
      name: isMaster ? 'Master' : 'Outlet',
      selector: 'name',
    }, {
      name: 'Slug',
      selector: 'slug',
    }, {
      name: 'Actions',
      selector: 'id',
      compact: true,
      wrap: true,
      format: ({loc_id, is_outlet}) => {
        return (
          <>
            <Button outline className='btn-icon ml-25' color='primary' onClick={() => edit(loc_id, is_outlet)}><Edit2 color='var(--primary)' className='ml-0 p-0' size={20} /></Button>
          </>
        );
      }
    }
  ];

  const renderOutlets = (outlets) => {
    if(!outlets || outlets.length === 0)
      return null;

    return <DataTable
      columns={columns(false)}
      data={outlets}
      noHeader
    />;
  }

	const renderMaster = (location) => {

		if(!location.is_master_venue) {
			return null;
		}

		return [<DataTable
      columns={columns(true)}
      data={[location]}
      noHeader
    />, <br />];
	}

	const render = (location, outlets) => {
		return [ renderMaster(location), renderOutlets(outlets) ];
	}

  return render(props.location, props.outlets);
}

export default OutletList;