import React from "react";
import DataTable from "react-data-table-component";
import TimeHelper from "../../../helpers/TimeHelper";
import ViewHelper from "../../../helpers/ViewHelper";
import Checkbox from "../../@vuexy/checkbox/CheckboxesVuexy";
import {Check} from "react-feather";


const ListAppReports = (props) => {

  const columns = [
    {
      name: 'ID',
      selector: 'report_id'
    },
    {
      name: 'Reporter ID',
      selector: 'NewUser.email',
      minWidth: '300px',
      sortable: true,
      format: (val) => {
        return  val.NewUser.email ? val.NewUser.email : 'Not Available';
      }
    }, {
      name: 'Author ID',
      selector: 'AgainstUser.email',
      minWidth: '300px',
      sortable: true,
      format: (val) => {
        return  val.AgainstUser.email ? val.AgainstUser.email : 'Not Available';
      }
    }, {
      name: 'Date',
      selector: 'date',
      minWidth: '300px',
      format: (val) => {
        return  val.date ? TimeHelper.toFormattedDateTime(val.date) : 'Not available';
      }
    }, {
      name: 'Type',
      selector: 'type',
      sortable: true,
      minWidth: '200px',
      format: (val) => {
        return  val.type ? val.type : 'Not Available';
      }
    }
    , {
      name: 'Report Reason',
      selector: 'report_reason',
      minWidth: '200px',
      sortable: true,
      format: (val) => {
        return  val.report_reason ? val.report_reason : 'Not available';
      }
    }, {
      name: 'Report Reason Text',
      selector: 'report_reason_text',
      minWidth: '300px',
      wrap: true,
      sortable: true,
      format: (val) => {
        return  val.report_reason_text ? val.report_reason_text : 'Not available';
      }
    }, {
      name: 'Deleted',
      selector: 'is_deleted'
    }
  ];

  const renderAppReports = (reports,handleChange) => {
    if (!reports || reports.length === 0)
      return <h1>No App report found.</h1>;

    return <DataTable
      noHeader
      pagination
      paginationRowsPerPageOptions={[25, 50, 100]}
      paginationPerPage={50}
      selectableRows
      selectableRowsComponent={Checkbox}
      selectableRowsComponentProps={{
        color: "primary",
        icon: <Check className="vx-icon" size={12} />,
        label: "",
        size: "sm"
      }}
      onSelectedRowsChange={handleChange}
      columns={columns}
      data={reports}
    />;
  }
  return renderAppReports(props.reports,props.handleChange);

}

export default ListAppReports;
