import React, { Component } from 'react';
import { connect } from 'react-redux';
import SpinnerComponent from '../../@vuexy/spinner/Fallback-spinner';
import ErrorHandler from '../../shared/ErrorHandler';
import { getAllReports, deleteReport } from '../../../redux/actions/users';
import '../../../assets/scss/pages/page-pricing.scss';
import ListAppReports from './ListAppReports';
import { Button } from 'reactstrap';
import ConfirmationModal from '../../shared/forms/ConfirmationModal';

class AppReports extends Component {

  constructor(props) {
    super(props);
    this.state = {
      reports: '',
      errorDialog: {
        show: false,
        rejectCallback: () => { },
        title: 'App Reports',
        header: 'App Reports'
      },
      confirmationDialog: {
        show: false,
        acceptCallback: () => { },
        rejectCallback: () => { },
        title: 'App Reports',
        header: 'App Reports'
      }
    }
  }

  async componentDidMount() {
    await this.props.getAllReports();
  }

  handleChange = (state) => {
    let reports = [];
    for (let i = 0; i < state.selectedRows.length; i++) {
      reports.push(state.selectedRows[i].report_id);
    }
    this.setState({ reports: reports });
  };

  async deleteReport() {
    if (this.state.reports.length > 0) {
      this.showConfirmationDialog(async () => {
        await this.props.deleteReport(this.state.reports)
      }, null, 'Are you sure you want to delete this reports?', 'App Reports');
    } else {
      this.showErrorDialog(null, 'Please select atleast one report to delete it.', 'Warning');
    }
  }

  showConfirmationDialog(accept, reject, title, header) {
    const defaultState = {
      show: false,
      acceptCallback: () => { },
      rejectCallback: () => { },
      title: '',
      header: ''
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        if (accept)
          accept();
        this.setState({ confirmationDialog: defaultState });

      },
      rejectCallback: () => {
        this.setState({ confirmationDialog: defaultState });
        if (reject)
          reject();
      },
      title: title,
      header: header
    }
    this.setState({ confirmationDialog });
  }

  showErrorDialog(reject, title, header) {
    const defaultState = {
      show: false,
      rejectCallback: () => { },
      title: '',
      header: ''
    };

    const errorDialog = {
      show: true,
      rejectCallback: () => {
        this.setState({ errorDialog: defaultState });
        if (reject)
          reject();
      },
      title: title,
      header: header
    }
    this.setState({ errorDialog });
  }

  render() {
    const { props: {
      reports,
      loading
    } } = this;
    const { state: {
      confirmationDialog,
      errorDialog,
    } } = this;
    if (loading) return <SpinnerComponent />;
    return (
      <>
        <div id='pricing-table'>
          <Button className='mb-1' color='danger' size='md' onClick={() => this.deleteReport()}>
            <span className='ml-1 mr-1 d-md-inline'>Delete</span>
          </Button>
          {!loading && reports ? <ListAppReports reports={reports} handleChange={this.handleChange.bind(this)} /> : null}
        </div>
        <ConfirmationModal header={confirmationDialog.header} title={confirmationDialog.title} isOpen={confirmationDialog.show} reject={confirmationDialog.rejectCallback.bind(this)} rejectTitle={'Cancel'} accept={confirmationDialog.acceptCallback.bind(this)} />
        <ConfirmationModal header={errorDialog.header} title={errorDialog.title} isOpen={errorDialog.show} reject={errorDialog.rejectCallback.bind(this)} rejectTitle={'OK'} />
      </>
    );
    return <ErrorHandler />;
  }
}

function mapStateToProps(state) {
  const { users: {
    allreports: {
      data: reports, loading: loading
    }
  } } = state;

  return { reports, loading: loading };
  return '';
}

export default connect(mapStateToProps, { getAllReports, deleteReport })(AppReports);