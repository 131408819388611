import React, { Component } from 'react';
import { Card, Input, Row, Col, Label, Button } from 'reactstrap';
import SpinnerComponent from '../@vuexy/spinner/Fallback-spinner';
import ErrorHandler from '../shared/ErrorHandler';
import { AvInput, AvGroup, AvFeedback, AvField, AvForm } from "availity-reactstrap-validation";
import Flatpickr from "react-flatpickr";
import _ from 'lodash';
import TimeHelper from "../../helpers/TimeHelper";
import Radio from "../@vuexy/radio/RadioVuexy";
import CONSTANTS from "../../constants";
import { Trash } from "react-feather";
import moment from 'moment';

import '../../assets/scss/plugins/forms/switch/react-toggle.scss';
import 'react-toggle/style.css';
import Toggle from "react-toggle";

const CustomInput = ({ value, defaultValue, inputRef, ...props }) => {
  return <>
    <input type='text' {...props} defaultValue={defaultValue} ref={inputRef} className='btn opacity-0 position-absolute zindex-4' />
    <div className='btn btn-outline-primary'>
      <span className="ml-1 mr-1 primary">Add Dates</span>
    </div>
  </>
};

class LocationTimings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      location: null,
      opening_time_type: 0,
      delivery_time_type: 0,
      pickup_time_type: 0,
      weekdays: [{
        label: 'Monday',
        value: 0,
      }, {
        label: 'Tuesday',
        value: 1,
      }, {
        label: 'Wednesday',
        value: 2,
      }, {
        label: 'Thursday',
        value: 3,
      }, {
        label: 'Friday',
        value: 4,
      }, {
        label: 'Saturday',
        value: 5,
      }, {
        label: 'Sunday',
        value: 6,
      }],
      flexibleWeekdays: [],
      multipleDates: [],
      options: '',
      event: {
        event_name: '',
        event_desc: '',
        event_timeslots: [],
        multipleDates: [],
      },
      event_name: '',
      event_desc: '',
      event_timeslots: [],
      events: [],
      event_holidays: []
    }
  }

  async componentDidMount() {
    const options = this.props.options ? this.props.options : CONSTANTS.DUMMY_OPTIONS;
    if (options) {
      await this.setState({ options: options, events: options.opening_hours.events ? options.opening_hours.events : [], flexibleWeekdays: options.opening_hours.flexible_hours, event_holidays: options.opening_hours.event_holidays ? options.opening_hours.event_holidays : [] });
      if (options.opening_hours.opening_type === 'daily') {
        await this.setState({ opening_time_type: 1 })
      } else if (options.opening_hours.opening_type === 'flexible') {
        await this.setState({ opening_time_type: 2 })
      } else {
        await this.setState({ opening_time_type: 0 })
      }
      await this.setState({ pickup_time_type: options.opening_hours.collection_type, delivery_time_type: options.opening_hours.delivery_type });

    }
  }

  async onChangeInput(event, index) {
    const {
      state: {
        events
      }
    } = this;
    let new_events = events;
    new_events[index][event.target.name] = event.target.value;
    let options = JSON.parse(JSON.stringify(this.state.options));
    options['opening_hours']['events'] = new_events;
    await this.setState({ options, events: new_events });
  }

  async onChangeHolidayInput(event, index) {
    const {
      state: {
        event_holidays
      }
    } = this;
    let new_event_holidays = event_holidays;
    new_event_holidays[index][event.target.name] = event.target.value;
    let options = JSON.parse(JSON.stringify(this.state.options));
    options['opening_hours']['event_holidays'] = new_event_holidays;
    await this.setState({ options, event_holidays: new_event_holidays });
  }

  deleteTimeSlot(index) {
    let event_slots = this.state.event_timeslots;
    event_slots.splice(index, 1);
    this.setState({ event_timeslots: event_slots });
  }

  async deleteEventTimeSlot(eventIndex, index) {
    let events = this.state.events;
    events[eventIndex].event_timings.splice(index, 1);
    let options = JSON.parse(JSON.stringify(this.state.options));
    options['opening_hours']['events'] = events;
    await this.setState({ options, events })
  }


  async updateEventSlotsValue(value, key, type, index) {
    if (!isNaN(value) && value)
      value = parseInt(value);
    let options = JSON.parse(JSON.stringify(this.state.options));
    let obj = options['opening_hours']['events'][key]['event_timings'];
    let parts = type.split('.');
    const n = parts.length - 1;
    for (let i = 0; i < n; i++)
      obj = obj[parts[i]];
    obj[index][parts[n]] = value;
    await this.setState({ options });
  }

  async updateHolidayEventSlotsValue(value, type, index) {
    if (!isNaN(value) && value)
      value = parseInt(value);
    let options = JSON.parse(JSON.stringify(this.state.options));
    let obj = options['opening_hours']['event_holidays'];
    let parts = type.split('.');
    const n = parts.length - 1;
    for (let i = 0; i < n; i++)
      obj = obj[parts[i]];
    obj[index][parts[n]] = value;
    await this.setState({ options });
  }

  async updateEventTimings(value, type, index) {
    if (!isNaN(value) && value)
      value = parseInt(value);
    let options = JSON.parse(JSON.stringify(this.state.options));
    let obj = options['opening_hours']['events'];
    let parts = type.split('.');
    const n = parts.length - 1;
    for (let i = 0; i < n; i++)
      obj = obj[parts[i]];
    obj[index][parts[n]] = value;
    await this.setState({ options });
  }

  async updateEventValue(value, key, type, index) {
    if (!isNaN(value) && value)
      value = parseInt(value);
    let events = JSON.parse(JSON.stringify(this.state.events));
    let obj = events[key]['event_timings'];
    let parts = type.split('.');
    const n = parts.length - 1;
    for (let i = 0; i < n; i++)
      obj = obj[parts[i]];
    obj[index][parts[n]] = value;
    await this.setState({ events });
  }

  async updateFlexiableValue(value, key, type, index, isEvent = false, event_index = 0) {
    let options = JSON.parse(JSON.stringify(this.state.options));
    let obj = isEvent ? options['opening_hours']['events'][event_index]['event_timings'][key] : options['opening_hours'][key];
    let parts = type.split('.');
    const n = parts.length - 1;
    for (let i = 0; i < n; i++)
      obj = obj[parts[i]];
    obj[index][parts[n]] = value;
    await this.setState({ options });
  }

  async updateSpecialValue(value, key, type, index) {
    let options = JSON.parse(JSON.stringify(this.state.options));
    let obj = options['opening_hours'][key];
    let parts = type.split('.');
    const n = parts.length - 1;
    for (let i = 0; i < n; i++)
      obj = obj[parts[i]];
    obj[index][parts[n]] = value;
    await this.setState({ options });
  }

  async updateValue(value, type, event_index = 0, isEvent = false) {
    if (!isNaN(value) && value)
      value = parseInt(value);
    let options = JSON.parse(JSON.stringify(this.state.options));
    let obj = isEvent ? options['opening_hours']['events'][event_index]['event_timings'] : options['opening_hours'];
    let parts = type.split('.');
    const n = parts.length - 1;
    for (let i = 0; i < n; i++)
      obj = obj[parts[i]];
    let data = obj[parts[n]];
    if (data.includes(value.toString())) {
      var index = data.indexOf(value.toString());
      if (index !== -1) {
        data.splice(index, 1);
      }
    } else {
      data.push(value.toString());
    }
    data.sort((a, b) => a - b);
    obj[parts[n]] = data;
    await this.setState({ options });
  }

  async updateValueFields(value, key, type, isEvent = false, index = 0) {
    if (!isNaN(value) && value)
      value = parseInt(value);
    let options = JSON.parse(JSON.stringify(this.state.options));
    let obj = isEvent ? options['opening_hours']['events'][index]['event_timings'][key] : options['opening_hours'][key];
    let parts = type.split('.');
    const n = parts.length - 1;
    for (let i = 0; i < n; i++)
      obj = obj[parts[i]];
    obj[parts[n]] = value;
    await this.setState({ options });
  }

  async updateTimeSlotsTypes(value, key, type) {
    if (key === 'opening_type') {
      await this.setState({ [type]: value });
      if (value == 0) {
        await this.updateTypesOptions('24/7', key);
      } else if (value == 1) {
        await this.updateTypesOptions('daily', key);
      } else {
        await this.updateTypesOptions('flexible', key);
      }
    } else {
      await this.setState({ [type]: value });
      await this.updateTypesOptions(String(value), key);
    }
  }

  async updateEventTimeSlotsTypes(value, type, index) {
    let opening_type_value = value;
    if (value == 0) {
      value = '24/7';
    } else if (value == 1) {
      value = 'daily';
    } else {
      value = 'flexible';
    }
    let options = JSON.parse(JSON.stringify(this.state.options));
    let obj = options['opening_hours']['events'][index]['event_timings'];
    let parts = type.split('.');
    const n = parts.length - 1;
    for (let i = 0; i < n; i++)
      obj = obj[parts[i]];
    obj[parts[n]] = value;
    obj['opening_type_value'] = parseInt(opening_type_value);
    await this.setState({ options });
  }

  async updateTypesOptions(value, type) {
    let options = JSON.parse(JSON.stringify(this.state.options));
    let obj = options['opening_hours'];
    let parts = type.split('.');
    const n = parts.length - 1;
    for (let i = 0; i < n; i++)
      obj = obj[parts[i]];
    obj[parts[n]] = value;
    await this.setState({ options });
  }

  async updateTimings() {
    await this.props.updateOptions(this.state.options, this.state.events);
  }

  async updateSpecialTimings(index) {
    const {
      state: {
        events
      }
    } = this;
    let dates = events[index].multipleDates.map(d => TimeHelper.toFormattedDate(d, CONSTANTS.DATES.FORMAT.DB_DATE));
    let currentTimeSlots = [];
    for (let i = 0; i < dates.length; i++) {
      currentTimeSlots.push({
        day: dates[i],
        open: '00:00',
        close: '23:59',
        status: 1,
      });
    }
    events[index].event_timings = currentTimeSlots;
    let options = JSON.parse(JSON.stringify(this.state.options));
    options['opening_hours']['events'] = events;
    await this.setState({ options, events });
  }

  async addEvent() {
    const {
      state: {
        events
      }
    } = this;
    events.push({
      event_name: '',
      event_description: '',
      event_start_date: moment(new Date()).format('YYYY MM DD'),
      event_end_date: moment(new Date()).format('YYYY MM DD'),
      event_timings: CONSTANTS.EVENT_TIMINGS,
    });
    let options = JSON.parse(JSON.stringify(this.state.options));
    options['opening_hours']['events'] = events;
    await this.setState({ options });
  }

  async addHoliday() {
    const {
      state: {
        event_holidays
      }
    } = this;
    event_holidays.push({
      event_name: '',
      event_description: '',
      event_date: TimeHelper.toFormattedTime(new Date(), CONSTANTS.DATES.FORMAT.DB_DATE),
      multipleDates: [],
    });
    let options = JSON.parse(JSON.stringify(this.state.options));
    options['opening_hours']['event_holidays'] = event_holidays;
    await this.setState({ options, event_holidays });
  }

  async deleteEvent(index) {
    const {
      state: {
        events
      }
    } = this;
    let new_events = events;
    new_events.splice(index, 1);
    let options = JSON.parse(JSON.stringify(this.state.options));
    options['opening_hours']['events'] = new_events;
    await this.setState({ options, events: new_events })
  }

  async deleteHolidayEvent(index) {
    const {
      state: {
        event_holidays
      }
    } = this;
    let new_event_holidays = event_holidays;
    new_event_holidays.splice(index, 1);
    let options = JSON.parse(JSON.stringify(this.state.options));
    options['opening_hours']['event_holidays'] = new_event_holidays;
    await this.setState({ options, event_holidays: new_event_holidays })
  }


  async deleteHolidaySlot(event_index, index) {
    const {
      state: {
        event_holidays
      }
    } = this;

    let new_event_holidays = event_holidays[event_index];
    new_event_holidays.multipleDates.splice(index, 1);
    let options = JSON.parse(JSON.stringify(this.state.options));
    event_holidays[event_index] = new_event_holidays;
    options['opening_hours']['event_holidays'] = event_holidays;
    await this.setState({ options, event_holidays })
  }

  renderTimeSlots(days, key, isEvent = false, index = 0) {
    let showActive = true;
    const weekdays = this.state.weekdays;
    return weekdays.map((timeSlot, i) => (
      <>
        {showActive &&
          <Col className='col-3 col-md-1'>
            {<label className='react-toggle-wrapper w-25'>
              <Toggle
                name={'active'}
                data-id={days ? days.includes(timeSlot.value.toString()) ? true : false : false}
                defaultChecked={days ? days.includes(timeSlot.value.toString()) ? true : false : false}
                checked={days ? days.includes(timeSlot.value.toString()) ? true : false : false}
                className='switch-primary'
                onChange={(e) => {
                  this.updateValue(timeSlot.value, key, index, isEvent);
                }}
              />
            </label>}
          </Col>

        }
        <Col className='col-9 col-md-3 mb-2'>
          <h4>{this.state.weekdays[i].label}</h4>
        </Col>

      </>
    ));

  }

  renderFlexibleTimeSlots(days, key, isEvent = false, index = 0) {
    let showActive = true;
    let fWeekDays = days
    return fWeekDays.map((timeSlot, i) => (
      <>
        {showActive && <Col className='col-3 col-md-1'>
          <label className='react-toggle-wrapper w-25'>
            <Toggle
              name={'active'}
              data-id={timeSlot.status ? true : false}
              defaultChecked={timeSlot.status ? true : false}
              checked={timeSlot.status ? true : false}
              className='switch-primary'
              onChange={(e) => {
                this.updateFlexiableValue(e.target.checked, key, 'status', i, isEvent, index)
              }}
            />
          </label>
        </Col>}
        <Col className='col-6 col-md-3 mb-2'>
          <h4>{this.state.weekdays[i].label}</h4>
        </Col>
        <Col md={3} sm={6} className='mb-2'>
          <Flatpickr
            className='form-control'
            name='start_time'
            defaultValue={fWeekDays ? fWeekDays.some(item => _.isEqual(item.day, days[i] ? days[i].day : '')) ? days[i].open : timeSlot.open : timeSlot.open}
            value={fWeekDays ? fWeekDays.some(item => _.isEqual(item.day, days[i] ? days[i].day : '')) ? days[i].open : timeSlot.open : timeSlot.open}
            options={{
              enableTime: true,
              noCalendar: true,
              time_24hr: false,
              minuteIncrement: 1
            }}
            onClose={date => {
              this.updateFlexiableValue(TimeHelper.toFormattedTime(date[0], CONSTANTS.TIMES.FORMAT.DB), key, 'open', i, isEvent, index);
            }}
          />
        </Col>
        <Col md={3} sm={6} className='mb-2'>
          <Flatpickr
            className='form-control'
            name='end_time'
            defaultValue={fWeekDays ? fWeekDays.some(item => _.isEqual(item.day, days[i] ? days[i].day : '')) ? days[i].close : timeSlot.close : timeSlot.close}
            value={fWeekDays ? fWeekDays.some(item => _.isEqual(item.day, days[i] ? days[i].day : '')) ? days[i].close : timeSlot.close : timeSlot.close}
            options={{
              enableTime: true,
              noCalendar: true,
              time_24hr: false,
              minuteIncrement: 15
            }}
            onChange={date => {
              this.updateFlexiableValue(TimeHelper.toFormattedTime(date[0], CONSTANTS.TIMES.FORMAT.DB), key, 'close', i, isEvent, index);
            }}
          />
        </Col>
        <Col md={2}>
        </Col>

      </>
    ));

  }


  renderEventsTimeSlots(event, key) {
    return event && event.event_timings.map((timeSlot, i) => (
      <>
        <Col className='col-9 col-md-3 mb-2'>
          <h4>{TimeHelper.toFormattedDate(timeSlot.day, CONSTANTS.DATES.FORMAT.EVENT_DISPLAY)}</h4>
        </Col>
        <Col md={3} sm={6} className='mb-2'>
          <Flatpickr
            className='form-control'
            name='start_time'
            defaultValue={timeSlot.open}
            value={timeSlot.open}
            options={{
              enableTime: true,
              noCalendar: true,
              time_24hr: false,
              minuteIncrement: 1,
            }}
            onClose={date => {
              this.updateEventSlotsValue(TimeHelper.toFormattedTime(date[0], CONSTANTS.TIMES.FORMAT.DB), key, 'open', i);
              this.updateEventValue(TimeHelper.toFormattedTime(date[0], CONSTANTS.TIMES.FORMAT.DB), key, 'open', i);
            }}
          />
        </Col>
        <Col md={3} sm={6} className='mb-2'>
          <Flatpickr
            className='form-control'
            name='end_time'
            defaultValue={timeSlot.close}
            value={timeSlot.close}
            options={{
              enableTime: true,
              noCalendar: true,
              time_24hr: false,
              minuteIncrement: 1
            }}
            onChange={date => {
              this.updateEventSlotsValue(TimeHelper.toFormattedTime(date[0], CONSTANTS.TIMES.FORMAT.DB), key, 'close', i);
              this.updateEventValue(TimeHelper.toFormattedTime(date[0], CONSTANTS.TIMES.FORMAT.DB), key, 'close', i);
            }}
          />
        </Col>
        <Col md={2} sm={12} className='mb-2'>
          <Button outline className='font-weight-bold custom-padding danger-2-border mx-2' color={'danger'} size='md'
            onClick={() => this.deleteEventTimeSlot(key, timeSlot.index)}>
            <Trash size={16} />
          </Button>
        </Col>
      </>
    ));

  }

  renderEvents(events) {
    return events && events.map((event, i) => {
      return (
        <>
          <Card className='full-width'>
            <Row className='p-1'>
              <Col md={12} sm={12} className="mb-2">
                <AvGroup>
                  <Row>
                    <Col md="12" sm="12">
                      <Label> Events # {i + 1} </Label>
                      <button className='float-right btn btn-sm btn-danger' onClick={() => this.deleteEvent(i)}>Delete</button>
                    </Col>
                  </Row>
                </AvGroup>
                <AvGroup>
                  <Input
                    data-id={event.event_name}
                    name={`event_name`}
                    placeholder={'Event Name'}
                    type='text'
                    value={event.event_name}
                    onChange={(e) => this.onChangeInput(e, i)}
                  />
                </AvGroup>
                <AvGroup>
                  <Input
                    data-id={event.event_description}
                    name={`event_description`}
                    placeholder={'Event Description'}
                    type='text'
                    value={event.event_description}
                    onChange={(e) => this.onChangeInput(e, i)}
                  />
                </AvGroup>
              </Col>

              <Col md={6} sm={6} className='mb-2'>
                <Label> Start Date </Label>
                <Flatpickr
                  className='form-control'
                  name='event_date'
                  defaultValue={event.event_start_date}
                  value={event.event_start_date}
                  onChange={date => {
                    this.updateEventTimings(TimeHelper.toFormattedTime(date[0], CONSTANTS.DATES.FORMAT.DB_DATE), 'event_start_date', i)
                  }}
                />
              </Col>
              <Col md={6} sm={6} className='mb-2'>
                <Label> End Date </Label>
                <Flatpickr
                  className='form-control'
                  name='event_date'
                  defaultValue={event.event_end_date}
                  value={event.event_end_date}
                  onChange={date => {
                    this.updateEventTimings(TimeHelper.toFormattedTime(date[0], CONSTANTS.DATES.FORMAT.DB_DATE), 'event_end_date', i)
                  }}
                />
              </Col>
              <Col md="12" sm="12">
                <AvGroup>
                  <Label> Timings </Label>
                  <div id='time' onChange={(e) => this.updateEventTimeSlotsTypes(e.target.value, 'opening_type', i)}>
                    <div className="d-inline-block mr-1">
                      <Radio label="24/7" value={CONSTANTS.VARIABLES.LOCATIONS.OPENING_TYPE.ALL_TIME} checked={event.event_timings.opening_type_value == CONSTANTS.VARIABLES.LOCATIONS.OPENING_TYPE.ALL_TIME} />
                    </div>
                    <div className="d-inline-block mr-1">
                      <Radio label="Daily" value={CONSTANTS.VARIABLES.LOCATIONS.OPENING_TYPE.DAILY} checked={event.event_timings.opening_type_value == CONSTANTS.VARIABLES.LOCATIONS.OPENING_TYPE.DAILY} />
                    </div>
                    <div className="d-inline-block mr-1">
                      <Radio label="Flexible" value={CONSTANTS.VARIABLES.LOCATIONS.OPENING_TYPE.FLEXIBLE} checked={event.event_timings.opening_type_value == CONSTANTS.VARIABLES.LOCATIONS.OPENING_TYPE.FLEXIBLE} />
                    </div>
                  </div>
                </AvGroup>
              </Col>

              {event.event_timings.opening_type_value == CONSTANTS.VARIABLES.LOCATIONS.OPENING_TYPE.FLEXIBLE &&
                <>
                  {this.renderFlexibleTimeSlots(event.event_timings.flexible_hours, 'flexible_hours', true, i)}
                </>
              }
              {event.event_timings.opening_type_value == CONSTANTS.VARIABLES.LOCATIONS.OPENING_TYPE.DAILY &&
                <>

                  <Col md="12" sm="12">
                    <Row>
                      <Col md={6} sm={6} className='mb-2'>
                        <Flatpickr
                          className='form-control'
                          name='start_time'
                          defaultValue={event.event_timings.daily_hours.open}
                          value={event.event_timings.daily_hours.open}
                          options={{
                            enableTime: true,
                            noCalendar: true,
                            time_24hr: false,
                            dateFormat: 'H:i K',
                            minuteIncrement: 1
                          }}
                          onClose={date => {
                            this.updateValueFields(TimeHelper.toFormattedTime(date[0]), 'daily_hours', 'open', true, i);
                          }}
                        />
                      </Col>
                      <Col md={6} sm={6} className='mb-2'>
                        <Flatpickr
                          className='form-control'
                          name='end_time'
                          defaultValue={event.event_timings.daily_hours.close}
                          value={event.event_timings.daily_hours.close}
                          options={{
                            enableTime: true,
                            noCalendar: true,
                            time_24hr: false,
                            dateFormat: 'H:i K',
                            minuteIncrement: 1
                          }}
                          onChange={date => {
                            this.updateValueFields(TimeHelper.toFormattedTime(date[0]), 'daily_hours', 'close', true, i);
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                  {this.renderTimeSlots(event.event_timings.daily_days, 'daily_days', true, i)}
                </>
              }
            </Row>
          </Card>
        </>
      );
    });
  }

  renderHolidaySlots(multipleDates, index) {
    return multipleDates && multipleDates.map((multipleDate, i) => {
      return (
        <>
          <Col md={6} sm={6} className='mb-2'>
            <AvGroup>
              <Row>
                <Col md="12" sm="12">
                  <Label> {moment(multipleDate).format('YYYY-MM-DD')} </Label>
                  <button className='float-right btn btn-sm btn-danger' onClick={() => this.deleteHolidaySlot(index, i)}>Delete</button>
                </Col>
              </Row>
            </AvGroup>
          </Col>
        </>
      );
    });
  }

  renderHolidayEvents(events) {
    return events && events.map((event, i) => {
      return (
        <>
          <Card className='full-width'>
            <Row className='p-1'>
              <Col md={12} sm={12} className="mb-2">
                <AvGroup>
                  <Row>
                    <Col md="12" sm="12">
                      <Label> HolidayEvents # {i + 1} </Label>
                      <button className='float-right btn btn-sm btn-danger' onClick={() => this.deleteHolidayEvent(i)}>Delete</button>
                    </Col>
                  </Row>
                </AvGroup>
                <AvGroup>
                  <Input
                    data-id={event.event_name}
                    name={`event_name`}
                    placeholder={'Event Name'}
                    type='text'
                    value={event.event_name}
                    onChange={(e) => this.onChangeHolidayInput(e, i)}
                  />
                </AvGroup>
                <AvGroup>
                  <Input
                    data-id={event.event_description}
                    name={`event_description`}
                    placeholder={'Event Description'}
                    type='text'
                    value={event.event_description}
                    onChange={(e) => this.onChangeHolidayInput(e, i)}
                  />
                </AvGroup>
              </Col>
              <Col md={12} sm={12} className='mb-2'>
                <Flatpickr
                  className='form-control'
                  options={{
                    mode: 'multiple', dateFormat: 'YYYY-MM-DD',
                  }}
                  onChange={async date => {
                    let dates = [];
                    date.map((date) => {
                      dates.push(moment(date).format('YYYY-MM-DD'));
                    })
                    event.multipleDates = dates;
                    await this.setState({ event_holidays: events });
                  }}
                  render={({ defaultValue, value, ...props }, ref) => {
                    return <CustomInput defaultValue={defaultValue} inputRef={ref} />;
                  }}
                />

                {/* <Flatpickr
                  className='form-control'
                  name='event_date'
                  defaultValue={event.event_date}
                  value={event.event_date}
                  onChange={date => {
                    this.updateHolidayEventSlotsValue(TimeHelper.toFormattedTime(date[0], CONSTANTS.DATES.FORMAT.DB_DATE), 'event_date', i)
                  }}
                /> */}
              </Col>
              {this.renderHolidaySlots(events[i].multipleDates, i)}
            </Row>
          </Card>
        </>
      );
    });
  }
  render() {
    const { state: {
      loading,
      opening_time_type,
      delivery_time_type,
      pickup_time_type,
      options,
      event_timeslots,
      event_holidays,

    } } = this;
    console.log(options);
    let events = options && options.opening_hours.events ? options.opening_hours.events : [];
    if (options)
      return (
        <>
          <div className='order-types'>
            <div>
              <AvForm>
                <Card className='full-width'>
                  <Row className='p-1'>
                    <Col md="12" sm="12">
                      <AvGroup>
                        <Label> Opening Type </Label>
                        <div id='time' onChange={(e) => this.updateTimeSlotsTypes(e.target.value, 'opening_type', 'opening_time_type')}>
                          <div className="d-inline-block mr-1">
                            <Radio label="24/7" name={`opening_time_type`} value={CONSTANTS.VARIABLES.LOCATIONS.OPENING_TYPE.ALL_TIME} checked={opening_time_type == CONSTANTS.VARIABLES.LOCATIONS.OPENING_TYPE.ALL_TIME} />
                          </div>
                          <div className="d-inline-block mr-1">
                            <Radio label="Daily" name={`opening_time_type`} value={CONSTANTS.VARIABLES.LOCATIONS.OPENING_TYPE.DAILY} checked={opening_time_type == CONSTANTS.VARIABLES.LOCATIONS.OPENING_TYPE.DAILY} />
                          </div>
                          <div className="d-inline-block mr-1">
                            <Radio label="Flexible" name={`opening_time_type`} value={CONSTANTS.VARIABLES.LOCATIONS.OPENING_TYPE.FLEXIBLE} checked={opening_time_type == CONSTANTS.VARIABLES.LOCATIONS.OPENING_TYPE.FLEXIBLE} />
                          </div>
                        </div>
                      </AvGroup>
                    </Col>
                    {opening_time_type == CONSTANTS.VARIABLES.LOCATIONS.OPENING_TYPE.FLEXIBLE &&
                      <>
                        {this.renderFlexibleTimeSlots(options.opening_hours.flexible_hours, 'flexible_hours')}
                      </>
                    }
                    {opening_time_type == CONSTANTS.VARIABLES.LOCATIONS.OPENING_TYPE.DAILY &&
                      <>
                        <Col md="12" sm="12">
                          <Row>
                            <Col md={6} sm={6} className='mb-2'>
                              <Flatpickr
                                className='form-control'
                                name='start_time'
                                defaultValue={options.opening_hours.daily_hours.open}
                                value={options.opening_hours.daily_hours.open}
                                options={{
                                  enableTime: true,
                                  noCalendar: true,
                                  time_24hr: false,
                                  dateFormat: 'H:i K',
                                  minuteIncrement: 1
                                }}
                                onClose={date => {
                                  this.updateValueFields(TimeHelper.toFormattedTime(date[0]), 'daily_hours', 'open');
                                }}
                              />
                            </Col>
                            <Col md={6} sm={6} className='mb-2'>
                              <Flatpickr
                                className='form-control'
                                name='end_time'
                                defaultValue={options.opening_hours.daily_hours.close}
                                value={options.opening_hours.daily_hours.close}
                                options={{
                                  enableTime: true,
                                  noCalendar: true,
                                  time_24hr: false,
                                  dateFormat: 'H:i K',
                                  minuteIncrement: 1
                                }}
                                onChange={date => {
                                  this.updateValueFields(TimeHelper.toFormattedTime(date[0]), 'daily_hours', 'close');
                                }}
                              />
                            </Col>
                          </Row>
                        </Col>
                        {this.renderTimeSlots(options.opening_hours.daily_days, 'daily_days')}
                      </>
                    }
                  </Row>
                </Card>
                <Card className='full-width'>
                  <Row className='p-1'>
                    <Col md="12" sm="12">
                      <AvGroup>
                        <Label> Delivery  Hours </Label>
                        <div id='time' onChange={(e) => this.updateTimeSlotsTypes(e.target.value, 'delivery_type', 'delivery_time_type')}>
                          <div className="d-inline-block mr-1">
                            <Radio label="Same as opening" name={`delivery_time_type`} value={CONSTANTS.VARIABLES.LOCATIONS.DELIVERY_HOURS.SAME_AS_OPENING} checked={delivery_time_type == CONSTANTS.VARIABLES.LOCATIONS.DELIVERY_HOURS.SAME_AS_OPENING} />
                          </div>
                          <div className="d-inline-block mr-1">
                            <Radio label="24/7" name={`delivery_time_type`} value={CONSTANTS.VARIABLES.LOCATIONS.DELIVERY_HOURS.ALL_TIME} checked={delivery_time_type == CONSTANTS.VARIABLES.LOCATIONS.DELIVERY_HOURS.ALL_TIME} />
                          </div>
                          <div className="d-inline-block mr-1">
                            <Radio label="Daily" name={`delivery_time_type`} value={CONSTANTS.VARIABLES.LOCATIONS.DELIVERY_HOURS.DAILY} checked={delivery_time_type == CONSTANTS.VARIABLES.LOCATIONS.DELIVERY_HOURS.DAILY} />
                          </div>
                          <div className="d-inline-block mr-1">
                            <Radio label="Flexible" name={`delivery_time_type`} value={CONSTANTS.VARIABLES.LOCATIONS.DELIVERY_HOURS.FLEXIBLE} checked={delivery_time_type == CONSTANTS.VARIABLES.LOCATIONS.DELIVERY_HOURS.FLEXIBLE} />
                          </div>
                        </div>
                      </AvGroup>
                    </Col>
                    {delivery_time_type == CONSTANTS.VARIABLES.LOCATIONS.DELIVERY_HOURS.FLEXIBLE &&
                      <>
                        {this.renderFlexibleTimeSlots(options.opening_hours.delivery_flexible_hours, 'delivery_flexible_hours')}
                      </>
                    }
                    {delivery_time_type == CONSTANTS.VARIABLES.LOCATIONS.DELIVERY_HOURS.DAILY &&
                      <>
                        <Col md="12" sm="12">
                          <Row>
                            <Col md={6} sm={6} className='mb-2'>
                              <Flatpickr
                                className='form-control'
                                name='start_time'
                                defaultValue={options.opening_hours.delivery_hours.open}
                                value={options.opening_hours.delivery_hours.open}
                                options={{
                                  enableTime: true,
                                  noCalendar: true,
                                  time_24hr: false,
                                  dateFormat: 'H:i K',
                                  minuteIncrement: 1
                                }}
                                onClose={date => {
                                  this.updateValueFields(TimeHelper.toFormattedTime(date[0]), 'delivery_hours', 'open');
                                }}
                              />
                            </Col>
                            <Col md={6} sm={6} className='mb-2'>
                              <Flatpickr
                                className='form-control'
                                name='end_time'
                                defaultValue={options.opening_hours.delivery_hours.close}
                                value={options.opening_hours.delivery_hours.close}
                                options={{
                                  enableTime: true,
                                  noCalendar: true,
                                  time_24hr: false,
                                  dateFormat: 'H:i K',
                                  minuteIncrement: 15
                                }}
                                onChange={date => {
                                  this.updateValueFields(TimeHelper.toFormattedTime(date[0]), 'delivery_hours', 'close');
                                }}
                              />
                            </Col>
                          </Row>
                        </Col>
                        {this.renderTimeSlots(options.opening_hours.delivery_days, 'delivery_days')}
                      </>
                    }
                  </Row>
                </Card>
                <Card className='full-width'>
                  <Row className='p-1'>
                    <Col md="12" sm="12">
                      <AvGroup>
                        <Label> Pickup  Hours </Label>
                        <div id='time' onChange={(e) => this.updateTimeSlotsTypes(e.target.value, 'collection_type', 'pickup_time_type')}>
                          <div className="d-inline-block mr-1">
                            <Radio label="Same as opening" name={`pickup_time_type`} value={CONSTANTS.VARIABLES.LOCATIONS.PICKUP_HOURS.SAME_AS_OPENING} checked={pickup_time_type == CONSTANTS.VARIABLES.LOCATIONS.PICKUP_HOURS.SAME_AS_OPENING} />
                          </div>
                          <div className="d-inline-block mr-1">
                            <Radio label="24/7" name={`pickup_time_type`} value={CONSTANTS.VARIABLES.LOCATIONS.PICKUP_HOURS.ALL_TIME} checked={pickup_time_type == CONSTANTS.VARIABLES.LOCATIONS.PICKUP_HOURS.ALL_TIME} />
                          </div>
                          <div className="d-inline-block mr-1">
                            <Radio label="Daily" name={`pickup_time_type`} value={CONSTANTS.VARIABLES.LOCATIONS.PICKUP_HOURS.DAILY} checked={pickup_time_type == CONSTANTS.VARIABLES.LOCATIONS.PICKUP_HOURS.DAILY} />
                          </div>
                          <div className="d-inline-block mr-1">
                            <Radio label="Flexible" name={`pickup_time_type`} value={CONSTANTS.VARIABLES.LOCATIONS.PICKUP_HOURS.FLEXIBLE} checked={pickup_time_type == CONSTANTS.VARIABLES.LOCATIONS.PICKUP_HOURS.FLEXIBLE} />
                          </div>
                        </div>
                      </AvGroup>
                    </Col>

                    {pickup_time_type == CONSTANTS.VARIABLES.LOCATIONS.PICKUP_HOURS.FLEXIBLE &&
                      <>
                        {this.renderFlexibleTimeSlots(options.opening_hours.pickup_flexible_hours, 'pickup_flexible_hours')}
                      </>
                    }
                    {pickup_time_type == CONSTANTS.VARIABLES.LOCATIONS.PICKUP_HOURS.DAILY &&
                      <>
                        <Col md="12" sm="12">
                          <Row>
                            <Col md={6} sm={6} className='mb-2'>
                              <Flatpickr
                                className='form-control'
                                name='start_time'
                                defaultValue={options.opening_hours.collection_hours.open}
                                value={options.opening_hours.collection_hours.open}
                                options={{
                                  enableTime: true,
                                  noCalendar: true,
                                  time_24hr: false,
                                  dateFormat: 'H:i K',
                                  minuteIncrement: 1
                                }}
                                onClose={date => {
                                  this.updateValueFields(TimeHelper.toFormattedTime(date[0]), 'collection_hours', 'open');
                                }}
                              />
                            </Col>
                            <Col md={6} sm={6} className='mb-2'>
                              <Flatpickr
                                className='form-control'
                                name='end_time'
                                defaultValue={options.opening_hours.collection_hours.close}
                                value={options.opening_hours.collection_hours.close}
                                options={{
                                  enableTime: true,
                                  noCalendar: true,
                                  time_24hr: false,
                                  dateFormat: 'H:i K',
                                  minuteIncrement: 15
                                }}
                                onChange={date => {
                                  this.updateValueFields(TimeHelper.toFormattedTime(date[0]), 'collection_hours', 'close');
                                }}
                              />
                            </Col>
                          </Row>
                        </Col>
                        {this.renderTimeSlots(options.opening_hours.collection_days, 'collection_days')}
                      </>
                    }
                  </Row>
                </Card>

                {events && events.length > 0 ? this.renderEvents(events) : ''}
                <Card className='full-width'>
                  <Row className='p-1'>
                    <div className='actions pl-2'>
                      <button className='btn btn-primary' onClick={() => this.addEvent()}>Temporary Timings</button>
                    </div>
                  </Row>
                </Card>
                {event_holidays && event_holidays.length > 0 ? this.renderHolidayEvents(event_holidays) : ''}
                <Card className='full-width'>
                  <Row className='p-1'>
                    <div className='actions pl-2'>
                      <button className='btn btn-primary' onClick={() => this.addHoliday()}>Add special closure</button>
                    </div>
                  </Row>
                </Card>
                <div className='actions pt-2'>
                  <button className='btn btn-primary' onClick={() => this.updateTimings()}>Save Timings</button>
                </div>
              </AvForm>
            </div>
          </div >
        </>
      );

    if (loading) return <SpinnerComponent />;
    return <ErrorHandler />;
  }
}

export default LocationTimings;
