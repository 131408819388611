import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getLocation } from '../../redux/actions/locations';
import SpinnerComponent from '../@vuexy/spinner/Fallback-spinner';
import SelectLocation from '../shared/SelectLocation';
import LocationSelectHeader from '../shared/LocationSelectHeader';
import CONSTANTS from '../../constants';
import LocationService from '../../services/LocationService';
import { getSmsCampaigns, cancelCampaign, deleteCampaign } from '../../redux/actions/campaigns';
import CampaignsList from './SmsCampaignsList';
import { Plus } from 'react-feather';
import { Button } from 'reactstrap';
import ConfirmationModal from '../shared/forms/ConfirmationModal';

class SmsCampaigns extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      active: '1',
      selectLocation: false,
      location: null,
      loading: true,
      confirmationDialog: {
        show: false,
        acceptCallback: () => { },
        rejectCallback: () => { },
        title: '',
      },
    };
  }

  async componentDidMount() {
    const location = LocationService.getLocation(`${CONSTANTS.CACHE_KEY_PREFIX}_location`);
    if (location) {
      await this.refreshData(location.loc_id);
    } else {
      this.setState({ loading: false, selectLocation: true })
    }
  }

  async refreshData(loc_id) {
    let location;
    if (loc_id !== 0 && (!this.state.location || loc_id !== this.state.location.loc_id)) {
      await this.props.getLocation(loc_id);
      location = this.props.location;
    } else {
      location = this.state.location;
    }
    await this.getSmsCampaigns(loc_id);
    this.setState({ loading: false, location: JSON.parse(JSON.stringify(location)) });
  }

  async getSmsCampaigns(loc_id) {
    return this.props.getSmsCampaigns(loc_id);
  }

  onBlur() {
    if (this.state.location)
      this.setState({ selectLocation: !this.state.selectLocation });
  }

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  async onSelect(location) {
    this.setState({ loading: true });
    LocationService.setLocation(location, `${CONSTANTS.CACHE_KEY_PREFIX}_location`);
    await this.refreshData(location.loc_id);
    this.setState({ selectLocation: false, loading: false });
  }

  editCampaign(id) {
    this.props.history.push(`/locations/sms/campaigns/edit/${id}`);
  }

  viewCampaign(id) {
    this.props.history.push(`/locations/sms/campaigns/details/${id}`);
  }

  cancelCampaign(id) {
    this.showConfirmationDialog(
      async () => {
        await this.props.cancelCampaign(this.props.location.loc_id, id);
      }, null, 'Are you sure you want to cancel this campaign?', 'Cancel Campaign');
  }

  deleteCampaign(id) {
    this.showConfirmationDialog(
      async () => {
        await this.props.deleteCampaign(this.props.location.loc_id, id);
      }, null, 'Are you sure you want to delete this campaign?', 'Delete Campaign');
  }

  showConfirmationDialog(accept, reject, title, header) {
    const defaultState = {
      show: false,
      acceptCallback: () => { },
      rejectCallback: () => { },
      title: '',
      header: '',
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        accept();
        this.setState({ confirmationDialog: defaultState });
      },
      rejectCallback: () => {
        this.setState({ confirmationDialog: defaultState });
        if (reject)
          reject();
      },
      title: title,
      header: header,
    }
    this.setState({ confirmationDialog });
  }

  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab })
    }
  }

  toggle = tab => {
    if (this.state.active !== tab) {
      this.setState({ active: tab })
    }
  }

  render() {
    const { props: {
      campaigns
    } } = this;
    const { state: {
      selectLocation,
      confirmationDialog,
      loading,
      location: stateLocation
    } } = this;
    let sms_wallet = campaigns ? campaigns.staff.wallet : 0;
    if (loading) return <SpinnerComponent />;
    const locationName = stateLocation ? stateLocation.name : '';
    if (selectLocation)
      return <SelectLocation onBack={this.toggleLocation.bind(this)} onSelect={this.onSelect.bind(this)} />;
    return (
      <div className='location'>
        <div className='display-flex flex-row flex-space-between mb-1 align-items-center'>
          <LocationSelectHeader text={locationName} onIconClick={this.toggleLocation.bind(this)} />
          <Button outline className='font-weight-bold custom-padding-btn disabled-2-border btn-sm-font' color={'secondary'} size='md' >
            <span className='d-md-inline'>SMS In Wallet : {sms_wallet} Units</span>
          </Button>
          <Button outline className='font-weight-bold custom-padding disabled-2-border btn-sm-font' color={'secondary'} size='md' onClick={() => this.props.history.push('/locations/sms/campaigns/new')}>
            <Plus size={20} /> <span className='ml-1 mr-1 d-md-inline'>Add New Campaign</span>
          </Button>
        </div>
        { !loading && campaigns ? <CampaignsList campaigns={campaigns} editCampaign={this.editCampaign.bind(this)} cancelCampaign={this.cancelCampaign.bind(this)} deleteCampaign={this.deleteCampaign.bind(this)} viewCampaign={this.viewCampaign.bind(this)} toggle={this.toggle.bind(this)} toggleTab={this.toggleTab.bind(this)} active={this.state.active} activeTab={this.state.activeTab} /> : null}
        <ConfirmationModal title={confirmationDialog.title} header={confirmationDialog.header} isOpen={confirmationDialog.show} accept={confirmationDialog.acceptCallback.bind(this)} reject={confirmationDialog.rejectCallback.bind(this)} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { campaigns: {
    allSmsCampaigns: {
      data: campaigns, loading: campaignsLoading
    }
  }, locations: {
    location: {
      data: locationData, loading: locationLoading
    }
  } } = state;

  return { campaigns, location: locationData, loading: campaignsLoading || locationLoading };
}

export default connect(mapStateToProps, { getLocation, getSmsCampaigns, cancelCampaign, deleteCampaign })(SmsCampaigns);
