import React from "react";
import DataTable from "react-data-table-component";
import { Check, Edit2 } from "react-feather";
import { Button } from "reactstrap";

const ListLocations = (props) => {

  const editLocation = (id) => {
    props.editLocation(id);
  }

  const columns = [
    {
      name: 'Edit',
      selector: 'loc_id',
      format: (val) => {
        return <Button outline className='btn-icon' color='primary' onClick={() => editLocation(val.loc_id)}><Edit2 color='var(--primary)' className='ml-0 p-0' size={10} /></Button>

      }
    },
    {
      name: 'ID',
      selector: 'loc_id'
    },
    {
      name: 'Name',
      selector: 'name',
      format: (val) => {
        return  val.name ? val.name : 'Not available';
      }
    }, {
      name: 'Category Name',
      selector: 'category_name',
      format: (val) => {
        return val.category_name ?  val.category_name  : 'Not available';
      }
    }, {
      name: 'Address',
      selector: 'formattedAddress',
      format: (val) => {
        return  val.formattedAddress ? val.formattedAddress : 'Not available';
      }
    }
    , {
      name: 'City',
      selector: 'city',
      format: (val) => {
        return  val.city ? val.city : 'Not available';
      }
    }, {
      name: 'State',
      selector: 'state',
      format: (val) => {
        return  val.state ? val.state : 'Not available';
      }
    }, {
      name: 'Country',
      selector: 'country',
      format: (val) => {
        return  val.country ? val.country : 'Not available';
      }
    }, {
      name: 'Admin Assigned',
      selector: 'StaffLocation',
      format: (val) => {
        return  val.StaffLocation ? 'true' : 'false';
      }
    }, {
      name: 'Menu SetUp	',
      selector: 'Menu',
      format: (val) => {
        return  val.Menu ? 'true' : 'false';
      }
    }, {
      name: 'No. of orders',
      selector: 'order_count',
      format: (val) => {
        return val.order_count ? val.order_count : 0;
      }
    }, {
      name: 'No. of reviews',
      selector: 'review_count',
      format: (val) => {
        return val.review_count ? val.review_count : 0;
      }
    }
  ];

  const renderLocations = (locations) => {
    if (!locations || locations.length === 0)
      return <h1>No App Locations found.</h1>;

    return <DataTable
      noHeader
      pagination
      paginationRowsPerPageOptions={[25, 50, 100]}
      paginationPerPage={50}
      columns={columns}
      data={locations}
    />;
  }
  return renderLocations(props.locations);

}

export default ListLocations;
