import React, { Component } from 'react';
import {Button, Card, CardBody, Col, FormGroup, Input, Label, Media, Row} from 'reactstrap';
import LocationSelectHeader from '../shared/LocationSelectHeader';
import '../../assets/scss/plugins/forms/switch/react-toggle.scss';
import 'react-toggle/style.css';


class Terms extends Component {

  render() {
    return (
    <Row className='m-0 justify-content-center'>
        <Col
        sm='8'
        xl='7'
        lg='10'
        md='8'
        className='d-flex justify-content-center'
        >
        <div className="terms" >
            <p style={{textAlign: 'center'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><strong><u><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>TERMS AND CONDITIONS</span></span></u></strong></span></span></p>
            <p style={{textAlign: 'center'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><strong><u><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>FOR RESTAURANTS AND CONSUMABLE ITEMS DELIVERY</span></span></u></strong></span></span></p>
            <p style={{textAlign: 'justify'}}>&nbsp;</p>
            <p style={{textAlign: 'justify'}}>&nbsp;</p>
            <p style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Plugd Technologies LTD, a company incorporated under the laws of Abu Dhabi Global Markets, having its registered office at DD-14-102-017, 14 Floor, WeWork Hub71, Al Khatem Tower, ADGM Square, Al Maryah Island, Abu Dhabi, UAE, (hereinafter referred to as “Plugd”) is honored to have you onboard its platform. Please read these Terms and Conditions carefully. These Terms and Conditions constitute a legally binding agreement between you (the “Restaurant” or “you”) acting as a provider of consumable products and beverages (collectively referred to as “Food”) and Plugd (“Plugd”). </span></span></span></span></p>
            <p style={{textAlign: 'justify'}}>&nbsp;</p>
            <p style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>This Agreement governs your use of the Plugd application, website, and any other platform operated and managed by Plugd (hereinafter collectively referred to as the “Platform”) which facilitates the listing, sale and delivery of Food to Customers (“Platform Services”).</span></span></span></span></p>
            <p style={{textAlign: 'justify'}}>&nbsp;</p>
            <p style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT, YOU MAY NOT USE OR ACCESS THE PLATFORM SHALL NOT BE RESPONSIBLE OR LIABLE IN ANY MANNER FOR WHATEVER REASON.</span></span></span></span></p>
            <p style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></p>
            <p style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Plugd, unilaterally and at its sole discretion, may make amendments and modifications to the Terms and Conditions from time to time, and such terms shall be effective as and when done. Your continued access or use of the Platform constitutes your consent to be bound by these Terms and Conditions.</span></span></span></span></p>
            <p style={{textAlign: 'justify'}}>&nbsp;</p>
            <p style={{textAlign: 'center'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><strong><u><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Plugd Obligations</span></span></u></strong></span></span></p>
            <p style={{textAlign: 'center'}}>&nbsp;</p>
            <ol>
            <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Plugd, at its will and discretion, will display your menu and any information on the Platform;</span></span></span></span>
                <ol>
                <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>The Users will be given access and the right to make orders which Orders will be forwarded to you via the Platform on a real-time basis;</span></span></span></span></li>
                <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Plugd will not be responsible or liable for the </span></span></span></span>
                    <ol>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>quality, quantity, descriptive accuracy and/or taste of the Food advertised on the Platform; and/or </span></span></span></span></li>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>processing of the Orders which have been placed by the User with you on the Platform; and/or </span></span></span></span></li>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>any failure on your part to comply with the requests / instructions of the User; and/or </span></span></span></span></li>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>the delivery of the Goods to the Customer. </span></span></span></span></li>
                    </ol>
                </li>
                <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Where an Order is not collected or received by the User, Plugd will use reasonable endeavours to have the Order returned to you and you acknowledge and agree that you might not be paid for such Order or entitled to receive any other sum. Plugd reserves the right to make such deduction as deemed necessary from any other payment made from or against any monies under this Agreement;</span></span></span></span></li>
                <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Plugd reserves the right to deactivate, suspend, or terminate your account on the Platform Pugd is of the opinion that you are non-compliant with any applicable law.</span></span></span></span></li>
                </ol>
            </li>
            </ol>
            <p style={{textAlign: 'justify'}}>&nbsp;</p>
            <p style={{textAlign: 'center'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><strong><u><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Your Obligations</span></span></u></strong></span></span></p>
            <p style={{textAlign: 'center'}}>&nbsp;</p>
            <ol start={2}>
                <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>You are legally bound to perform the following obligations</span></span></span></span>
                    <ol>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>You undertake and warrant that all Orders via the Platform will be made and performed with the same level of care, prep times, and attention, including providing the correct food items as per the Order and providing the right packaging;</span></span></span></span></li>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>You undertake and represent that the information, including the menu, provided to Plugd and uploaded on the Platform is up to date and accurate at all times;</span></span></span></span></li>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>You will timely update any information including menu items, prices, operating hours and any other Information, on the Platform. If you fail to update the information, you shall be liable to pay Plugd the commission percentage on any Order/s that could not be processed due to outdated information;</span></span></span></span></li>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>You will accept all Orders placed by the User within ____ minutes of the Order appearing on the Platform; in case otherwise you agree to pay Plugd the commission percentage on any Orders not accepted;</span></span></span></span></li>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>For any modification or amendment to the Order placed, you agree and will direct the User to contact Plugd directly;</span></span></span></span></li>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>You warrant and undertake that the Food provided by you are: (a) of high quality and fit for human consumption; and (b) comply with all applicable laws, including all applicable food industry regulations including all packaging and labelling requirements;</span></span></span></span></li>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>For any queries and clarification regarding the Order placed by the User, you will contact Plugd or inform Plugd if an Order cannot be processed as requested;</span></span></span></span></li>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>You will ensure that the preparation time of an Order will not exceed 20 minutes from the acceptance time of the relevant Order;</span></span></span></span></li>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>You will be responsible for any and all issues and costs associated with delayed delivery resulting from your failure to comply with such 20-minute preparation time period including costs associated with compensating the User, which shall be at Plugd’s sole discretion;</span></span></span></span></li>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>You will be responsible for the costs of any discounted promotion offered by you;</span></span></span></span></li>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>You will not at any time offer, by way of any medium, a price for any food and beverage item which is lower than the then-current price, and shall be inclusive of applicable taxes and charges including VAT, of the applicable item set out in the menu available through the Platform;</span></span></span></span></li>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>You shall disclose all relevant details pertaining to problem Order(s) to Plugd upon becoming aware of the same;</span></span></span></span></li>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>You shall perform your obligations and provide the services hereunder in accordance with: (a) best industry practice; (b) technical standards; (c) applicable professional rules, code of conduct, regulations and associated guidelines; (d) any timescales set out herein; and (e) applicable law.</span></span></span></span></li>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>You will ensure that no Order contains any expired or non-consumable Food or product. In case User informs Plugd receiving any expired or non-consumable or unhygienic Food/s or product, you acknowledge and agree that you will not be paid for such Order and to refund along with any charge or expense thereon, as determined by Plugd, as and when demanded; in case otherwise Plugd will have a right to deduct or offset such amount from or against any other monies;</span></span></span></span></li>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>You warrant, represent and undertake that prior to dispatch/delivery of any Food, any such Foods have at all times been handled, stored and maintained in hygienic and sanitary conditions and in accordance with applicable law, best industry practice and any instructions or requirements of the manufacturer and/or producer of the Foods or product;</span></span></span></span></li>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>YOU WARRANT, REPRESENT AND UNDERTAKE THAT YOU HAVE, AND WILL MAINTAIN, ALL NECESSARY LICENSES AND PERMITS REQUIRED FOR MARKETING (IN ALL CHANNELS), SALE AND DELIVERY OF FOODS IN ALL TERRITORIES WHERE THE SERVICES ARE PERFORMED.&nbsp; IF ANY SUCH LICENSE OR PERMIT IS REVOKED, EXPIRES OR IS TERMINATED FOR ANY REASON WHATSOEVER THEN YOU SHALL PROMPTLY NOTIFY PLUGD IN WRITING.</span></span></span></span></li>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>You will notify Plugd if you intend to use self delivery for a particular Order.</span></span></span></span></li>
                    </ol>
                </li>
            </ol>
            <p style={{textAlign: 'justify'}}>&nbsp;</p>
            <p style={{textAlign: 'center'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><strong><u><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>The Platform</span></span></u></strong></span></span></p>
            <p style={{textAlign: 'center'}}>&nbsp;</p>
            <ol start={3}>
                <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><strong><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Platform </span></span></strong></span></span>
                    <ol>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Plugd is a digital delivery and transportation network which operates a software platform matching, amongst other things, Customers seeking to place Orders with you via the Platform.</span></span></span></span></li>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>NEITHER PLUGD NOR ITS AFFILIATES PROVIDE DELIVERY OR TRANSPORTATION OR FOOD SERVICES OR FUNCTION AS A TRANSPORTATION SERVICE PROVIDER OR OWN ANY VEHICLES FOR DELIVERY AND THAT ALL DELIVERY SERVICES ARE PROVIDED BY INDEPENDENT THIRD-PARTY CONTRACTORS WHO ARE NOT EMPLOYED BY PLUGD OR ANY OF ITS AFFILIATES.</span></span></span></span></li>
                    </ol>
                </li>
            </ol>
            <p style={{marginLeft: '72px', textAlign: 'justify'}}>&nbsp;</p>
            <ol start={4}>
            <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><strong><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>License</span></span></strong></span></span>
                <ol>
                <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Plugd grants you a limited, non-exclusive, non-sub-licensable, revocable, non-transferrable license to</span></span></span></span>
                    <ol>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>access and use the Platform for the purposes of the Services offered by the Plugd from time to time; and </span></span></span></span></li>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>access and use any content, information and related materials that may be made available through the Platform, in each case solely to provide services to User. Any rights not expressly granted herein are reserved by Plugd and Plugd’s licensors.</span></span></span></span></li>
                    </ol>
                </li>
                </ol>
            </li>
            </ol>
            <p style={{marginLeft: '72px', textAlign: 'justify'}}>&nbsp;</p>
            <ol start={5}>
            <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><strong><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Restrictions</span></span></strong></span></span>
                <ol>
                <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>You may not remove any copyright, trademark or other proprietary notices from any portion of the Platform; reproduce, modify, prepare derivative works based upon, distribute, license, lease, sell, resell, transfer, publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit the Platform; decompile, reverse engineer or disassemble the Platform;or link to, mirror or frame any portion of the Platform.</span></span></span></span></li>
                </ol>
            </li>
            </ol>
            <p style={{marginLeft: '72px', textAlign: 'justify'}}>&nbsp;</p>
            <ol start={6}>
                <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><strong><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Ownership</span></span></strong></span></span>
                    <ol>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>The Platform and any other digital application or platform and all rights therein are and shall remain Plugd’s property. </span></span></span></span></li>
                    </ol>
                </li>
            </ol>
            <p style={{marginLeft: '72px', textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></p>
            <ol start={7}>
                <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><strong><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Intellectual Property Rights </span></span></strong></span></span>
                    <ol>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>You hereby grant to Plugd a worldwide, perpetual, non-exclusive, assignable, royalty-free license (with full right to sublicense) for the term of the Agreement to use, reproduce, publicly display, exploit, modify, alter or integrate any of your intellectual property rights (whether directly or indirectly) or are uploaded onto the Platform, from time to time, for the purpose of providing the Platform services and anything done in relation to the Services. You warrant, represent and undertake that none of the content, information or related materials provided to Plugd under this Agreement, or that are uploaded onto the Platform, will violate or infringe any third party intellectual property rights or any other third party rights.</span></span></span></span></li>
                    </ol>
                </li>
            </ol>
            <p style={{marginLeft: '72px', textAlign: 'justify'}}>&nbsp;</p>
            <ol start={8}>
                <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><strong><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Third Party Services and Content</span></span></strong></span></span>
                    <ol>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>The Platform may be made available or accessed in connection with third party services and content (including advertising) that Plugd does not control. You acknowledge that different terms of use and privacy policies may apply to your use of such third-party services and content. Plugd does not endorse such third-party services and content and in no event shall Plugd be responsible or liable for any products or services of such third-party providers. Your access to the Platform using these services or applications is subject to terms set forth in the applicable third-party beneficiary’s terms of service.</span></span></span></span></li>
                    </ol>
                </li>
            </ol>
            <p style={{textAlign: 'justify'}}>&nbsp;</p>
            <p style={{textAlign: 'center'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><strong><u><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Use of the Platform</span></span></u></strong></span></span></p>
            <p style={{textAlign: 'justify'}}>&nbsp;</p>
            <ol start={9}>
                <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Your use of the Platform and shall be subject to the terms and conditions set forth in this Agreement and the privacy policy of Plugd from time to time.</span></span></span></span></li>
            </ol>
            <p style={{textAlign: 'justify'}}>&nbsp;</p>
            <p style={{textAlign: 'center'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><strong><u><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Charges, Payments and Commission</span></span></u></strong></span></span></p>
            <p style={{textAlign: 'justify'}}>&nbsp;</p>
            <ol start={10}>
                <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>The financials are as follows </span></span></span></span>
                    <ol>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>You acknowledge and agree that while Plugd will use its reasonable efforts to ensure that the Platform is not misused by the User/s, it is your ultimate responsibility to determine and confirm the accuracy of the Order placed. In the event of a wrong Order is placed by the User, you must such a wrong Order to Plugd on immediate basis.</span></span></span></span></li>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Plugd may perform a variety of marketing activities for your promotion and for the promotion of your menu; provided, however, that all such marketing activities will be determined in Plugd’s sole and absolute discretion at a cost to be borne by you. </span></span></span></span></li>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>You acknowledge and agree that the Platform will provide the following payment mechanisms to Users for the payment of the Order Value: (a) Cash on delivery; (b) Electronic Payment Mechanism; and (c) Redemption of vouchers and/or discount promotion (if any) approved by Plugd.</span></span></span></span></li>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>You shall pay to Plugd the commission percentage for all Orders.</span></span></span></span></li>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Plugd will settle the financials in accordance with the payment terms agreed amongst the parties (“Payments”). </span></span></span></span></li>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>You agree that Plugd shall be entitled to set-off any amounts You owe to Plugd hereunder from the Payments. You will be responsible for any taxes or charges payable on the Food including collecting, paying and reporting such taxes to the appropriate authorities and providing Users with compliant tax invoices.</span></span></span></span></li>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>You acknowledge and agree that Plugd will provide you with a monthly statement within a period of 14 (fourteen) business days from the last day of each month setting forth all of the Orders during such month and any amounts due to Plugd in respect of Orders. Such statements will be sent to you using the email address specified herein.</span></span></span></span></li>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>The parties acknowledge and agree that Order ratings are entirely under the control of User and your accumulated average rating displayed on the Platform is calculated using the ratings which are given by the User.</span></span></span></span></li>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>You acknowledge and agree that Plugd reserves the right to review and modify the foregoing delivery rating system and the commission percentage periodically upon sending an email notice and that Plugd’s decision in this regard shall be final and binding.</span></span></span></span></li>
                    </ol>
                </li>
            </ol>
            <p style={{textAlign: 'justify'}}>&nbsp;</p>
            <p style={{textAlign: 'center'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><strong><u><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Restricted Activities</span></span></u></strong></span></span></p>
            <p style={{textAlign: 'justify'}}>&nbsp;</p>
            <ol start={11}>
                <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><strong><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Online Payment </span></span></strong></span></span>
                    <ol>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>You must meet all of the following requirements when receiving an online payment Order, ensure that you do not receive any additional payment from the User, whether or not the payment is made through online or in cash; and follow all special instructions contained on the Order receipt or as communicated.</span></span></span></span></li>
                    </ol>
                </li>
            </ol>
            <p style={{marginLeft: '72px', textAlign: 'justify'}}>&nbsp;</p>
            <ol start={12}>
                <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><strong><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Confidentiality </span></span></strong></span></span>
                    <ol>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>You agree that you will treat all information concerning this Agreement, Plugd, the Platform and all information concerning Users which has been provided to you pursuant to the terms of this Agreement as confidential information (“Confidential Information”) and will not, except as provided in this Agreement, disclose, use or permit the disclosure or use of such information to any third party. You further agree that a breach of this provision would cause Plugd to suffer irreparable harm and damage that could not be adequately remedied by payment of monetary damages and, therefore, in addition to monetary damages and other legal remedies, you agree that Plugd will be entitled to obtain equitable and injunctive relief as may be necessary to restrain any threatened, continuing or further breach by you. The restrictions referred to in this Clause will not apply to any Confidential Information to the extent that such information: (a) is already known to you, (b) is in or comes into the public domain otherwise than as a result of any breach of this Agreement or (c) is required to be disclosed by law.</span></span></span></span></li>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>You shall Process any Personal Information received from Plugd or any User in accordance with applicable law and only for the purposes of discharging your obligations under this Agreement. </span></span></span></span></li>
                    </ol>
                </li>
            <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>For the purposes of this Clause, the following definitions shall apply: (a) “Personal Information” means any information relating to an identified or identifiable person who can be identified, directly or indirectly, in particular by reference to an identification number or to one or more factors specific to his physical, physiological, mental, economic, cultural or social identity, and (b) “Process” means to collect, hold, use, transfer, destroy and otherwise deal in any with Personal Information.</span></span></span></span></li>
            </ol>
            <p style={{textAlign: 'justify'}}>&nbsp;</p>
            <p style={{textAlign: 'center'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><strong><u><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Disclaimers; Limitation of Liability; Indemnity</span></span></u></strong></span></span></p>
            <p style={{textAlign: 'justify'}}>&nbsp;</p>
            <ol start={14}>
                <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><strong><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Disclaimer</span></span></strong></span></span>
                    <ol>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>THE PLATFORM IS PROVIDED “AS IS” AND “AS AVAILABLE.” PLUGD AND ITS AFFILIATES DISCLAIM ALL REPRESENTATIONS AND WARRANTIES, EXPRESS, IMPLIED OR STATUTORY, NOT EXPRESSLY SET OUT IN THIS AGREEMENT, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. IN ADDITION, PLUGD AND ITS AFFILIATES MAKES NO REPRESENTATION, WARRANTY, OR GUARANTEE REGARDING THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY OR AVAILABILITY OF THE PLATFORM OR ANY SERVICES OR GOODS REQUESTED THROUGH THE USE OF THE PLATFORM, OR THAT THE PLATFORM WILL BE UNINTERRUPTED OR ERROR-FREE. NEITHER PLUGD NOR ITS AFFILIATES GUARANTEE THE QUALITY, SUITABILITY, SAFETY OR ABILITY OF USER. YOU AGREE THAT THE ENTIRE RISK ARISING OUT OF YOUR USE OF THE PLATFORM, AND ANY SERVICE OR GOOD REQUESTED OR PROVIDED IN CONNECTION THEREWITH, REMAINS SOLELY WITH THE YOU, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW.</span></span></span></span></li>
                    </ol>
                </li>
            </ol>
            <p style={{textAlign: 'justify'}}>&nbsp;</p>
            <ol start={15}>
            <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><strong><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Limitation of Liability</span></span></strong></span></span>
                <ol>
                <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>YOU UNDERSTAND AND ACCEPT THAT PLUGD DOES NOT PROVIDE TRANSPORTATION, FOOD DELIVERY OR LOGISTICS SERVICES OR FUNCTION AS A TRANSPORTATION OR GOODS CARRIER AND THAT ALL SUCH TRANSPORTATION, FOOD DELIVERY OR LOGISTICS SERVICES ARE PROVIDED BY INDEPENDENT THIRD-PARTY CONTRACTORS WHO ARE NOT EMPLOYED BY PLUGD OR ANY OF ITS AFFILIATES. PLUGD’S LIABILITY FOR ANY LOSS SUFFERED BY YOU ARISING OUT OF OR RELATING TO THIS AGREEMENT SHALL NOT EXCEED USD $10 (OR THE EQUIVALENT AMOUNT IN THE RELEVANT LOCAL CURRENCY).</span></span></span></span></li>
                <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>YOU AGREE THAT PLUGD HAS NO RESPONSIBILITY OR LIABILITY TOWARDS YOU RELATED TO ANY DELIVERY SERVICES PROVIDED BY YOU TO USER OTHER THAN AS EXPRESSLY SET FORTH IN THIS AGREEMENT AND THAT PLUGD HAS NO RESPONSIBILITY OR LIABILITY TO YOU FOR THE ACTS OR OMISSIONS OF ANY DELIVERY PERSON.</span></span></span></span></li>
                </ol>
            </li>
            </ol>
            <p style={{textAlign: 'justify'}}>&nbsp;</p>
            <ol start={16}>
                <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><strong><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Indemnity</span></span></strong></span></span>
                    <ol>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>You agree to indemnify and hold Plugd, its affiliates and their officers, directors, employees and agents harmless from any and all claims, demands, losses, liabilities, and expenses (including attorneys’ fees) arising out of or in connection with: (a) your use of the Platform and the sale of food/goods and delivery services obtained through the use of Platform; (b) your breach or violation of this Agreement oryou’re your violation of the rights of any third party, including delivery persons.</span></span></span></span></li>
                    </ol>
                </li>
            </ol>
            <p style={{textAlign: 'justify'}}>&nbsp;</p>
            <ol start={17}>
            <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><strong><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Indirect Damages</span></span></strong></span></span>
                <ol>
                <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Plugd will not be liable to you for any indirect or consequential losses (including loss of profit, opportunity and goodwill).</span></span></span></span></li>
                </ol>
            </li>
            </ol>
            <p style={{textAlign: 'justify'}}>&nbsp;</p>
            <p style={{textAlign: 'center'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><strong><u><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Choice of Law and Dispute Resolution</span></span></u></strong></span></span></p>
            <p style={{textAlign: 'justify'}}>&nbsp;</p>
            <ol start={18}>
                <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><strong><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Choice of Law</span></span></strong></span></span>
                    <ol>
                    <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>This Agreement is governed by and construed in accordance with the laws of the jurisdiction of Pakistan without giving effect to any conflict of law principles.</span></span></span></span></li>
                    </ol>
                </li>
            </ol>
            <p style={{textAlign: 'justify'}}>&nbsp;</p>
            <ol start={19}>
            <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><strong><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Arbitration</span></span></strong></span></span>
                <ol>
                <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>You agree that any dispute, claim or controversy arising out of or relating to this Agreement or the breach, termination, enforcement, interpretation or validity thereof or the use of the Platform or the Services (collectively, “Disputes”) will be settled by binding arbitration between you and Plugd, except that each party retains the right to seek injunctive or other equitable relief in a court of competent jurisdiction to prevent the actual or threatened infringement, misappropriation or violation of a party’s copyrights, trademarks, trade secrets, patents or other intellectual property rights. </span></span></span></span></li>
                <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Arbitration and the Arbitration process and rules will survive any termination of this Agreement. </span></span></span></span></li>
                </ol>
            </li>
            </ol>
            <p style={{textAlign: 'justify'}}>&nbsp;</p>
            <ol start={20}>
            <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><strong><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Arbitration Process and Rules.</span></span></strong></span></span>
                <ol>
                <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Any Dispute shall be first mandatorily submitted to mediation proceedings. If such Dispute has not been settled within sixty (60) days after a request for mediation has been submitted, such Dispute can be referred to and shall be exclusively and finally resolved by arbitration under the Arbitration Act. 1940. (“Arbitration Act”). The Dispute shall be resolved by one (1) arbitrator to be appointed in accordance with the Arbitration Act. The place of both mediation and arbitration shall be in the city of Lahore, Pakistan. </span></span></span></span></li>
                <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>The language of the mediation and/or arbitration shall be English. </span></span></span></span></li>
                <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>The existence and content of the mediation and arbitration proceedings, and correspondence, orders and awards issued by the sole arbitrator, shall remain strictly confidential and shall not be disclosed to any third party without the express written consent from the other party unless: (i) the disclosure to the third party is reasonably required in the context of conducting the mediation or arbitration proceedings; and (ii) the third party agrees unconditionally in writing to be bound by the confidentiality obligation stipulated herein.</span></span></span></span></li>
                <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>The decision of the Arbitrator shall be final and binding upon both the parties. </span></span></span></span></li>
                </ol>
            </li>
            </ol>
            <p style={{textAlign: 'justify'}}>&nbsp;</p>
            <p style={{textAlign: 'center'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><strong><u><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Other Provisions</span></span></u></strong></span></span></p>
            <p style={{textAlign: 'justify'}}>&nbsp;</p>
            <ol start={21}>
            <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><strong><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Upon Expiry or Termination</span></span></strong></span></span>
                <ol>
                <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>In the event that this Agreement expires or is terminated for any reason, you will no longer be entitled to access and/or use the Platform and you shall return all Confidential Information received hereunder.</span></span></span></span></li>
                </ol>
            </li>
            </ol>
            <p style={{textAlign: 'justify'}}>&nbsp;</p>
            <ol start={22}>
            <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><strong><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Notice</span></span></strong></span></span>
                <ol>
                <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>Plugd may give notice by means of a general notice on the Platform, electronic mail to your email address in your account, or by written communication sent to your address as set forth. </span></span></span></span></li>
                <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>You may give notice to Plugd by written communication to Plugd’s email address at help@plugd.app for any matter and will give at least 30 calendar days’ written notice with respect to terminating the use of the Platform.</span></span></span></span></li>
                </ol>
            </li>
            </ol>
            <p style={{textAlign: 'justify'}}>&nbsp;</p>
            <p style={{textAlign: 'center'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><strong><u><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>General</span></span></u></strong></span></span></p>
            <p style={{textAlign: 'center'}}>&nbsp;</p>
            <ol start={23}>
                <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>You shall not assign or transfer your rights under this Agreement in whole or in part without Plugd’s prior written approval. Plugd may assign and/or transfer your rights and obligations under this Agreement in whole or in part, including to: (a) a subsidiary or affiliate; (b) an acquirer of Plugd’s equity, business or assets; or (c) a successor by merger.</span></span></span></span></li>
                <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>No joint venture, partnership, employment or agency relationship exists between you, Plugd, its affiliates or any User as a result of the contract between you and Plugd or use of the Platform. You and Plugd are and shall remain independent parties. Neither party shall have, or represent itself to have, any authority to bind the other party or act on its behalf.</span></span></span></span></li>
                <li style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Calibri,sans-serif'}}><span style={{fontSize: '12.0pt'}}><span style={{fontFamily: '"Times New Roman",serif'}}>If any provision of this Agreement is held to be illegal, invalid or unenforceable, in whole or in part, under any law, such provision or part thereof shall to that extent be deemed not to form part of this Agreement but the legality, validity and enforceability of the other provisions in this Agreement shall not be affected. In that event, the parties shall replace the illegal, invalid or unenforceable provision or part thereof with a provision or part thereof that is legal, valid and enforceable and that has, to the greatest extent possible, a similar effect as the illegal, invalid or unenforceable provision or part thereof, given the contents and purpose of this Agreement. The Enrollment Agreement shall constitute an integral part of this Agreement.</span></span></span></span></li>
            </ol>
      </div>

        </Col>
    </Row>
    );
  }
}

export default Terms;
