import { act } from 'react-dom/test-utils';
import CONSTANTS from '../../../constants';

export function allSessionActivity(state = { data: null, loading: true, filters: [], filtered_data: [], emptyList:false }, action) {
    if (action.type === CONSTANTS.ACTIONS.SESSION_ACTIVITY.ALL_SESSIONS_ACTIVITY) {
        return { data: action.session_activity, loading: action.loading, filters: [], filtered_data: [], emptyList:false, filterType:"" };
    }

    else if (action.type === CONSTANTS.ACTIONS.SESSION_ACTIVITY.SESSION_ACTIVITY_FILTER_UPDATE) {
        console.log("from action")
        const retOjb = {
            ...state,
            filtered_data: action.filtered_data,
            filters: action.filters
        }
        return retOjb;
    }

    else if (action.type === CONSTANTS.ACTIONS.SESSION_ACTIVITY.SET_EMPTY_LIST) {
        return {
            ...state,
            emptyList:action.emptyList,
        }
       
    }

    else if (action.type ===  CONSTANTS.ACTIONS.SESSION_ACTIVITY.FILTER_TYPE) {
        return {
            ...state,
            filterType:action.filterType,
        }
       
    }

   

    else if (action.type === CONSTANTS.ACTIONS.SESSION_ACTIVITY.CLEAR_FILTERS) {
        return {
            ...state,
            filtered_data: [],
            filters: [], 
            emptyList:true
        }
    }
    return state;

}