import CONSTANTS from '../constants';
import { customFetch as fetch } from '../helpers/FetchHelper';
import LoginService from './LoginService';

export default {
    getAllSessionActivities,
}

async function getAllSessionActivities() {
    let { token } = LoginService.getToken();
    let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.SESSION_ACTIVITY.GET}`, {
        method: 'GET',
        headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
    });
    response = await response.json();
    if (response) return response;
    throw new Error('Could not fetch roles');
}