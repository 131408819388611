import CONSTANTS from '../constants';

export default {
  setFilterData,
  getFilterData,
  removeFilterData
};

function setFilterData(filterData) {
  let { filter, sort, page } = filterData;
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_filterData`, JSON.stringify({ filter, sort, page }));
}

function getFilterData() {
  let filterData =   localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_filterData`);
  if(filterData) return JSON.parse(filterData);
}

function removeFilterData() {
  localStorage.removeItem(`${CONSTANTS.CACHE_KEY_PREFIX}_filterData`);
}
