import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import TimeHelper from "../../helpers/TimeHelper";
import ViewHelper from "../../helpers/ViewHelper";
import Checkbox from "../@vuexy/checkbox/CheckboxesVuexy";
import { Check, Edit2 } from "react-feather";
import { Button } from "reactstrap";
import ImageHelper from "../../helpers/ImageHelper";

const ListRiders = (props) => {
  const openModal = (data) => {
    props.toggleModal(data);
  };

  const columns = [
    {
      name: "Rider ID",
      selector: "user_id",
      sortable: true,
      minWidth: "100px",
    },
    {
      name: "Settle Payment",
      selector: "staff_id",
      minWidth: "150px",
      format: (val) => {
        return (
          <Button
            outline
            className="btn-icon"
            color="primary"
            onClick={() => openModal({ earning: val.earning, total_cash: val.total_cash, rider_id: val.user_id, rider_name: val.Staff.staff_name })}
          >
            Settle Payment
          </Button>
        );
      },
    },
    {
      name: "Rider Email",
      selector: "email",
      sortable: true,
      minWidth: "300px",
      format: (val) => {
        return val && val.email ? val.email : val.facebook_email;
      },
    },
    {
      name: "Name",
      selector: "Staff.staff_name",
      sortable: true,
      minWidth: "300px",
      format: (val) => {
        return val.Staff.staff_name;
      },
    },
    {
      name: "Earnings",
      selector: "earning",
      sortable: true,
      minWidth: "300px",
      format: (val) => {
        return val.earning ? val.earning : 0;
      },
    },
    {
      name: "Setteled Earnings",
      selector: "settledEarnings",
      sortable: true,
      minWidth: "300px",
      format: (val) => {
        return val.settledEarnings ? val.settledEarnings : 0;
      },
    },
    {
      name: "Cash in hand",
      selector: "total_cash",
      sortable: true,
      minWidth: "300px",
      format: (val) => {
        return val.total_cash ? val.total_cash : 0;
      },
    },
    {
      name: "Setteled Payments",
      selector: "payments",
      sortable: true,
      minWidth: "300px",
      format: (val) => {
        return val.payments ? val.payments : 0;
      },
    },
  
  ];

  const renderRiders = (riders, handleChange) => {
    if (!riders || riders.length === 0) return <h1>No App riders found.</h1>;
    const tableData = {
      columns: columns,
      data: riders,
      exportHeaders: true,
      print: false,
    };
    return (
      <DataTableExtensions {...tableData}>
        <DataTable
          noHeader
          pagination
          paginationRowsPerPageOptions={[25, 50, 100]}
          paginationPerPage={50}
        />
      </DataTableExtensions>
    );
  };
  return renderRiders(props.riders, props.handleChange);
};

export default ListRiders;
