import React, { Component } from 'react';
import { connect } from 'react-redux';
import SpinnerComponent from '../@vuexy/spinner/Fallback-spinner';
import { getSubscriptions } from '../../redux/actions/sms';
import '../../assets/scss/pages/page-pricing.scss';
import ListSubscriptions from './ListSubscriptions';
import { Button } from 'reactstrap'


class SmsSubscriptions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectLocation: false,
      location: null,
    };
  }

  async componentDidMount() {
    await this.props.getSubscriptions();
  }

  render() {
    const { props: {
      subscriptions,
      loading
    } } = this;
    if (loading) return <SpinnerComponent />;
    return (
      <>
        <div className='text-center mb-2'>
          <Button outline className='font-weight-bold custom-padding disabled-2-border btn-sm-font' color={'secondary'} size='md' >
            <span className='ml-1 mr-1 d-md-inline'>SMS In Wallet : { subscriptions.staff ? subscriptions.staff.wallet : 0} Units</span>
          </Button>
        </div>
        <div id='pricing-table'>
          {!loading && subscriptions ? <ListSubscriptions subscriptions={subscriptions.userSubscriptions} /> : null}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { sms: {
    allSubscriptions: {
      data: subscriptions, loading: subscriptionsLoading
    }
  } } = state;

  return { subscriptions, loading: subscriptionsLoading };
}

export default connect(mapStateToProps, { getSubscriptions })(SmsSubscriptions);