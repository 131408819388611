import React, { Component } from 'react';
import SpinnerComponent from '../@vuexy/spinner/Fallback-spinner';
import LocationService from '../../services/LocationService';
import { Media } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CampaignService from '../../services/CampaignService';
import TimeHelper from '../../helpers/TimeHelper';
import "../../assets/scss/pages/app-email.scss";

class SmsCampaignsUsersList extends Component {

  state = {
    campaign: {
      id: '',
      loc_id: '',
      name: '',
      sms: '',
      status: 0,
      timestamp: new Date(),
      sms_limit: 0,
      users: '',
    },
    loading: false
  }

  async getCampaignFromId(loc_id, id) {
    let campaign = await CampaignService.getCampaign(loc_id, id);
    campaign.users = (campaign.SmsCampaignUsers.map(user => user.user_id)).toString();
    return campaign;
  }

  componentDidMount() {
    this.setData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.campaign_id !== this.props.campaign_id) {
      this.setData();
    }
  }

  async setData() {
    const location = LocationService.getLocation();
    if (location) {
      await this.setState({ loading: true });
      let campaign = this.state.campaign;
      campaign = await this.getCampaignFromId(location.loc_id, this.props.campaign_id);
      campaign.id = this.props.campaign_id;
      await this.setState({ campaign });
      this.setState({ loading: false });
    } else {
      this.props.history.replace('/locations/sms/campaigns');
    }
  }

  render() {
    if (this.state.loading) return <SpinnerComponent />;
    if (this.state.campaign.id !== '') {
      const renderCampaignUsers = this.state.campaign.SmsCampaignUsers.length > 0 ? (
        this.state.campaign.SmsCampaignUsers.map(d => {
          return (
            <>
              <Media
                tag='li'
                key={d.User.id}
                className='mail-read'
              >
                <Media className='pr-50' tag='div' left>
                  <div className='avatar'>
                    <Media object src='https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50' width='50' height='50' />
                  </div>
                </Media>
                <Media body>
                  <div className='user-details flex-wrap'>
                    <div className='mail-items'>
                      <h5 className='text-bold-600 mb-25 '>{d.User.first_name}</h5>
                      <span className='text-truncate'>{d.User.last_name}</span>
                    </div>
                    <div className='mail-meta'>
                      <span className='float-right'>

                        <span
                          className={`bullet bullet-${d.status === 0
                              ? 'danger'
                              : d.status === 1
                                ? 'success'
                                : 'primary'
                            } bullet-sm d-none d-md-inline-block mr-1`}
                          key={d.status}
                        />

                        <span className='mail-date'>{TimeHelper.toFormattedDateTime(this.state.campaign.timestamp)}</span>
                      </span>
                    </div>
                  </div>
                  <div className='mail-message'>
                    <p className='list-group-item-text truncate mb-0'>
                      {d.User.phone_number}
                    </p>
                  </div>
                </Media>
              </Media>
            </>
          )
        })
      ) : (
        <div className='no-results show'>
          <h5>No Items Found</h5>
        </div>
      )
      return (
        <div className='content-right'>
          <div className='location'>
            <div className='email-app-area'>
              <div className='email-app-list-wrapper'>
                <div className='email-app-list'>
                  <PerfectScrollbar
                    className='email-user-list list-group'
                    options={{
                      wheelPropagation: false
                    }}
                  >
                    <ul className='users-list-wrapper media-list'>{(this.state.campaign.id !== '') ? renderCampaignUsers : ''}</ul>
                  </PerfectScrollbar>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }else{
      return (
				<div className='no-results show'>
					<h5>No Data Found</h5>
				</div>
			);
    }
  }
}

export default SmsCampaignsUsersList;