import CONSTANTS from '../constants';
import LoginService from './LoginService';
import { customFetch as fetch } from '../helpers/FetchHelper';

export default {
  getAllReviews,
  deleteReviews,
};

async function getAllReviews(locationId) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.REVIEWS.DEFAULT(locationId)}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if (response) return response;
  throw new Error('Could not fetch currencies');
}
async function deleteReviews(locationId, reviews) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.REVIEWS.DELETE(locationId)}`, {
    method: 'POST',
    body: JSON.stringify(reviews),
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if (response) return response;
  throw new Error('Could not delete reviews');
}