import CONSTANTS from '../../../constants'
import CampaignService from '../../../services/CampaignService';

export function getSmsCampaigns(loc_id, initialize = false) {
	return async dispatch => {
		try {
			if (initialize) dispatch({ type: CONSTANTS.ACTIONS.SMS_CAMPAIGNS.ALL_CAMPAIGNS, data: null, loading: true });
			let campaigns = await CampaignService.getSmsCampaigns(loc_id);
			dispatch({ type: CONSTANTS.ACTIONS.SMS_CAMPAIGNS.ALL_CAMPAIGNS, data: campaigns, loading: false });
		}
		catch (error) {
			console.log(error);
			dispatch({ type: CONSTANTS.ACTIONS.SMS_CAMPAIGNS.ALL_CAMPAIGNS, data: null, loading: false });
		}
	};
}

export function saveCampaign(loc_id, campaign, initialize = false) {
	return async dispatch => {
		try {
			if (initialize) dispatch({ type: CONSTANTS.ACTIONS.SMS_CAMPAIGNS.GET_CAMPAIGN, data: null, loading: true });
			let comp = await CampaignService.saveCampaign(loc_id, campaign);
			dispatch({ type: CONSTANTS.ACTIONS.SMS_CAMPAIGNS.GET_CAMPAIGN, data: comp, loading: false });
		}
		catch (error) {
			console.log(error);
			dispatch({ type: CONSTANTS.ACTIONS.SMS_CAMPAIGNS.GET_CAMPAIGN, data: null, loading: false });
		}
	};
}

export function getCustomersStats(loc_id, filters, initialize = false) {
	return async dispatch => {
		try {
			if (initialize) dispatch({ type: CONSTANTS.ACTIONS.SMS_CAMPAIGNS.GET_CAMPAIGN_CUSTOMERS, data: null, loading: true });
			let stats = await CampaignService.getCustomerStats(loc_id, filters);
			dispatch({ type: CONSTANTS.ACTIONS.SMS_CAMPAIGNS.GET_CAMPAIGN_CUSTOMERS, data: stats, loading: false });
		}
		catch (error) {
			console.log(error);
			dispatch({ type: CONSTANTS.ACTIONS.SMS_CAMPAIGNS.GET_CAMPAIGN_CUSTOMERS, data: null, loading: false });
		}
	};
}

export function cancelCampaign(loc_id, campaign_id, initialize = false) {
	return async dispatch => {
		try {
			if (initialize) dispatch({ type: CONSTANTS.ACTIONS.SMS_CAMPAIGNS.ALL_CAMPAIGNS, data: null, loading: true });
			let comp = await CampaignService.cancelCampaign(loc_id, campaign_id);
			dispatch({ type: CONSTANTS.ACTIONS.SMS_CAMPAIGNS.ALL_CAMPAIGNS, data: comp, loading: false });
		}
		catch (error) {
			console.log(error);
			dispatch({ type: CONSTANTS.ACTIONS.SMS_CAMPAIGNS.ALL_CAMPAIGNS, data: null, loading: false });
		}
	};
}

export function deleteCampaign(loc_id, campaign_id, initialize = false) {
	return async dispatch => {
		try {
			if (initialize) dispatch({ type: CONSTANTS.ACTIONS.SMS_CAMPAIGNS.ALL_CAMPAIGNS, data: null, loading: true });
			let comp = await CampaignService.deleteCampaign(loc_id, campaign_id);
			dispatch({ type: CONSTANTS.ACTIONS.SMS_CAMPAIGNS.ALL_CAMPAIGNS, data: comp, loading: false });
		}
		catch (error) {
			console.log(error);
			dispatch({ type: CONSTANTS.ACTIONS.SMS_CAMPAIGNS.ALL_CAMPAIGNS, data: null, loading: false });
		}
	};
}