import { combineReducers } from 'redux'
import { overallStats } from "./OverallStatsReducer";
import { customerStats } from "./CustomerStatsReducer";
import { itemsStats } from "./ItemsStatsReducer";


const analyticsReducer = combineReducers({
  overallStats,
  customerStats,
  itemsStats
});

export default analyticsReducer;
