import React, { Component } from 'react'
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  FormGroup,
  Col,
  Input,
  Button
} from 'reactstrap';

class PaymentProcessorForm extends Component {
  render() {
    let { paymentProcessor, onChange, onSave } = this.props;
    return(
      <Card className='card-action'>
        <CardHeader>
          <CardTitle>{paymentProcessor.name}</CardTitle>
        </CardHeader>
        <CardBody>
          <FormGroup row>
            <Col md='4'><span>Merchant Account</span></Col>
            <Col md='8'>
              <Input
                name='merchant_account'
                placeholder='Merchant Account'
                value={paymentProcessor.merchant_account}
                onChange={onChange}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col md='4'><span>Api Key</span></Col>
            <Col md='8'>
              <Input
                name='api_key'
                placeholder='Api Key'
                value={paymentProcessor.api_key}
                onChange={onChange}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col md='4'><span>Secret</span></Col>
            <Col md='8'>
              <Input
                type='password'
                name='secret'
                placeholder='Secret'
                value={paymentProcessor.secret}
                onChange={onChange}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col md='4'><span>Environment</span></Col>
            <Col md='8'>
              <Input
                name='env'
                placeholder='Environment'
                value={paymentProcessor.env}
                onChange={onChange}
              />
            </Col>
          </FormGroup>

          <Button color='primary' type='submit' className='mr-1 mb-1' onClick={onSave}>Save</Button>
        </CardBody>
      </Card>
    );
  }
}
export default PaymentProcessorForm;
