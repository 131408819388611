import moment from 'moment';
import TimeHelper from './TimeHelper';
import OrderTypeHelper from './OrderTypeHelper';
import PaymentHelper from './PaymentHelper';
import CONSTANTS from '../constants';

export default class OrderHelper {
  static isPlaced(status) {
    return status.status_name === CONSTANTS.VARIABLES.ORDERS.STATUSES.PLACED;
  }

  static isSeen(status) {
    return status.status_name === CONSTANTS.VARIABLES.ORDERS.STATUSES.SEEN;
  }

  static isCompleted(status) {
    return status.status_name === CONSTANTS.VARIABLES.ORDERS.STATUSES.COMPLETE;
  }

  static isCancelled(status) {
    return status.status_name === CONSTANTS.VARIABLES.ORDERS.STATUSES.CANCELLED;
  }

  static shouldMarkAsSeen(order) {
    return this.isPlaced(order.OrderStatus);
  }

  static allowedStatusIds(orderType) {
    if (OrderTypeHelper.isDineIn([], orderType))
      return CONSTANTS.VARIABLES.ORDERS.ORDER_TYPE_STATUSES.DINE_IN;
    if (OrderTypeHelper.isDelivery([], orderType)) {
      return CONSTANTS.VARIABLES.ORDERS.ORDER_TYPE_STATUSES.DELIVERY;
    }
    if (OrderTypeHelper.isPickup([], orderType))
      return CONSTANTS.VARIABLES.ORDERS.ORDER_TYPE_STATUSES.PICK_UP;
    if (OrderTypeHelper.isCurbsidePickup([], orderType))
      return CONSTANTS.VARIABLES.ORDERS.ORDER_TYPE_STATUSES.CURBSIDE_PICKUP;
  }

  static allowedStatuses(orderType, statuses) {
    if (!orderType) return statuses;

    let statusIds = this.allowedStatusIds(orderType.order_type);
    return statuses.filter((status) => statusIds.includes(status.status_id));
  }

  static orderTime(order) {
    let dateAdded = moment(order.date_added);
    let orderTime = TimeHelper.getLocalDateTimeFromUTCString(`${order.order_date} ${order.order_time}`);
    if (dateAdded >= orderTime) return CONSTANTS.VARIABLES.ORDERS.ASAP_LABEL;
    return TimeHelper.toFormattedDateTime(orderTime);
  }

  static nextStatus(order, currentStatus, statuses) {
    // No next status if cancelled
    if (currentStatus) {
      if (currentStatus.status_name === CONSTANTS.VARIABLES.ORDERS.STATUSES.CANCELLED) return;
      // Do not return cancel in next action button
      let nextStatuses = statuses.filter((status) => {
        return status.order > currentStatus.order && status.status_name !== CONSTANTS.VARIABLES.ORDERS.STATUSES.CANCELLED
      });
      // Return appropriate next action
      if (nextStatuses && nextStatuses.length > 0) return nextStatuses[0];
    }else{
      return;
    }
    
  }

  static status(statusId, statuses) {
    return statuses.find((status) => parseInt(status.status_id, 10) === parseInt(statusId, 10));
  }

  static validateOrder(order, statuses, currentStatusId) {
    let status = this.status(currentStatusId, statuses);
    let warnings = [];
    if (this.isCancelled(status))
      warnings.push({ type: CONSTANTS.VARIABLES.ORDERS.WARNINGS.CANCELLED });
    if (this.isCompleted(status) && PaymentHelper.isUnpaid(order.PaymentStatus))
      warnings.push({ type: CONSTANTS.VARIABLES.ORDERS.WARNINGS.UN_PAID });

    return warnings;
  }
}
