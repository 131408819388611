import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Row, Button } from 'reactstrap';
import SpinnerComponent from '../@vuexy/spinner/Fallback-spinner';
import EditableStatus from './EditableStatus';
import { getOrder, markAsSeen } from '../../redux/actions/orders';
import { getStatuses } from '../../redux/actions/configurations';
import SocketService from '../../services/SocketService';
import ParamHelper from '../../helpers/ParamHelper';
import ViewHelper from '../../helpers/ViewHelper';
import OrderHelper from '../../helpers/OrderHelper';
import TimeHelper from '../../helpers/TimeHelper';
import CONSTANTS from '../../constants';

import '../../assets/css/orders.scss';
import OrderTypeHelper from '../../helpers/OrderTypeHelper';
import DeliveryStatus from './DeliveryStatus';
import { getRiders } from '../../redux/actions/users';

import RiderTrackModal from './delivery/RiderTrackModal';

class Order extends Component {

  state = {
    showRiderModal: false,
  }

  componentDidMount() {
    if (!this.props.statuses) this.props.getStatuses();
    // if(!this.props.riders) this.props.getRiders();
    this.props.getOrder(ParamHelper.getLocationId(this.props), ParamHelper.getOrderId(this.props));
    SocketService.listenTo(this.socketEvents());
  }

  componentWillUnmount() {
    SocketService.turnOff(this.socketEvents());
  }

  socketEvents() {
    return [
      { key: 'connect', callback: () => console.log('Socket connection was established') },
      { key: 'disconnect', callback: () => console.log('Socket connection was disconnected') },
      { key: 'reconnect', callback: this.updateOrder.bind(this) },
      { key: CONSTANTS.VARIABLES.ORDERS.EVENTS.BILL_REQUESTED, callback: this.billRequested.bind(this) },
      { key: CONSTANTS.VARIABLES.ORDERS.EVENTS.UPDATE, callback: this.updateOrder.bind(this) },
    ];
  }

  billRequested(data) {
    let orderId = ParamHelper.getOrderId(this.props);
    let locationId = ParamHelper.getLocationId(this.props);
    if (parseInt(data.bill_requested.order_id, 10) === parseInt(orderId, 10)) this.props.getOrder(locationId, orderId, false);
  }

  updateOrder(_orderId) {
    let orderId = ParamHelper.getOrderId(this.props);
    let locationId = ParamHelper.getLocationId(this.props);
    if (parseInt(orderId, 10) === parseInt(_orderId, 10)) this.props.getOrder(locationId, orderId, false);
  }

  renderOptions(order, orderMenuId) {
    let options = order.OrderOptions.filter((option) => option.order_menu_id === orderMenuId);
    return options.map((option) => {
      return <div key={option.order_option_id} className='text-muted text-small'>
        {option.display_type === CONSTANTS.VARIABLES.MENUS.OPTIONS.DISPLAY_TYPES.SELECT ? `${option.quantity}x ` : ''}
        {option.order_option_name}   {ViewHelper.toCurrency(option.order_option_price, order.Location.Currency.currency_code, order.Location.decimal_places)}
      </div>;
    });
  }

  renderOrderDetails(order, statuses) {
    let status = OrderHelper.status(this.props.order.status_id, statuses);
    const orderTotal = order.OrderTotals[2].code === 'order_total' ? order.OrderTotals[2].value : order.OrderTotals[3].value;
    return (
      <Card>
        <CardHeader>Order Details</CardHeader>
        <CardBody>
          <div className='mb-2'>
            <div><strong>Table Name</strong></div>
            <div>{ViewHelper.getTableName(order.Table)}</div>
          </div>

          <div className='mb-2'>
            <div><strong>Customer Name</strong></div>
            <div>{order.first_name} {order.last_name}</div>
          </div>

          <div className='mb-2'>
            <div><strong>Order Status</strong></div>
            <div className='f2 mb-1 mr-1' style={{ color: status.status_color }}>
              {status.status_name}
            </div>
          </div>

          {order.PaymentStatus &&
            <div className='mb-2'>
              <div><strong>Payment Status</strong></div>
              <div>{order.PaymentStatus.label}</div>
            </div>
          }

          {order.OrderType &&
            <div className='mb-2'>
              <div><strong>Order Type</strong></div>
              <div>{order.OrderType.label}</div>
            </div>
          }

          <div className='mb-2'>
            <div><strong>Location Name</strong></div>
            <div>{order.Location.name}</div>
          </div>
          {order.Promotion &&
            <div className='mb-2'>
              <div><strong>Promo Code</strong></div>
              <div>{order.Promotion.code}</div>
            </div>
          }
          <div className='mb-2'>
            <div><strong>Order Total</strong></div>
            <div>{ViewHelper.toCurrency(orderTotal, order.Location.Currency.currency_code, order.Location.decimal_places)}</div>
          </div>

          {order.Promotion &&
            <div className='mb-2'>
              <div><strong>Promotion Type</strong></div>
              <div>{order.Promotion.automatic == 1 ? 'Automatic' : 'Manual'}</div>
            </div>
          }

          {order.Promotion &&
            <div className='mb-2'>
              <div><strong>Promotion</strong></div>
              <div>{order.Promotion.code}</div>
            </div>
          }

          <div className='mb-2'>
            <div><strong>Time Ordered</strong></div>
            <div>{TimeHelper.toFormattedDateTime(order.date_added)}</div>
          </div>

          <div className='mb-2'>
            <div><strong>Last Updated</strong></div>
            <div>{TimeHelper.toFormattedDateTime(order.date_modified)}</div>
          </div>

          <div className='mb-2'>
            <div><strong>To Be Served By</strong></div>
            <div>{OrderHelper.orderTime(order)}</div>
          </div>
        </CardBody>
      </Card>
    );
  }

  renderMenuDetails(order) {
    const orderTotals = order.OrderTotals;
    let startIndex = 0;
    let subTotal = orderTotals[startIndex++].value;
    let discounts = 0;
    if (orderTotals.length > 3) {
      discounts = orderTotals[startIndex++].value;
      subTotal += parseInt(discounts);
    }
    if (order.delivery_charges)
      subTotal -= parseInt(order.delivery_charges);
    const tax = orderTotals[startIndex++].value;
    const totals = orderTotals[startIndex].value;

    return (
      <Card>
        <CardHeader>Menu Details</CardHeader>
        <CardBody>
          {
            order.OrderMenus.map((menu) => {
              return [
                <div className='order-item mb-1' key={menu.order_menu_id}>
                  <div className='f2 name pr-1'>
                    {menu.quantity}x {menu.name}
                    {this.renderOptions(order, menu.order_menu_id)}
                  </div>
                  <div className='f2 price'>
                    {ViewHelper.toCurrency(parseInt(menu.quantity) * menu.price, order.Location.Currency.currency_code, order.Location.decimal_places)}
                  </div>
                </div>,
                <div className='order-item mb-3' key='comment'>
                  {menu.comment &&
                    <div className='f3 name pr-1'>
                      Special Instructions: <span className='font-italic text-muted'>{menu.comment}</span>
                    </div>
                  }
                </div>
              ]
            })
          }
          <div className='order-item'>
            <div className='f2 name pr-1'>Sub Total</div>
            <div className='f2 price'>{ViewHelper.toCurrency(subTotal, order.Location.Currency.currency_code, order.Location.decimal_places)}</div>
          </div>

          <div className='order-item'>
            <div className='f2 name pr-1'>Discounts</div>
            <div className='f2 price'>{ViewHelper.toCurrency(-discounts, order.Location.Currency.currency_code, order.Location.decimal_places)}</div>
          </div>

          <div className='order-item'>
            <div className='f2 name pr-1'>Tax</div>
            <div className='f2 price'>{ViewHelper.toCurrency(tax, order.Location.Currency.currency_code, order.Location.decimal_places)}</div>
          </div>

          {this.renderDeliveryCharges(order.delivery_charges, order.Location.Currency.currency_code, order.Location.decimal_places)}

          <div className='order-item'>
            <div className='f2 name pr-1'>Total</div>
            <div className='f2 price'>{ViewHelper.toCurrency(totals, order.Location.Currency.currency_code, order.Location.decimal_places)}</div>
          </div>
        </CardBody>
      </Card>
    )
  }

  renderDeliveryCharges(deliveryCharges, currencyCode, decimalPaces) {
    if (deliveryCharges) {
      return (
        <div className='order-item'>
          <div className='f2 name pr-1'>Delivery Charges</div>
          <div className='f2 price'>{ViewHelper.toCurrency(deliveryCharges, currencyCode, decimalPaces)}</div>
        </div>
      )
    }
    return '';
  }

  renderStatusHistory(histories) {
    return (
      <Card>
        <CardHeader>Status History</CardHeader>
        <CardBody>
          {
            histories.map((history) => {
              return (
                <div className='mb-2' key={history.status_history_id}>
                  <div className='f3'>
                    <strong style={{ color: history.OrderStatus.status_color }}>{history.OrderStatus.status_name}</strong>
                    <div className='f3'>{TimeHelper.toFormattedDateTime(history.date_added)}</div>
                    {history.comment && <div className='f3 font-italic text-muted'>{history.comment}</div>}
                  </div>
                </div>
              )
            })
          }
        </CardBody>
      </Card>
    )
  }

  renderPaymentDetails(payments) {
    return (
      <Card>
        <CardHeader>Payment Details</CardHeader>
        <CardBody>
          {payments.length > 0 && payments.map((payment) => {
            return (
              <div className='mb-2 payment-details' key={payment.id}>
                <div className='f3'>
                  <strong>Method</strong>
                  <div className='f3'>{CONSTANTS.VARIABLES.ORDERS.PAYMENT_METHODS[payment.method.toLowerCase()]}</div>
                </div>
              </div>
            );
          })
          }
          {payments.length === 0 && <div className='f3'>No payment details available.</div>}
        </CardBody>
      </Card>
    );
  }

  getRiderData(orderDelivery) {
    if (!orderDelivery) {
      return null;
    }
    if (orderDelivery.type === 1) {
      const data = orderDelivery.third_party_data ? JSON.parse(orderDelivery.third_party_data) : null;
      if (data && data.driver && data.driver.phone_number) {
        return {
          name: data.driver.name,
          phone: data.driver.phone_number,
          riderLocation: data.riderLocation,
          lastUpdated: data.updatedAt,
          pandagoId: orderDelivery.third_party_id,
          delivery_fee: data.delivery_fee,
          ...data.timeline,
        }
      }
    }
    return null;
  }

  renderDeliveryHistory(order, riderData) {
    let histories = order.OrderDelivery ? order.OrderDelivery.StatusHistories : [];
    return (
      <Card>
        <CardHeader style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <p>
            Manage Delivery
          </p>
          <DeliveryStatus order={order}
            onOrderUpdate={this.updateOrder.bind(this, order.order_id)}
            showActionText={true} />
        </CardHeader>
        <CardBody>
          {
            histories.length > 0 ?
              [(histories.map((history) => {
                if (!history.OrderStatus)
                  return;

                let name = history.delivery_status_type === 1 ? 'PandaGo' : history.User ? history.User.Staff.staff_name : 'N/A';
                if (name === '' && history.User)
                  name = history.User.email;

                let status = `${history.OrderStatus.status_name === 'Third Party' ? 'PandaGo requested' : history.OrderStatus.status_name}`;
                if (history.OrderStatus.status_id === CONSTANTS.VARIABLES.ORDERS.DELIVERY_ACTIONS.SPECIFIC_RIDERS) {
                  status += ' requested';
                }

                status += ` by ${name}`;

                if (history.OrderStatus.status_id === CONSTANTS.VARIABLES.ORDERS.DELIVERY_ACTIONS.ON_THE_WAY) {
                  status = 'On the way to customer';
                }

                return (
                  <div className='mb-2' key={history.status_history_id}>
                    <div className='f3'>
                      <strong style={{ color: history.OrderStatus.status_color }}>{status}</strong>
                      <div className='f3'>{TimeHelper.toFormattedDateTime(history.date_added)}</div>
                      {history.comment && <div className='f3 font-italic text-muted'>{history.comment}</div>}
                    </div>
                  </div>
                )
              })), order.OrderDelivery && order.OrderDelivery.third_party_id && <div className="driver-section">
                <div className='mb-2 mt-2'>
                  <span><strong>PandaGo ID: </strong></span>
                  <span>{order.OrderDelivery.third_party_id}</span>
                </div>
                {riderData && <>
                  <div className='mb-2'>
                    <span><strong>Rider Name: </strong></span>
                    <span>{riderData.name}</span>
                  </div>
                  <div className='mb-2'>
                    <span><strong>Contact: </strong></span>
                    <span><a href={`tel:${riderData.phone}`}>{riderData.phone}</a></span>
                  </div>
                  <div className='mb-2'>
                    <span><strong>Customer ETA: </strong></span>
                    <span>{TimeHelper.toFormattedDateTime(riderData.estimated_delivery_time)}</span>
                  </div>
                  <div className='mb-2'>
                    <span><strong>PandaGo Fee: </strong></span>
                    <span>{ViewHelper.toCurrency(riderData.delivery_fee, order.Location.Currency.currency_code, order.Location.decimal_places)}</span>
                  </div>
                  <Button color="primary" onClick={this.toggleRiderModal.bind(this)}>
                    See Location
                  </Button>
                </>}
              </div>] : (
                <div>
                  <p>{order.delivery_action === CONSTANTS.VARIABLES.ORDERS.DELIVERY_ACTIONS.NOT_ASSIGNED ? 'No Rider has been assigned to this order yet!' : 'No Rider has accepted order yet!'}</p>
                </div>
              )
          }
        </CardBody>
      </Card>
    );
  }

  renderAdditionalDetails(order) {
    return (
      <Card>
        <CardHeader>Additional Details</CardHeader>
        <CardBody>
          {order.comment &&
            <div className='mb-2'>
              <div><strong>Order Instructions</strong></div>
              <div>{order.comment}</div>
            </div>
          }

          {order.car_number &&
            <div className='mb-2'>
              <div><strong>Car Number</strong></div>
              <div>{order.car_number}</div>
            </div>
          }

          {order.car_model &&
            <div className='mb-2'>
              <div><strong>Car Color & Model</strong></div>
              <div>{order.car_model}</div>
            </div>
          }

          {order.delivery_phone &&
            <div className='mb-2'>
              <div><strong>Phone Number</strong></div>
              <div>{order.delivery_phone}</div>
            </div>
          }

          {order.delivery_address &&
            <div className='mb-2'>
              <div><strong>Delivery Address</strong></div>
              <div>{order.delivery_address}</div>
            </div>
          }

          {order.delivery_location &&
            <div className='mb-2'>
              <div><strong>Delivery Location</strong></div>
              <div>
                <a rel='noopener noreferrer' href={ViewHelper.getGoogleMapsLink(order.delivery_location)} target='_blank'>
                  View on google maps
                </a>
              </div>
            </div>}
        </CardBody>
      </Card>
    );
  }

  renderStatusDetails(order, statuses, isBlock = false) {
    let filteredStatuses = OrderHelper.allowedStatuses(order.OrderType, statuses);
    return (
      <EditableStatus order={order}
        statuses={filteredStatuses}
        showId={false}
        onOrderUpdate={this.props.getOrder.bind(this, ParamHelper.getLocationId(this.props), ParamHelper.getOrderId(this.props), false)}
        bottomComponent={!isBlock}
        cardComponent={isBlock}
      />
    )
  }

  toggleRiderModal() {
    this.setState({ showRiderModal: !this.state.showRiderModal });
  }

  render() {
    const { order, statuses, loading } = this.props;
    // TODO: Implement proper error handling
    if (order && order.order_id && statuses) {
      if (OrderHelper.shouldMarkAsSeen(order)) {
        this.props.markAsSeen(order, statuses);
      }
      const restaurantLocation = `${order.Location.latitude},${order.Location.longitude}`;
      const riderData = order.OrderDelivery ? this.getRiderData(order.OrderDelivery) : {};
      return (
        <div className='order'>
          <Row style={{ marginTop: '2rem' }}>
            <Col md='4'>
              {this.renderOrderDetails(order, statuses)}
            </Col>
            <Col md='4'>

              { order.OrderType && OrderTypeHelper.isDelivery([], order.OrderType.order_type) && order.status_id > CONSTANTS.VARIABLES.ORDERS.ORDER_TYPE_STATUSES.DELIVERY[1] ?
                this.renderDeliveryHistory(order, riderData) : null}
              {this.renderMenuDetails(order)}
              {this.renderAdditionalDetails(order)}
            </Col>
            <Col md='4'>
              <div className='d-md-block d-none d-sm-none'>
                {this.renderStatusDetails(order, statuses, true)}
              </div>
              {this.renderStatusHistory(order.StatusHistories)}
              {this.renderPaymentDetails(order.Payments)}
            </Col>
            <Col xl='9' lg='12' md='12' sm='12' xs='12' className='d-sm-block d-block d-none d-md-none fixed-bottom p-0 m-0'>
              {this.renderStatusDetails(order, statuses)}
            </Col>
          </Row>
          <RiderTrackModal
            orderId={order.order_id}
            locationId={order.location_id}
            deliveryLocation={order.delivery_location}
            riderLocation={riderData ? riderData.riderLocation : ''}
            restaurantLocation={restaurantLocation}
            estimatedDeliveryTime={riderData ? riderData.estimated_delivery_time : ''}
            showModal={this.state.showRiderModal}
            toggleModal={this.toggleRiderModal.bind(this)}
            lastUpdated={riderData ? riderData.lastUpdated : null}
          />
        </div>
      );
    }
    if (loading) return <SpinnerComponent />;
  }
}

function mapStateToProps(state) {
  const { order } = state.orders;
  const { statuses } = state.configurations;
  return { order: order.data, statuses: statuses.data, loading: (order.loading || statuses.loading) };
}

export default connect(mapStateToProps, { getOrder, getStatuses, markAsSeen, getRiders })(Order);
