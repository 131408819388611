import React, { Component } from 'react';
import {Button, Card, CardBody, CardHeader, CardTitle, Col, Collapse, FormGroup, Input, Label, Row} from 'reactstrap';
import {serialize} from "php-serialize";
import SpinnerComponent from '../@vuexy/spinner/Fallback-spinner';

import '../../assets/scss/plugins/forms/switch/react-toggle.scss';
import 'react-toggle/style.css';
import {connect} from 'react-redux';
import {getLocation} from '../../redux/actions/locations';
import classnames from 'classnames';
import {ChevronDown} from 'react-feather';
import LocationSelectHeader from '../shared/LocationSelectHeader';
import LocationService from '../../services/LocationService';
import SelectLocation from '../shared/SelectLocation';

class RiderCompensation extends Component {

  constructor(props) {
    super(props);
    this.state = { selectLocation: false, loadingFromNetwork: false, locationCard: { 'isCollapsed': false }, zoneCard: { 'isCollapsed': false }, location: {}, options: {}, loading: true };
  }

  locationCardToggle() {
    let locationCard = this.state.locationCard;
    locationCard.isCollapsed = !locationCard.isCollapsed;
    this.setState({ locationCard });
  }

  zoneCardToggle() {
    let zoneCard = this.state.zoneCard;
    zoneCard.isCollapsed = !zoneCard.isCollapsed;
    this.setState({ zoneCard });
  }

  async componentDidMount() {
    const location = LocationService.getLocation();
    if(location) {
      await this.refreshData(location.loc_id);
      this.setState({loading: false });
    } else {
      this.setState({loading: false, selectLocation: true})
    }
  }

  async refreshData(loc_id) {
    this.setState({ loadingFromNetwork: true });
    await this.props.getLocation(loc_id);
    this.setState({ loadingFromNetwork: false, location: JSON.parse(JSON.stringify(this.props.location)), options: JSON.parse(JSON.stringify(this.props.location.options)) });
  }

  toggleLocation() {
    this.setState({selectLocation: !this.state.selectLocation});
  }

  async onSelect(location) {
    this.setState({loading: true});
    await this.refreshData(location.loc_id);
    this.setState({selectLocation: false, loading: false});
  }

  onLocationFieldChange(value, field) {
    let location = this.state.location;
    location[field] = value;
    this.setState({location});
  }

  onAreaFieldChange(value, field, index) {
    let options = this.state.options;
    options.delivery_areas[index][field] = value;
    this.setState({options});
  }

  async saveLocation() {
    this.setState({loading: true});
		const location = JSON.parse(JSON.stringify(this.state.location));
    location.options = serialize(this.state.options);
    await LocationService.updateLocation(location);
    await this.refreshData(this.state.location.loc_id);
    this.setState({loading: false});
  }

  async saveDeliveryAreas() {
    this.setState({loading: true});
    const location = JSON.parse(JSON.stringify(this.props.location));
    location.options = serialize(this.state.options);
    await LocationService.updateLocation(location);
    await this.refreshData(this.state.location.loc_id);
    this.setState({loading: false});
  }

  renderLocationCompensationForm(location) {
    return (
      <Card className={classnames('card-action', {
        'card-collapsed': !this.state.locationCard.isCollapsed,
        'card-shown': this.state.locationCard.isCollapsed
      })}>
        <CardHeader onClick={this.locationCardToggle.bind(this)}>
          <CardTitle>Restaurant Level</CardTitle>
          <ChevronDown
            className='collapse-icon'
            size={15}
          />
        </CardHeader>

        <Collapse isOpen={this.state.locationCard.isCollapsed} >
          <CardBody>
            <FormGroup row>
              <Col md='4' className={'form-group'}>
                <span>{location.name}</span>
              </Col>
              <Col md='4'>
                <FormGroup className='form-label-group'>
                  <Input
                    name={`fixedCompensation`}
                    placeholder={'Fixed'}
                    value={location.delivery_fixed_compensation}
                    onChange={(e) => this.onLocationFieldChange(e.target.value, 'delivery_fixed_compensation')}
                  />
                  <Label for="fixedCompensation">Fixed</Label>
                </FormGroup>
              </Col>
              <Col md='4'>
                <FormGroup className='form-label-group'>
                  <Input
                    name={`perKMCompensation`}
                    placeholder={'Per KM'}
                    value={location.delivery_per_km_compensation}
                    onChange={(e) => this.onLocationFieldChange(e.target.value, 'delivery_per_km_compensation')}
                  />
                  <Label for="fixedCompensation">Per KM</Label>
                </FormGroup>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Col md={{size: 8, offset: 4}}>
                <Button
                  color='primary'
                  type='submit'
                  className='mr-1 mb-1'
                  onClick={this.saveLocation.bind(this)}
                >
                  Save
                </Button>
              </Col>
            </FormGroup>
          </CardBody>
        </Collapse>
      </Card>
    );
  }

  renderZoneCompensationForm(options) {
    if(options && options.delivery_areas && Object.keys(options.delivery_areas).length > 0 ) {
      let deliveryAreas = options.delivery_areas;
      return (
        <Card className={classnames('card-action', {
          'card-collapsed': !this.state.zoneCard.isCollapsed,
          'card-shown': this.state.zoneCard.isCollapsed
        })}>
          <CardHeader onClick={this.zoneCardToggle.bind(this)}>
            <CardTitle>Zone Level</CardTitle>
            <ChevronDown
              className='collapse-icon'
              size={15}
            />
          </CardHeader>

          <Collapse isOpen={this.state.zoneCard.isCollapsed} >
            <CardBody>
              {Object.keys(deliveryAreas).map((key) => {
                  let deliveryArea = deliveryAreas[key];
                  return (
                    <FormGroup row>
                      <Col md='4' className={'form-group'}>
                        <span>{deliveryArea.name}</span>
                      </Col>
                      <Col md='4'>
                        <FormGroup className='form-label-group'>
                          <Input
                            name={`fixedCompensation`}
                            placeholder={'Fixed'}
                            value={deliveryArea.delivery_fixed_compensation}
                            onChange={(e) => this.onAreaFieldChange(e.target.value, 'delivery_fixed_compensation', key)}
                          />
                          <Label for="fixedCompensation">Fixed</Label>
                        </FormGroup>
                      </Col>
                      <Col md='4'>
                        <FormGroup className='form-label-group'>
                          <Input
                            name={`perKMCompensation`}
                            placeholder={'Per KM'}
                            value={deliveryArea.delivery_per_km_compensation}
                            onChange={(e) => this.onAreaFieldChange(e.target.value, 'delivery_per_km_compensation', key)}
                          />
                          <Label for="fixedCompensation">Per KM</Label>
                        </FormGroup>
                      </Col>
                    </FormGroup>
                  );
                })
              }

              <FormGroup row>
                <Col md={{size: 8, offset: 4}}>
                  <Button
                    color='primary'
                    type='submit'
                    className='mr-1 mb-1'
                    onClick={this.saveDeliveryAreas.bind(this)}
                  >
                    Save
                  </Button>
                </Col>
              </FormGroup>
            </CardBody>
          </Collapse>
        </Card>
      );
    }
    return null;
  }

  render() {
    const { location } = this.props;
    let loading = this.state.loading || this.state.loadingFromNetwork;
    const { state: {
      selectLocation
    }} = this;

    if ( selectLocation )
      return <SelectLocation onBack={this.toggleLocation.bind(this)} onSelect={this.onSelect.bind(this)} />;

    // TODO: Implement proper error handling
    if(location && location.loc_id) {
      return (
        <div className='location'>
          {
            <LocationSelectHeader text={location.name} onIconClick={this.toggleLocation.bind(this)}/>
          }
          <div className='collapse-margin'>
            { this.renderLocationCompensationForm(this.state.location) }
          </div>
          <div className='collapse-margin'>
            { this.renderZoneCompensationForm(this.state.options) }
          </div>
        </div>
      );
    } else if(!loading) {
      return (
        <SelectLocation onBack={this.toggleLocation.bind(this)} onSelect={this.onSelect.bind(this)} />
      );
    }
    if(loading) return <SpinnerComponent />;
  }
}

function mapStateToProps(state) {
  const { location } = state.locations;
  return { location: location.data, loading: location.loading };
}

export default connect(mapStateToProps, { getLocation })(RiderCompensation);
