import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUtensils, faMapMarkerAlt, faPencilAlt, faMoneyBillAlt, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Card, Col, CustomInput, FormGroup, Row, Button } from 'reactstrap';
import SpinnerComponent from '../@vuexy/spinner/Fallback-spinner';
import EditableStatus from './EditableStatus';
import DeliveryStatus from './DeliveryStatus';
import Pagination from '../shared/Pagination';
import { getLiveOrders } from '../../redux/actions/orders';
import { getStatuses } from '../../redux/actions/configurations';

import NetworkErrorHandler from '../shared/NetworkErrorHandler';
import NotificationService from '../../services/NotificationService';
import SocketService from '../../services/SocketService';
import CacheService from '../../services/CacheService';
import ViewHelper from '../../helpers/ViewHelper';
import TimeHelper from '../../helpers/TimeHelper';
import OrderHelper from '../../helpers/OrderHelper';
import CONSTANTS from '../../constants';

import '../../assets/css/orders.scss';
import OrderTypeHelper from '../../helpers/OrderTypeHelper';

const DEFAULT_FILTER = CONSTANTS.FILTERS.ORDERS.find((filter) => filter.default);
const DEFAULT_SORT = CONSTANTS.SORTS.ORDERS.find((sort) => sort.default);
const DEFAULT_STATE = { filter: DEFAULT_FILTER.key, sort: DEFAULT_SORT.key, page: 0 };

class LiveOrders extends Component {
  constructor(props) {
    super(props);
    this.state = DEFAULT_STATE;
  }

  componentDidMount() {
    this.refreshData();
    this.saveToken();
    SocketService.listenTo(this.socketEvents());
  }

  refreshData() {
    this.props.getStatuses();
    this.initializeStoredState(this.getOrders.bind(this));
  }

  componentWillUnmount() {
    CacheService.setFilterData(this.state);
    // SocketService.turnOff(this.socketEvents());
  }

  socketEvents() {
    return [
      { key: 'connect', callback: () => console.log('Socket connection was established') },
      { key: 'disconnect', callback: () => console.log('Socket connection was disconnected') },
      { key: 'reconnect', callback: this.getNewOrders.bind(this) },
      { key: CONSTANTS.VARIABLES.ORDERS.EVENTS.NEW, callback: this.getNewOrders.bind(this) },
      { key: CONSTANTS.VARIABLES.ORDERS.EVENTS.BILL_REQUESTED, callback: this.getNewOrders.bind(this) },
      { key: CONSTANTS.VARIABLES.ORDERS.EVENTS.UPDATE, callback: this.getNewOrders.bind(this) },
    ];
  }

  async saveToken() {
    let token = NotificationService.getTokenFromCache();
    //TODO: Correct check would be if request is not sent by webview of native app.
    if (!token) token = await NotificationService.getToken();
    if (token) await NotificationService.saveToken(token);
    NotificationService.removeTokenFromCache();
  }

  initializeStoredState(callback) {
    let filterData = CacheService.getFilterData();
    if (filterData) return this.setState(filterData, () => {
      callback(this.state, true);
    });
    return callback(this.state, true);
  }

  getOrders(filters, initialize = false) {
    CacheService.removeFilterData();
    this.props.getLiveOrders(filters, initialize);
  }

  getNewOrders() {
    this.getOrders(this.state, false);
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value, page: 0 }, () => {
      this.getOrders(this.state, true);
    });
  }

  onPageChange(page) {
    this.setState({ page: page.selected }, () => {
      this.getOrders(this.state, true);
    });
  }

  goToOrder(locationId, orderId) {
    this.props.history.push(`${CONSTANTS.BASE_URL}locations/${locationId}/orders/${orderId}`);
  }

  renderOrder(order, statuses) {
    let filteredStatuses = OrderHelper.allowedStatuses(order.OrderType, statuses);
    const orderTotal = order.OrderTotals[2].code === 'order_total' ? order.OrderTotals[2].value : order.OrderTotals[3].value;
    return (
      <div key={order.order_id} className='f3 live-order p-2' onClick={(e) => {
        if (e.target.id !== 'item_parent') {
          return;
        }
        this.goToOrder(order.location_id, order.order_id);
      }}>
        <Row id='item_parent'>
          <EditableStatus order={order}
            statuses={filteredStatuses}
            showId={true}
            onOrderClick={this.goToOrder.bind(this, order.location_id, order.order_id)}
            onOrderUpdate={this.getOrders.bind(this, this.state)} />
          {order.OrderType && OrderTypeHelper.isDelivery([], order.OrderType.order_type) && order.status_id > CONSTANTS.VARIABLES.ORDERS.ORDER_TYPE_STATUSES.DELIVERY[1] ?
            <DeliveryStatus order={order}
              onOrderUpdate={this.getOrders.bind(this, this.state)} />
            : null}
        </Row>
        <Row className='info bottom-margin-1' >
          <span>
            <FontAwesomeIcon icon={faUser} size={'sm'} />
            <span className='pl-1'>{order.first_name.substr(0, 10)} {order.last_name ? order.last_name.charAt(0) : null}.</span>
          </span>

          <span>
            <FontAwesomeIcon icon={faUtensils} size={'sm'} />
            <span className='pl-1'>{ViewHelper.getOrderTypeName(order.OrderType)} {ViewHelper.getTableName(order.Table)}</span>
          </span>

          <span>
            <FontAwesomeIcon icon={faMapMarkerAlt} size={'sm'} />
            <span className='pl-1'>{order.Location.name}</span>
          </span>
        </Row>

        <Row className='info' onClick={this.goToOrder.bind(this, order.location_id, order.order_id)}>
          <span>
            <FontAwesomeIcon icon={faMoneyBillAlt} size={'sm'} />
            <span className='pl-1'>{ViewHelper.toCurrency(orderTotal, order.Location.Currency.currency_code, order.Location.decimal_places)}</span>
          </span>

          <span>
            <FontAwesomeIcon icon={faCalendarAlt} size={'sm'} />
            <span className='pl-1'>{TimeHelper.toFormattedDateTime(order.date_added)}</span>
          </span>

          <span>
            <FontAwesomeIcon icon={faPencilAlt} size={'sm'} />
            <span className='pl-1'>{TimeHelper.toFormattedDateTime(order.date_modified)}</span>
          </span>
        </Row>
      </div>
    )
  }

  renderOrders(orders, statuses) {
    if (orders.length === 0) return <div className='p-3 border-top-1'>No live orders at the moment.</div>;
    return orders.map(order => this.renderOrder(order, statuses));
  }

  renderField(label, options) {
    return (
      <FormGroup row style={{ width: '50%' }}>
        <Col md='2' style={{ display: 'table-cell', alignSelf: 'center' }}>
          <span>{label.charAt(0).toUpperCase() + label.slice(1)}</span>
        </Col>
        <Col md='6'>
          <CustomInput
            type='select'
            name={label}
            id={label}
            value={this.state[label]}
            onChange={this.onChange.bind(this)}
          >
            {
              options.map((option) => {
                return <option key={option.key} value={option.key}>{option.name}</option>
              })
            }
          </CustomInput>
        </Col>
      </FormGroup>
    );
  }

  render() {
    const { orders, totalCount, statuses, loading } = this.props;
    let email = localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_login_user`);
    let roles = localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_role`);
    if (orders && statuses) {
      return (
        <>
          <Card className='live-orders'>
            <div className='filters border-bottom p-2'>
              {this.renderField('filter', CONSTANTS.FILTERS.ORDERS)}
              {this.renderField('sort', CONSTANTS.SORTS.ORDERS)}
            </div>
            {this.renderOrders(orders, statuses)}
            {orders.length > 0 && <Pagination onPageChange={this.onPageChange.bind(this)} page={this.state.page} count={totalCount} />}
          </Card>
          {email &&
            <>
              <div className='text-right' >
                <small><div className='col-sm-12 col-md-6 col-lg-3 float-right' style={{color:"#606479"}}>Logged in with: <b>{email}</b></div></small>
                <br></br>
                <small><div className='col-sm-12 col-md-6 col-lg-3 float-right' style={{color:"#606479"}}><b>[{roles}]</b></div></small>
              </div>
            </>
          }
        </>
      );
    }
    if (loading) return <SpinnerComponent />;
    return <NetworkErrorHandler retry={this.refreshData.bind(this)} />;
  }
}

function mapStateToProps(state) {
  const { orders } = state.orders;
  const { statuses } = state.configurations;
  let orderRows, totalCount;
  if (orders.data) {
    orderRows = orders.data.rows;
    totalCount = orders.data.count;
  }
  return { orders: orderRows, totalCount, statuses: statuses.data, loading: (orders.loading || statuses.loading) };
}

export default connect(mapStateToProps, { getLiveOrders, getStatuses })(LiveOrders);
