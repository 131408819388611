import { combineReducers } from 'redux'
import { allSmsPlans, smsPlan, allCards, allSubscriptions } from "./SmsReducer";


const smsReducer = combineReducers({
    allSmsPlans,
    smsPlan,
    allCards,
    allSubscriptions
});

export default smsReducer;