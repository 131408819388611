import React, { useState } from "react";
import DataTable from "react-data-table-component";
import TimeHelper from "../../helpers/TimeHelper";
import ViewHelper from "../../helpers/ViewHelper";
import Checkbox from "../@vuexy/checkbox/CheckboxesVuexy";
import { Check, Edit2 } from "react-feather";
import { Button } from "reactstrap";
import Toggle from 'react-toggle';
import ImageHelper from "../../helpers/ImageHelper";

const ListTables = (props) => {

  const editTable = (id) => {
    props.editTable(id);
  }
  const open = (url) => {
    props.openImage(url);
  }

  const columns = [
    {
      name: 'Edit',
      selector: 'table_id',
      format: (val) => {
        return <Button outline className='btn-icon' color='primary' onClick={() => editTable(val.table_id)}><Edit2 color='var(--primary)' className='ml-0 p-0' size={10} /></Button>

      }
    }, {
      name: 'Status',
      selector: 'table_status',
      format: (val) => {
        return <Toggle
          name={'table_status'}
          defaultChecked={val.table_status}
          className='switch-danger align-self-baseline'
          onChange={(e) => props.onChangeToggle(e, val.table_id, 'toggle')}
        />
      }
    }, {
      name: 'Name',
      selector: 'table_name',
      format: (val) => {
        return val.table_name ? val.table_name : 'Not Available';
      }
    }, {
      name: 'Max',
      selector: 'max_capacity',
      width: '8%',
      format: (val) => {
        return val.max_capacity ? val.max_capacity : '0';
      }
    }, {
      name: 'Location',
      selector: 'Location.name',
      width: '20%',
      format: (val) => {
        return val.Location && val.Location.name ? val.Location.name : 'Not Available';
      }
    }, {
      name: 'QR Image',
      selector: 'image_name',
      format: (val) => {
        let url = ImageHelper.getImageURL(val.image_name,'');
        return val.image_name ?
          <>
            <img src={url} width='70' height='70' className='p-1' onClick={() => open(url)} style={{cursor:'pointer'}}/>
          </> : 'Not available';
      }
    }, {
      name: 'Date',
      selector: 'date_added',
      width: '20%',
      format: (val) => {
        return val.date_added ? TimeHelper.toFormattedDateTimeDB(val.date_added) : 'Not available';
      }
    }
  ];

  const renderTables = (tables, handleChange) => {
    if (!tables || tables.length === 0)
      return <h1>No App table found.</h1>;

    return <DataTable
      noHeader
      pagination
      paginationRowsPerPageOptions={[25, 50, 100]}
      paginationPerPage={50}
      selectableRows
      selectableRowsComponent={Checkbox}
      selectableRowsComponentProps={{
        color: "primary",
        icon: <Check className="vx-icon" size={12} />,
        label: "",
        size: "sm"
      }}
      onSelectedRowsChange={handleChange}
      columns={columns}
      data={tables}
    />;
  }
  return renderTables(props.tables, props.handleChange);

}

export default ListTables;
