import LoginService from './LoginService';
import CONSTANTS from '../constants';
import { customFetch as fetch } from '../helpers/FetchHelper';

export default {
  getBranding,
  postBranding
};

async function getBranding(locationId) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.BRANDING.DEFAULT(locationId)}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if(response) return response;

  throw new Error('Could not fetch branding settings');
}

async function postBranding(branding) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.BRANDING.DEFAULT(branding.loc_id)}`, {
    method: 'POST',
    body: branding,
    headers: { 'Accept': CONSTANTS.REQUEST_HEADERS.Accept, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if(response) return response;

  throw new Error('Could not update branding settings');
}
