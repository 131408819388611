import CONSTANTS from '../constants';
import LoginService from './LoginService';
import { customFetch as fetch } from '../helpers/FetchHelper';

export default {
	getAllRidersHistory,
	getAllRidersPaymentHistory,
	createPayment,
	deletePayments,
	getAllRiders
};

async function getAllRiders(locationId) {
	let { token } = LoginService.getToken();
	let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.RIDERS.DEFAULT(locationId)}`, {
		method: 'GET',
		headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
	});
	response = await response.json();
	if (response) return response;
	throw new Error('Could not fetch riders');
}

async function getAllRidersHistory(locationId) {
	let { token } = LoginService.getToken();
	let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.RIDERS.HISTORY(locationId)}`, {
		method: 'GET',
		headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
	});
	response = await response.json();
	if (response) return response;
	throw new Error('Could not fetch history');
}

async function getAllRidersPaymentHistory(locationId) {
	let { token } = LoginService.getToken();
	let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.RIDERS.PAYMENTS(locationId)}`, {
		method: 'GET',
		headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
	});
	response = await response.json();
	if (response) return response;
	throw new Error('Could not fetch payments');
}

async function createPayment(locationId, payment) {
	let { token } = LoginService.getToken();
	let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.RIDERS.DEFAULT(locationId)}`, {
		method: 'POST',
		body: JSON.stringify(payment),
		headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
	});
	response = await response.json();
	if (response) return response;
	throw new Error('Could not fetch currencies');
}

async function deletePayments(locationId, payments) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.RIDERS.DELETE(locationId)}`, {
    method: 'POST',
    body: JSON.stringify(payments),
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if (response) return response;
  throw new Error('Could not delete payments');
}