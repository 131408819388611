import CONSTANTS from '../constants';
import LoginService from './LoginService';
import { customFetch as fetch } from '../helpers/FetchHelper';

export default {
  getOverallStats,
  getCustomerStats,
  getItemStats,
};

async function getOverallStats(loc_id, filters) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.ANALYTICS.DEFAULT(loc_id, 'overall')}`, {
    method: 'POST',
    body: JSON.stringify(filters),
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if(response) return response;

  throw new Error('Could not fetch stats');
}

async function getCustomerStats(loc_id, filters) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.ANALYTICS.DEFAULT(loc_id, 'customers')}`, {
    method: 'POST',
    body: JSON.stringify(filters),
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if(response) return response;

  throw new Error('Could not fetch stats');
}

async function getItemStats(loc_id, filters) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.ANALYTICS.DEFAULT(loc_id, 'items')}`, {
    method: 'POST',
    body: JSON.stringify(filters),
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });
  response = await response.json();
  if(response) return response;

  throw new Error('Could not fetch stats');
}