import { createStore, applyMiddleware, compose } from 'redux';
import createDebounce from 'redux-debounced';
import ReduxPromise from 'redux-promise';
import thunk from 'redux-thunk';
import rootReducer from '../reducers/rootReducer';
import localforage from 'localforage';
import { persistStore, persistReducer } from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage: localforage,
  whitelist: ['notificationReducer']
}

const presistedReducer = persistReducer(persistConfig, rootReducer);
const middlewares = [ReduxPromise, thunk, createDebounce()];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  presistedReducer,
  {},
  composeEnhancers(applyMiddleware(...middlewares))
);
const presistor = persistStore(store);
export { store, presistor };
