import React, { Component } from 'react';
import { ArrowDown } from 'react-feather';
import { Dropdown, Button } from 'react-bootstrap';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  CustomInput,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import { ClipLoader } from 'react-spinners';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import OrderService from '../../services/OrderService';
import OrderHelper from '../../helpers/OrderHelper';
import CONSTANTS from '../../constants';

import '../../assets/css/orders.scss';

class EditableStatus extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, showPrompt: false, promptComponent: null, reason_to_cancel: null };
    this.promptFnctions = {
      unpaid: this.unpaid.bind(this),
      cancel: this.cancel.bind(this)
    };
  }

  hidePrompt() {
    this.setState({ showPrompt: false, promptComponent: null });
  }

  changeReason(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  renderOptions(order, statuses) {
    return statuses.map((status) => {
      return <option value={status.status_id} key={status.status_id}>{status.status_name}</option>
    });
  }

  statusOptions(statuses, currentStatus) {
    return statuses.map((status) => {
      let active = currentStatus.status_id === status.status_id ? 'active' : '';
      return <Dropdown.Item className={active} key={status.status_id} eventKey={status.status_id}>{status.status_name}</Dropdown.Item>
    });
  }

  async updateOrder(order, statusId, forceUpdate, reasonToCancel) {
    let { statuses } = this.props;
    let warnings = OrderHelper.validateOrder(order, statuses, statusId);
    if(warnings && warnings.length > 0 && !forceUpdate) {
      let warning = warnings[0];
      return this.setState({ showPrompt: true, promptComponent: warning.type });
    } else {
      try {
        this.setState({ loading: true, showPrompt: false, promptComponent: null });
        await OrderService.updateStatus(order.location_id, order.order_id, statusId, reasonToCancel);
        await this.props.onOrderUpdate();
        this.setState({ loading: false });
      } catch (e) {
        alert('Something went wrong. Please Try again.');
      }
    }
  }

  onStatusChange(order, event) {
    let statusId;
    if(event.target) statusId = event.target.value;
    else statusId = event;
    return this.updateOrder(order, statusId, false, '');
  }

  cancelOrder(order, statuses) {
    let cancelStatus = statuses.find((status) => status.status_name === CONSTANTS.VARIABLES.ORDERS.STATUSES.CANCELLED);
    return this.updateOrder(order, cancelStatus.status_id, false, '');
  }

  cancel() {
    let { order, statuses } = this.props;
    let cancelStatus = statuses.find((status) => OrderHelper.isCancelled(status));
    return [
      <ModalHeader key='header' className='bg-white text-dark'><b>Reason for cancellation</b></ModalHeader>,
      <ModalBody key='body'>
        {this.renderReasonsToCancel()}
        <div className='reason' onClick={() => this.setState({ reason_to_cancel: '' })}>
          <div className='text'>Other</div>
          <input type='radio'
                 name='reason_to_cancel'
                 onChange={() => this.setState({ reason_to_cancel: '' })}
                 checked={!CONSTANTS.VARIABLES.ORDERS.REASONS_TO_CANCEL.includes(this.state.reason_to_cancel) && this.state.reason_to_cancel !== null} />
        </div>
        <textarea onChange={this.changeReason.bind(this)} name='reason_to_cancel' className='form-control' value={this.state.reason_to_cancel || ''} />
      </ModalBody>,
      <ModalFooter key='footer'>
        <Button variant='dark' onClick={this.hidePrompt.bind(this)}>Don't Cancel (Go Back)</Button>
        <Button disabled={!this.state.reason_to_cancel}
                variant='primary'
                onClick={this.updateOrder.bind(this, order, cancelStatus.status_id, true, this.state.reason_to_cancel)}>
          Cancel Order
        </Button>
      </ModalFooter>
    ];
  }

  unpaid() {
    let { order, statuses } = this.props;
    let completeStatus = statuses.find((status) => OrderHelper.isCompleted(status));
    return [
      <ModalHeader key='header' className='bg-danger text-white'><b>Order Unpaid</b></ModalHeader>,
      <ModalBody key='body' >Order is not paid. Are you sure?</ModalBody>,
      <ModalFooter key='footer'>
        <Button variant='dark' onClick={this.hidePrompt.bind(this)}>Cancel</Button>
        <Button variant='primary' onClick={this.updateOrder.bind(this, order, completeStatus.status_id, true, '')}>
          {completeStatus.status_name} and mark paid
        </Button>
      </ModalFooter>
    ];
  }

  renderReasonsToCancel() {
    return CONSTANTS.VARIABLES.ORDERS.REASONS_TO_CANCEL.map((reason) => {
      return (
        <div className='reason' key={reason} onClick={() => this.setState({ reason_to_cancel: reason })}>
          <div className='text'>{reason}</div>
          <input type='radio'
                 name='reason_to_cancel'
                 onChange={() => this.setState({ reason_to_cancel: reason })}
                 checked={this.state.reason_to_cancel === reason} />
        </div>
      )
    })
  }

  renderButtons(order) {
    return (
      <>
        <Button outline size="md" className="m-25 btn-primary"
                       onClick={() => this.onStatusChange(order, CONSTANTS.VARIABLES.ORDERS.ORDER_STATUS_IDS.BEING_PREPARED)}>
          Accept
        </Button>
        <Button outline size="md" className="m-25 btn-danger"
                       onClick={() => this.onStatusChange(order, CONSTANTS.VARIABLES.ORDERS.ORDER_STATUS_IDS.CANCELLED)}>
          Reject
        </Button>
      </>
    );
  }

  renderStatusComponent() {
    const { order, statuses, showId, bottomComponent, cardComponent } = this.props;
    let status = OrderHelper.status(order.status_id, statuses);
    let nextStatus = OrderHelper.nextStatus(order, status, statuses);
    if(bottomComponent) {
      return (
        <div className='editable-status-bottom' key='status'>
          { this.state.loading && <ClipLoader color={status.status_color} size={30} /> }
          { !this.state.loading && <div className='status-buttons'>
            { order.status_id < CONSTANTS.VARIABLES.ORDERS.ORDER_STATUS_IDS.BEING_PREPARED ?
              this.renderButtons(order)
              :
              <>
                <Button  disabled={OrderHelper.isCancelled(status)} variant={'danger'} onClick={this.cancelOrder.bind(this, order, statuses)}>
                  <FontAwesomeIcon icon={faTrashAlt}/>
                </Button>
                { nextStatus && <Button className='status-button' onClick={this.updateOrder.bind(this, order, nextStatus.status_id, false, '')}>
                  Change status to: <b>{nextStatus.status_name}</b>
                </Button> }
                { !nextStatus && <Button  disabled className='status-button'>Status: {status.status_name}</Button> }
                <Dropdown onSelect={this.onStatusChange.bind(this, order)}>
                  <Dropdown.Toggle variant='dark'>
                    <ArrowDown className='dropdown-toggle-icon' />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>{this.statusOptions(statuses, status)}</Dropdown.Menu>
                </Dropdown>
              </> }
          </div> }
        </div>
      )
    } else if (cardComponent) {
      return (
        <Card>
          <CardHeader>Manage Status</CardHeader>
          <CardBody>
            { this.state.loading && <ClipLoader color={status.status_color} size={30} /> }
            { !this.state.loading &&
            <Row className='status-button-card'>
              { order.status_id < CONSTANTS.VARIABLES.ORDERS.ORDER_STATUS_IDS.BEING_PREPARED ?
                this.renderButtons(order)
                :
                <>
                  <Col md={7} >
                    { nextStatus && <Button onClick={this.updateOrder.bind(this, order, nextStatus.status_id, false, '')}>
                      Change status to: <b>{nextStatus.status_name}</b>
                    </Button> }
                    { !nextStatus && <Button disabled className='status-button-card'>Status: {status.status_name}</Button> }
                  </Col>
                  <Col md={3}>
                    <Dropdown onSelect={this.onStatusChange.bind(this, order)}>
                      <Dropdown.Toggle variant='dark'>
                        <ArrowDown className='dropdown-toggle-icon' />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>{this.statusOptions(statuses, status)}</Dropdown.Menu>
                    </Dropdown>
                  </Col>
                  <Col md={2} className='p-0'>
                    <Button className='line-height-1-5' disabled={OrderHelper.isCancelled(status)} variant={'danger'} onClick={this.cancelOrder.bind(this, order, statuses)}>
                      <FontAwesomeIcon icon={faTrashAlt}/>
                    </Button>
                  </Col>
                </> }
            </Row> }
          </CardBody>
        </Card>
      )
    } else {
      return (
        <div className='editable-status' key='status'>
          { showId && <div className='f2 mb-1 mr-1' style={{ color: status ? status.status_color : '#fff' }} onClick={this.props.onOrderClick}>
            # {order.order_id}
          </div> }
          <div className='f2 mb-1'>
            { order.status_id < CONSTANTS.VARIABLES.ORDERS.ORDER_STATUS_IDS.BEING_PREPARED ?
              this.renderButtons(order)
              :
              <CustomInput
                id='status-select'
                type='select'
                style={{color: status ? status.status_color : '#fff' }}
                value={order.status_id}
                onChange={this.onStatusChange.bind(this, order)}>
                {this.renderOptions(order, statuses)}
              </CustomInput>
            }
          </div>
          {this.state.loading && <ClipLoader color={status ? status.status_color : '#fff' } size={15} />}
        </div>
      );
    }
  }

  render() {
    return [
      this.renderStatusComponent(),
      <Modal isOpen={this.state.showPrompt}
             toggle={this.hidePrompt.bind(this)}
             className='modal-dialog-centered'
             key='modal'>
        { this.state.promptComponent && this.promptFnctions[this.state.promptComponent]()}
      </Modal>
    ]
  }
}

export default EditableStatus;
