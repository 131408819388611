import CONSTANTS from '../constants';
import LoginService from './LoginService';
import { customFetch as fetch } from '../helpers/FetchHelper';

export default {
	getSmsPlans,
	subscribePlan,
	getSubscribedSmsPlans,
	getCards,
	getSmsPlan,
	saveSmsPlan,
	delPlan,
	getSmsSubscriptions,
	getSmsPlansForAdmin,
	getAllSmsSubscriptions,
};

async function getSmsPlansForAdmin() {
	let { token } = LoginService.getToken();
	let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.SMS.DEFAULT}`, {
		method: 'GET',
		headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
	});
	response = await response.json();
	if (response) return response;
	throw new Error('Could not fetch sms plans');
}

async function getSmsPlans() {
	let { token } = LoginService.getToken();
	let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.SMS.ALL_PLANS}`, {
		method: 'GET',
		headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
	});
	response = await response.json();
	if (response) return response;
	throw new Error('Could not fetch sms plans');
}

async function getSmsPlan(planid) {
	let { token } = LoginService.getToken();
	let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.SMS.SMSPLAN(planid)}`, {
		method: 'GET',
		headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
	});
	response = await response.json();
	if (response) return response;
	throw new Error('Could not fetch sms plan');
}

async function getAllSmsSubscriptions() {
	let { token } = LoginService.getToken();
	let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.SMS.ALL_SUBSCRIPTIONS}`, {
		method: 'GET',
		headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
	});
	response = await response.json();
	if (response) return response;
	throw new Error('Could not fetch sms subscriptions');
}

async function getSmsSubscriptions() {
	let { token } = LoginService.getToken();
	let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.SMS.SUBSCRIPTIONS}`, {
		method: 'GET',
		headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
	});
	response = await response.json();
	if (response) return response;
	throw new Error('Could not fetch sms subscriptions');
}

async function getSubscribedSmsPlans() {
	let { token } = LoginService.getToken();
	let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.SMS.SUBSCRIBED}`, {
		method: 'GET',
		headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
	});
	response = await response.json();
	if (response) return response;
	throw new Error('Could not fetch sms plans');
}

async function subscribePlan(plan_id, payment_id) {
	let { token } = LoginService.getToken();
	let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.SMS.SUBSCRIBE}`, {
		method: 'POST',
		body: JSON.stringify({ "plan_id": plan_id, "payment_id": payment_id }),
		headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
	});
	response = await response.json();
	if (response) return response;

	throw new Error('Could not subscribe sms plans');
}

async function getCards() {
	let { token } = LoginService.getToken();
	let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.SMS.CARDS}`, {
		headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
	});
	response = await response.json();
	if (response) return response;
	throw new Error('Could not fetch cards.');
}

async function saveSmsPlan(plan) {
	let { token } = LoginService.getToken();

	let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.SMS.DEFAULT}`, {
		method: 'POST',
		body: JSON.stringify(plan),
		headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
	});
	response = await response.json();
	if (response) return response;

	throw new Error('Could not save sms plans');
}
async function delPlan(plan_id) {
	let { token } = LoginService.getToken();
	let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.SMS.SMSPLAN(plan_id)}`, {
		method: 'DELETE',
		headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
	});
	response = await response.json();
	if (response) return response;

	throw new Error('Could not delete plan');
}
