
let ENV = {
  PROD: {
    WEB_URL: 'https://legacy.now.menu',
    API_URL: 'https://legacy.plugd.app:4000',
    SOCKET_URL: 'https://legacy.plugd.app:3000',
    PANEL_URL: 'https://legacy.plugd.app/restaurants',
    BASE_URL: '/'
  },
  STAGE: {
    WEB_URL: 'https://dev.plugd.app/plugdWeb',
    API_URL: 'https://dev.plugd.app:4000',
    SOCKET_URL: 'https://dev.plugd.app:3000',
    PANEL_URL: 'https://dev.plugd.app/restaurants',
    BASE_URL: '/'
  },
  DEV: {
    WEB_URL: 'http://localhost:3000',
    API_URL: 'http://localhost:4000',
    PANEL_URL: 'http://localhost/restaurants',
    SOCKET_URL: 'http://localhost:3001',
    BASE_URL: '/'
  }
};

let CONSTANTS = {
  BASE_URL: `${ENV[process.env.REACT_APP_STAGE].BASE_URL}`,
  PANEL_URL: `${ENV[process.env.REACT_APP_STAGE].PANEL_URL}`,
  API_URL: `${ENV[process.env.REACT_APP_STAGE].API_URL}`,
  WEB_URL: `${ENV[process.env.REACT_APP_STAGE].WEB_URL}`,
  SOCKET_URL: `${ENV[process.env.REACT_APP_STAGE].SOCKET_URL}`,
  APP_TITLE: 'Plugd Admin',
  CACHE_KEY_PREFIX: 'plugd_admin',
  CACHE_KEY_PREFIX_ANALYTICS: 'plugd_admin_location_analytics',
  WEB_PUSH_ID: process.env.REACT_APP_WEB_PUSH_ID,
  CLOUD_IMAGE_URL: process.env.REACT_APP_CLOUD_IMAGE_URL,
  GOOGLE_MAPS_KEY: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  STRIPE_PUBLIC_KEY: 'pk_live_51IpXQ0GW90bZiXDtThPS0d8cbw0IKAcza8P6FMLr29KbgdP8GRi0f6NofL7E8uOCJ194gXJZoI1rRBu7jZGtdvGQ00PPfNsJGn',
  REQUEST_HEADERS: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
  URLS: {
    SESSION_ACTIVITY: {
      GET: '/session',
    },
    LOGIN: '/sessions/create',
    ORDERS: {
      DEFAULT: (locationId) => `/locations/${locationId}/orders`,
      GET_DELIVERY_PROCESSORS: (locationId, orderId) => `/locations/${locationId}/orders/${orderId}/delivery_processors`,
      ADD_RIDERS: (locationId, orderId) => `/locations/${locationId}/orders/${orderId}/addRiders`,
      CANCEL_RIDER_REQUEST: (locationId, orderId) => `/locations/${locationId}/orders/${orderId}/cancel`,
      LIST: '/orders/live',
      GET_RIDER_LOCATION: (locationId, orderId) => `/locations/${locationId}/orders/${orderId}/delivery_details`
    },
    USER_SUBSCRIPTIONS: {
      DEFAULT: '/user_subscriptions'
    },
    CONFIGURATIONS: {
      DEFAULT: '/configurations'
    },
    LOCATIONS: {
      DEFAULT: '/locations',
      ALL_LOCATIONS: '/locations/all',
      CHECK_SLUG: '/locations/checkSlug',
      ENABLE_OUTLETS: '/locations/enableOutlets',
      UPDATE_TIMINGS: (locationId) => `/locations/${locationId}/timings`,
      GET_DELIVERY_PROCESSORS: (locationId) => `/locations/${locationId}/deliveryProcessors`,
      OUTLETS: (locationId) => `/locations/${locationId}/outlets`,
      DELIVERIES: (locationId) => `/locations/${locationId}/deliveries`,
    },
    ORDER_TYPES: {
      DEFAULT: (locationId) => `/locations/${locationId}/order_types`,
    },
    BRANDING: {
      DEFAULT: (locationId) => `/locations/${locationId}/layouts`,
    },
    USERS: {
      DEFAULT: '/users',
      RIDERS: (locationId) => `/users/riders/${locationId}`
    },
    PAYMENT_PROCESSORS: {
      DEFAULT: (locationId) => `/locations/${locationId}/payment_processors`,
    },
    DELIVERY_PROCESSORS: {
      DEFAULT: (locationId) => `/locations/${locationId}/delivery_processors`,
    },
    ANALYTICS: {
      DEFAULT: (locationId, filter) => `/locations/${locationId}/analytics/${filter}`,
    },
    MENU: {
      DEFAULT: (locationId) => `/locations/${locationId}/menus`,
      BULK_UPDATE_PRIORITY: (locationId) => `/locations/${locationId}/menus/bulkUpdate`,
      MENU_UPDATE_OPTIONS: (locationId, menuId) => `/locations/${locationId}/menus/${menuId}/update`,
      GET_DUMMY_ITEM: locationId => `/locations/${locationId}/menus/dummy_item`,
      GET_MODIFIERS: (locationId, menuId) => `/locations/${locationId}/menus/${menuId}/modifiers`,
      GET_ALL_MODIFIERS: (locationId) => `/locations/${locationId}/menus/all/modifiers`,
    },
    MENU_OPTIONS: {
      DEFAULT: (locationId) => `/locations/${locationId}/options`,
      FILE: (locationId) => `/locations/${locationId}/options/file`,
    },
    CATEGORY: {
      DEFAULT: (locationId) => `/locations/${locationId}/categories`,
      GET: (locationId) => `/locations/${locationId}/categories/get`,
      BULK_UPDATE_PRIORITY: (locationId) => `/locations/${locationId}/categories/bulkUpdate`,
    },
    PROMOTION: {
      DEFAULT: (locationId) => `/locations/${locationId}/promotions`,
    },
    SMS_CAMPAIGNS: {
      DEFAULT: (locationId) => `/locations/${locationId}/sms_campaigns`,
      CUSTOMERS: (locationId, filter) => `/locations/${locationId}/sms_campaigns/${filter}`,
      CANCEL_CAMPAIGN: (locationId, campaign_id) => `/locations/${locationId}/sms_campaigns/cancel/${campaign_id}`,
      DELETE_CAMPAIGN: (locationId, campaign_id) => `/locations/${locationId}/sms_campaigns/delete/${campaign_id}`,
    },
    SMS: {
      DEFAULT: '/sms_subcriptions',
      ALL_SUBSCRIPTIONS: '/sms_subcriptions/show',
      SUBSCRIBE: `/sms_subscribe`,
      ALL_PLANS: `/sms_subscribe/plans`,
      SUBSCRIBED: `/sms_subscribe/subscribed`,
      SUBSCRIPTIONS: `/sms_subscribe/subscriptions`,
      CARDS: `/sms_subscribe/cards`,
      SMSPLAN: (planid) => `/sms_subcriptions/${planid}`,
    },
    REVIEWS: {
      DEFAULT: (locationId) => `/admin/${locationId}/reviews`,
      DELETE: (locationId) => `/admin/${locationId}/reviews/delete`,
    },
    RIDERS: {
      DEFAULT: (locationId) => `/admin/${locationId}/riders`,
      HISTORY: (locationId) => `/admin/${locationId}/riders/history`,
      PAYMENTS: (locationId) => `/admin/${locationId}/riders/paymentshistory`,
      DELETE: (locationId) => `/admin/${locationId}/riders/delete`
    },
    ADMIN: {
      DEFAULT: '/admin/users',
      UPDATE_BAN: '/admin/users/ban',
      ALL_REPORTS: '/admin/users/reports',
      DEL_REPORT: '/admin/users/reports/delete',
      ALL_FEEDBACKS: '/admin/feedbacks',
      ALL_COUNTRIES: '/admin/countries',
      TABLES: {
        DEFAULT: (locationId) => `/admin/${locationId}/tables`,
        GET_TABLE: (locationId, tableId) => `/admin/${locationId}/tables/${tableId}`,
        STATUSES: (locationId) => `/admin/${locationId}/tables/status`,
        STATUSESID: (locationId, table) => `/admin/${locationId}/tables/status/${table}`,
        QR: (locationId) => `/admin/${locationId}/tables/qr`,
      },
      CURRENCY: {
        DEFAULT: '/admin/currencies',
        GET_CURRENCY: (currencyId) => `/admin/currencies/${currencyId}`,
        STATUSES: '/admin/currencies/status',
      },
      STAFF: {
        DEFAULT: '/admin/staff',
        GET_STAFF: (staffId) => `/admin/staff/${staffId}`,
        GET_ALL_STAFF: `/admin/staff/all`,
        STATUSES: '/admin/staff/status',
        ROLES: '/admin/staff/roles',
        ASSIGN: '/admin/staff/assign',
        DEL: '/admin/staff/del',
        SEARCH: '/admin/staff/search',
        SEARCH_RIDER: '/admin/staff/searchrider'

      },
      ROLES: {
        DEFAULT: '/admin/roles',
        GET_ROLE: (roleId) => `/admin/roles/${roleId}`,
        STATUSES: '/admin/roles/status',
      }
    }

  },
  ACTIONS: {
    SESSION_ACTIVITY: {
      ALL_SESSIONS_ACTIVITY: 'ALL_SESSIONS_ACTIVITY',
      SESSION_ACTIVITY_FILTER_UPDATE: 'SESSION_ACTIVITY_FILTER_UPDATE',
      FILTER_TYPE: 'FILTER_TYPE',
      CLEAR_FILTERS: 'CLEAR_FILTERS',
      SET_EMPTY_LIST: 'SET_EMPTY_LIST'
    },
    ANALYTICS: {
      OVERALL: 'GET_OVERALL_STATS',
      CUSTOMERS: 'GET_CUSTOMER_STATS',
      ITEMS: 'GET_ITEM_STATS',
    },
    CATEGORIES: {
      CATEGORY_MENU_UPDATE: 'UPDATE_CATEGORY_WITH_MENU',
      CATEGORY_MENU: 'GET_CATEGORY_WITH_MENU',
      GET_CATEGORY: 'GET_CATEGORY',
    },
    PROMOTIONS: {
      ALL_PROMOTIONS: 'GET_ALL_PROMOTIONS',
      GET_PROMOTION: 'GET_PROMOTION',
    },
    SMS_CAMPAIGNS: {
      ALL_CAMPAIGNS: 'GET_ALL_CAMPAIGNS',
      GET_CAMPAIGN: 'GET_CAMPAIGN',
      GET_CAMPAIGN_CUSTOMERS: 'GET_CUSTOMER_STATS',
    },
    SMS: {
      ALL_SMS_PLANS: 'GET_ALL_PLANS',
      SUBSCRIBE_SMS_PLAN: 'SUBSCRIBE_SMS',
      CARDS: 'GET_ALL_CARDS',
      SUBSCRIPTIONS: 'GET_ALL_SUBSCRIPTIONS',
    },
    MENU_OPTIONS: {
      GET_ALL: 'GET_ALL_MENU_OPTIONS',
      SAVE: 'SAVE_MENU_OPTION',
      DELETE: 'DELETE_MENU_OPTION',
      STATUS: 'STATUS_MENU_OPTION'
    },
    MENU: {
      BULK_UPDATE: 'BULK_UPDATE_MENU',
      GET: 'GET_MENU',
      CREATE: 'CREATE_MENU',
      DELETE: 'DELETE_MENU',
      GET_DUMMY_ITEM: 'GET_DUMMY_ITEM',
      GET_ALL_MODIFIERS: 'GET_ALL_MODIFIERS'
    },
    ORDERS: {
      LIST: 'GET_LIVE_ORDERS',
      GET: 'GET_ORDER'
    },
    CONFIGURATIONS: {
      STATUSES: 'GET_STATUSES',
      CURRENCIES: 'GET_CURRENCIES',
      CATEGORIES: 'GET_CATEGORIES',
    },
    LOCATIONS: {
      GET: 'GET_LOCATION',
      GET_OUTLETS: 'GET_OUTLETS',
      GET_DELIVERY_PROCESSORS: 'GET_DELIVERY_PROCESSORS',
      GET_DELIVERIES: 'GET_DELIVERIES',
    },
    USERS: {
      ALL_USERS: 'GET_ALL_USERS',
      GET_RIDERS: 'GET_RIDERS',
      ALL_REPORTS: 'GET_ALL_REPORTS',
    },
    FEEDBACKS: {
      ALL_FEEDBACKS: 'GET_ALL_FEEDBACKS',
    },
    COUNTRIES: {
      ALL_COUNTRIES: 'GET_ALL_COUNTRIES',
    },
    CURRENCIES: {
      ALL_CURRENCIES: 'GET_ALL_CURRENCIES',
    },
    ROLES: {
      ALL_ROLES: 'GET_ALL_ROLES',
    },
    STAFF: {
      ALL_STAFF: 'GET_ALL_STAFF',
      ALL_ROLES: 'GET_ALL_ROLES',
      DEFAULT: 'GET_STAFF'
    },
    REVIEWS: {
      ALL_REVIEWS: 'GET_ALL_REVIEWS',
    },
    RIDERS: {
      ALL_RIDERS_HISTORY: 'GET_ALL_RIDERSHISTORY',
      ALL_RIDERS_PAYMENTS: 'GET_ALL_RIDERSPAYMENTS',
      PAYMENT: 'GET_PAYMENT',
      ALL_RIDERS: 'GET_ALL_RIDERS',
      PAYMENT: 'GET_PAYMENT',
    },
    TABLES: {
      ALL_TABLES: 'GET_ALL_TABLES',
    },
    LOADING: 'LOADING'
  },
  SETTINGS: {
    STATUS_POLLING_INTERVAL: 10000
  },
  RATING_COLORS: {
    0: '#F90403',
    1: '#F90403',
    2: '#FFA400',
    3: '#92D050',
    4: '#00EAD9',
    5: '#00EAD9'
  },
  DATES: {
    FORMAT: {
      DISPLAY: 'Do MMM, YYYY',
      DB_DATE: 'YYYY-MM-DD',
      EVENT_DISPLAY: 'ddd,Do MMM, YYYY'
    }
  },
  TIMES: {
    FORMAT: {
      DISPLAY: 'hh:mm A',
      DB: 'HH:mm:ss',
      PICKER: 'H:i'
    }
  },
  DATE_TIMES: {
    FORMAT: {
      DISPLAY: 'DD MMM hh:mm A',
      DISPLAYYEAR: 'DD MMM YYYY hh:mm A',
      DB: 'YYYY-MM-DD HH:mm:ss',
    }
  },
  VARIABLES: {
    ORDER_TYPES: {
      DELIVERY: 'delivery',
      DINE_IN: 'dine_in',
      PICK_UP: 'pick_up',
      CURBSIDE_PICKUP: 'curbside_pickup'
    },
    ORDERS: {
      DEFAULT_PAGE_LENGTH: 100,
      ASAP_LABEL: 'ASAP',
      STATUSES: {
        PLACED: 'Placed',
        SEEN: 'Seen',
        BEING_PREPARED: 'Being Prepared',
        READY: 'Ready For Pickup',
        ON_THE_WAY: 'On The Way',
        COMPLETE: 'Complete',
        CANCELLED: 'Cancelled'
      },
      REASONS_TO_CANCEL: [
        'Customer changed their mind',
        'Items were not available',
        'Outside delivery zone',
        'Rider unavailable',
        'Duplicate order by customer',
        'Test order'
      ],
      PAYMENT_STATUSES: {
        PAID: 'paid'
      },
      PAYMENT_METHODS: {
        scheme: 'Card',
        cash: 'Cash',
        default: 'Cash'
      },
      ORDER_TYPE_STATUSES: {
        DELIVERY: [11, 12, 13, 22, 15, 19],
        DINE_IN: [11, 12, 13, 14, 15, 19],
        PICK_UP: [11, 12, 13, 21, 15, 19],
        CURBSIDE_PICKUP: [11, 12, 13, 21, 15, 19]
      },
      ORDER_STATUS_IDS: {
        BEING_PREPARED: 13,
        CANCELLED: 19,
      },
      WARNINGS: {
        UN_PAID: 'unpaid',
        CANCELLED: 'cancel'
      },
      EVENTS: {
        NEW: 'order_recieved',
        UPDATE: 'order_update',
        BILL_REQUESTED: 'bill_requested'
      },
      DELIVERY_ACTIONS: {
        NOT_ASSIGNED: 0,
        BROADCAST: 23,
        SPECIFIC_RIDERS: 24,
        THIRD_PARTY: 25,
        ACCEPTED: 26,
        PICKED_UP: 27,
        ON_THE_WAY: 28,
        CANCELLED: 30,
        COMPLETE: 29
      },
      DELIVERY_ACTIONS_TO_TEXT: {
        0: 'Not Assigned',
        23: 'Broadcasted to riders',
        24: 'Specific riders requested',
        25: 'PandaGo requested',
        26: 'Accepted',
        27: 'Pickep Up',
        28: 'On the way	to customer',
        30: 'Cancelled',
        29: 'Delivered'
      },
      DELIVERY_ACTION_TEXT: {
        '0_action': 'Request Riders',
        0: 'Request Riders',
        23: 'Riders requested',
        24: 'Riders requested',
        25: 'Rider requested from PandaGo',
        26: 'Rider on way to Restaurant',
        27: 'Picked Up by Rider',
        28: 'Rider on way to Customer',
        30: 'Request Riders',
        29: 'Completed by Rider',
      },
      DELIVERY_ACTION_TEXT_COLORS: {
        '0_action': 'danger',
        0: 'danger',
        23: 'warning',
        24: 'warning',
        25: 'warning',
        26: 'primary',
        27: 'primary',
        28: 'primary',
        30: 'danger',
        29: 'success',
      },
    },
    MENUS: {
      OPTIONS: {
        DISPLAY_TYPES: {
          RADIO: 'radio',
          SELECT: 'select',
          CHECKBOX: 'checkbox'
        }
      }
    },
    PAYMENTS: {
      DEFAULT: 'CyberSource'
    },
    DELIVERY: {
      DEFAULT: 1, //Pandago
    },
    ANALYTICS: {
      OVERALL_STATS_CHART: {
        TOTAL_SALES: 'Total Sales',
        COMPLETED_ORDERS: 'Completed Orders',
        CANCELLED_ORDERS: 'Cancelled Orders',
      }
    },
    PROMOTIONS: {
      TYPE: {
        AUTOMATIC: 1,
        MANUAL: 0,
      },
      APPLICABLE_TIME_TYPE: {
        ALL_TIME: 0,
        EVERY_DAY_SPECIFIC_TIMES: 3,
        SPECIFIC_DATES: 1,
        WEEK_DAYS: 2,
      },
      DISCOUNTS: {
        TYPE: {
          BASKET: 0,
          ALL_ITEMS: 1,
          SPECIFIC_ITEMS: 2
        },
        AMOUNT_TYPE: {
          FIXED: 0,
          PERCENTAGE: 1,
        },
        REWARD_TYPE: {
          FIXED: 0,
          PERCENTAGE: 1,
        },
        ITEM_TYPES: {
          CATEGORY: 0,
          ITEM: 1,
        }
      }
    },
    LOCATIONS: {
      OPENING_TYPE: {
        ALL_TIME: 0,
        DAILY: 1,
        FLEXIBLE: 2,
      },
      DELIVERY_HOURS: {
        SAME_AS_OPENING: 0,
        ALL_TIME: 1,
        DAILY: 2,
        FLEXIBLE: 3,
      },
      PICKUP_HOURS: {
        SAME_AS_OPENING: 0,
        ALL_TIME: 1,
        DAILY: 2,
        FLEXIBLE: 3,
      }
    }
  },
  FIREBASE_CONFIG: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  },
  FILTERS: {
    ORDERS: [
      { name: 'Live Orders', key: 'live_orders', default: true },
      { name: 'Closed Orders', key: 'closed_orders', default: false },
      { name: 'All Orders', key: 'all', default: false }
    ]
  },
  SORTS: {
    ORDERS: [
      { name: 'Most Recent First', key: 'DESC', default: true },
      { name: 'Oldest First', key: 'ASC', default: false }
    ]
  },
  RANDOM: {
    MIN: 1000000,
    MAX: 99999999
  },
  EVENT_TIMINGS: {
    opening_type_value: 0,
    opening_type: '24/7',
    daily_days: ['0', '1', '2', '3', '4', '5', '6'],
    daily_hours: { open: '00:00 AM', close: '11:59 PM' },
    flexible_hours: [
      {
        "day": "0",
        "open": "12:00 AM",
        "close": "11:59 PM",
        "status": true
      },
      {
        "day": "1",
        "open": "12:00 PM",
        "close": "01:59 PM",
        "status": true
      },
      {
        "day": "2",
        "open": "10:45 AM",
        "close": "23:59:00",
        "status": true
      },
      {
        "day": "3",
        "open": "12:00 AM",
        "close": "11:59 PM",
        "status": true
      },
      {
        "day": "4",
        "open": "12:00 AM",
        "close": "11:59 PM",
        "status": true
      },
      {
        "day": "5",
        "open": "12:00 AM",
        "close": "11:59 PM",
        "status": true
      },
      {
        "day": "6",
        "open": "12:00 AM",
        "close": "11:59 PM",
        "status": true
      }
    ]
  },
  DUMMY_WORKING_HOURS: [
    {
      "id": 0,
      "weekday": 0,
      "location_id": 0,
      "type": "opening",
      "status": true,
      "opening_time": "00:00:00",
      "closing_time": "23:59:00"
    },
    {
      "id": 0,
      "weekday": 1,
      "location_id": 0,
      "type": "opening",
      "status": true,
      "opening_time": "00:00:00",
      "closing_time": "23:59:00"
    },
    {
      "id": 0,
      "weekday": 2,
      "location_id": 0,
      "type": "opening",
      "status": true,
      "opening_time": "00:00:00",
      "closing_time": "23:59:00"
    },
    {
      "id": 0,
      "weekday": 3,
      "location_id": 0,
      "type": "opening",
      "status": true,
      "opening_time": "00:00:00",
      "closing_time": "23:59:00"
    },
    {
      "id": 0,
      "weekday": 4,
      "location_id": 0,
      "type": "opening",
      "status": true,
      "opening_time": "00:00:00",
      "closing_time": "23:59:00"
    },
    {
      "id": 0,
      "weekday": 5,
      "location_id": 0,
      "type": "opening",
      "status": true,
      "opening_time": "00:00:00",
      "closing_time": "23:59:00"
    },
    {
      "id": 0,
      "weekday": 6,
      "location_id": 0,
      "type": "opening",
      "status": true,
      "opening_time": "00:00:00",
      "closing_time": "23:59:00"
    },
    {
      "id": 5460,
      "weekday": 0,
      "location_id": 0,
      "type": "delivery",
      "status": true,
      "opening_time": "00:00:00",
      "closing_time": "23:59:00"
    },
    {
      "id": 0,
      "weekday": 1,
      "location_id": 0,
      "type": "delivery",
      "status": true,
      "opening_time": "00:00:00",
      "closing_time": "23:59:00"
    },
    {
      "id": 0,
      "weekday": 2,
      "location_id": 0,
      "type": "delivery",
      "status": true,
      "opening_time": "00:00:00",
      "closing_time": "23:59:00"
    },
    {
      "id": 0,
      "weekday": 3,
      "location_id": 0,
      "type": "delivery",
      "status": true,
      "opening_time": "00:00:00",
      "closing_time": "23:59:00"
    },
    {
      "id": 0,
      "weekday": 4,
      "location_id": 0,
      "type": "delivery",
      "status": true,
      "opening_time": "00:00:00",
      "closing_time": "23:59:00"
    },
    {
      "id": 0,
      "weekday": 5,
      "location_id": 0,
      "type": "delivery",
      "status": true,
      "opening_time": "00:00:00",
      "closing_time": "23:59:00"
    },
    {
      "id": 0,
      "weekday": 6,
      "location_id": 0,
      "type": "delivery",
      "status": true,
      "opening_time": "00:00:00",
      "closing_time": "23:59:00"
    },
    {
      "id": 0,
      "weekday": 0,
      "location_id": 0,
      "type": "collection",
      "status": true,
      "opening_time": "00:00:00",
      "closing_time": "23:59:00"
    },
    {
      "id": 0,
      "weekday": 1,
      "location_id": 0,
      "type": "collection",
      "status": true,
      "opening_time": "00:00:00",
      "closing_time": "23:59:00"
    },
    {
      "id": 0,
      "weekday": 2,
      "location_id": 0,
      "type": "collection",
      "status": true,
      "opening_time": "00:00:00",
      "closing_time": "23:59:00"
    },
    {
      "id": 0,
      "weekday": 3,
      "location_id": 0,
      "type": "collection",
      "status": true,
      "opening_time": "00:00:00",
      "closing_time": "23:59:00"
    },
    {
      "id": 0,
      "weekday": 4,
      "location_id": 0,
      "type": "collection",
      "status": true,
      "opening_time": "00:00:00",
      "closing_time": "23:59:00"
    },
    {
      "id": 0,
      "weekday": 5,
      "location_id": 0,
      "type": "collection",
      "status": true,
      "opening_time": "00:00:00",
      "closing_time": "23:59:00"
    },
    {
      "id": 0,
      "weekday": 6,
      "location_id": 0,
      "type": "collection",
      "status": true,
      "opening_time": "00:00:00",
      "closing_time": "23:59:00"
    }
  ],
  DUMMY_ORDER_TYPES: [
    {
      "order_type": "delivery",
      "label": "Delivery",
      "minimum_order": 0,
      "location_id": 0,
      "pre_payment": false,
      "active": true
    },
    {
      "order_type": "dine_in",
      "label": "Dine In",
      "minimum_order": 0,
      "location_id": 0,
      "pre_payment": false,
      "active": false
    },
    {
      "order_type": "pick_up",
      "label": "Pick up",
      "minimum_order": 0,
      "location_id": 0,
      "pre_payment": false,
      "active": false
    },
    {
      "order_type": "curbside_pickup",
      "label": "CURBSIDE",
      "minimum_order": 0,
      "location_id": 0,
      "pre_payment": false,
      "active": false
    }
  ],
  DUMMY_VALUES: {
    "auto_lat_lng": "1",
    "opening_hours": {
      "opening_type": "24/7",
      "daily_days": [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6"
      ],
      "daily_hours": {
        "open": "07:30 AM",
        "close": "11:00 PM"
      },
      "flexible_hours": [
        {
          "day": "0",
          "open": "12:00 AM",
          "close": "11:59 PM",
          "status": "1"
        },
        {
          "day": "1",
          "open": "12:00 AM",
          "close": "11:59 PM",
          "status": "1"
        },
        {
          "day": "2",
          "open": "12:00 PM",
          "close": "2:00 AM",
          "status": "1"
        },
        {
          "day": "3",
          "open": "3:15 PM",
          "close": "2:00 AM",
          "status": "1"
        },
        {
          "day": "4",
          "open": "3:00 PM",
          "close": "2:00 AM",
          "status": "1"
        },
        {
          "day": "5",
          "open": "12:00 AM",
          "close": "11:59 PM",
          "status": "1"
        },
        {
          "day": "6",
          "open": "12:00 AM",
          "close": "11:59 PM",
          "status": "1"
        }
      ],
      "delivery_type": "0",
      "delivery_days": [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6"
      ],
      "delivery_hours": {
        "open": "12:00 AM",
        "close": "11:59 PM"
      },
      "collection_type": "0",
      "collection_days": [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6"
      ],
      "collection_hours": {
        "open": "12:00 AM",
        "close": "11:59 PM"
      }
    },
    "future_orders": "0",
    "future_order_days": {
      "delivery": "5",
      "collection": "5"
    },
    "delivery_areas": {
      "0": {
        "name": "Area 0",
        "type": "circle",
        "minimum_order": "",
        "status": true,
        "default_charges": "",
        "isEdit": false,
        "charge": {
          "1": {
            "amount": 0,
            "condition": "all",
            "label": "on all orders",
            "total": 0
          }
        },
        "vertices": "[{\"lat\":31.52534220698884,\"lng\":74.35343648699197},{\"lat\":31.512765793011162,\"lng\":74.35343648699197},{\"lat\":31.512765793011162,\"lng\":74.33868351300802},{\"lat\":31.52534220698884,\"lng\":74.33868351300802}]",
        "circle": "[{\"center\":{\"lat\":31.518918282526524,\"lng\":74.34486375936821}},{\"radius\":4000}]"
      }
    },
    "gallery": {
      "title": "",
      "description": ""
    },
    "currency_id": ""
  },
  DUMMY_OPTIONS: {
    "auto_lat_lng": "1",
    "opening_hours": {
      "opening_type": "24/7",
      "daily_days": [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6"
      ],
      "daily_hours": {
        "open": "09:00 PM",
        "close": "11:59 PM"
      },
      "flexible_hours": [
        {
          "day": "0",
          "open": "12:00 AM",
          "close": "11:59 PM",
          "status": true
        },
        {
          "day": "1",
          "open": "12:00 PM",
          "close": "01:59 PM",
          "status": true
        },
        {
          "day": "2",
          "open": "10:45 AM",
          "close": "23:59:00",
          "status": false
        },
        {
          "day": "3",
          "open": "12:00 AM",
          "close": "11:59 PM",
          "status": false
        },
        {
          "day": "4",
          "open": "12:00 AM",
          "close": "11:59 PM",
          "status": true
        },
        {
          "day": "5",
          "open": "12:00 AM",
          "close": "11:59 PM",
          "status": true
        },
        {
          "day": "6",
          "open": "21:00:00",
          "close": "23:59:00",
          "status": true
        }
      ],
      "delivery_type": "0",
      "delivery_days": [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6"
      ],
      "delivery_hours": {
        "open": "12:00 AM",
        "close": "11:59 PM"
      },
      "collection_type": "0",
      "collection_days": [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6"
      ],
      "collection_hours": {
        "open": "04:00 PM",
        "close": "06:59 PM"
      },
      "events": [],
      "event_holidays": [],
      "delivery_flexible_hours": [
        {
          "day": "0",
          "open": "12:00 AM",
          "close": "11:59 PM",
          "status": true
        },
        {
          "day": "1",
          "open": "12:00 AM",
          "close": "11:59 PM",
          "status": false
        },
        {
          "day": "2",
          "open": "12:00 AM",
          "close": "11:59 PM",
          "status": false
        },
        {
          "day": "3",
          "open": "12:00 AM",
          "close": "11:59 PM",
          "status": false
        },
        {
          "day": "4",
          "open": "12:00 AM",
          "close": "11:59 PM",
          "status": false
        },
        {
          "day": "5",
          "open": "12:00 AM",
          "close": "11:59 PM",
          "status": false
        },
        {
          "day": "6",
          "open": "10:00:00",
          "close": "23:59:00",
          "status": true
        }
      ],
      "pickup_flexible_hours": [
        {
          "day": "0",
          "open": "12:00 AM",
          "close": "11:59 PM",
          "status": true
        },
        {
          "day": "1",
          "open": "12:00 AM",
          "close": "11:59 PM",
          "status": true
        },
        {
          "day": "2",
          "open": "12:00 AM",
          "close": "11:59 PM",
          "status": true
        },
        {
          "day": "3",
          "open": "12:00 AM",
          "close": "11:59 PM",
          "status": true
        },
        {
          "day": "4",
          "open": "12:00 AM",
          "close": "11:59 PM",
          "status": true
        },
        {
          "day": "5",
          "open": "12:00 AM",
          "close": "11:59 PM",
          "status": true
        },
        {
          "day": "6",
          "open": "12:00 AM",
          "close": "11:59 PM",
          "status": true
        }
      ]
    },
    "future_orders": "0",
    "future_order_days": {
      "delivery": "5",
      "collection": "5"
    },
    "delivery_areas": {
      "1": {
        "name": "Area 1",
        "type": "circle",
        "minimum_order": "",
        "status": true,
        "default_charges": "",
        "isEdit": true,
        "charge": {
          "1": {
            "amount": "",
            "condition": "all",
            "label": "on all orders",
            "total": 0
          }
        },
        "vertices": "[{\"lat\":31.469424206988837,\"lng\":74.42197507823047},{\"lat\":31.45684779301116,\"lng\":74.42197507823047},{\"lat\":31.45684779301116,\"lng\":74.40723092176952},{\"lat\":31.469424206988837,\"lng\":74.40723092176952}]",
        "circle": "[{\"center\":{\"lat\":31.52339807886401,\"lng\":74.34921320795493}},{\"radius\":11000}]"
      }
    },
    "gallery": {
      "title": "",
      "description": ""
    },
    "currency_id": "1"
  }
};

export default CONSTANTS;
