import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Collapse, FormGroup, Input, Label, Row } from 'reactstrap';

import { Trash, Plus } from 'react-feather'
import { Table } from 'reactstrap'

import Radio from "../@vuexy/radio/RadioVuexy";
import MapComponent from './deliveryAreas/MapComponent';
import MapsHelper from '../../helpers/MapsHelper';


import '../../assets/scss/plugins/forms/switch/react-toggle.scss';
import 'react-toggle/style.css';
import Toggle from "react-toggle";

const defaultChargeObjall = Object.freeze({
	amount: 0,
	condition: 'all',
	label: 'on all orders',
	total: 0,
});

const defaultChargeObj = Object.freeze({
	amount: 0,
	condition: 'above',
	label: 'on orders above',
	total: 0,
});

const dummyOptions = {
	'auto_lat_lng': '',
	'currency_id': '',
	'delivery_areas': {},
	'future_order_days': {},
	'future_orders': '0',
	'gallery': { title: '', description: '' },
	'opening_hours': {}
}

class DeliveryAreas extends Component {

	state = { zoneCard: { 'isCollapsed': false }, isOpenCollapsed: {} };

	zoneCardToggle() {
		let zoneCard = this.state.zoneCard;
		zoneCard.isCollapsed = !zoneCard.isCollapsed;
		this.setState({ zoneCard });
	}

	componentDidMount() {
		const { props: {
			location
		} } = this;

		let options = location.options ? location.options : dummyOptions;
		this.setState({ options: options, locationOnMap: { lat: location.latitude, lng: location.longitude } })
	}

	async onAreaFieldChange(value, field, index) {
		let options = JSON.parse(JSON.stringify(this.state.options));
		if (field === 'radius') {
			if (Number.parseFloat(value) < 0) {
				return;
			}
			const circle = JSON.parse(options.delivery_areas[index].circle);
			circle[1].radius = Number.parseFloat(value) * 1000;
			options.delivery_areas[index].circle = JSON.stringify(circle);
		} else {
			options.delivery_areas[index][field] = value;
		}
		await this.setState({ options });
		if (field === 'default_charges') {
			await this.onChargeChange(value, 'amount', Object.keys(options.delivery_areas[index].charge)[0], index)
		}
	}

	async onDefaultChargeChange(key, amount) {
		const options = JSON.parse(JSON.stringify(this.state.options));
		let charges = {};
		const length = Object.keys(charges).length + 1;
		charges[length] = JSON.parse(JSON.stringify(defaultChargeObjall));
		charges[length].amount = amount;
		options.delivery_areas[key].charge = charges;
		await this.setState({ options });
	}

	async onChargeChange(value, field, chargeIndex, deliveryAreaIndex) {
		const options = JSON.parse(JSON.stringify(this.state.options));
		options.delivery_areas[deliveryAreaIndex].charge[chargeIndex][field] = value;
		await this.setState({ options });
	}

	onChargeConditionDelete(key, dKey) {
		const options = JSON.parse(JSON.stringify(this.state.options));
		delete options.delivery_areas[dKey].charge[key];
		this.setState({ options });
	}

	async addNewCharge(dKey) {
		const options = JSON.parse(JSON.stringify(this.state.options));
		let charges = options.delivery_areas[dKey].charge;
		if (Array.isArray(charges)) {
			charges = {};
		}
		const length = Object.keys(charges).length + 1;
		charges[length] = JSON.parse(JSON.stringify(defaultChargeObj));
		options.delivery_areas[dKey].charge = charges;
		await this.setState({ options });
	}

	async toggleCollapse(dKey) {
		let isOpenCollapsed = JSON.parse(JSON.stringify(this.state.isOpenCollapsed));
		let key = Object.keys(isOpenCollapsed);
		if (isOpenCollapsed[key]) {
			isOpenCollapsed[key] = !!!isOpenCollapsed[key];
			let options = this.state.options;
			options.delivery_areas[key].isEdit = isOpenCollapsed[key];
			await this.setState({ isOpenCollapsed: {} });
			options = this.state.options;
			if (options.delivery_areas[dKey].isEdit) {
				isOpenCollapsed[dKey] = false;
				options.delivery_areas[dKey].isEdit = false
			}
			await this.setState({ isOpenCollapsed, options })
		} else {
			isOpenCollapsed = JSON.parse(JSON.stringify(this.state.isOpenCollapsed));
			isOpenCollapsed[dKey] = !!!isOpenCollapsed[dKey];
			const options = this.state.options;
			options.delivery_areas[dKey].isEdit = isOpenCollapsed[dKey];
			await this.setState({ isOpenCollapsed, options });
		}
	}

	deleteDeliveryArea(key) {
		const options = JSON.parse(JSON.stringify(this.state.options));
		delete options.delivery_areas[key];
		this.setState({ options });
	}

	getChargesConditionTable(dKey) {
		const option = this.state.options.delivery_areas[dKey];
		if (!option.charge) {
			return null;
		}

		const keys = Object.keys(option.charge);
		return ([
			<Table borderless responsive>
				<thead>
					<tr>
						<th>Charge</th>
						<th>Condition</th>
						<th></th>
						<th>Total</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
					{keys.map(key => {
						const charge = option.charge[key];
						if (charge.condition === 'all')
							return '';
						return <tr>
							<td colspan='1'>
								<Input
									id='amount'
									name={`amount`}
									placeholder={'Amount'}
									type='number'
									value={charge.amount}
									onChange={(e) => this.onChargeChange(e.target.value, 'amount', key, dKey)}
								/>
							</td>
							<td colspan='2' style={{ padding: '0px' }}>

								<Input
									id='condition'
									name={`condition`}
									placeholder={'Condition'}
									type='text'
									value={charge.condition === 'all' ? 'on all orders' : 'on above orders'}
									onChange={(e) => this.onChargeChange(e.target.value, 'condition', key, dKey)}
									disabled
									style={{ padding: '0px' }}
								/>
							</td>
							<td colspan='1' style={{ padding: '10px' }}>
								<Input
									id='total'
									name={`total`}
									placeholder={'Total'}
									type='number'
									value={charge.total}
									disabled={charge.condition === 'all'}
									onChange={(e) => this.onChargeChange(e.target.value, 'total', key, dKey)}
									style={{ padding: '0px' }}
								/>
							</td>
							<td colspan='1'>
								<Trash className='mr-50' size={15} onClick={this.onChargeConditionDelete.bind(this, key, dKey)} />
							</td>
						</tr>
					})}
				</tbody>
			</Table>,
		]
		)
	}

	async addNewArea() {
		if (this.mapsHelper) {
			const length = this.state.options.delivery_areas ? Object.keys(this.state.options.delivery_areas).length : 0;
			const area = this.mapsHelper.createDeliveryArea(length);
			const vertices = area.getPath();
			const outputVertices = [];
			for (let i = 0; i < vertices.getLength(); i++) {
				const xy = vertices.getAt(i);
				outputVertices.push({
					lat: xy.lat(),
					lng: xy.lng()
				});
			}
			const outputCircle = [];
			outputCircle.push({ center: { lat: +this.state.locationOnMap.lat, lng: +this.state.locationOnMap.lng } });
			outputCircle.push({ radius: 1000 * (length + 1) / 2 });
			const newArea = {
				name: `Area ${length}`,
				type: 'circle',
				minimum_order: '',
				status: true,
				default_charges: '',
				isEdit: false,
				charge: {},
				vertices: JSON.stringify(outputVertices),
				circle: JSON.stringify(outputCircle),
			}
			const options = JSON.parse(JSON.stringify(this.state.options));
			let areas = Object.keys(this.state.options.delivery_areas).map((key) => this.state.options.delivery_areas[key]);
			if (areas) {
				areas.push(newArea);
				options.delivery_areas = Object.assign({}, areas);
			} else {
				options.delivery_areas = [newArea];
			}
			await this.setState({ options });
			for (let i = 0; i < areas.length; i++) {
				if (this.state.isOpenCollapsed[i]) {
					await this.toggleCollapse(i);
				}
			}
			await this.toggleCollapse(areas.length - 1);
			this.onDefaultChargeChange(length, newArea.default_charges);

		}
	}

	saveMapsHelperReference(mapsHelper) {
		this.mapsHelper = mapsHelper;
	}

	getRadius(d) {
		const circle = JSON.parse(d.circle);
		const rad = (circle[1].radius / 1000);
		if (rad !== Number.parseInt(rad) && rad.toString().split('.')[1].length > 2) {
			return rad.toFixed(2);
		}
		return rad;
	}

	renderZoneCompensationForm(options) {
		if (options) {
			let deliveryAreas = options.delivery_areas ? options.delivery_areas : {};

			return (
				<Card className={'card-action card-shown'}>
					<CardHeader>
						<CardTitle>Saved Delivery Areas</CardTitle>
						<Button
							color='primary'
							type='submit'
							className='mr-1 mb-1'
							onClick={() => {
								this.props.updateAreas(this.state.options);
							}}
						>
							Save
						</Button>
					</CardHeader>
					<CardBody>
						<Row>
							<Col md='5'>
								{Object.keys(deliveryAreas).map((key) => {
									let deliveryArea = deliveryAreas[key];
									return (
										<Card id='header' className="card-border-delivery" >
											<CardHeader id='header' onClick={(e) => {
												if (e.target.id === 'header') {
													this.toggleCollapse(key)
												}
											}}>
												<div className="w-75 h-75" id='header'>
													<CardTitle id='header'>{deliveryArea.name}
													</CardTitle>
												</div>
												<div className='row row d-flex flex-row justify-content-center align-items-center'>
													<div className='mr-1' style={{ height: '10px', width: '10px', backgroundColor: MapsHelper.getColorsByIndex(key) }}></div>

													<Trash
														className='collapse-icon mr-50'
														size={15}
														onClick={this.deleteDeliveryArea.bind(this, key)}
													/>
													<Toggle
														name={'active'}
														data-id={0}
														id='toggle'
														defaultChecked={deliveryArea.status}
														className='switch-danger'
														onChange={() => this.onAreaFieldChange(!deliveryArea.status, 'status', key)}
													/>

												</div>
											</CardHeader>
											<Collapse isOpen={this.state.isOpenCollapsed[key]}>
												<div className='m-1'>
													<FormGroup row>
														<Col md='12' className={'form-group'}>
															<Label for="type">Type:</Label>
															<FormGroup>
																<div id='type' onChange={(e) => this.onAreaFieldChange(e.target.value, 'type', key)}>
																	<div className="d-inline-block mr-1">
																		<Radio label="Shape" name={`type-${key}`} value={'shape'} checked={deliveryArea.type === 'shape'} />
																	</div>
																	<div className="d-inline-block mr-1">
																		<Radio label="Circle" name={`type-${key}`} value={'circle'} checked={deliveryArea.type === 'circle'} />
																	</div>
																</div>
															</FormGroup>
														</Col>
														{deliveryArea.type === 'circle' && <Col md='12' className={'form-group'}>
															<Label for="radius">Radius in KMs (Approx.)</Label>
															<Input
																id='radius'
																name={`radius`}
																placeholder={'Radius in KMs'}
																type='number'
																value={this.getRadius(deliveryArea)}
																onChange={(e) => this.onAreaFieldChange(e.target.value, 'radius', key)}
															/>
														</Col>}
														<Col md='12' className={'form-group'}>
															<Label for="name">Name</Label>
															<Input
																id='name'
																name={`name`}
																placeholder={'Name'}
																type='text'
																value={deliveryArea.name}
																onChange={(e) => this.onAreaFieldChange(e.target.value, 'name', key)}
															/>
														</Col>
														<Col md='12' className={'form-group'}>
															<Row>
																<Col md='6'>
																	<FormGroup className='form-group'>
																		<Label for="minimum_order">Minimum Order</Label>
																		<Input
																			id='minimum_order'
																			name={`minimum_order`}
																			placeholder={'Minimum Order'}
																			type='number'
																			value={deliveryArea.minimum_order}
																			onChange={(e) => this.onAreaFieldChange(e.target.value, 'minimum_order', key)}
																		/>
																	</FormGroup>
																</Col>
																<Col md='6'>
																	<FormGroup className='form-group'>
																		<Label for="defualt_charges">Default Charges</Label>
																		<Input
																			id='defualt_charges'
																			name={`defualt_charges`}
																			placeholder={'Defualt Charges'}
																			type='number'
																			value={deliveryArea.default_charges}
																			onChange={(e) => this.onAreaFieldChange(e.target.value, 'default_charges', key)}
																		/>
																	</FormGroup>
																</Col>
															</Row>
														</Col>
														<Col md='12'>
															{Object.keys(deliveryArea.charge).length > 1 ? this.getChargesConditionTable(key) : ''}
															<Button outline className="font-weight-bold custom-padding disabled-2-border mb-1" color={"secondary"} size="md" onClick={() => this.addNewCharge(key)}>
																<Plus className="mx-1" size={20} /> <span className="ml-1 mr-3">Add New Condition</span>
															</Button>
														</Col>
													</FormGroup>
												</div>
											</Collapse>
										</Card>

									);
								})
								}
								<Button outline className="font-weight-bold custom-padding disabled-2-border mb-1" color={"secondary"} size="md" onClick={() => this.addNewArea()}>
									<Plus className="mx-2" size={20} /> <span className="ml-1 mr-5">Add New Area</span>
								</Button>
							</Col>
							<Col md='7'>
								<MapComponent
									location={this.state.locationOnMap}
									zones={deliveryAreas}
									updateMapsHelperReference={this.saveMapsHelperReference.bind(this)}
									updateDeliveryArea={this.onAreaFieldChange.bind(this)}
								/>
							</Col>
						</Row>
					</CardBody>
				</Card>
			);
		}
		return null;
	}

	render() {
		return (
			<>
				{this.renderZoneCompensationForm(this.state.options)}
			</>
		);
	}
}

export default DeliveryAreas;
