import React from "react";
import { AvInput } from "availity-reactstrap-validation";
import {
  Label,
  FormGroup,
  Button,
  Input,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import SpinnerComponent from "../../@vuexy/spinner/Fallback-spinner";
import StaffService from "../../../services/StaffService";

import Radio from "../../@vuexy/radio/RadioVuexy";

import _ from "lodash";
import ViewHelper from "../../../helpers/ViewHelper";

import "../../../assets/scss/plugins/forms/react-select/_react-select.scss";
import { X } from "react-feather";
import { ClipLoader } from "react-spinners";

class SettlementCashModal extends React.Component {
  state = {
    loading: false,
    saving: false,
    users: [],
    isOpen: false,
    isAdmin: false,
    error: "",
    settlement_type: 0,
    amount: 0,
    rider_id: 0,
    rider_name: '',
  };

  async componentDidMount() {
    await this.setState({ saving: false });
  }

  onChange(selected) {
    if (selected) {
      if (this.props.onSelect) this.props.onSelect(selected.location);
      else this.onSelect(selected.location);
    }
  }

  onBlur() {
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  }

  async save() {
    await this.setState({ saving: true });
    await this.props.savePayment(this.state);
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ saving: false, error: this.props.error, amount: this.props.cashInHand, rider_id: this.props.rider_id, rider_name: this.props.rider_name });
    }
  }
  async updateItem(key, val) {
    if (key === 'settlement_type' && val == 0) {
      await this.setState({ amount: this.props.cashInHand });
    }
    if (key === 'settlement_type' && val == 1) {
      await this.setState({ amount: this.props.earnings });
    }
    await this.setState({ [key]: val });
  }

  render() {
    const {
      props: { isOpen, toggleModal },
    } = this;

    const {
      state: { loading, error, saving, settlement_type, amount, rider_id, rider_name },
    } = this;

    if (loading) return <SpinnerComponent />;
    return (
      <React.Fragment>
        <Modal
          isOpen={isOpen}
          toggle={toggleModal}
          className="modal-dialog-centered d-sm-margin-0 p-0"
        >
          <div className="modal-header display-flex full-width flex-space-between">
            <h5 className="modal-title">Settle Payments</h5>
            <X size={20} onClick={toggleModal} />
          </div>
          <ModalBody>
            {error ? <span className="danger">{error}</span> : null}
            <FormGroup>
              <Label>RiderID: {rider_id}</Label><br></br>
              <Label>Name: {rider_name}</Label>
            </FormGroup>
            <FormGroup>
              <Label for="status">Settlement Types:</Label>
              <div
                id="status"
                onChange={(e) => this.updateItem('settlement_type', parseInt(e.target.value))}
              >
                <div className="d-inline-block mr-1">
                  <Radio
                    label="Collect Cash in hand"
                    checked={settlement_type === 0}
                    name="settlement_type"
                    value={0}
                  />
                </div>
                <div className="d-inline-block mr-1">
                  <Radio
                    label="Settle Earnings"
                    name="settlement_type"
                    checked={settlement_type === 1}
                    value={1}
                  />
                </div>
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="email">Amount Collected:</Label>
              <Input
                type="input"
                id="name"
                value={amount}
                placeholder="Enter amount"
                onChange={(e) => this.updateItem("amount", e.target.value)}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <div className="d-none d-sm-flex flex-space-between full-width">
              <div>
                <Button
                  color="dark"
                  onClick={() => {
                    toggleModal();
                  }}
                >
                  Cancel
                </Button>{" "}
                <Button color="primary" onClick={() => this.save()}>
                  {saving ? <ClipLoader size={15} /> : "Save"}
                </Button>
              </div>
            </div>

            <p className="d-none d-sm-inline col-12 align-text-right font-small-1 pr-0">
              Hit Ctrl + Enter to Save
            </p>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default SettlementCashModal;
