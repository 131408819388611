import React from 'react';
import { AvForm, AvInput, AvGroup, AvFeedback, AvField } from 'availity-reactstrap-validation';
import { Label, Row, Col, Card, CardBody, CardHeader, Button, ButtonGroup } from 'reactstrap';
import LocationService from '../../services/LocationService';
import CONSTANTS from '../../constants';
import { connect } from 'react-redux';
import SpinnerComponent from '../@vuexy/spinner/Fallback-spinner';
import StaffService from '../../services/StaffService';
import RolesService from '../../services/RolesService';
import SelectLocation from "../shared/SelectLocation";
import LocationSelectHeader from '../shared/LocationSelectHeader';
import ConfirmationModal from '../shared/forms/ConfirmationModal';
import { getAllLocations } from '../../redux/actions/locations';
import { getStaffByEmail } from '../../redux/actions/staff';
import { createRiderPayment } from '../../redux/actions/riders';
import Toggle from 'react-toggle';
import Select from 'react-select'
import AsyncSelect from 'react-select/async';
import _ from 'lodash';
import ViewHelper from "../../helpers/ViewHelper";

import "../../assets/scss/plugins/forms/react-select/_react-select.scss"

let roleOptions = [];

class PaymentForm extends React.Component {
	state = {
		activeStep: 0,
		isOpenSelect: false,

		location: {
			loc_id: 0,
			name: 'All Venues',
			Currency: {
				currency_code: 'PKR'
			},
			decimal_places: 2,
		},
		loading: false,
		saving: false,
		errorSaving: false,
		errorWallet: false,
		users: [],
		isOpen: false,
		isAdmin: false,
		roles: [],
		searchTerm: '',
		searchEmailTerm: '',
		location: null,
		selectLocation: false,
		amount_received: false,
		rider_id: 0,
		errorDialog: {
			show: false,
			rejectCallback: () => { },
			title: 'App Reports',
			header: 'App Reports'
		},
		confirmationDialog: {
			show: false,
			acceptCallback: () => { },
			rejectCallback: () => { },
			title: 'Wallet',
			header: 'Wallet'
		}
	}

	async getStaffFromId(id) {
		let staff = await StaffService.getStaff(id);
		return staff;
	}

	async componentDidMount() {

		this.setState({ loading: true });
		try {
			const location = LocationService.getLocation();
			if (location) {
				await this.refreshData(location.loc_id);
				// const parts = window.location.pathname.split('/');
				// // await this.props.getStaff(parts[parts.length - 1]);
				// await this.updateValue(parts[parts.length - 1], 'staff_id');
				this.setState({ loading: false });
			} else {
				this.setState({ loading: false, selectLocation: true })
			}
		} catch (e) {
			console.log(e);
			this.setState({ loading: false, error: true });
		}
	}

	async refreshData(loc_id, updateLocation = true) {
		let loc = this.state.location;
		if (updateLocation)
			loc = await LocationService.getLocationFromServer(loc_id);
		const location = {
			loc_id: loc.loc_id,
			location_status: loc.location_status,
			name: loc.name
		};
		this.setState({ location });
	}

	toggleLocation() {
		this.setState({ selectLocation: !this.state.selectLocation });
	}

	async onSelect(location) {
		this.setState({ loading: true });
		LocationService.setLocation(location);
		await this.refreshData(location.loc_id);
		await this.setState({ selectLocation: false, loading: false });
	}

	changeLocation() {
		this.setState({ selectLocation: true })
	}


	onBlur() {
		this.filterUpdated();
	}

	toggleItemsModal() {
		this.setState({ isMenuItemModalOpen: !this.state.isMenuItemModalOpen });
	}

	async updateValue(value, type) {
		await this.setState({ [type]: value });
	}

	async setOpen(open) {
		await this.setState({ isOpen: open });
	}

	async setOpenSelect() {
		await this.setState({ isOpenSelect: !this.state.isOpenSelect });
	}

	async savePayment() {

		let payment = {
			'rider_id': this.state.rider_id,
			'loc_id': this.state.location.loc_id,
			'amount_received': this.state.amount_received,
			'status': 1,
			'created_at': new Date(),
			'updated_at': new Date()
		}
		await this.props.createRiderPayment(this.state.location.loc_id, payment);
		this.showErrorDialog(async () => {
			if (this.props.payments.status === 'success') {
				this.props.history.push('/riders/payments');
			}
		}, this.props.payments.message, 'Alert');
	}

	handleStepChange(activeStep) {
		this.setState({ activeStep });
	}

	showConfirmationDialog(accept, reject, title, header) {
		const defaultState = {
			show: false,
			acceptCallback: () => { },
			rejectCallback: () => { },
			title: '',
			header: ''
		};

		const confirmationDialog = {
			show: true,
			acceptCallback: async () => {
				accept();
				this.setState({ confirmationDialog: defaultState });
			},
			rejectCallback: () => {
				this.setState({ confirmationDialog: defaultState });
				if (reject)
					reject();
			},
			title: title,
			header: header
		}
		this.setState({ confirmationDialog });
	}


	showErrorDialog(reject, title, header) {
		const defaultState = {
			show: false,
			rejectCallback: () => { },
			title: '',
			header: ''
		};

		const errorDialog = {
			show: true,
			rejectCallback: () => {
				this.setState({ errorDialog: defaultState });
				if (reject)
					reject();
			},
			title: title,
			header: header
		}
		this.setState({ errorDialog });
	}

	onConditionChange = (selectedOption) => {
		this.updateValue(selectedOption.value, 'plan_time',);
	}

	handleEmailInputChange = (searchTerm) => {
		this.setState({ searchEmailTerm: searchTerm });
		return searchTerm;
	};

	async loadOptionsEmail(term, callback) {
		if (term.length < 3) return callback([]);
		try {
			const staffs = await StaffService.getRiderByEmail(term,this.state.location.loc_id);
			callback(ViewHelper.transformedStaff(staffs));
		} catch (e) {
			console.log(e);
			alert('Could not load staffs');
		}
	}

	onChangeEmail(selected) {
		if (selected) {
			this.onSelectRole(selected);
		}
	}

	onChangeRole(selected) {
		if (selected) {
			this.onSelectRole(selected.value);
		}
	}

	onChangeEmail(selected) {
		if (selected) {
			this.onSelectEmail(selected);
		}
	}
	onBlur() {
		if (this.props.onBlur) {
			this.props.onBlur();
		}
	}
	async onSelectEmail(staff) {
		await this.updateValue(staff.staff.User.user_id, 'rider_id');
	}

	async onInputChange(event) {
		await this.updateValue(event.target.value, 'amount_received');
	}

	render() {
		const { props: {
			roles,
			staff,
			locations,
		} } = this;

		const { state: {
			errorSaving,
			confirmationDialog,
			errorDialog,
			selectLocation,
			location,
			loading,
			amount_received
		} } = this;


		if (roles) {
			roleOptions = [];
			roles.map((row) => { roleOptions.push({ value: row.staff_group_id, label: row.staff_group_name }) })
		}


		const locationName = location ? location.name : '';

		if (selectLocation)
			return <SelectLocation onBack={this.toggleLocation.bind(this)} onSelect={this.onSelect.bind(this)} />;

		if (loading) return <SpinnerComponent />;
		return (
			<>
				<div className='display-block mb-1'>
					<LocationSelectHeader text={locationName} onIconClick={this.changeLocation.bind(this)} />
				</div>
				<div className='location'>
					<Card>
						<CardHeader className='flex-column align-items-start'>
							{errorSaving ? <span className='danger'>Unable to save staff.</span> : null}
						</CardHeader>
						<CardBody>
							<AvForm >
								<Row>
									<>
										<Col md='12' sm='12'>
											<AvGroup>
												<Label> Rider Email </Label>

												<AsyncSelect
													placeholder='Please enter email'
													autoFocus
													isClearable
													cacheOptions
													onBlur={this.onBlur.bind(this)}
													onChange={this.onChangeEmail.bind(this)}
													loadOptions={this.loadOptionsEmail.bind(this)}
													onInputChange={this.handleEmailInputChange.bind(this)}
													noOptionsMessage={() => 'No email presents.'}
													className='React'
													classNamePrefix='select'
													styles={{
														control: (provided) => { return { ...provided, borderRadius: '0.3rem' } },
														dropdownIndicator: (provided) => { return { ...provided, display: 'none' } },
														indicatorSeparator: (provided) => { return { ...provided, display: 'none', color: '#fff' } },
														input: (provided) => { return { ...provided, color: '#fff' } }
													}}
												/>
											</AvGroup>
										</Col>
										<Col md='12' sm='12'>
											<AvGroup>
												<Label> Amount </Label>
												<AvInput name='amount_received' type='text' value={amount_received} onChange={this.onInputChange.bind(this)} />
												<AvFeedback>Please enter valid amount.</AvFeedback>
											</AvGroup>
										</Col>
									</>
								</Row>
								<Button color='primary' size='md' onClick={() => this.savePayment()}>
									<span className='ml-1 mr-1 d-md-inline'>Submit</span>
								</Button>
							</AvForm>
						</CardBody>
					</Card>
					<ConfirmationModal header={confirmationDialog.header} title={confirmationDialog.title} isOpen={confirmationDialog.show} reject={confirmationDialog.rejectCallback.bind(this)} rejectTitle={'OK'} />
					<ConfirmationModal header={errorDialog.header} title={errorDialog.title} isOpen={errorDialog.show} reject={errorDialog.rejectCallback.bind(this)} rejectTitle={'OK'} />
				</div>
			</>
		)
	}
}

function mapStateToProps(state) {
	console.log(state);
	const { riders: {
		payment: {
			data: payments
		}
	},
		locations: {
			location: {
				data: locations, loading: loading
			}
		} } = state;

	return { payments, locations, loading: loading };
}

export default connect(mapStateToProps, { getAllLocations, createRiderPayment, getStaffByEmail })(PaymentForm);
