import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import "./../../App.css";
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import CloseIcon from '../../assets/img/static/notification_closeIcon.svg';
import SoundIcon from '../../assets/img/static/notification_soundIcon.svg';

import { Card, CardBody, CardHeader, CardTitle, FormGroup, Label, Media } from "reactstrap";
import Radio from "../../components/@vuexy/radio/RadioVuexy";
import CONSTANTS from "../../constants";
import styled from "styled-components";
import playSoundIcon from "../../assets/img/static/play_sound.svg";
import { connect } from "react-redux";

import {
  setMode,
  setVolume,
} from "../../redux/actions/notifications";
import ReactHowler from "react-howler";

function ToastifyNotification(props) {

  const [isSilent, setIsSilent] = useState(props.mode === "Silent" ? true : false)
  const [audioRepeat, setAudioRepeat] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [audio, setAudio] = useState(`../../${props.volume}`);
  const audioChangedHandler = (event) => {
    props.setVolume(event.target.value)
    setAudio(`../../${event.target.value}`)
    setPlaying(false)
  }

  const audioLoopChangedHandler = (event) => {
    let audioType = event.target.value;
    //localStorage.setItem('notification_sound_mode', audioType);
    props.setMode(event.target.value)
    if (audioType === 'Repeated') {
      setAudioRepeat(true);
      setIsSilent(false)
      setPlaying(false)
    } else if (audioType === 'Single') {
      setAudioRepeat(false)
      setIsSilent(false)
      setPlaying(false)
    } else if (audioType === 'Silent') {
      setAudioRepeat(false);
      setIsSilent(true)
      setPlaying(false)
    }
  }

  const mute = () => {
    setPlaying(false)
  }

  const notify = () => {
    if (!playing) {
      if (isSilent) {
        setPlaying(false)
      } else {
        setPlaying(true)
        setTimeout(() => {
          if (!audioRepeat) {
            setPlaying(false)
          }
        }, 3000)
      }
    }
  }


  useEffect(() => {
    if (props.mode === "Repeated") {
      setAudioRepeat(true);
      setIsSilent(false)
    }
  }, [])

  return (
    <CustomCard>
      <CardBody>
        <StyledToastifyNotification>
          <div>
            <h3 className={"setting-title"}>Set Your Notification Preference:</h3>
            <ReactHowler
              src={audio}
              playing={playing}
              loop={audioRepeat}
            />
            <Row>
              <Col className={"select-sound"} md="12" sm="12">
                <Label>Select Sound: </Label>
                <div id='time'>
                  <div className="d-inline-block mr-1">
                    <Radio
                      onChange={audioChangedHandler}
                      checked={props.volume === "notification.wav" ? true : false}
                      label="Loud" value="notification.wav" name="audioType" />
                  </div>
                  <div className="d-inline-block mr-1">
                    <Radio
                      checked={props.volume === "notification_medium.wav" ? true : false}
                      onChange={audioChangedHandler}
                      label="Medium" value="notification_medium.wav" name="audioType" />
                  </div>
                  <div className="d-inline-block mr-1">
                    <Radio
                      checked={props.volume === "notification_low.wav" ? true : false}
                      onChange={audioChangedHandler}
                      label="Low" value="notification_low.wav" name="audioType" />
                  </div>
                </div>
              </Col>
              <Col className={"select-sound-mode"} md="12" sm="12">
                <Label>Select Sound Mode: </Label>
                <div id='time'>
                  <div className="d-inline-block mr-1">
                    <Radio
                      checked={props.mode === "Single" ? true : false}
                      onChange={audioLoopChangedHandler}
                      label="Single" name="pattern" value="Single" />
                  </div>
                  <div className="d-inline-block mr-1">
                    <Radio
                      checked={props.mode === "Repeated" ? true : false}
                      onChange={audioLoopChangedHandler} label="Repeating" name="pattern"
                      value="Repeated" />
                  </div>
                  <div className="d-inline-block mr-1">
                    <Radio
                      checked={props.mode === "Silent" ? true : false}
                      onChange={audioLoopChangedHandler} label="Silent" name="pattern"
                      value="Silent" />
                  </div>
                </div>
              </Col>

              <Col lg="3" md="6" sm="6">
                <Button className={"play-sound-btn"} onClick={notify}><img src={playSoundIcon} alt="" /> Play Sound</Button>
              </Col>
              <Col lg="3" md="6" sm="6">
                <Button className={"play-sound-btn"} onClick={mute}><img src={playSoundIcon} alt="" /> Stop Sound</Button>
              </Col>
            </Row>
          </div>
          <ToastContainer
            position="top-right"
            hideProgressBar={true}
            newestOnTop={false}
            closeButton={false}
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
            className="foo" style={{ paddingTop: "64px" }}
          />
        </StyledToastifyNotification>
      </CardBody>
    </CustomCard>

  )
};
const CustomCard = styled(Card)`
  .card-body {
    padding: 30px;
  }

  .select-sound {
    margin-bottom: 70px;

    label {
      margin-bottom: 20px;
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
      color: #c2c6dc;
    }

    .vx-radio-con {
      span {
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        color: #c2c6dc;
      }
    }
  }

  .select-sound-mode {
    margin-bottom: 50px;

    label {
      margin-bottom: 20px;
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
      color: #c2c6dc;
    }

    .vx-radio-con {
      span {
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        color: #c2c6dc;
      }
    }
  }

  .play-sound-btn {
    border-radius: 5px;
    background: transparent !important;
    width: 100%;
    min-width: 175px;
    height: 50px;
    border: 1px solid #BCC0D6;
    border-color: #BCC0D6 !important;
    display: flex;
    column-gap: 20px;
    padding: 16px 25px;
    margin-bottom: 15px;
  }
`
const StyledToastifyNotification = styled.div`
  .setting-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 14px;
    color: #ffffff;
    margin-bottom: 40px;
  }

  /*Notification Toast Popup*/

  .Toastify__toast-container {
    @media (max-width: 477px) {
      padding-top: 81px !important;
    }
  }

  .Toastify__toast-container--top-right {
    right: -23px;
    @media (max-width: 501px) {
      right: -38px;
    }
  }

  .custom-toast {
    color: white;
    background: #7065EB;
    height: 36px;
    width: 260px;
  }

  .toast-button {
    float: right;
    cursor: pointer;
    background: none;
    border: none;
  }

  .Toastify__toast {
    position: absolute !important;
    min-height: 36px;
    box-sizing: border-box;
    /* margin-bottom: 0rem; */
    margin: 0px;
    padding: 0px;
    border-radius: 5px;
    padding-left: 10px !important;
    padding-right: 10px !important;
    box-shadow: 0 1px 0px 0 rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%);
    //right: 18px;
    @media (max-width: 477px) {
      right: 18px;
    }
  }

  .Toastify__toast-body {
    margin: auto 0 !important;
    flex: 1 1 auto;
    padding: 6px !important;
    display: flex;
    align-items: start !important;
  }

  .toast-button1 {
    padding-top: 10px;
    position: absolute;
    right: -124px;
    top: -9px;
    z-index: 2;
  }

  .toast-button2 {
    padding-right: 20px;
    /*padding-top: 9px;*/
  }

  .toast-button2-hide {
    opacity: 0;
  }

  .toast-text {
    font-size: 12px !important;
    font-weight: 600;
    padding-top: 4px !important;
    padding-left: 10px !important;
    @media (max-width: 477px) {
      padding-left: 28px !important;
    }
  }
`;

function mapStateToProps(state) {
  return {
    volume: state.notificationReducer ? state.notificationReducer.volume : 'notification_medium.wav',
    mode: state.notificationReducer ? state.notificationReducer.mode : 'Single',
  };
}

export default connect(
  mapStateToProps,
  {
    setVolume, setMode
  }
)(ToastifyNotification)
