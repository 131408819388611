import React, { Component } from 'react';
import { Map, Marker } from 'google-maps-react';
import MapsHelper from '../../../helpers/MapsHelper';

export default class MapComponent extends Component {

	constructor(props) {
    super(props);
    this.mapRef = React.createRef();
  }

	componentDidUpdate(prevProps) {
		if(JSON.stringify(this.props.zones) !== JSON.stringify(prevProps.zones)) {
			this.redrawAreas();
		}
	}

	toggleMapArea(area) {
		console.log("Hello Map Area");
	}

	toggleHoverMapArea(area) {
		console.log("Hello Hover map area");
	}

	updatedDeliveryArea(area) {
		console.log(`Area ${area.row} ${area.type} Updated`);
		const output = [];
		if(area.type === 'circle') {
			output.push({center: {lat: area.getCenter().lat(), lng: area.getCenter().lng()}});
			output.push({radius: area.getRadius()});
			this.props.updateDeliveryArea(JSON.stringify(output), 'circle', area.row);
		} else {
			const vertices = area.getPath();
			for (let i = 0; i < vertices.getLength(); i++) {
				const xy = vertices.getAt(i);
				output.push({
					lat: xy.lat(),
					lng: xy.lng()
				});
			}
			this.props.updateDeliveryArea(JSON.stringify(output), 'vertices', area.row);
		}
	}

	getDeliveryAreaZones(zones) {
		if(!this.mapRef.current) {
			return;
		}
		const keys = Object.keys(zones);
		const deliveryAreas = keys.map( k => ({ ...zones[k], row: k }) );
		this.mapsHelper = new MapsHelper(this.mapRef.current.map, deliveryAreas, {
			toggleMapArea: this.toggleMapArea.bind(this),
			toggleHoverMapArea: this.toggleHoverMapArea.bind(this),
			updatedDeliveryArea: this.updatedDeliveryArea.bind(this),
		}, this.props.location);
		this.props.updateMapsHelperReference(this.mapsHelper);
	}

	redrawAreas() {
		if(this.mapsHelper) {
			const deliveryAreas = Object.keys(this.props.zones).map( k => ({ ...this.props.zones[k], row: k}) );
			this.mapsHelper.redrawAreas(deliveryAreas);
		}
	}

  render () {
    const location = this.props.location;
		const zones = this.props.zones;
		this.redrawAreas();
		return <div className={"map-box"}>
			<Map
				ref={this.mapRef}
        mapTypeControl={false}
        fullscreenControl={true}
        streetViewControl={false}
        containerStyle={{ position: 'relative' }}
        google={window.google}
        key='map'
        initialCenter={location}
        center={location}
        zoom={ 12 }
        gestureHandling={'greedy'}
        onReady={() => {
					this.getDeliveryAreaZones(zones);
				}}>
					<Marker
						name={'Restaurant location'}
						position={location}
					/>
      </Map>
		</div>;
  }
}