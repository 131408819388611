import CONSTANTS from '../../../constants';
import ReviewsService from '../../../services/ReviewsService';

export function getAllReviews(locationId) {
  return async dispatch => {
    try {
      dispatch({ type: CONSTANTS.ACTIONS.REVIEWS.ALL_REVIEWS, reviews: [], loading: true });
      let reviews = await ReviewsService.getAllReviews(locationId);
      dispatch({ type: CONSTANTS.ACTIONS.REVIEWS.ALL_REVIEWS, reviews, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.REVIEWS.ALL_REVIEWS, reviews: [], loading: false });
    }
  };
}
export function deleteReviews(locationId, data) {
  return async dispatch => {
    try {
      dispatch({ type: CONSTANTS.ACTIONS.REVIEWS.ALL_REVIEWS, reviews: [], loading: true });
      let reviews = await ReviewsService.deleteReviews(locationId, data);
      dispatch({ type: CONSTANTS.ACTIONS.REVIEWS.ALL_REVIEWS, reviews, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.ACTIONS.REVIEWS.ALL_REVIEWS, reviews: [], loading: false });
    }
  };
}

