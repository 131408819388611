import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';

import Checkbox from "../@vuexy/checkbox/CheckboxesVuexy";
import { Check, Edit2 } from "react-feather";


const ListRoles = (props) => {

  const editStaff = (id) => {
    props.editStaff(id);
  }


  const columns = [
    {
      name: 'Staff ID',
      selector: 'User.staff_id',
      format: (val) => {
        return val.User ? val.User.staff_id : '';
      }
    },
    {
      name: 'Name',
      selector: 'User.first_name',
      format: (val) => {
        return val.User ? val.User.first_name + ' ' + val.User.last_name : '';
      }
    },
    {
      name: 'Email',
      selector: 'User.email',
      sortable: true,
      minWidth: '300px',
      format: (val) => {
        return val.User ? val.User.email != '' ? val.User.email : val.User.facebook_email : '';
      }
    }, {
      name: 'Location Name',
      selector: 'Location.name',
      format: (val) => {
        return val.Location ? val.Location.name : '';
      }
    }, {
      name: 'Location Address',
      selector: 'Location.formattedAddress',
      minWidth: '400px',
      wrap: true,
      format: (val) => {
        return val.Location ? val.Location.formattedAddress : '';
      }
    }, {
      name: 'Role Level',
      selector: 'StaffGroup',
      sortable: true,
      format: (val) => {
        return val.StaffGroup && val.StaffGroup.staff_group_name ?
          <>
            {val.StaffGroup.staff_group_name}
          </>
          : 'No Role';
      }
    }, {
      name: 'Date Added',
      selector: 'date_added',
      sortable: true,
      format: (val) => {
        return val.date_added ? val.date_added : 'Not available';
      }
    }
  ];

  const renderStaff = (staff, handleChange) => {
    if (!staff || staff.length === 0)
      return <h1>No App Location Roles found.</h1>;

    const tableData = {
      columns: columns,
      data: staff,
      print: false,
      export: false,
    };

    return <DataTableExtensions
      {...tableData}
    >

      <DataTable
        noHeader
        pagination
        paginationRowsPerPageOptions={[25, 50, 100]}
        paginationPerPage={50}
        selectableRows
        selectableRowsComponent={Checkbox}
        selectableRowsComponentProps={{
          color: "primary",
          icon: <Check className="vx-icon" size={12} />,
          label: "",
          size: "sm"
        }}
        onSelectedRowsChange={handleChange}
      />

    </DataTableExtensions>;
  }
  return renderStaff(props.staff, props.handleChange);

}

export default ListRoles;
