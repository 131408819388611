import React, { Component } from 'react';
import { connect } from 'react-redux';
import SpinnerComponent from '../@vuexy/spinner/Fallback-spinner';
import ErrorHandler from '../shared/ErrorHandler';
import { getAllFeedbacks } from '../../redux/actions/feedback';
import '../../assets/scss/pages/page-pricing.scss';
import ListFeedback from './ListFeedback';
import { Button } from 'reactstrap';

class Feedback extends Component {

  async componentDidMount() {
    await this.props.getAllFeedbacks();
  }

  render() {
    const { props: {
      feedbacks,
      loading
    } } = this;

    if (loading) return <SpinnerComponent />;
    return (
      <>
        <div id='pricing-table'>
          {!loading && feedbacks ? <ListFeedback feedbacks={feedbacks} /> : null}
        </div>
      </>
    );
    return <ErrorHandler />;
  }
}

function mapStateToProps(state) {
  const { feedback: {
    allfeedbacks: {
      data: feedbacks, loading: loading
    }
  } } = state;

  return { feedbacks, loading: loading };
}

export default connect(mapStateToProps, { getAllFeedbacks })(Feedback);