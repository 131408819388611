import CONSTANTS from '../constants';
import LoginService from './LoginService';
import { customFetch as fetch } from '../helpers/FetchHelper';

export default {
	getSmsCampaigns,
	saveCampaign,
	getCampaign,
	getCustomerStats,
	cancelCampaign,
	deleteCampaign,
};

async function getSmsCampaigns(loc_id) {
	let { token } = LoginService.getToken();
	let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.SMS_CAMPAIGNS.DEFAULT(loc_id)}`, {
		method: 'GET',
		headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
	});
	response = await response.json();
	if (response) return response;
	throw new Error('Could not fetch campaigns');
}

async function saveCampaign(loc_id, campaign) {
	let { token } = LoginService.getToken();
	let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.SMS_CAMPAIGNS.DEFAULT(loc_id)}`, {
		method: 'POST',
		body: JSON.stringify(campaign),
		headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
	});
	response = await response.json();
	if (response) return response;

	throw new Error('Could not save campaigns');
}

async function cancelCampaign(loc_id, campaign_id) {
	let { token } = LoginService.getToken();
	let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.SMS_CAMPAIGNS.CANCEL_CAMPAIGN(loc_id, campaign_id)}`, {
		method: 'GET',
		headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
	});
	response = await response.json();
	if (response) return response;

	throw new Error('Could not cancel campaigns');
}

async function deleteCampaign(loc_id, campaign_id) {
	let { token } = LoginService.getToken();
	let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.SMS_CAMPAIGNS.DELETE_CAMPAIGN(loc_id, campaign_id)}`, {
		method: 'DELETE',
		headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
	});
	response = await response.json();
	if (response) return response;

	throw new Error('Could not delete campaigns');
}

async function getCampaign(loc_id, id) {
	let { token } = LoginService.getToken();
	let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.SMS_CAMPAIGNS.DEFAULT(loc_id)}/${id}`, {
		method: 'GET',
		headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
	});
	response = await response.json();
	if (response) return response;

	throw new Error('Could not fetch campaigns');
}

async function getCustomerStats(loc_id, filters) {
	let { token } = LoginService.getToken();
	let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.SMS_CAMPAIGNS.CUSTOMERS(loc_id, 'customers')}`, {
		method: 'POST',
		body: JSON.stringify(filters),
		headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
	});
	response = await response.json();
	if (response) return response;

	throw new Error('Could not fetch stats');
}