import _ from 'lodash';
import inflection from 'lodash-inflection';
import getSymbolFromCurrency from 'currency-symbol-map';

export default class ViewHelper {
  static toCurrency(amount, currency = 'USD', decimalPlaces = 2) {
    if (amount) {
      return `${getSymbolFromCurrency(currency)} ${parseFloat(amount)
        .toLocaleString(undefined, { minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces })}`;
    }
    return '';
  }

  static toDecimal(amount, decimalPlaces = 2) {
    if (amount) {
      return `${parseFloat(amount)
        .toLocaleString(undefined, { minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces })}`;
    }
    return '';
  }

  static getTableName(table) {
    if (table && !_.isEmpty(table)) return inflection.titleize(table.table_name);
    return '';
  }

  static getOrderTypeName(orderType) {
    if (orderType && !_.isEmpty(orderType)) return orderType.label;
    return '';
  }

  static getGoogleMapsLink(location) {
    return `https://www.google.com/maps/place/${location}`;
  }

  static select2Theme(theme) {
    let themeColor = localStorage.getItem('DashColor');
    if (themeColor === 'dark') {
      return {
        ...theme,
        borderRadius: 5,
        colors: {
          ...theme.colors,
          primary25: '#10163a',
          primary: '#c2c6dc',
          neutral0: '#262c49',
          neutral80: '#c2c6dc'
        },
      }
    }else{
      return {
        ...theme,
        borderRadius: 5,
        colors: {
          ...theme.colors,
          primary25: '#10163a',
          primary: '#c2c6dc',
          neutral0: '#fff',
          neutral80: '#333'
        },
      }
    }
  }

  static transformedLocations(locations) {
    if (locations && locations.length > 0)
      return locations.map((location) => {
        return { loc_id: location.loc_id, label: `${location.loc_id} - ${location.name}`, location };
      });
    return [];
  }

  static transformedRoles(roles) {
    if (roles && roles.length > 0)
      return roles.map((role) => {
        return { value: role.staff_group_id, label: role.staff_group_name };
      });
    return [];
  }

  static transformedStaff(staffs) {
    if (staffs && staffs.length > 0)
      return staffs.map((staff) => {
        return { staff_id: staff.staff_id, label: staff.User.email != '' ? staff.User.email : staff.User.facebook_email , staff };
      });
    return [];
  }

  static toOptions(options, configOptions = { idColumn: null, valueColumns: null, joiner: null }, htmlOptions = { allowEmpty: false }) {
    let { idColumn, valueColumns, joiner } = configOptions;
    let { allowEmpty } = htmlOptions;
    let id, value = '';

    let formattedOptions = options.map((option) => {
      id = idColumn ? option[idColumn] : option;
      value = valueColumns ? valueColumns.map((column) => option[column]).join(joiner) : option;
      return { id, value };
    });

    if (allowEmpty) formattedOptions = [{ id: '', value: 'Please select' }, ...formattedOptions];

    return formattedOptions;
  }

  // a little function to help us with reordering the result
  static reorder(list, startIndex, endIndex, isMenu = false) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    for (let i = 0; i < list.length; i++) {
      if (isMenu)
        result[i].menu_priority = i + 1;
      else
        result[i].priority = i + 1;
    }
    return result;
  }
}
