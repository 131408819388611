import React, {Component} from "react";
import {connect} from "react-redux";

import { getLocation } from "../../redux/actions/locations";
import {getAll} from "../../redux/actions/options";

import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import SelectLocation from "../shared/SelectLocation";
import LocationSelectHeader from "../shared/LocationSelectHeader";

import LocationService from "../../services/LocationService";
import DnDListView from "../shared/DnDListView";
import {getMenuGroupedByCategory, saveCategory, updateCategoryPriority, delCategory} from "../../redux/actions/categories";
import CollapsibleCardComponent from "../shared/CollapsibleCardComponent";
import {ChevronDown, ChevronUp, Plus} from "react-feather";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {Button, ListGroup, ListGroupItem} from "reactstrap";
import ViewHelper from "../../helpers/ViewHelper";
import {saveItem, updateMenuPriority, delItem, getDummyItem} from "../../redux/actions/menus";
import ScrollToTop from "../shared/ScrollToTop";
import CategoryModal from "../shared/forms/CategoryModal";
import MenuItemModal from "../shared/forms/MenuItemModal";
import ConfirmationModal from "../shared/forms/ConfirmationModal";
import ParamHelper from "../../helpers/ParamHelper";

class Menu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectLocation: false,
      location: null,
      loading: false,
      menu: [],
      collapseItems: [],
      isCategoryModalOpen: false,
      selectedCategory: null,
      isMenuItemModalOpen: false,
      selectedItem: null,
      menuCategory: null,
      deletingCat: false,
      deletingItem: false,
      refreshing: false,
			isOutlet: false,
      confirmationDialog: {
        show: false,
        acceptCallback: () => {},
        rejectCallback: () => {},
      }
    }
    this.endRef = React.createRef();
  }

  endRef = null;

  async componentDidMount() {
    const location = LocationService.getLocation();
    if(location) {
      await this.refreshData(location.loc_id);
      this.setState({loading: false });
    } else {
      this.setState({loading: false, selectLocation: true})
    }
  }

  async refreshData(loc_id, initialize = true, fetchMenu = true) {
    this.setState({ loading: initialize, refreshing: true });
    let locationUpdated = false;
    let collapseItems = this.state.collapseItems.slice();
    if( !this.state.location || loc_id !== this.state.location.loc_id ) {
      this.props.getAll(loc_id);
      await this.props.getLocation(loc_id);
      locationUpdated = true;
      collapseItems = [];
    }
    if(fetchMenu) {
			this.props.getDummyItem(loc_id);
      await this.getMenu(loc_id);
		}

    const customisedMenu = [];
    const { props: {
      menu
    }} = this;

    for(let i=0; i<menu.length; i++) {
      customisedMenu.push({
        id: menu[i].category_id,
        title: menu[i].name,
        ...menu[i]
      });
      if (locationUpdated) {
        collapseItems.push(menu[i].category_id);
      }
    }

    let selectedItem = this.state.selectedItem;

    if(selectedItem) {
      const cat = customisedMenu.filter(c => c.id === this.state.menuCategory.value)[0];
      const it = cat.Menus.filter(mo => mo.menu_id === selectedItem.menu_id)[0];
      selectedItem = it;
    }

    this.setState({ deletingCat: false, deletingItem: false, loading: false, menu: customisedMenu, collapseItems, location: JSON.parse(JSON.stringify(this.props.location)), selectedItem, refreshing: false, isOutlet: this.props.location.is_outlet });
  }

  async getMenu(loc_id) {
    return this.props.getMenuGroupedByCategory(loc_id);
  }

  onBlur() {
    if( this.state.location )
      this.setState({selectLocation: !this.state.selectLocation});
  }

  toggleLocation() {
    this.setState({selectLocation: !this.state.selectLocation});
  }

  async onSelect(location) {
    this.setState({loading: true});
    await LocationService.setLocation(location);
    await this.refreshData(location.loc_id);
    this.setState({selectLocation: false, loading: false});
  }

  toggleCollapse = collapseID => {
    let index = this.state.collapseItems.indexOf(collapseID);
    if (index >= 0) {
      let items = this.state.collapseItems;
      items.splice(index, 1);
      this.setState({ collapseItems: items });
    } else {
      let items = this.state.collapseItems;
      items.push(collapseID);
      this.setState({ collapseItems: items });
    }
  }

  onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return
    }
    const items = ViewHelper.reorder(
      this.state.menu,
      result.source.index,
      result.destination.index
    );
    this.setState({
      menu: items
    });
    const itemsToSave = ParamHelper.getCategoriesForBulkUpdate(items);
    this.props.updateCategoryPriority(this.props.location.loc_id, itemsToSave);
  }

  async toggleCategoryModal(category = null) {
    let {
      state: {
        isCategoryModalOpen,
      }} = this;
    const selectedCategory = category;
    isCategoryModalOpen = !isCategoryModalOpen;
    return this.setState({ selectedCategory, isCategoryModalOpen });
  }

  async saveCategory(category) {
    if( category.name === '' )
      return;

    let isNew = false;

    if(!category.priority) {
      category.priority = this.state.menu.length + 1;
      isNew = true;
    }
    if(category.Menus) delete category.Menus;
    await this.props.saveCategory(this.state.location.loc_id, category);
    await this.toggleCategoryModal();
    await this.refreshData(this.state.location.loc_id, false);
    if(isNew) {
      let { state: {
        collapseItems,
        menu
      }} = this;
      collapseItems.push(menu[menu.length - 1].category_id);
      await this.setState({ collapseItems } );
      setTimeout(() => this.endRef.current.scrollIntoView({behavior: 'smooth'}), 200);
    }
  }

  async toggleMenuModal(category, item = null) {
    let {
      state: {
        isMenuItemModalOpen,
      }} = this;
    isMenuItemModalOpen = !isMenuItemModalOpen;
    if(!isMenuItemModalOpen) {
      return this.setState({ selectedItem: null, isMenuItemModalOpen, menuCategory: null });
    }
    const selectedItem = item ? item : { ...this.props.dummyItem, MenuOptions: [] };
    const menuCategory = { value: category.id, label: category.name };
    return this.setState({ selectedItem, isMenuItemModalOpen, menuCategory });
  }

  async saveItem(item) {
    if( item.menu_name === '' || item.menu_price == 0 )
      return;
    item.menu_category_id = item.menu_category.value;
    delete item.img;
    if(!item.menu_priority) {
      let menus = this.state.menu.filter( m => m.id === item.menu_category_id );
      menus = menus.length > 0 ? menus[0].Menus : null;
      const size = menus ? menus.length : 0;
      item.menu_priority = size + 1;
    }
    const fileInput = document.querySelector("#fileInput");
    const formData = new FormData();
    formData.append('file', fileInput.files[0]);
    formData.append('menuItem', JSON.stringify(item));
    await this.props.saveItem(this.state.location.loc_id, formData);
    await this.toggleMenuModal();
    await this.refreshData(this.state.location.loc_id, false);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(JSON.stringify(prevProps.menu) !== JSON.stringify(this.props.menu) && this.state.location && !this.state.refreshing) {
      this.refreshData(this.state.location.loc_id, false, false);
    }
  }

  async deleteCategory(category) {
    const defaultState = {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        this.setState({ confirmationDialog: defaultState, deletingCat: true });
        await this.props.delCategory(this.state.location.loc_id, category.category_id);
        await this.toggleCategoryModal();
        await this.refreshData(this.state.location.loc_id, false);
      },
      rejectCallback: () => {
        this.setState({ confirmationDialog: defaultState, deletingCat: false });
      }
    }
    this.setState({ confirmationDialog });
  }

  async deleteMenu(menu) {
    const defaultState = {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        this.setState({ confirmationDialog: defaultState, deletingItem: true });
        await this.props.delItem(this.state.location.loc_id, menu.menu_id);
        await this.toggleMenuModal();
        await this.refreshData(this.state.location.loc_id, false);
      },
      rejectCallback: () => {
        this.setState({ confirmationDialog: defaultState, deletingItem: false });
      }
    }

    this.setState({ confirmationDialog });
  }

  async toggleItemStatus(item) {
    const menuItem = {
      menu_id: parseInt(item.id),
      menu_status: (item.status + 1)%3
    };
    const formData = new FormData();
    formData.append('menuItem', JSON.stringify(menuItem));
    this.props.saveItem(this.state.location.loc_id, formData);
    let menu = JSON.parse(JSON.stringify(this.state.menu));
    let category = menu.filter(c => c.category_id === item.menu_category_id)[0];
    let m = category.Menus.filter(i => i.menu_id === menuItem.menu_id)[0];
    m.menu_status = menuItem.menu_status;
    this.setState({ menu });
  }

  async toggleCatStatus(item) {
    const catItem = {
      category_id: item.id,
      status: (parseInt(item.status) + 1)%2
    };
    this.props.saveCategory(this.state.location.loc_id, catItem);
    let menu = JSON.parse(JSON.stringify(this.state.menu));
    let category = menu.filter(c => c.category_id === item.id)[0];
    category.status = catItem.status;
    this.setState({ menu });
  }

  renderCategoryCards() {
    return (
      <ListGroup id="list-group-dnd">
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div ref={provided.innerRef}>
                { this.state.menu.map( (item, index) => (
                  <Draggable
                    key={`${item.id}`}
                    draggableId={`${item.id}`}
                    index={index}
										isDragDisabled={this.state.isOutlet}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className="drag-wrapper"
                      >
                        <ListGroupItem className='no-padding no-border'>
                          <CollapsibleCardComponent isOutlet={this.state.isOutlet} toggleStatus={this.toggleCatStatus.bind(this)} toggleMenuModal={this.toggleMenuModal.bind(this, item)} toggleCategoryModal={this.toggleCategoryModal.bind(this)} dragHandleProps={provided.dragHandleProps} item={item} toggleCollapse={this.toggleCollapse.bind(this)} isOpen={ this.state.collapseItems.indexOf(item.id) >= 0 } >
                            <DnDListView isOutlet={this.state.isOutlet} toggleMenuModal={this.toggleMenuModal.bind(this, item)} updateMenuPriority={ this.props.updateMenuPriority } menu={item.Menus} location={this.props.location} toggleStatus={this.toggleItemStatus.bind(this)} />
                          </CollapsibleCardComponent>
                        </ListGroupItem>
                      </div>
                    )}
                  </Draggable>
                )) }
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </ListGroup>
    )
  }

  closeAll() {
    this.setState({ collapseItems: [] });
  }

  openAll() {
    let collapseItems = this.state.menu.map( item => item.id );
    this.setState({ collapseItems });
  }

  showConfirmationDialog(accept, reject) {
    const defaultState = {
      show: false,
      acceptCallback: () => {},
      rejectCallback: () => {},
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        accept();
        this.setState({ confirmationDialog: defaultState });
      },
      rejectCallback: () => {
        reject();
        this.setState({ confirmationDialog: defaultState });
      }
    }
    this.setState({ confirmationDialog });
  }

  render() {
    const { props: {
      location,
    }} = this;

    const { state: {
      selectLocation,
      loading,
      menu,
    }} = this;

    if( loading ) return <SpinnerComponent />;

    const locationName = location ? location.name : '';

    if ( selectLocation )
      return <SelectLocation onBack={this.toggleLocation.bind(this)} onSelect={this.onSelect.bind(this)}  />;

    return (
      <div className='location'>
        <div className='display-flex flex-row justify-content-between mb-1'>
          <LocationSelectHeader text={locationName} onIconClick={this.toggleLocation.bind(this)}/>
          <div className='mr-3'>
            <ChevronUp size={30} color='white' className='cursor-pointer bg-dark mr-1' onClick={this.closeAll.bind(this)} />
            <ChevronDown size={30} color='white' className='cursor-pointer bg-dark' onClick={this.openAll.bind(this)} />
          </div>
        </div>
        { this.renderCategoryCards() }
        { !this.state.isOutlet && <Button outline className="font-weight-bold custom-padding disabled-2-border mb-1" color={ "secondary" } size="md" onClick={() => this.toggleCategoryModal()}>
          <Plus className="mx-2" size={20} /> <span className="ml-1 mr-5">Add New Category</span>
        </Button> }
        <CategoryModal deleting={this.state.deletingCat} deleteCategory={this.deleteCategory.bind(this)} saveCategory={this.saveCategory.bind(this)} isOpen={this.state.isCategoryModalOpen} category={this.state.selectedCategory} toggleModal={this.toggleCategoryModal.bind(this)} />
        <MenuItemModal showConfirmationDialog={this.showConfirmationDialog.bind(this)} deleting={this.state.deletingItem} deleteMenu={this.deleteMenu.bind(this)} selectedCategory={this.state.menuCategory} categories={this.state.menu} saveItem={this.saveItem.bind(this)} location={location} isOpen={this.state.isMenuItemModalOpen} item={this.state.selectedItem} toggleModal={this.toggleMenuModal.bind(this)} />
        <ConfirmationModal isOpen={this.state.confirmationDialog.show} accept={this.state.confirmationDialog.acceptCallback.bind(this)} reject={this.state.confirmationDialog.rejectCallback.bind(this)} />
        <ScrollToTop />
        <div ref={this.endRef} id='emptyEndDiv'></div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { categories: {
    categoryMenu: {
      data: menuData, loading: menuLoading
    }
  }, locations: {
    location: {
      data: locationData, loading: locationLoading
    }
  }, menu: {
		dummyItem: {
			data: dummyItem,
		}
	}} = state;
  return { dummyItem, menu: menuData, location: locationData, loading: menuLoading || locationLoading };
}

export default connect(mapStateToProps, { getLocation, getMenuGroupedByCategory, updateCategoryPriority, updateMenuPriority, saveCategory, saveItem, delCategory, delItem, getAll, getDummyItem })(Menu);