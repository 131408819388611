import React from "react";
import DataTable from "react-data-table-component";
import TimeHelper from "../../helpers/TimeHelper";
import ViewHelper from "../../helpers/ViewHelper";
import Checkbox from "../@vuexy/checkbox/CheckboxesVuexy";
import { Check } from "react-feather";
import DataTableExtensions from 'react-data-table-component-extensions';


const ListFeedback = (props) => {

  const columns = [
    {
      name: 'ID',
      selector: 'feedback_id',
      sortable: true,
    },
    {
      name: 'User Email',
      sortable: true,
      selector: 'user_email',
      minWidth: '200px',
      format: (val) => {
        return val.user_email ? val.user_email : 'Not available';
      }
    }, {
      name: 'Type',
      sortable: true,
      selector: 'feedback_type',
      minWidth: '150px',
      format: (val) => {
        return val.feedback_type ? val.feedback_type : 'Not available';
      }
    }, {
      name: 'Comment',
      selector: 'feedback_comment',
      minWidth: '300px',
      wrap: true,
      format: (val) => {
        return val.feedback_comment ? val.feedback_comment : 'Not available';
      }
    }
    , {
      name: 'Device',
      sortable: true,
      selector: 'device',
      format: (val) => {
        return val.device ? val.device : 'Not available';
      }
    }, {
      name: 'OS',
      sortable: true,
      selector: 'os',
      format: (val) => {
        return val.os ? val.os : 'Not available';
      }
    }, {
      name: 'Address',
      selector: 'address',
      minWidth: '200px',
      format: (val) => {
        return val.address ? val.address : 'Not available';
      }
    }, {
      name: 'Datetime',
      selector: 'feedback_datetime',
      minWidth: '200px',
      format: (val) => {
        return val.feedback_datetime ? TimeHelper.toFormattedDateTimeDB(val.feedback_datetime) : 'Not available';
      }
    },
    
    // {
    //   name: 'Feedback Image',
    //   selector: 'feedback_image',
    //   minWidth: '200px',
    //   format: (val) => {
    //     return val.feedback_image ? <img src={val.feedback_image} /> : 'Not available';
    //   }
    // }
  ];

  const renderFeedbacks = (feedbacks) => {
    if (!feedbacks || feedbacks.length === 0)
      return <h1>No App user found.</h1>;

    const tableData = {
      columns: columns,
      data: feedbacks,
      print: false,
      export: false,
    };
    return <DataTableExtensions
      {...tableData}
    >
      <DataTable
        noHeader
        pagination
        paginationRowsPerPageOptions={[25, 50, 100]}
        paginationPerPage={50}
        columns={columns}
        data={feedbacks}
      /></DataTableExtensions>;
  }
  return renderFeedbacks(props.feedbacks);

}

export default ListFeedback;
