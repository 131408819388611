import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import SpinnerComponent from '../@vuexy/spinner/Fallback-spinner';
import ParamHelper from '../../helpers/ParamHelper';
import { getLocation } from '../../redux/actions/locations';
import OutletForm from "./OutletForm";

class Outlet extends Component {

  componentDidMount() {
    this.props.getLocation(ParamHelper.getLocationId(this.props));
  }

  render() {
    const { location, loading } = this.props;
    // TODO: Implement proper error handling
    if(location && location.loc_id) {
      return (
        <div className='location'>
          <Card>
            <CardBody>
              <OutletForm />
            </CardBody>
          </Card>
        </div>
      );
    }
    if(loading) return <SpinnerComponent />;
    return '';
  }
}

function mapStateToProps(state) {
  const { location } = state.locations;
  return { location: location.data, loading: location.loading };
}

export default connect(mapStateToProps, { getLocation })(Outlet);
