import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { Button, Form, Label, Media, Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { FormGroup } from "react-bootstrap";
import momentTimezone from 'moment-timezone';
import TimezonePicker from 'react-bootstrap-timezone-picker';
import { serialize } from "php-serialize";

import SpinnerComponent from '../@vuexy/spinner/Fallback-spinner';
import Text from '../shared/fields/Text';
import PrependText from '../shared/fields/PrependText';
import Hidden from '../shared/fields/Hidden';
import Select from '../shared/fields/Select';
import SearchSelect from '../shared/fields/SearchSelect';
import Checkbox from '../shared/fields/Checkbox';
import TextArea from '../shared/fields/TextArea';
import ErrorHandler from '../shared/ErrorHandler';
import { getCurrencies, getCategories } from '../../redux/actions/configurations';
import { updateLocation, createLocation } from '../../redux/actions/locations';
import LocationService from '../../services/LocationService';
import ViewHelper from '../../helpers/ViewHelper';
import ValidationHelper from '../../helpers/ValidationHelper';
import CONSTANTS from '../../constants';
import { getAllCountries } from '../../redux/actions/currency';
import Toggle from "react-toggle";

import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';
import { Image, X } from "react-feather";
import ImageHelper from "../../helpers/ImageHelper";

class OutletForm extends Component {
  fileRef = React.createRef();
  state = {
    timezoneLabel: null,
    timezone: 'Asia/Karachi +05:00',
    img: null,
    deletePhoto: false
  }

  handlePhotoChange(e) {
    if (e.target.files.length > 0) {
      let reader = new FileReader();
      reader.onload = (ev) => {
        this.setState({ img: ev.target.result, deletePhoto: false });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  componentDidMount() {
    this.props.getCurrencies();
    this.props.getCategories();
    this.getCountries();
    const masterLocation = LocationService.getLocation();
    this.setState({ masterLocation });
  }


  async getCountries() {
    return this.props.getAllCountries();
  }

  async saveLocation(values) {
    debugger
    let asyncCall = this.props.createLocation;
    values.timezone = this.state.timezone;
    const fileInput = document.querySelector("#fileInput");
    const formData = new FormData();
    formData.append('file', fileInput.files[0]);
    values.deletePhoto = this.state.deletePhoto;
    if (values.loc_id) {
      asyncCall = this.props.updateLocation;
    } else {
      const {
        state: {
          masterLocation: {
            country,
            loc_id,
            category_name,
          }
        }
      } = this;
      values.country = country;
      values.category_name = category_name;
      values.parent_id = loc_id;
      values.is_outlet = true;
      const outputCircle = [];
			outputCircle.push({ center: { lat: +values.latitude, lng: +values.longitude } });
			outputCircle.push({ radius: 1000 * 11 });
      let tempValues = CONSTANTS.DUMMY_VALUES;
      tempValues.delivery_areas[0].circle = JSON.stringify(outputCircle);
      values.options = tempValues;
      values.OrderTypes = CONSTANTS.DUMMY_ORDER_TYPES;
      values.WorkingHours = CONSTANTS.DUMMY_WORKING_HOURS;
    }
    formData.append('locationItem', JSON.stringify(values));
    formData.loc_id = values.loc_id;
    await asyncCall(formData);
    if (this.props.onSuccess) this.props.onSuccess();
  }

  handleChange(e) {
    const diff = momentTimezone.tz(e).format('Z');
    this.setState({ timezoneLabel: e, timezone: `${e} ${diff}` });
  }

  render() {
    const { location, countries, currencies, categories, currentValues, loading, handleSubmit, submitting } = this.props;
    if (loading) return <SpinnerComponent />;
    if (currencies && location && categories) {
      let parent = location.Parent;
      let timezoneLabel = this.state.timezoneLabel ? this.state.timezoneLabel : location.timezone ? location.timezone.split(' ')[0] : this.state.timezone.split(' ')[0];
      let currencyOptions = ViewHelper.toOptions(currencies, { idColumn: 'currency_id', valueColumns: ['currency_code', 'currency_name'], joiner: ' - ' }, { allowEmpty: true });
      let countriesOptions = ViewHelper.toOptions(countries, { idColumn: 'country', valueColumns: ['country_name'] }, { allowEmpty: true });
      let categoryOptions = ViewHelper.toOptions(categories, {}, { allowEmpty: true });
      let parentValue = parent ? { loc_id: parent.loc_id, label: `${parent.loc_id} - ${parent.name}` } : {};
      const image = this.state.img || this.state.deletePhoto ? this.state.img : ImageHelper.getImageURL(location.img);
      return (
        <>
          <Form onSubmit={handleSubmit(this.saveLocation.bind(this))}>
            <Card>
              <CardHeader><strong>Restaurant Settings</strong></CardHeader>
              <CardBody>
                <Field name='loc_id' component={Hidden} />
                <Field name='name' component={Text} label='Restaurant name' validate={ValidationHelper.required} />
                <Row>
                  <Col md="6">
                    <Field name='latitude' component={Text} label='Latitude' validate={ValidationHelper.required} />
                  </Col>
                  <Col md="6">
                    <Field name='longitude' component={Text} label='Longitude' validate={ValidationHelper.required} />
                  </Col>
                </Row>
                <Field name='slug' component={PrependText} label='Slug' validate={ValidationHelper.required} prepend={`${CONSTANTS.WEB_URL}/`} />
                <Field name='formattedAddress' component={Text} label='Address' validate={ValidationHelper.required} />
                <Row>
                  <Col md="6">
                    <Field name='location_telephone' component={Text} label='Phone Number' validate={ValidationHelper.required} />
                  </Col>
                  <Col md="6">
                    <Field name='whatsapp_number' component={Text} label='Whatsapp Number' validate={ValidationHelper.required} />
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <Field name='city' component={Text} label='City' validate={ValidationHelper.required} />
                  </Col>
                  <Col md="4">
                    <Field name='state' component={Text} label='State' validate={ValidationHelper.required} />
                  </Col>
                  <Col md="4">
                    <Field name='country' component={Select} label='Country' validate={ValidationHelper.required} options={countriesOptions} />
                  </Col>
                  {/* <Col md="4">
                    <Field name='country' component={Text} label='Country' validate={ValidationHelper.required} />
                  </Col> */}
                </Row>
                <Row>
                  <Col md="6">
                    <Field name='timezone' component={() => {
                      return <FormGroup>
                        <Label>Timezone</Label>
                        <TimezonePicker
                          absolute={false}
                          defaultValue={timezoneLabel}
                          placeholder="Select timezone..."
                          onChange={this.handleChange.bind(this)}
                          className='col-12 p-0'
                        />
                      </FormGroup>;
                    }} />
                  </Col>
                  <Col md="6">
                    <Field name='category_name' component={Select} label='Category Name' validate={ValidationHelper.required} options={categoryOptions} />
                  </Col>
                </Row>
                <FormGroup>
                  <Label for="photoDiv">Banner Photo:</Label>
                  <div tabIndex="0" id="photoDiv" className='display-flex flex-column border border-2 border-light cursor-pointer' onKeyUp={(e) => e.keyCode === 13 && !e.ctrlKey ? this.fileRef.current.click() : null} onClick={() => this.fileRef.current.click()} style={{ position: 'relative', height: 120, width: 150 }}>
                    <div style={{ textAlign: '-webkit-center' }}>
                      {image ?
                        <div>
                          <div style={{ position: 'absolute', top: -10, right: -10 }}>
                            <Button.Ripple onClick={() => {
                              this.setState({ img: null, deletePhoto: true });
                              this.fileRef.current.value = null;
                            }} style={{ width: 20, height: 20 }} className="m-0 p-0" color="danger">
                              <X size={14} />
                            </Button.Ripple>
                          </div>
                          <div style={{ position: 'absolute', bottom: 3 }} className='full-width'>
                            <Button.Ripple className='p-25' color="primary" onClick={() => this.fileRef.current.click()}>
                              <span className='font-small-1'>Change Photo</span>
                            </Button.Ripple>
                          </div>
                          <Media
                            object
                            src={image}
                            alt="image"
                            style={{ maxWidth: 148, maxHeight: 118 }}
                            className='align-self-center'
                          />
                        </div> :
                        <div className='align-self-center' style={{ padding: 9, height: 50, width: 50, }}>
                          <Image size={32} className='align-self-center' />
                        </div>
                      }
                    </div>
                    {image ? null :
                      <React.Fragment>
                        <span className='font-small-2 primary align-self-center'>Click to upload a file</span>
                        <span className='font-small-2 color- align-self-center align-text-center'>PNG, JPG, IMG <br /> up to 10mb</span>
                      </React.Fragment>
                    }
                  </div>
                  <input
                    type="file"
                    id="fileInput"
                    name="fileInput"
                    onChange={this.handlePhotoChange.bind(this)}
                    ref={this.fileRef}
                    style={{ display: 'none' }}
                  />
                </FormGroup>
              </CardBody>
            </Card>
            <Card>
              <CardHeader><strong>Settings</strong></CardHeader>
              <CardBody>
                <Row>
                  <Col md="4">
                    <Field name='currency_id' component={Select} label='Currency' validate={ValidationHelper.required} options={currencyOptions} />
                  </Col>
                  <Col md="4">
                    <Field name='tax_percent' component={Text} label='Tax Rate' validate={ValidationHelper.required} />
                  </Col>
                  <Col md="4">
                    <Field name='decimal_places' component={Text} label='Decimals' validate={ValidationHelper.required} />
                  </Col>
                </Row>
                <Field name='pixel_trackers' component={TextArea} label='Pixel Tracking' helpText={'Please paste scripts for all your pixel trackers here. Append in new line to add more.'} />

                <Field name='visible' component={Checkbox} label='Visible' />
                <Field name='location_status' component={Checkbox} label='Is Restaurant' />

              </CardBody>
            </Card>
            <div className='actions'>
              <button className='btn btn-primary' type='submit' disabled={submitting}>Save Restaurant</button>
            </div>
          </Form>
        </>
      );
    } else {
      return '';
    }
  }
}

function mapStateToProps(state) {
  let { location, } = state.locations;
  let { currencies, categories } = state.configurations;
  let { allcountries } = state.currency;
  let initialValues = {};
  if (location.data) {
    const { loc_id, name, latitude, longitude, slug, formattedAddress, city, state, country, currency_id, category_name, location_status, visible, is_parent, parent_id, pixel_trackers, location_telephone, whatsapp_number, decimal_places, tax_percent } = location.data;
    initialValues = { loc_id, name, latitude, longitude, slug, formattedAddress, city, state, country, currency_id, category_name, location_status, visible, is_parent, parent_id, pixel_trackers, location_telephone, whatsapp_number, decimal_places, tax_percent };
  }

  let currentValues = {};
  if (state.form && state.form.outletForm) currentValues = state.form.outletForm.values;
  return {
    currentValues,
    initialValues,
    location: location.data,
    countries: allcountries.data,
    currencies: currencies.data,
    categories: categories.data,
    loading: (currencies.loading || location.loading || categories.loading)
  };
}

export default connect(mapStateToProps, { getAllCountries, getCurrencies, getCategories, updateLocation, createLocation })(reduxForm({ form: 'outletForm' })(OutletForm));
