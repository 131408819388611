import React from "react";
import DataTable from "react-data-table-component";
import TimeHelper from "../../../helpers/TimeHelper";
import ViewHelper from "../../../helpers/ViewHelper";


const ListAllSubscriptions = (props) => {

  const columns = [
    {
      name: 'Transaction ID',
      selector: 'id',
    },
    {
      name: 'Payment Gateway ID',
      selector: 'transaction_id',
    }, {
      name: 'Payment Method',
      selector: 'payment_method',
    }, {
      name: 'Staff ID',
      selector: 'Staff.staff_id',
      format: (val) => {
          return val.Staff ? val.Staff.staff_id : '';        
      }
    }, {
      name: 'Email',
      selector: 'Staff.User.email',
      format: (val) => {
        if(val.Staff && val.Staff.User){
          return val.Staff.User.email === "" ? val.Staff.User.facebook_email : val.Staff.User.email;
        }else{
          return '';
        }
      }
    }
    , {
      name: 'Plan Name',
      selector: 'SmsSubscription.SmsPlan.name',
      format: (val) => {
        return val.SmsSubscription ? val.SmsSubscription.SmsPlan.name : '';
      }
    }, {
      name: 'Quantity',
      selector: 'SmsSubscription.quantity',
      format: (val) => {
        return val.SmsSubscription ? val.SmsSubscription.quantity : 0;
      }
    }, {
      name: 'Amount',
      selector: 'SmsSubscription.plan_amount',
      format: (val) => {
        return val.SmsSubscription ? val.SmsSubscription.Currency.currency_symbol + " " + val.SmsSubscription.plan_amount : 0;
      }
    }, {
      name: 'Purchase Date',
      selector: 'purchased_at',
      format: (val) => {
        return TimeHelper.toFormattedDateTime(val.purchased_at);
      }
    }
  ];

  const renderSubscriptions = (subscriptions) => {
    if (!subscriptions || subscriptions.length === 0)
      return <h1>No Subscriptions found.</h1>;

    return <DataTable
      columns={columns}
      data={subscriptions}
      noHeader
    />;
  }
  return renderSubscriptions(props.subscriptions);

}

export default ListAllSubscriptions;