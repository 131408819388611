import React from "react";
import DataTable from "react-data-table-component";
import { Button } from "reactstrap";
import { Edit2, Pause, Play, Send, ShieldOff, Trash } from "react-feather";

function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(array[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach(item => {
        let ctr = 0;
        keys.forEach(key => {
            if (ctr > 0) result += columnDelimiter;

            result += item[key];

            ctr++;
        });
        result += lineDelimiter;
    });

    return result;
}

function downloadCSV(array) {
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = `plugd_session_activity_${new Date().toDateString()}.csv`;

    if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
}

const Export = ({ onExport }) => <Button onClick={e => onExport(e.target.value)} color='primary'>Export CSV</Button>;

const customStyles = {
    header: {
        style: {
            background: 'transparent',
            border: 0,
            position: 'static',
            marginBottom: '5px'
        }
    }
}


const SessionActivityList = (props) => {
  const columns = [
    {
      name: "Date",
      selector: "created_at",
      maxWidth: "200px",
      format: ({ created_at }) => {
        return new Date(created_at).toDateString();
      },
    },
    {
      name: "Time",
      selector: "created_at",
      maxWidth: "150px",
      format: ({ created_at }) => {
        return new Date(created_at).toLocaleTimeString();
      },
    },
    {
      name: "Restaurant Endpoint",
      maxWidth: "250px",
      selector: "base_url",
    },
    {
      name: "Referer URL",
      selector: "referer_url",
      maxWidth: "500px",
      format: ({ referer_url }) => {
        return referer_url ? referer_url : "No Referer URL";
      },
    },
    {
      name: "IP Address",
      maxWidth: "200px",
      selector: "remote_ip",
    },
  ];

  const renderSessionActivities = (sessionActivities) => {
    if (!sessionActivities || sessionActivities.length === 0)
        return null;

    return <DataTable
        columns={columns}
        data={sessionActivities}
        actions={<Export onExport={() => downloadCSV(props.sessionActivities)} />}
        customStyles={customStyles}
    />;
}
return renderSessionActivities(props.sessionActivities);
 
};
        
 

export default SessionActivityList;
