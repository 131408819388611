import React, { Component } from 'react';
import {Button, Card, CardBody, Col, FormGroup, Input, Label, Media, Row} from 'reactstrap';
import {SketchPicker} from 'react-color';
import SpinnerComponent from '../@vuexy/spinner/Fallback-spinner';
import LocationSelectHeader from '../shared/LocationSelectHeader';
import LocationService from '../../services/LocationService';

import '../../assets/scss/plugins/forms/switch/react-toggle.scss';
import 'react-toggle/style.css';
import SelectLocation from "../shared/SelectLocation";
import BrandingService from "../../services/BrandingService";
import {Image, X} from "react-feather";
import ImageHelper from "../../helpers/ImageHelper";
import {faSquare} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Branding extends Component {

  fileRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      highlightTextPicker: false,
      highlightPicker: false,
      h2Picker: false,
      h2TextPicker: false,
      basePicker: false,
      selectLocation: false,
      location: null,
      branding: null,
      img: null,
      deletePhoto: false
    }
  }

  handlePhotoChange (e) {
    if(e.target.files.length > 0) {
      let reader = new FileReader();
      reader.onload = (ev) => {
        this.setState({img: ev.target.result, deletePhoto: false});
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  async componentDidMount() {
    this.setState({ loading: true });
    try {
      const location = LocationService.getLocation();
      if(location) {
        await this.refreshData(location.loc_id);
        this.setState({loading: false });
      } else {
        this.setState({loading: false, selectLocation: true})
      }
    } catch (e) {
      console.log(e);
      this.setState({ loading: false, error: true });
    }
  }

  async refreshData(loc_id, updateLocation= true) {
    let loc = this.state.location;
    if(updateLocation)
      loc = await LocationService.getLocationFromServer(loc_id);
    const branding = await BrandingService.getBranding(loc_id);
    const location = {
      loc_id: loc.loc_id,
      location_status: loc.location_status,
      name: loc.name
    };
    this.setState({ location, branding });
  }

  changeLocation() {
    this.setState({ selectLocation: true })
  }

  updateBranding(e, key) {
    const { state: {
      branding
    }} = this;
    this.setState({ branding: { ...branding, [key]: e } });
  }

  async saveBranding() {
    this.setState({ loading: true });
    const { state: {
      branding,
      location,
      deletePhoto,
    }} = this;
    const fileInput = document.querySelector("#fileInput");
    const formData = new FormData();
    formData.append('file', fileInput.files[0]);
    branding.deletePhoto = deletePhoto;
    formData.append('brandingItem', JSON.stringify(branding));
    formData.loc_id = location.loc_id;
    await BrandingService.postBranding(formData);
    await this.refreshData(location.loc_id, false);
    this.setState({ loading: false });
  }

  async onSelect(location) {
    this.setState({loading: true});
    LocationService.setLocation(location);
    await this.refreshData(location.loc_id);
    this.setState({selectLocation: false, loading: false});
  }

  toggleLocation() {
    this.setState({selectLocation: !this.state.selectLocation});
  }

  togglePicker(type) {
    this.setState({ [type]: !this.state[type]})
  }

  renderBrandingForm(branding) {
    const popover = {
      position: 'absolute',
      zIndex: '2',
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }

    const {
      state: {
        img,
        deletePhoto
    }} = this;

    const image = img || deletePhoto ? img : ImageHelper.getImageURL(branding.logo,'?func=crop&w=120&h=80');

    return (
      <Card className='card-action'>
        <CardBody>
          <FormGroup row>
            <Col md="4" sm="12">
              <Label>Base color:</Label>
              <FormGroup className="position-relative has-icon-right">
                <Input
                  name='basecolor'
                  placeholder='Base Color'
                  value={branding.basecolor}
                  editable={false}
                  onClick={this.togglePicker.bind(this, 'basePicker')}
                />
                <div className="form-control-position">
                  <FontAwesomeIcon icon={faSquare} style={{ color: branding.basecolor }}/>
                </div>
              </FormGroup>
              { this.state.basePicker && <div style={ popover }>
                <div style={ cover } onClick={ this.togglePicker.bind(this, 'basePicker') }/>
                <SketchPicker
                  color={ branding.basecolor }
                  onChangeComplete={ (color) => this.updateBranding(color.hex, 'basecolor') }
                />
              </div> }
            </Col>
            <Col md="4" sm="12">
              <Label>Highlight 1 color:</Label>
              <FormGroup className="position-relative has-icon-right">
                <Input
                  name='highlight_color'
                  placeholder='Highlight Color'
                  value={branding.highlight_color}
                  editable={false}
                  onClick={this.togglePicker.bind(this, 'highlightPicker')}
                />
                <div className="form-control-position">
                  <FontAwesomeIcon icon={faSquare} style={{ color: branding.highlight_color }}/>
                </div>
              </FormGroup>
              { this.state.highlightPicker && <div style={ popover }>
                <div style={ cover } onClick={ this.togglePicker.bind(this, 'highlightPicker') }/>
                <SketchPicker
                  color={ branding.highlight_color }
                  onChangeComplete={ (color) => this.updateBranding(color.hex, 'highlight_color') }
                />
              </div> }
            </Col>
            <Col md="4" sm="12">
              <Label>Highlight 1 Text color:</Label>
              <FormGroup className="position-relative has-icon-right">
                <Input
                  name='highlight_text_color'
                  placeholder='Highlight Color'
                  value={branding.highlight_text_color}
                  editable={false}
                  onClick={this.togglePicker.bind(this, 'highlightTextPicker')}
                />
                <div className="form-control-position">
                  <FontAwesomeIcon icon={faSquare} style={{ color: branding.highlight_text_color }}/>
                </div>
              </FormGroup>
              { this.state.highlightTextPicker && <div style={ popover }>
                <div style={ cover } onClick={ this.togglePicker.bind(this, 'highlightTextPicker') }/>
                <SketchPicker
                  color={ branding.highlight_text_color }
                  onChangeComplete={ (color) => this.updateBranding(color.hex, 'highlight_text_color') }
                />
              </div> }
            </Col>
            <Col md="4" sm="12">
              <Label>Highlight 2 color:</Label>
              <FormGroup className="position-relative has-icon-right">
                <Input
                  name='h2_color'
                  placeholder='H2 Color'
                  value={branding.h2_color}
                  editable={false}
                  onClick={this.togglePicker.bind(this, 'h2Picker')}
                />
                <div className="form-control-position">
                  <FontAwesomeIcon icon={faSquare} style={{ color: branding.h2_color }}/>
                </div>
              </FormGroup>
              { this.state.h2Picker && <div style={ popover }>
                <div style={ cover } onClick={ this.togglePicker.bind(this, 'h2Picker') }/>
                <SketchPicker
                  color={ branding.h2_color }
                  onChangeComplete={ (color) => this.updateBranding(color.hex, 'h2_color') }
                />
              </div> }
            </Col>
            <Col md="4" sm="12">
              <Label>Highlight 2 Text color:</Label>
              <FormGroup className="position-relative has-icon-right">
                <Input
                  name='h2_text_color'
                  placeholder='H2 Text Color'
                  value={branding.h2_text_color}
                  editable={false}
                  onClick={this.togglePicker.bind(this, 'h2TextPicker')}
                />
                <div className="form-control-position">
                  <FontAwesomeIcon icon={faSquare} style={{ color: branding.h2_text_color }}/>
                </div>
              </FormGroup>
              { this.state.h2TextPicker && <div style={ popover }>
                <div style={ cover } onClick={ this.togglePicker.bind(this, 'h2TextPicker') }/>
                <SketchPicker
                  color={ branding.h2_text_color }
                  onChangeComplete={ (color) => this.updateBranding(color.hex, 'h2_text_color') }
                />
              </div> }
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md="6" sm="12">
              <Label for="photoDiv">Logo Photo:</Label>
              <div tabIndex="0" id="photoDiv" className='display-flex flex-column border border-2 border-light cursor-pointer' onKeyUp={(e) => e.keyCode === 13 && !e.ctrlKey ? this.fileRef.current.click() : null} onClick={() => this.fileRef.current.click()} style={{ position: 'relative', height: 120, width: 150}}>
                <div style={{ textAlign: '-webkit-center' }}>
                  { image ?
                    <div>
                      <div style={{ position: 'absolute', top: -10, right: -10 }}>
                        <Button.Ripple onClick={() => {
                          this.setState({ img: null, deletePhoto: true });
                          this.fileRef.current.value = null;
                        }} style={{ width: 20, height: 20 }} className="m-0 p-0" color="danger">
                          <X size={14} />
                        </Button.Ripple>
                      </div>
                      <div style={{ position: 'absolute', bottom: 3 }} className='full-width'>
                        <Button.Ripple className='p-25' color="primary" onClick={() => this.fileRef.current.click()}>
                          <span className='font-small-1'>Change Photo</span>
                        </Button.Ripple>
                      </div>
                      <Media
                        object
                        src={image}
                        alt="image"
                        style={{ maxWidth: 148, maxHeight: 118 }}
                        className='align-self-center'
                      />
                    </div> :
                    <div className='align-self-center' style={{ padding: 9, height: 50, width: 50, }}>
                      <Image size={32} className='align-self-center' />
                    </div>
                  }
                </div>
                { image ? null :
                  <React.Fragment>
                    <span className='font-small-2 primary align-self-center'>Click to upload a file</span>
                    <span className='font-small-2 color- align-self-center align-text-center'>PNG, JPG, IMG <br /> up to 10mb</span>
                  </React.Fragment>
                }
              </div>
              <input
                type="file"
                id="fileInput"
                name="fileInput"
                onChange={this.handlePhotoChange.bind(this)}
                ref={this.fileRef}
                style={{ display: 'none' }}
              />
            </Col>
          </FormGroup>
          <Button color='primary' type='submit' className='mr-1 mb-1' onClick={this.saveBranding.bind(this)}>Save</Button>
        </CardBody>
      </Card>
    )
  }

  render() {
    const { state: {
      branding,
      loading,
      selectLocation,
      location
    }} = this;

    const locationName = location ? location.name : '';

    // TODO: Implement proper error handling
    if( selectLocation )
      return <SelectLocation onBack={this.toggleLocation.bind(this)} onSelect={this.onSelect.bind(this)} />;

    if(loading) return <SpinnerComponent />;

    if(branding)
      return (
        <div className='order-types'>
          <LocationSelectHeader text={locationName} onIconClick={this.changeLocation.bind(this)} />
          <Row className='mx-50 my-1'>
            <h2>Restaurant Branding</h2>
          </Row>
          <div className='mt-1'>
            {this.renderBrandingForm(branding)}
          </div>
        </div>
      );
      return '';
  }
}

export default Branding;
