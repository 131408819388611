import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from 'react-redux';
import { save, getAll, deleteOption, statusOption } from "../../redux/actions/options";
import CollapsibleCardComponent from "./CollapsibleCardComponent";
import GlobalModifierForm from "./forms/GlobalModifierForm";
import { Plus } from "react-feather";
import { Button } from "reactstrap";
import MathHelper from "../../helpers/MathHelper";

const GlobalModifiersList = (props) => {

  const dispatch = useDispatch();
  let endRef = useRef();

  const getItemsForView = (items) => {
    return items.map(item => ({
      title: item.option_name,
      id: item.option_id,
      ...item
    }));
  }

  let {
    selectedModifiers,
    updateModifiers,
    modifiersList,
    showConfirmationDialog,
    setSelectedIds,
    loc_id
  } = props;

  const [items, setItems] = useState(modifiersList ? getItemsForView(modifiersList) : []);
  const [savedId, lastSaved] = useState(-1);
  const [activeId, setActiveId] = useState(0);

  useEffect(() => {
    modifiersList = props.modifiersList;
    setItems(getItemsForView(modifiersList));
    selectedModifiers = props.selectedModifiers;
    let n = items.length;
    if (savedId !== -1) {
      const savedItem = modifiersList.filter((m) => m.option_id == savedId ? true : false);
      if (savedItem.length === 0) {
        updateSelectedModifiers(modifiersList[n - 1].option_id);
      } else
        updateSelectedModifiers(savedItem[0].option_id);
      lastSaved(-1);
    }
  }, [modifiersList]);

  const addNewModifier = () => {
    const newItem = {
      title: 'New Modifier',
      id: MathHelper.getRndInteger(),
      option_name: '',
      display_type: 'radio',
      priority: 1,
      loc_id: loc_id,
      description: '',
      options_layout: 1,
    };
    const itemsCopy = items.slice();
    itemsCopy.push(newItem);
    setItems(itemsCopy);
    toggleCollapse(newItem.id);
    setTimeout(() => {
      endRef.current.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  }

  const delItemFromList = (item) => {
    let index = 0;
    for (let i = 0; i < items.length; i++)
      if (items[i].id === item.id) {
        index = i;
        break;
      }
    const itemsCopy = items.slice();
    itemsCopy.splice(index, 1);
    setItems(itemsCopy);
  }

  const updateItem = async (formData, item) => {
    lastSaved(item.id);
    await dispatch(save(loc_id, formData));
    return dispatch(getAll(loc_id));
  }

  const toggleCollapse = id => {
    if (id === activeId)
      setActiveId(0)
    else
      setActiveId(id);
  }

  const updateSelectedModifiers = (selection) => {
    selection = parseInt(selection);
    let selected = selectedModifiers.slice();
    const index = selected.indexOf(selection);
    if (index === -1)
      selected.push(selection);
    else
      selected.splice(index, 1);
    setSelectedIds(selected);
  }

  const deleteItem = (item) => {
    showConfirmationDialog(async () => {
      let itemsCopy = items.filter(i => i.id !== item.id);
      await dispatch(deleteOption(loc_id, item.id));
      dispatch(getAll(loc_id));
      setItems(itemsCopy);
    }, null, 'Are you sure you want to delete this modifier?');
  }

  const changeStatus = async (item) => {
    await dispatch(statusOption(loc_id, item.id));
    dispatch(getAll(loc_id));
    let index = 0;
    for (let i = 0; i < items.length; i++)
      if (items[i].id === item.id) {
        index = i;
        break;
      }
    const itemsCopy = items[index];
    if(itemsCopy.status == 1){
      itemsCopy.status = 0;
    }else{
      itemsCopy.status = 1;
    }
    items[index] = itemsCopy
    setItems(items);
  }

  const options = {
    radio: 'Single',
    select: 'Selector',
    checkbox: 'Checkbox',
  }

  return (

    <div>
      {items && items.map(item => (
        <CollapsibleCardComponent changeStatusItem={changeStatus.bind(this)} deleteCardItem={deleteItem.bind(this)} badgeTitle={item.option_id ? item.display_type : null} updateSelectedModifiers={item.option_id ? updateSelectedModifiers.bind(this) : null} selectedIds={selectedModifiers} bodyColor='content-dark-bg-color' item={item} toggleCollapse={toggleCollapse.bind(this)} isOpen={item.id === activeId} >
          <GlobalModifierForm delItemFromList={delItemFromList.bind(this)} showConfirmationDialog={showConfirmationDialog} item={item} updateModifier={updateItem.bind(this)} toggleCollapse={toggleCollapse.bind(this)} />
        </CollapsibleCardComponent>
      ))}
      {items && items.length === 0 ? <span className="mb-1">No Modifiers created yet.</span> : null}
      <Button outline className="font-weight-bold custom-padding disabled-2-border" color={"secondary"} size="md" onClick={addNewModifier}>
        <Plus className="mx-50" size={14} /> <span className="ml-50 mr-50">Create a new modifier</span>
      </Button>
      <div ref={endRef} id='emptyEndDiv'></div>
    </div>
  );
}

export default GlobalModifiersList;