import React, { Component } from 'react';
import { Card, Input, Row, Col, Label, Button } from 'reactstrap';
import SpinnerComponent from '../@vuexy/spinner/Fallback-spinner';
import ErrorHandler from '../shared/ErrorHandler';
import LocationService from '../../services/LocationService';
import OrderTypeService from '../../services/OrderTypeService';
import OrderTypeHelper from '../../helpers/OrderTypeHelper';
import _ from 'lodash';
import '../../assets/scss/plugins/forms/switch/react-toggle.scss';
import 'react-toggle/style.css';
import Toggle from "react-toggle";

class LocationOrderTypes extends Component {

  constructor(props) {
    super(props);
    this.state = {
      orderTypes: null,
      location: null,
      loading: false
    }
  }

  async componentDidMount() {
    const orderTypes = await OrderTypeService.getOrderTypes(this.props.location.loc_id);
    await this.setState({ orderTypes })
  }

  onMinimumOrderChange(event) {
    let orderTypeId = parseInt(event.target.getAttribute('data-id'), 10);
    let orderTypes = [...this.state.orderTypes];
    orderTypes.forEach((orderType) => {
      if (orderType.id === orderTypeId) orderType[event.target.name] = event.target.value;
    });
    this.setState({ orderTypes });
  }

  onValueChange(isToggle, event) {
    let orderTypeId = parseInt(event.target.getAttribute('data-id'), 10);
    let orderTypes = [...this.state.orderTypes];
    let orderType = orderTypes.filter(o => o.id === orderTypeId)[0];
    if (isToggle)
      orderType[event.target.name] = !orderType[event.target.name];
    else
      orderType[event.target.name] = event.target.value;
    this.setState({ orderTypes });
  }

  async onChangeInput(event) {
    await this.setState({ [event.target.name]: event.target.value });
  }

  saveOrderType() {
    this.updateTypes(this.props.location.loc_id, this.state.orderTypes);
  }

  async updateTypes(loc_id, orderTypes) {
    try {
      await this.setState({ loading: true });
      await OrderTypeService.updateOrderTypes(loc_id, orderTypes);
      await this.setState({ loading: false });
    } catch (e) {
      alert('Something went wrong. Please refresh the page and try again.');
    }
  }

  renderOrderTypes() {
    return <Card className='full-width'>
      <div className='display-flex flex-row flex-space-between my-50 mx-50'>
        <div className='flex-3'>
        </div>
        <h6 className='flex-2'>
          Status
        </h6>
        <h6 className='flex-2'>
          Prepayment
        </h6>
        <h6 className='d-sm-none d-none d-md-block flex-2'>
          Minimum Order
        </h6>
      </div>
      {this.state.orderTypes.map((orderType, index) => {
        if (orderType.loading) return <SpinnerComponent size={20} key={orderType.id} />;
        return (
          <div className='display-flex flex-row flex-space-between my-1 mx-50'>
            <h5 className='flex-3'>
              {orderType.label}
            </h5>
            <div className='flex-2'>
              <Toggle
                name={'active'}
                data-id={orderType.id}
                defaultChecked={orderType['active']}
                className='switch-danger'
                onChange={this.onValueChange.bind(this, true)}
              />
              <label className='vertical-align-super font-small-3 ml-25'>{orderType['active'] ? 'Active' : 'Inactive'}</label>
            </div>
            <div className='flex-2'>
              <Toggle
                name={'pre_payment'}
                data-id={orderType.id}
                defaultChecked={orderType['pre_payment']}
                className='switch-danger'
                onChange={this.onValueChange.bind(this, true)}
              />
              <label className='vertical-align-super font-small-3 ml-25'>{orderType['pre_payment'] ? 'Mandatory' : 'Optional'}</label>
            </div>
            <div className=' d-md-block flex-2'>
              {OrderTypeHelper.isDelivery(this.state.orderTypes, orderType.id) ?
                <Input
                  data-id={orderType.id}
                  name={`minimum_order`}
                  placeholder={'Minimum Order'}
                  type='number'
                  value={orderType.minimum_order}
                  onChange={this.onValueChange.bind(this, false)}
                /> :
                <Input
                  data-id={orderType.id}
                  name={`order`}
                  placeholder={'Order'}
                  type='number'
                  hidden
                />
              }
            </div>
          </div>
        );
      })}

    </Card>
  }

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  render() {
    const { state: {
      orderTypes,
      loading,
    } } = this;
    if (loading) return <SpinnerComponent />;
    if (orderTypes && orderTypes.length > 0) {
      return (
        <>
          <div className='order-types'>
            <div className=''>
              {this.renderOrderTypes()}
            </div>
            <div className='actions'>
              <button className='btn btn-primary' onClick={this.saveOrderType.bind(this)} >Save Order Types</button>
            </div>
          </div>
        </>
      );
    } else {
      return '';
    }

    return <ErrorHandler />;
  }
}

export default LocationOrderTypes;
