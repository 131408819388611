import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import {
  getAllSessionActivities,
  applyFilter,
  clearFilters,
  setEmptyList,
  setFilterType,
} from "../../redux/actions/session-activity";
import SessionActivityList from "./SessionActivityList";
import { Input, Row, Col } from "reactstrap";
import InfoModal from "./InfoModal";
import NoData from "./noData";
import SessionCount from "./sessionCount";
const SessionActivity = (props) => {
  const {
    sessions,
    applyFilter,
    filtered_data,
    filters,
    getAllSessionActivities,
    loading,
    clearFilters,
    emptyList,
    setEmptyList,
    filterType,
    setFilterType,
  } = props;
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    getAllSessionActivities();
  }, []);

  const updateSearchFilter = (value) => {
    setSearchValue(value);
    const keyword = value;
    if (keyword !== "") {
      if (filters.length > 0) {
        const filteredList = filtered_data.filter((value) =>
          value.base_url.includes(searchValue)
        );
        filteredList.length === 0 && setEmptyList(true);
        applyFilter(filteredList, filters);
      } else {
        const filteredList = sessions.filter((value) =>
          value.base_url.includes(searchValue)
        );
        filteredList.length === 0 && setEmptyList(true);
        applyFilter(filteredList, filteredList);
      }
    } else {
      if (filterType === "time" && filters.length > filtered_data.length) {
        applyFilter(filters, filters);
      } else {
        clearFilters();
        setEmptyList(false);
      }
    }
  };

  return (
    <>
      <div className="location">
        <Row>
          <Col md="6" sm="12">
            <InfoModal />
          </Col>

          <Col md="6" sm="12">
            <div className="display-flex flex-row justify-content-flex-end mb-1 align-items-center">
              <Input
                disabled={
                  filterType === "time" &&
                  filtered_data.length === 0 &&
                  filters.length === 0
                }
                style={{ border: "1px solid #444" }}
                value={searchValue}
                placeholder="Search..."
                width={"100%"}
                onChange={(e) => updateSearchFilter(e.target.value)}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="6" sm="12"></Col>
          <Col md="6" sm="12">
            {filtered_data?.length === 0 && emptyList ? (
              <SessionCount count={0} />
            ) : (
              !loading &&
              sessions &&
              (filtered_data.length > 0 ? (
                <SessionCount count={filtered_data.length} />
              ) : (
                <SessionCount count={sessions.length} />
              ))
            )}
          </Col>
        </Row>
        {loading && <SpinnerComponent />}
        {!loading && sessions && (
          <SessionActivityList
            sessionActivities={
              filtered_data?.length > 0
                ? filtered_data
                : filtered_data?.length === 0 && emptyList
                ? []
                : sessions
            }
          />
        )}
        {filtered_data?.length === 0 && emptyList && <NoData />}
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const {
    sessionActivity: {
      allSessionActivity: {
        data: sessions,
        loading,
        filters,
        filtered_data,
        emptyList,
        filterType,
      },
    },
  } = state;
  return { sessions, loading, filters, filtered_data, emptyList, filterType };
}

export default connect(mapStateToProps, {
  getAllSessionActivities,
  applyFilter,
  clearFilters,
  setEmptyList,
  setFilterType,
})(SessionActivity);
