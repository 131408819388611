import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Media } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SmsCampaignsUsersList from './SmsCampaignsUsersList';
import { Edit2, XCircle, Trash, Play } from 'react-feather';

class SmsCampaignInbox extends Component {

	constructor(props) {
		super(props);
		this.state = {
			campaign_id: 0,
		};
	}

	async componentDidMount() {
		if (this.props.data.length > 0) {
			await this.setState({ campaign_id: this.props.data[0].id })
		}
	}

	async renderUsers(id) {
		await this.setState({ campaign_id: id })
	}

	render() {
		if (this.props.data.length > 0) {
			const renderCampaign = this.props.data.map(d => {
				return (
					<>
						<Media
							tag='li'
							key={d.id}
							className='mail-read'
							onClick={this.renderUsers.bind(this, d.id)}
						>
							<Media body>
								<div className='user-details flex-wrap'>
									<div className='mail-items'>
										<h5 className='text-bold-600 mb-25'>{d.name.substring(0, 13)}</h5>
									</div>
									<div>
										{d.status === 0 &&
											<>
												<Button outline className='btn-icon btn-small' color='danger' onClick={() => this.props.deleteCampaign(d.id)}><Trash color='var(--danger)' className='ml-0 p-0' size={10} /></Button>
												<Button outline className='btn-icon btn-small' color='warning' onClick={() => this.props.cancelCampaign(d.id)}><XCircle color='var(--warning)' className='ml-0 p-0' size={10} /></Button>
												<Button outline className='btn-icon btn-small' color='primary' onClick={() => this.props.editCampaign(d.id)}><Edit2 color='var(--primary)' className='ml-0 p-0' size={10} /></Button>
											</>
										}
										{d.status === 1 &&
											<>
												<Button outline className='btn-icon btn-small' color='danger' onClick={() => this.props.deleteCampaign(d.id)}><Trash color='var(--danger)' className='ml-0 p-0' size={10} /></Button>
												<Button outline className='btn-icon btn-small' color='warning' onClick={() => this.props.cancelCampaign(d.id)}><Play color='var(--warning)' className='ml-0 p-0' size={10} /></Button>
											</>
										}
									</div>
								</div>
								<div className='mail-message'>
									<p className='list-group-item-text truncate mb-0 white'>
										{d.sms}
									</p>
								</div>
							</Media>
						</Media>
					</>
				);
			})
			return (
				<div className='location'>
					<div className='email-application position-relative'>
						<div className='sidebar-content email-app-sidebar d-flex email-sidebar'>
							<div className='email-app-area email-side-width'>
								<div className='email-app-list-wrapper'>
									<div className='email-app-list'>
										<PerfectScrollbar
											className='email-user-list list-group'
											options={{
												wheelPropagation: false
											}}
										>
											<ul className='users-list-wrapper media-list'>
												{renderCampaign}
											</ul>
										</PerfectScrollbar>
									</div>
								</div>
							</div>
						</div>
						<SmsCampaignsUsersList campaign_id={this.state.campaign_id} />
					</div>
				</div>
			);
		} else {
			return (
				<div className='no-results show'>
					<h5>No Campaigns Found</h5>
				</div>
			);
		}
	}
}
export default withRouter(SmsCampaignInbox);