import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Button,
	Badge,
} from 'reactstrap';
import { ChevronDown } from 'react-feather';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import CONSTANTS from '../../constants';
import TimeHelper from '../../helpers/TimeHelper';
import ViewHelper from '../../helpers/ViewHelper';

const getTitle = (type) => {
	if(type === 0) {
		return 'Local Orders';
	} else if(type === 1) {
		return 'PandaGo Orders';
	}
	return 'All Orders';
}

const DeliveryList = (props) => {

	const [isOpen, setOpen] = useState(false);
  const [filterTitle, setTitle] = useState('All Orders');

  const columns = [{
      name: 'Type',
      selector: 'type',
			sortable: true,
			format: ({ type }) => {
				return type ? 'PandaGo' : 'Local';
			}
    }, {
      name: 'Order',
      selector: 'order_id',
			sortable: true,
			format: ({ Order }) => {
				return <Link className='cursor-pointer' to={`/locations/${Order.location_id}/orders/${Order.order_id}`}>
					{Order.order_id}
				</Link>
			}
    }, {
      name: 'PandaGo ID',
      selector: 'third_party_id',
    }, {
      name: 'fee',
      selector: 'fee',
			sortable: true,
			format: (obj) => {
				let charges = 0;
				if(!obj.type) {
					charges = obj.Order.delivery_charges
				}
				if(obj.third_party_data) {
					const data = JSON.parse(obj.third_party_data);
					if(data.delivery_fee) {
						charges = data.delivery_fee;
					}
				}
				return ViewHelper.toCurrency(charges, props.location.Currency.currency_code, props.location.decimal_places);
			}
    }, {
      name: 'Requested at',
      selector: 'created_at',
			sortable: true,
			grow: 2,
			format: ({created_at}) => TimeHelper.toFormattedDate(created_at, CONSTANTS.DATE_TIMES.FORMAT.DB)
    }, {
			name: 'status',
      selector: 'status_id',
			sortable: true,
			format: ({status_id}) => {
				return <Badge
					color={
						CONSTANTS.VARIABLES.ORDERS.DELIVERY_ACTION_TEXT_COLORS[status_id]
					}
					pill
				>
					{CONSTANTS.VARIABLES.ORDERS.DELIVERY_ACTIONS_TO_TEXT[status_id]}
				</Badge>
			}
		}, {
      name: 'Last Updated',
      selector: 'updated_at',
			sortable: true,
			grow: 2,
			format: ({updated_at}) => TimeHelper.toFormattedDate(updated_at, CONSTANTS.DATE_TIMES.FORMAT.DB)
    }
  ];

	const renderDeliveries = (deliveries) => {
		return <DataTable
      columns={columns}
      data={deliveries}
      noHeader
    />;
	}
  return props.deliveries && <Card onClick={(e) => {
		setOpen(false)
	}}>
		<CardHeader>
			<CardTitle>
				Order Deliveries
			</CardTitle>
			<div right>
				<div tag="small" className="text-bold-500 cursor-pointer" onClick={(e) => {
					e.stopPropagation();
					setOpen(!isOpen)
				}}>
					{filterTitle} <ChevronDown size={10} />
				</div>
				{ isOpen &&
				<div className='col-md-3 col-lg-3 col-xl-3 col-6 no-padding filter-container'>
					<Button.Ripple outline
						onClick={() => {
							props.filterUpdated(null);
							setTitle('All Orders');
							setOpen( !isOpen );
						}} className='square full-width'>
						All Orders
					</Button.Ripple>
					<Button.Ripple outline
						onClick={() => {
							props.filterUpdated(0);
							setTitle('Local Orders');
							setOpen( !isOpen );
						}} className='square full-width'>
						Local Orders
					</Button.Ripple>
					<Button.Ripple outline
						onClick={() => {
							props.filterUpdated(1);
							setTitle('PandaGo Orders');
							setOpen( !isOpen );
						}} className='square full-width'>
						PandaGo Orders
					</Button.Ripple>
				</div> }
			</div>
		</CardHeader>
		<CardBody>
			{renderDeliveries(props.deliveries)}
		</CardBody>
	</Card>
}

export default DeliveryList;