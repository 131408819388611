import { combineReducers } from 'redux'
import { allPromotions, promotion } from "./PromotionsReducer";


const promotionsReducer = combineReducers({
  allPromotions,
  promotion,
});

export default promotionsReducer;
