import CONSTANTS from "../../../constants";
import SessionActivityService from "../../../services/SessionActivityService";

export function getAllSessionActivities() {
  return async (dispatch) => {
    try {
      dispatch({
        type: CONSTANTS.ACTIONS.SESSION_ACTIVITY.ALL_SESSIONS_ACTIVITY,
        session_activity: [],
        loading: true,
      });
      let session_activity =
        await SessionActivityService.getAllSessionActivities();
      dispatch({
        type: CONSTANTS.ACTIONS.SESSION_ACTIVITY.ALL_SESSIONS_ACTIVITY,
        session_activity,
        loading: false,
      });
    } catch (error) {
      dispatch({
        type: CONSTANTS.ACTIONS.SESSION_ACTIVITY.ALL_SESSIONS_ACTIVITY,
        session_activity: [],
        loading: false,
      });
    }
  };
}

export const applyFilter = (filteredData, filters) => {
  let filteredSessions = [...filteredData];
  return async (dispatch) => {
    dispatch({
      type: CONSTANTS.ACTIONS.SESSION_ACTIVITY.SESSION_ACTIVITY_FILTER_UPDATE,
      filtered_data: filteredSessions,
      filters,
    });
  };
};

export const setFilterType = (filterType) => {
  return async (dispatch) => {
    dispatch({
      type: CONSTANTS.ACTIONS.SESSION_ACTIVITY.FILTER_TYPE,
      filterType: filterType,
    });
  };
};

export const setEmptyList = (value) => {
  return async (dispatch) => {
    dispatch({
      type: CONSTANTS.ACTIONS.SESSION_ACTIVITY.SET_EMPTY_LIST,
      emptyList: value,
    });
  };
};

export const clearFilters = () => {
  return async (dispatch) => {
    dispatch({
      type: CONSTANTS.ACTIONS.SESSION_ACTIVITY.CLEAR_FILTERS,
    });
  };
};
