import React from "react";
import Wizard from "../@vuexy/wizard/WizardComponent";
import { AvInput, AvGroup, AvFeedback, AvField, } from "availity-reactstrap-validation";
import {
  Label,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Button, FormGroup
} from "reactstrap";
import _ from 'lodash';
import Checkbox from "../@vuexy/checkbox/CheckboxesVuexy";
import {Check, ChevronDown, ChevronUp, Edit2, Phone, Plus, Trash} from "react-feather";
import Radio from "../@vuexy/radio/RadioVuexy";
import Flatpickr from "react-flatpickr";
import LocationService from "../../services/LocationService";
import CONSTANTS from "../../constants";
import TimeHelper from "../../helpers/TimeHelper";
import {connect} from "react-redux";
import { getMenuGroupedByCategory } from "../../redux/actions/categories";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import Select from "react-select";
import LocationSelectHeader from "../shared/LocationSelectHeader";
import {getLocation} from "../../redux/actions/locations";
import OrderTypeService from "../../services/OrderTypeService";
import {savePromotion, updatePromotion} from "../../redux/actions/promotions";
import PromotionService from '../../services/PromotionService';
import ViewHelper from "../../helpers/ViewHelper";
import CategoryItemSelectionModal from "./CategoryItemSelectionModal";
import Toggle from "react-toggle";

const CustomInput = ({ value, defaultValue, inputRef, ...props }) => {
  return <>
    <input type='text' {...props} defaultValue={defaultValue} ref={inputRef} className='btn opacity-0 position-absolute zindex-4' />
    <div className='btn btn-outline-primary'>
      <span className="ml-1 mr-1 primary">Add Dates</span>
    </div>
  </>
};

class PromotionForm extends React.Component {
  state = {
    activeStep: 0,
    isMenuItemModalOpen: false,
    promotion: {
      name: '',
      customer_description: '',
      active: true,
      automatic: true,
      loc_id: '',
      start_date: TimeHelper.getCurrentDate(),
      end_date: TimeHelper.getCurrentDate(),
      code: '',
      promotion_type: 0,
      usage_limit: 0,
      usage_limit_user: 0,
      max_discount_amount: 0,
      applicable_time_type: 0,
      order_types: [],
      time_slots: {
        start_time: '00:00',
        end_time: '23:59',
        days: [0, 1, 2, 3, 4, 5, 6],
      },
      PromotionDiscount: {
        discount_type: null,
        discount_amount: 0,
        discount_amount_type: 1,
        PromotionDiscountLevels: [{
          expenditure: 0,
          expenditure_type: 0,
          reward: 0,
          reward_type: 1,
        }],
        PromotionDiscountItems: [],
      },
      PromotionTimeOnlySlots: [{
        day: 7,
        start_time: '00:00',
        end_time: '23:59',
      }],
      PromotionTimeSlots: [{
        day: 0,
        start_time: '00:00',
        end_time: '23:59',
        active: true,
      }, {
        day: 1,
        start_time: '00:00',
        end_time: '23:59',
        active: true,
      }, {
        day: 2,
        start_time: '00:00',
        end_time: '23:59',
        active: true,
      }, {
        day: 3,
        start_time: '00:00',
        end_time: '23:59',
        active: true,
      }, {
        day: 4,
        start_time: '00:00',
        end_time: '23:59',
        active: true,
      }, {
        day: 5,
        start_time: '00:00',
        end_time: '23:59',
        active: true,
      }, {
        day: 6,
        start_time: '00:00',
        end_time: '23:59',
        active: true,
      }],
      PromotionDateSlots: [],
    },
    dummyDiscountItem: {
      label: '',
      item: 0,
      item_type: 0,
      discount_amount: 0,
      discount_amount_type: 1
    },
    dummyDiscountLevel: {
      expenditure: 0,
      expenditure_type: 0,
      reward: 0,
      reward_type: 1,
    },
    multipleDates: [],
    orderTypes: [],
    saving: false,
    errorSaving: false,
    weekdays: [{
      label: 'Monday',
      value: 0,
    },{
      label: 'Tuesday',
      value: 1,
    },{
      label: 'Wednesday',
      value: 2,
    },{
      label: 'Thursday',
      value: 3,
    },{
      label: 'Friday',
      value: 4,
    },{
      label: 'Saturday',
      value: 5,
    }, {
      label: 'Sunday',
      value: 6,
    }]
  }

  async getPromotionFromId(loc_id, id) {
    let promotion = await PromotionService.getPromotion(loc_id, id);
    promotion.order_types = promotion.PromotionOrderTypes.map(type => type.order_type_id);
    let days = promotion.PromotionTimeSlots.map(slot => slot.day);
    promotion.time_slots = {
      start_time: days.length > 0 ? promotion.PromotionTimeSlots[0].start_time : '00:00',
      end_time: days.length > 0 ? promotion.PromotionTimeSlots[0].end_time : '23:59',
    };
    days = _.uniq(days);
    if(promotion.applicable_time_type == CONSTANTS.VARIABLES.PROMOTIONS.APPLICABLE_TIME_TYPE.WEEK_DAYS) {
      let daysToAdd = _.difference(['0','1','2','3','4','5','6'], days);
      for(let i=0; i<promotion.PromotionTimeSlots.length; i++)
        promotion.PromotionTimeSlots[i]['active'] = true;
      for(let i=0; i<daysToAdd.length; i++)
        promotion.PromotionTimeSlots.push({
          day: parseInt(daysToAdd[i]),
          start_time: '00:00',
          end_time: '23:59',
          active: false,
        });
    } else {
      days = days.map( d => new Date(d));
    }
    promotion.time_slots.days = days;
    promotion.PromotionDateSlots = [];
    return promotion;
  }

  async componentDidMount() {
    const location = LocationService.getLocation();
    if(location) {
      this.props.getLocation(location.loc_id);
      this.props.getMenuGroupedByCategory(location.loc_id, 1);
      const orderTypes = await OrderTypeService.getOrderTypes(location.loc_id);
      const orderTypeIds = orderTypes.map( o => o.id );
      let promotion = this.state.promotion;
      promotion.order_types = orderTypeIds;
      let multipleDates = this.state.multipleDates;
      let editMode = false;
      if(window.location.pathname.indexOf('edit') !== -1) {
        const parts = window.location.pathname.split('/');
        promotion = await this.getPromotionFromId(location.loc_id, parts[parts.length - 1]);
        if(promotion.applicable_time_type == CONSTANTS.VARIABLES.PROMOTIONS.APPLICABLE_TIME_TYPE.EVERY_DAY_SPECIFIC_TIMES)
          promotion.PromotionTimeOnlySlots = promotion.PromotionTimeSlots;
        else
          promotion.PromotionTimeOnlySlots = this.state.promotion.PromotionTimeOnlySlots.slice();

        if(promotion.applicable_time_type == CONSTANTS.VARIABLES.PROMOTIONS.APPLICABLE_TIME_TYPE.SPECIFIC_DATES) {
          multipleDates = promotion.time_slots.days;
          promotion.PromotionDateSlots = promotion.PromotionTimeSlots;
          promotion.PromotionTimeSlots = this.state.promotion.PromotionTimeSlots.slice();
          promotion.time_slots.days = [];
        } else if(promotion.applicable_time_type != CONSTANTS.VARIABLES.PROMOTIONS.APPLICABLE_TIME_TYPE.WEEK_DAYS) {
          promotion.PromotionTimeSlots = this.state.promotion.PromotionTimeSlots.slice();
        }
        editMode = true;
      }
      this.setState({ orderTypes, promotion, editMode, multipleDates });
    } else {
      this.props.history.replace('/locations/promotions');
    }
  }

  async updateValue(value, type) {
    if(!isNaN(value) && value)
      value = parseInt(value);
    let promotion = JSON.parse(JSON.stringify(this.state.promotion));
    let obj = promotion;
    let parts = type.split('.');
    const n = parts.length - 1;
    for(let i=0; i<n; i++)
      obj = obj[parts[i]];
    obj[parts[n]] = value;
    this.setState({promotion});
  }

  addDiscountLevel() {
    let promotion = JSON.parse(JSON.stringify(this.state.promotion));
    let {
      PromotionDiscount: {
        PromotionDiscountLevels
      }
    } = promotion;
    PromotionDiscountLevels.push(JSON.parse(JSON.stringify(this.state.dummyDiscountLevel)));
    this.setState({ promotion });
  }

  deleteDiscountLevel(index) {
    let promotion = JSON.parse(JSON.stringify(this.state.promotion));
    let {
      PromotionDiscount: {
        PromotionDiscountLevels
      }
    } = promotion;
    PromotionDiscountLevels.splice(index, 1);
    this.setState({ promotion });
  }

  updateDiscountLevel(value, type, index) {
    let promotion = JSON.parse(JSON.stringify(this.state.promotion));
    let {
      PromotionDiscount: {
        PromotionDiscountLevels
      }
    } = promotion;
    PromotionDiscountLevels[index][type] = value;
    this.setState({ promotion });
  }

  addPromotionItem(itemIds, categoryIds) {
    let { state : {
      promotion: prom,
      dummyDiscountItem
    }} = this;
    let promotion = JSON.parse(JSON.stringify(prom));
    const {
      PromotionDiscount: {
        discount_amount,
        discount_amount_type,
        PromotionDiscountItems
      },
    } = promotion;

    const oldItems = {};
    const oldCats = {};

    for(let i=0; i<PromotionDiscountItems.length; i++) {
      const di = PromotionDiscountItems[i];
      if(di.item_type == 1)
        oldItems[di.item] = {discount_amount: di.discount_amount, discount_amount_type: di.discount_amount_type};
      else
        oldCats[di.item] = {discount_amount: di.discount_amount, discount_amount_type: di.discount_amount_type};
    }

    const { props: {
      items,
      categories
    } } = this;

    const promotionDiscountItems = [];

    for(let i=0; i<items.length; i++) {
      if(itemIds.indexOf(items[i].value) !== -1) {
        let dummyItem = JSON.parse(JSON.stringify(dummyDiscountItem));
        dummyItem.label = items[i].label;
        dummyItem.item = items[i].value;
        dummyItem.item_type = 1;
        dummyItem.menu_price = items[i].price;
        dummyItem.category = items[i].category;
        if(oldItems[items[i].value]) {
          dummyItem.discount_amount = oldItems[items[i].value].discount_amount;
          dummyItem.discount_amount_type = oldItems[items[i].value].discount_amount_type;
        } else {
          dummyItem.discount_amount = discount_amount;
          dummyItem.discount_amount_type = discount_amount_type;
        }
        promotionDiscountItems.push(dummyItem);
      }
    }

    for( let i=0; i<categories.length; i++ ) {
      if(categoryIds.indexOf(categories[i].value) !== -1) {
        let dummyItem = JSON.parse(JSON.stringify(dummyDiscountItem));
        dummyItem.label = categories[i].label;
        dummyItem.item = categories[i].value;
        dummyItem.item_type = 0;
        if(oldCats[categories[i].value]) {
          dummyItem.discount_amount = oldCats[categories[i].value].discount_amount;
          dummyItem.discount_amount_type = oldCats[categories[i].value].discount_amount_type;
        } else {
          dummyItem.discount_amount = discount_amount;
          dummyItem.discount_amount_type = discount_amount_type;
        }
        promotionDiscountItems.push(dummyItem);
      }
    }
    promotion.PromotionDiscount.PromotionDiscountItems = promotionDiscountItems;
    this.setState({ promotion });
  }

  toggleOrderTypeId(id) {
    const {
      state: {
        promotion: prom
      }
    } = this;

    let promotion = JSON.parse(JSON.stringify(prom));

    const {
      order_types
    } = promotion;

    const index = order_types.indexOf(id);
    if(index === -1)
      order_types.push(id);
    else
      order_types.splice(index, 1);
    this.setState({ promotion });
  }

  updatePromotionItem(value, type, index) {
    let promotion = JSON.parse(JSON.stringify(this.state.promotion));
    let {
      PromotionDiscount: {
        PromotionDiscountItems
      }
    } = promotion;
    PromotionDiscountItems[index][type] = value;
    this.setState({ promotion });
  }

  renderDiscountLevels(discountLevels) {
    const {
      props: {
        location: {
          Currency: {
            currency_code
          }
        }
      }
    } = this;

    if(discountLevels && discountLevels.length > 0) {
      return discountLevels.map((d, i) => (
        <>
          <Col className='col-10 col-md-4'>
            <Label>Order Above</Label>
            <AvInput name={`order_above_${i}`} type="text" value={d.expenditure} required
                     onChange={(e) => this.updateDiscountLevel(e.target.value, 'expenditure', i)}/>
          </Col>
          <Col className="d-sm-block d-block d-none d-md-none m-auto align-text-center col-2">
            <Trash size={20} className='btn-flat-danger' onClick={(e) => {
              e.stopPropagation();
              this.deleteDiscountLevel(i);
            }}/>
          </Col>
          <div className="d-sm-block d-block d-none d-md-none my-25 full-width">
          </div>
          <Col className='col-12 col-md-4'>
            <Label>Discount Type</Label>
            <div id='time' onChange={(e) => this.updateDiscountLevel(e.target.value, 'reward_type', i)}>
              <div className="d-inline-block mr-1">
                <Radio label="Fixed" name={`discount_amount_type_${i}`} value={CONSTANTS.VARIABLES.PROMOTIONS.DISCOUNTS.REWARD_TYPE.FIXED} checked={d.reward_type == CONSTANTS.VARIABLES.PROMOTIONS.DISCOUNTS.REWARD_TYPE.FIXED}/>
              </div>
              <div className="d-inline-block mr-1">
                <Radio label="Percentage" name={`discount_amount_type_${i}`} value={CONSTANTS.VARIABLES.PROMOTIONS.DISCOUNTS.REWARD_TYPE.PERCENTAGE} checked={d.reward_type == CONSTANTS.VARIABLES.PROMOTIONS.DISCOUNTS.REWARD_TYPE.PERCENTAGE}/>
              </div>
            </div>
          </Col>
          <Col className='col-12 col-md-3'>
            <Label>Discount Amount</Label>
            <FormGroup className="position-relative form-label-group has-icon-left input-divider-left">
              <AvInput name={`discount_amount_${i}`} type="text" value={d.reward} required
                       onChange={(e) => this.updateDiscountLevel(e.target.value, 'reward', i)} min="0" max={`${ d.reward_type == CONSTANTS.VARIABLES.PROMOTIONS.DISCOUNTS.REWARD_TYPE.FIXED ? d.expenditure : 100 }`} />
              <div className="form-control-position">
                <span className="font-small-1">{ d.reward_type == CONSTANTS.VARIABLES.PROMOTIONS.DISCOUNTS.REWARD_TYPE.FIXED ? currency_code : '%' }</span>
              </div>
            </FormGroup>
          </Col>
          <Col md="1" sm="4" xs="4" className="d-md-block d-none d-sm-none m-auto align-text-center">
            <Trash size={20} className='btn-flat-danger' onClick={(e) => {
              e.stopPropagation();
              this.deleteDiscountLevel(i);
            }}/>
          </Col>
          <div className="d-sm-block d-block d-none d-md-none my-50 full-width">
          </div>
        </>
      ));
    }
    return null;
  }

  renderPromotionItems(promotionItems, maximum_discount, currency_code = 'PKR') {
    const {
      props: {
        itemCatMap
      }
    } = this;
    return promotionItems.map( (d, i) => {
			return (
				<React.Fragment key={d.id}>
					<Col className='col-8 col-md-3'>
						<Label>{ d.item_type == CONSTANTS.VARIABLES.PROMOTIONS.DISCOUNTS.ITEM_TYPES.CATEGORY ? 'Category' : `Item (${itemCatMap[d.item]?.category})`}</Label>
						<AvInput name={`item_${i}`} type="text" value={d.label} disabled />
					</Col>
					<Col className='col-4 col-md-2'>
						<Label>Price</Label>
						<AvInput name={`item_price_${i}`} type="text" value={d.item_type == CONSTANTS.VARIABLES.PROMOTIONS.DISCOUNTS.ITEM_TYPES.CATEGORY ? '--' : itemCatMap[d.item]?.price} disabled />
					</Col>
					<div className="d-sm-block d-block d-none d-md-none my-25 full-width">
					</div>
					<Col md="3" sm="12">
						<Label>Discount Type</Label>
						<div id='time' onChange={(e) => this.updatePromotionItem(e.target.value, 'discount_amount_type', i) }>
							<div className="d-inline-block mr-1">
								<Radio label="Fixed" name={`discount_amount_type_${i}`} value={CONSTANTS.VARIABLES.PROMOTIONS.DISCOUNTS.AMOUNT_TYPE.FIXED} checked={d.discount_amount_type == CONSTANTS.VARIABLES.PROMOTIONS.DISCOUNTS.AMOUNT_TYPE.FIXED} />
							</div>
							<div className="d-inline-block mr-1">
								<Radio label="Percentage" name={`discount_amount_type_${i}`} value={CONSTANTS.VARIABLES.PROMOTIONS.DISCOUNTS.AMOUNT_TYPE.PERCENTAGE} checked={d.discount_amount_type == CONSTANTS.VARIABLES.PROMOTIONS.DISCOUNTS.AMOUNT_TYPE.PERCENTAGE} />
							</div>
						</div>
					</Col>
					<Col md="4" sm="12">
						<Label>Discount Amount</Label>
						<FormGroup className="position-relative form-label-group has-icon-left">
							<AvInput name={`item_discount_amount_${i}`} type="number" value={d.discount_amount} onChange={e => this.updatePromotionItem(e.target.value, 'discount_amount', i)} required validate={{
								min: { value: 0 },
								max: { value: d.discount_amount_type == CONSTANTS.VARIABLES.PROMOTIONS.DISCOUNTS.AMOUNT_TYPE.PERCENTAGE ? 100 : d.item_type == CONSTANTS.VARIABLES.PROMOTIONS.DISCOUNTS.ITEM_TYPES.CATEGORY ? maximum_discount ? maximum_discount : 1000000 : itemCatMap[d.item]?.price }
							}} />
							<div className="form-control-position">
								<span className="font-small-1">{ d.discount_amount_type == CONSTANTS.VARIABLES.PROMOTIONS.DISCOUNTS.AMOUNT_TYPE.FIXED ? currency_code : '%' }</span>
							</div>
							{/*<AvFeedback>{ d.discount_amount_type == 1 ? 'Percentage values can be between 0-100' : 'Discount amount cannot be greater than item price'}</AvFeedback>*/}
						</FormGroup>
					</Col>
					<div className="d-sm-block d-block d-none d-md-none my-50 full-width">
					</div>
				</React.Fragment>
			)
		});
  }

  renderOrderTypes(orderTypes, selected) {
    return orderTypes.map( ot => (
      <div className="d-inline-block mr-2">
        <Checkbox
          color="primary"
          icon={<Check className="vx-icon" size={16} />}
          label={ot.label}
          disabled={!ot.active}
          defaultChecked={selected.indexOf(ot.id) !== -1}
          checked={selected.indexOf(ot.id) !== -1}
          value={ot.id}
          onChange={e => this.toggleOrderTypeId(parseInt(e.target.value))}
        />
      </div>
    ))
  }

  duplicateTimeSlot(index) {
    let promotion = JSON.parse(JSON.stringify(this.state.promotion));
    let {
      PromotionTimeSlots,
      PromotionDateSlots,
      PromotionTimeOnlySlots,
    } = promotion;

    let currentSlots = promotion.applicable_time_type == CONSTANTS.VARIABLES.PROMOTIONS.APPLICABLE_TIME_TYPE.SPECIFIC_DATES ? PromotionDateSlots :
      promotion.applicable_time_type == CONSTANTS.VARIABLES.PROMOTIONS.APPLICABLE_TIME_TYPE.WEEK_DAYS ? PromotionTimeSlots : PromotionTimeOnlySlots;

    const timeSlot = JSON.parse(JSON.stringify(currentSlots[index]));
    currentSlots.splice(index, 0, timeSlot);
    this.setState({ promotion });
  }

  deleteTimeSlot(index) {
    let promotion = JSON.parse(JSON.stringify(this.state.promotion));
    let {
      PromotionTimeSlots,
      PromotionDateSlots,
      PromotionTimeOnlySlots,
    } = promotion;

    let currentSlots = promotion.applicable_time_type == CONSTANTS.VARIABLES.PROMOTIONS.APPLICABLE_TIME_TYPE.SPECIFIC_DATES ? PromotionDateSlots :
      promotion.applicable_time_type == CONSTANTS.VARIABLES.PROMOTIONS.APPLICABLE_TIME_TYPE.WEEK_DAYS ? PromotionTimeSlots : PromotionTimeOnlySlots;

    currentSlots.splice(index, 1);
    this.setState({ promotion });
  }

  updateTimeSlotValue(value, key, index) {
    let promotion = JSON.parse(JSON.stringify(this.state.promotion));
    let {
      PromotionTimeSlots,
      PromotionDateSlots,
      PromotionTimeOnlySlots
    } = promotion;
    let currentSlots = promotion.applicable_time_type == CONSTANTS.VARIABLES.PROMOTIONS.APPLICABLE_TIME_TYPE.SPECIFIC_DATES ? PromotionDateSlots :
      promotion.applicable_time_type == CONSTANTS.VARIABLES.PROMOTIONS.APPLICABLE_TIME_TYPE.WEEK_DAYS ? PromotionTimeSlots : PromotionTimeOnlySlots;
    currentSlots[index][key] = value;
    if(key == 'active') {
      for(let i=0; i<currentSlots.length; i++) {
        if(currentSlots[i].day === currentSlots[index].day)
          currentSlots[i].active = value;
      }
    }
    this.setState({ promotion });
  }

  toggleItemsModal() {
    this.setState({isMenuItemModalOpen: !this.state.isMenuItemModalOpen});
  }

  renderTimeSlots(promotion) {
    const {
      applicable_time_type
    } = promotion;

    let promotionTimeSlots = promotion.PromotionTimeSlots;
    let showActive = true;
    if(applicable_time_type == CONSTANTS.VARIABLES.PROMOTIONS.APPLICABLE_TIME_TYPE.SPECIFIC_DATES) {
      promotionTimeSlots = promotion.PromotionDateSlots;
      showActive = false;
    }

    promotionTimeSlots = promotionTimeSlots.map( (pt, i) => ({ ...pt, index: i }) );

    const groupedSlots = _.groupBy(promotionTimeSlots, 'day');
    const keys = Object.keys(groupedSlots);
    return keys.map( (key, i) => {
      return groupedSlots[key].map( (timeSlot, i) => (
        <>
          { showActive && <Col className='col-3 col-md-1'>
            { i === 0 && <label className='react-toggle-wrapper w-25'>
              <Toggle
                name={'active'}
                data-id={timeSlot.active}
                defaultChecked={timeSlot.active}
                checked={timeSlot.active}
                className='switch-primary'
                onChange={(e) => {
                  this.updateTimeSlotValue(!timeSlot.active, 'active', timeSlot.index);
                }}
              />
            </label> }
          </Col> }
          <Col className='col-9 col-md-3 mb-2'>
            { i === 0 && <h4>{`${applicable_time_type == CONSTANTS.VARIABLES.PROMOTIONS.APPLICABLE_TIME_TYPE.SPECIFIC_DATES ? TimeHelper.toFormattedDate(timeSlot.day) : this.state.weekdays[timeSlot.day].label}`} </h4>}
          </Col>
          <Col md={3} sm={6} className='mb-2'>
            <Flatpickr
              className='form-control'
              name='start_time'
              defaultValue={timeSlot.start_time}
              value={timeSlot.start_time}
              options={{
                enableTime: true,
                noCalendar: true,
                time_24hr: true,
                minuteIncrement: 1
              }}
              onClose={ date => {
                this.updateTimeSlotValue(TimeHelper.toFormattedTime(date[0], CONSTANTS.TIMES.FORMAT.DB), 'start_time', timeSlot.index);
              }}
            />
          </Col>
          <Col md={3} sm={6} className='mb-2'>
            <Flatpickr
              className='form-control'
              name='end_time'
              defaultValue={timeSlot.end_time}
              value={timeSlot.end_time}
              options={{
                enableTime: true,
                noCalendar: true,
                time_24hr: true,
                minuteIncrement: 15
              }}
              onChange={date => {
                this.updateTimeSlotValue(TimeHelper.toFormattedTime(date[0], CONSTANTS.TIMES.FORMAT.DB), 'end_time', timeSlot.index);
              }}
            />
          </Col>
          <Col md={2} sm={12} className='mb-2'>
            <Button outline className='font-weight-bold custom-padding disabled-2-border' color={'secondary'} size='md'
                    onClick={() => this.duplicateTimeSlot(timeSlot.index)}>
              <Plus size={15}/>
            </Button>
            { ((showActive && i > 0) || !showActive) && <Button outline className='font-weight-bold custom-padding danger-2-border mx-2' color={'danger'} size='md'
                    onClick={() => this.deleteTimeSlot(timeSlot.index)}>
              <Trash size={16}/>
            </Button> }
          </Col>
        </>
      ));
    });
  }

  renderTimeSlotsOnly(promotion) {

    let promotionTimeSlots = promotion.PromotionTimeOnlySlots;

    promotionTimeSlots = promotionTimeSlots.map( (pt, i) => ({ ...pt, index: i }) );

    return promotionTimeSlots.map( (timeSlot, i) => (
      <Row>
        <Col md={3} sm={6} className='mb-2'>
          <Flatpickr
            className='form-control'
            name='start_time'
            defaultValue={timeSlot.start_time}
            value={timeSlot.start_time}
            options={{
              enableTime: true,
              noCalendar: true,
              time_24hr: true,
              minuteIncrement: 15
            }}
            onClose={ date => {
              this.updateTimeSlotValue(TimeHelper.toFormattedTime(date[0], CONSTANTS.TIMES.FORMAT.DB), 'start_time', timeSlot.index);
            }}
          />
        </Col>
        <Col md={3} sm={6} className='mb-2'>
          <Flatpickr
            className='form-control'
            name='end_time'
            defaultValue={timeSlot.end_time}
            value={timeSlot.end_time}
            options={{
              enableTime: true,
              noCalendar: true,
              time_24hr: true,
              minuteIncrement: 15
            }}
            onChange={date => {
              this.updateTimeSlotValue(TimeHelper.toFormattedTime(date[0], CONSTANTS.TIMES.FORMAT.DB), 'end_time', timeSlot.index);
            }}
          />
        </Col>
        <Col md={2} sm={12} className='mb-2'>
          <Button outline className='font-weight-bold custom-padding disabled-2-border' color={'secondary'} size='md'
                  onClick={() => this.duplicateTimeSlot(timeSlot.index)}>
            <Plus size={15}/>
          </Button>
          { i != 0 && <Button outline className='font-weight-bold custom-padding danger-2-border mx-2' color={'danger'} size='md'
                  onClick={() => this.deleteTimeSlot(timeSlot.index)}>
            <Trash size={16}/>
          </Button> }
        </Col>
      </Row>
    ));
  }

  _getStepsContentForPromotion(promotion) {
    const {
      name,
      customer_description,
      active,
      automatic,
      start_date,
      end_date,
      code,
      promotion_type,
      usage_limit,
      usage_limit_user,
      max_discount_amount,
      PromotionTimeSlots: promotionTimeSlots,
      PromotionDiscount: {
        discount_type,
        discount_amount,
        discount_amount_type,
        PromotionDiscountItems: promotionDiscountItems,
        PromotionDiscountLevels: promotionDiscountLevels
      },
      applicable_time_type,
      order_types,
      time_slots: {
        start_time,
        end_time,
        days
      },
    } = promotion;

    let currency_code = 'PKR';

    const {
      props: {
        location
    }} = this;

    if(location)
      currency_code = location.Currency.currency_code;

    const steps = [
      {
        title: 1,
        content: <Row>
          <Col md="6" sm="12">
            <AvGroup>
              <Label>Name</Label>
              <AvInput name="name" type="text" value={name} required onChange={(e) => this.updateValue(e.target.value, 'name')} />
              <AvFeedback>Please enter valid Name</AvFeedback>
            </AvGroup>
          </Col>
          <Col md="6" sm="12">
            <Label>Status</Label>
            <div id='time' onChange={(e) => this.updateValue(e.target.value, 'active') }>
              <div className="d-inline-block mr-1">
                <Radio label="Active" name="ratable" value={1} checked={active} />
              </div>
              <div className="d-inline-block mr-1">
                <Radio label="Inactive" name="ratable" value={0} checked={!active} />
              </div>
            </div>
          </Col>
          <Col md="6" sm="12">
            <AvGroup>
              <Label> Promo Code </Label>
              <AvInput name="code" type="text" value={code} onChange={(e) => this.updateValue(e.target.value, 'code')} validate={{
                required: {value: true},
                pattern: {value: '^[A-Za-z0-9-_]+$'},
                minLength: {value: 6},
                maxLength: {value: 20}
              }} />
              <AvFeedback>No Spaces allowed. Only 6 - 20 alphabets allowed.</AvFeedback>
            </AvGroup>
          </Col>
          <Col md="6" sm="12">
            <Label>Promotion type</Label>
            <div id='time' onChange={(e) => this.updateValue(e.target.value, 'automatic') }>
              <div className="d-inline-block mr-1">
                <Radio label="Automatic" name="promotion_type" value={CONSTANTS.VARIABLES.PROMOTIONS.TYPE.AUTOMATIC} checked={automatic} />
              </div>
              <div className="d-inline-block mr-1">
                <Radio label="Manual" name="promotion_type" value={CONSTANTS.VARIABLES.PROMOTIONS.TYPE.MANUAL} checked={!automatic} />
              </div>
            </div>
          </Col>
          <Col md="12" sm="12">
            <AvGroup>
              <Label> Customer Description </Label>
              <AvInput name="customer_description" rows={3} type="textarea" value={customer_description} onChange={e => this.updateValue(e.target.value, 'customer_description')} />
            </AvGroup>
          </Col>
          <Col className='col-6'>
            <AvGroup>
              <Label> Start Date </Label>
              <Flatpickr
                id="date-interval"
                className="form-control"
                options={{
                  defaultDate: start_date
                }}
                value={start_date}
                onChange={(d) => {
                  this.updateValue(TimeHelper.toFormattedDate(d[0], CONSTANTS.DATES.FORMAT.DB_DATE), 'start_date')
                }}
              />
            </AvGroup>
          </Col>
          <Col className='col-6'>
            <AvGroup>
              <Label> End Date </Label>
              <Flatpickr
                id="date-interval"
                className="form-control"
                options={{
                  defaultDate: end_date
                }}
                value={end_date}
                onChange={(d) => {
                  this.updateValue(TimeHelper.toFormattedDate(d[0], CONSTANTS.DATES.FORMAT.DB_DATE), 'end_date')
                }}
              />
            </AvGroup>
          </Col>
          <Col md="4" sm="12">
            <AvGroup>
              <Label> Overall Usage Limit </Label>
              <AvInput name="usage_limit" type="number" value={usage_limit} onChange={e => this.updateValue(e.target.value, 'usage_limit')} />
            </AvGroup>
          </Col>
          <Col md="4" sm="12">
            <AvGroup>
              <Label> Usage Limit per User </Label>
              <AvInput name="usage_limit_user" type="number" value={usage_limit_user} onChange={e => this.updateValue(e.target.value, 'usage_limit_user')} />
            </AvGroup>
          </Col>
          <Col md="4" sm="12">
            <AvGroup>
              <Label> Maximum discount amount </Label>
              <FormGroup className="position-relative form-label-group has-icon-left input-divider-left">
                <AvInput name="max_discount_amount" type="number" value={max_discount_amount} onChange={e => this.updateValue(e.target.value, 'max_discount_amount')} />
                <div className="form-control-position">
                  <span className="font-small-1">{ currency_code }</span>
                </div>
              </FormGroup>
            </AvGroup>
          </Col>
        </Row>
      },
      {
        title: 2,
        content: <Row>
          <Col md="12" sm="12">
            <Label>Discount Type</Label>
            <div id='time' onChange={(e) => this.updateValue(e.target.value, 'PromotionDiscount.discount_type') }>
              <div className="d-inline-block mr-1 my-75">
                <Radio label="Basket Discount" name="discount_type" value={CONSTANTS.VARIABLES.PROMOTIONS.DISCOUNTS.TYPE.BASKET} checked={discount_type === CONSTANTS.VARIABLES.PROMOTIONS.DISCOUNTS.TYPE.BASKET} />
              </div>
              <div className="d-inline-block mr-1 my-75">
                <Radio label="Item Discount" name="discount_type" value={99} checked={discount_type > 0} />
              </div>
            </div>
          </Col>
          { discount_type === CONSTANTS.VARIABLES.PROMOTIONS.DISCOUNTS.TYPE.BASKET &&
            <>
              { this.renderDiscountLevels(promotionDiscountLevels) }
              <Col md="12">
                <Button outline className="font-weight-bold custom-padding disabled-2-border my-1" color={ "secondary" } size="md" onClick={() => this.addDiscountLevel()}>
                  <Plus className="mx-2" size={20} /> <span className="ml-1 mr-5">Add Level</span>
                </Button>
              </Col>
            </> }
          { discount_type > CONSTANTS.VARIABLES.PROMOTIONS.DISCOUNTS.TYPE.BASKET ?
            <>
              <Col md="12" sm="12" className="my-1">
                <Label>Discount On:</Label>
                <div id='discount_cat' onChange={(e) => this.updateValue(e.target.value, 'PromotionDiscount.discount_type') }>
                  <div className="d-inline-block mr-1">
                    <Radio label="All items" name="discount_cat" value={CONSTANTS.VARIABLES.PROMOTIONS.DISCOUNTS.TYPE.ALL_ITEMS} checked={discount_type == CONSTANTS.VARIABLES.PROMOTIONS.DISCOUNTS.TYPE.ALL_ITEMS} />
                  </div>
                  <div className="d-inline-block mr-1">
                    <Radio label="Specific Items" name="discount_cat" value={CONSTANTS.VARIABLES.PROMOTIONS.DISCOUNTS.TYPE.SPECIFIC_ITEMS}  checked={discount_type == CONSTANTS.VARIABLES.PROMOTIONS.DISCOUNTS.TYPE.SPECIFIC_ITEMS} />
                  </div>
                </div>
              </Col>
              { discount_type === CONSTANTS.VARIABLES.PROMOTIONS.DISCOUNTS.TYPE.ALL_ITEMS || discount_type === CONSTANTS.VARIABLES.PROMOTIONS.DISCOUNTS.TYPE.SPECIFIC_ITEMS ?
                <>
                  <Col className='col-12'>
                    <div className="row full-width my-1">
                      <Col md="4" sm="6">
                        <Label>Default Discount Type</Label>
                        <div id='time' onChange={(e) => this.updateValue(e.target.value, 'PromotionDiscount.discount_amount_type') }>
                          <div className="d-inline-block mr-1">
                            <Radio label="Fixed" name={`item_discount_amount_type`} value={CONSTANTS.VARIABLES.PROMOTIONS.DISCOUNTS.AMOUNT_TYPE.FIXED} checked={discount_amount_type == CONSTANTS.VARIABLES.PROMOTIONS.DISCOUNTS.AMOUNT_TYPE.FIXED} />
                          </div>
                          <div className="d-inline-block mr-1">
                            <Radio label="Percentage" name={`item_discount_amount_type`} value={CONSTANTS.VARIABLES.PROMOTIONS.DISCOUNTS.AMOUNT_TYPE.PERCENTAGE} checked={discount_amount_type == CONSTANTS.VARIABLES.PROMOTIONS.DISCOUNTS.AMOUNT_TYPE.PERCENTAGE} />
                          </div>
                        </div>
                      </Col>
                      <Col md="8" sm="6">
                        <AvGroup>
                          <Label> Default Discount amount </Label>
                          <FormGroup className="position-relative form-label-group has-icon-left">
                            <AvInput name="item_discount_amount" type="number" value={discount_amount} onChange={e => this.updateValue(e.target.value, 'PromotionDiscount.discount_amount')} validate={{
                              min: { value: 0 },
                              max: { value: discount_amount_type == CONSTANTS.VARIABLES.PROMOTIONS.DISCOUNTS.AMOUNT_TYPE.PERCENTAGE ? 100 : max_discount_amount ? max_discount_amount : 1000000 }
                            }} />
                            <div className="form-control-position">
                              <span className="font-small-1">{ discount_amount_type == CONSTANTS.VARIABLES.PROMOTIONS.DISCOUNTS.AMOUNT_TYPE.FIXED ? currency_code : '%' }</span>
                            </div>
                            <AvFeedback>{ discount_amount_type == CONSTANTS.VARIABLES.PROMOTIONS.DISCOUNTS.AMOUNT_TYPE.PERCENTAGE ? 'Percentage values can be between 0-100' : 'Discount amount cannot be greater than maximum discount amount'}</AvFeedback>
                          </FormGroup>
                        </AvGroup>
                      </Col>
                    </div>
                  </Col>
                  { discount_type == CONSTANTS.VARIABLES.PROMOTIONS.DISCOUNTS.TYPE.SPECIFIC_ITEMS && <>
                    { this.renderPromotionItems(promotionDiscountItems, max_discount_amount, currency_code) }
                    <Col md={12} sm={12}>
                      <Button className='mb-1' color='primary' outline onClick={(e) => this.toggleItemsModal()}>
                        <Edit2 className="mx-2" size={20} /> <span className="ml-1 mr-5 primary">Edit Items</span>
                      </Button>
                    </Col>
                  </> }
                </> : null }
            </>
          : null }
        </Row>
      },
      {
        title: 3,
        content: <Row>
          <Col md="12" sm="12">
            <AvGroup>
              <Label> Applicable Order Types </Label>
              <div className="stacked-checkbox">
                { this.renderOrderTypes(this.state.orderTypes, order_types) }
              </div>
            </AvGroup>
          </Col>
          <Col md="12" sm="12">
            <AvGroup>
              <Label> Applicable Time Slots </Label>
              <div id='time' onChange={(e) => this.updateValue(e.target.value, 'applicable_time_type') }>
                <div className="d-inline-block mr-1">
                  <Radio label="Every Day" name={`applicable_time_type`} value={CONSTANTS.VARIABLES.PROMOTIONS.APPLICABLE_TIME_TYPE.ALL_TIME} checked={applicable_time_type == CONSTANTS.VARIABLES.PROMOTIONS.APPLICABLE_TIME_TYPE.ALL_TIME || applicable_time_type == CONSTANTS.VARIABLES.PROMOTIONS.APPLICABLE_TIME_TYPE.EVERY_DAY_SPECIFIC_TIMES} />
                </div>
                <div className="d-inline-block mr-1">
                  <Radio label="Specific Weekdays" name={`applicable_time_type`} value={CONSTANTS.VARIABLES.PROMOTIONS.APPLICABLE_TIME_TYPE.WEEK_DAYS} checked={applicable_time_type == CONSTANTS.VARIABLES.PROMOTIONS.APPLICABLE_TIME_TYPE.WEEK_DAYS} />
                </div>
                <div className="d-inline-block mr-1">
                  <Radio label="Specific Dates" name={`applicable_time_type`} value={CONSTANTS.VARIABLES.PROMOTIONS.APPLICABLE_TIME_TYPE.SPECIFIC_DATES} checked={applicable_time_type == CONSTANTS.VARIABLES.PROMOTIONS.APPLICABLE_TIME_TYPE.SPECIFIC_DATES} />
                </div>
              </div>
            </AvGroup>
          </Col>
          { ( applicable_time_type == CONSTANTS.VARIABLES.PROMOTIONS.APPLICABLE_TIME_TYPE.ALL_TIME ||
              applicable_time_type == CONSTANTS.VARIABLES.PROMOTIONS.APPLICABLE_TIME_TYPE.EVERY_DAY_SPECIFIC_TIMES ) &&
              <>
                <Col md="12" sm="12">
                  <AvGroup>
                    <div id='time' onChange={(e) => this.updateValue(e.target.value, 'applicable_time_type') }>
                      <div className="d-inline-block mr-1">
                        <Radio label="All Time" name={`applicable_every_day_time_type`} value={CONSTANTS.VARIABLES.PROMOTIONS.APPLICABLE_TIME_TYPE.ALL_TIME} checked={applicable_time_type == CONSTANTS.VARIABLES.PROMOTIONS.APPLICABLE_TIME_TYPE.ALL_TIME} />
                      </div>
                      <div className="d-inline-block mr-1">
                        <Radio label="Specific Times" name={`applicable_every_day_time_type`} value={CONSTANTS.VARIABLES.PROMOTIONS.APPLICABLE_TIME_TYPE.EVERY_DAY_SPECIFIC_TIMES} checked={applicable_time_type == CONSTANTS.VARIABLES.PROMOTIONS.APPLICABLE_TIME_TYPE.EVERY_DAY_SPECIFIC_TIMES} />
                      </div>
                    </div>
                  </AvGroup>
                </Col>
                { applicable_time_type == CONSTANTS.VARIABLES.PROMOTIONS.APPLICABLE_TIME_TYPE.EVERY_DAY_SPECIFIC_TIMES &&
                <Col md="12" sm="12">
                  { this.renderTimeSlotsOnly(promotion) }
                </Col>
                }
              </> }
          { applicable_time_type != CONSTANTS.VARIABLES.PROMOTIONS.APPLICABLE_TIME_TYPE.ALL_TIME &&
            applicable_time_type != CONSTANTS.VARIABLES.PROMOTIONS.APPLICABLE_TIME_TYPE.EVERY_DAY_SPECIFIC_TIMES &&
            <>
              { this.renderTimeSlots(promotion) }
              <Col md={12} sm={12} className="mb-2">
                { applicable_time_type == CONSTANTS.VARIABLES.PROMOTIONS.APPLICABLE_TIME_TYPE.SPECIFIC_DATES && <>
                  <Flatpickr
                    className='form-control'
                    value={this.state.multipleDates}
                    defaultValue={this.state.multipleDates}
                    options={{ mode: 'multiple', }}
                    onChange={async date => {
                      await this.setState({ multipleDates : date });
                      this.updateTimeSlots();
                    }}
                    render={({defaultValue, value, ...props}, ref) => {
                      return <CustomInput defaultValue={defaultValue} inputRef={ref} />;
                    }}
                  />
                </> }
              </Col>
            </>
          }
          <Col md="12" className='mb-1'>
            Timezone: <span className="primary">{location.timezone}</span>
          </Col>
        </Row>
      },
    ];
    return steps;
  }

  updateTimeSlots() {
    const {
      state: {
        multipleDates,
        promotion: prom,
      }
    } = this;

    const promotion = JSON.parse(JSON.stringify(prom));
    const {
      applicable_time_type,
      PromotionTimeSlots: promotionTimeSlots,
      PromotionDateSlots: promotionDateSlots,
      time_slots: {
        days: dayObjects
      }
    } = promotion;

    let days = _.map(dayObjects, 'value');

    let currentTimeSlots = promotionTimeSlots;
    let dates = days;
    if(applicable_time_type == CONSTANTS.VARIABLES.PROMOTIONS.APPLICABLE_TIME_TYPE.SPECIFIC_DATES) {
      dates = multipleDates.map(d => TimeHelper.toFormattedDate(d, CONSTANTS.DATES.FORMAT.DB_DATE));
      currentTimeSlots = promotionDateSlots;
    }
    let allDays = _.map(currentTimeSlots, 'day');
    allDays = _.uniq(allDays);

    const datesLength = dates.length;
    const timeSlotLength = allDays.length;
    const diff = datesLength - timeSlotLength;
    if (diff > 0) {
      const newIds = _.difference(dates, allDays);
      for (let i = 0; i < newIds.length; i++) {
        currentTimeSlots.push({
          day: newIds[i],
          start_time: '00:00',
          end_time: '23:59',
        });
      }
    } else if (diff < 0) {
      const newIds = _.difference(allDays, dates);
      const timeSlots = [];
      for (let i = 0; i < currentTimeSlots.length; i++) {
        if (newIds.indexOf(currentTimeSlots[i].day) === -1)
          timeSlots.push(currentTimeSlots[i]);
      }
      if(applicable_time_type == CONSTANTS.VARIABLES.PROMOTIONS.APPLICABLE_TIME_TYPE.WEEK_DAYS)
        promotion.PromotionTimeSlots = timeSlots;
      else
        promotion.PromotionDateSlots = timeSlots;
    }
    this.setState({ promotion });
  }

  async savePromotion() {
    const {
      state: {
        promotion,
        editMode
      }
    } = this;

    if(promotion.applicable_time_type == CONSTANTS.VARIABLES.PROMOTIONS.APPLICABLE_TIME_TYPE.SPECIFIC_DATES)
      promotion.PromotionTimeSlots = promotion.PromotionDateSlots;
    else if(promotion.applicable_time_type == CONSTANTS.VARIABLES.PROMOTIONS.APPLICABLE_TIME_TYPE.EVERY_DAY_SPECIFIC_TIMES)
      promotion.PromotionTimeSlots = promotion.PromotionTimeOnlySlots;
    else {
      promotion.PromotionTimeSlots = promotion.PromotionTimeSlots.filter( ts => ts.active );
    }
    await this.setState({ saving: true });
    if(!editMode)
      await this.props.savePromotion(this.props.location.loc_id, promotion);
    else
      await this.props.updatePromotion(this.props.location.loc_id, promotion);
    if(this.props.promotion)
      await this.props.history.replace('/locations/promotions');
    else
      this.setState({ errorSaving: true, saving: false })
  }

  handleStepChange(activeStep) {
    this.setState({ activeStep });
  }

  render() {
    const { props: {
      loading,
      menu,
      location
    }} = this;

    const { state: {
      promotion,
      saving,
      errorSaving,
      activeStep,
      isMenuItemModalOpen
    }} = this;

    const titles = ['Enter the Basic Details', 'Define Your discounts', 'Where and When it can be availed?'];

    if( loading ) return <SpinnerComponent />;

    const steps = this._getStepsContentForPromotion(promotion);
    return (
      <div className='location'>
        <div className='display-flex flex-row justify-content-between mb-1'>
          <LocationSelectHeader text={location.name} />
        </div>
        <Card>
          <CardHeader className='flex-column align-items-start'>
            <CardTitle>{`${titles[activeStep]}`}</CardTitle>
            { errorSaving ? <span className='danger'>Unable to save promotion. Your code must be unique.</span> : null}
          </CardHeader>
          <CardBody>
            <Wizard
              validate
              steps={steps}
              saving={saving}
              activeStep={activeStep}
              handleStepChange={this.handleStepChange.bind(this)}
              onFinish={this.savePromotion.bind(this)}
            />
          </CardBody>
        </Card>
        <CategoryItemSelectionModal updatePromotionItem={this.addPromotionItem.bind(this)} selectedItems={promotion.PromotionDiscount.PromotionDiscountItems} items={menu ?? []} isOpen={isMenuItemModalOpen} toggleModal={this.toggleItemsModal.bind(this)} location={location} />
      </div>
    )
  }
}

function mapStateToProps(state) {
  console.log(state);
  const { categories: {
    categoryMenu: {
      data: menuData, loading
    }
  }, locations: {
    location: {
      data: locationData, loading: locationLoading
    }
  }, promotions: {
    promotion: {
      data: promotion
    }
  } } = state;

  const categories = [];
  const items = [];
  const itemCatMap = {};
	if(menuData) {
    for (let i = 0; i < menuData.length; i++) {
      const c = menuData[i];
      categories.push({label: c.name, value: c.category_id});
      for (let j = 0; j < c.Menus.length; j++) {
        items.push({value: c.Menus[j].menu_id, label: c.Menus[j].menu_name, price: c.Menus[j].menu_price, category: c.name});
        itemCatMap[c.Menus[j].menu_id] = {value: c.Menus[j].menu_id, label: c.Menus[j].menu_name, price: c.Menus[j].menu_price, category: c.name};
      }
    }
  }
  return { categories, items, menu: menuData, promotion, location: locationData, loading: loading || locationLoading, itemCatMap };
}

export default connect(mapStateToProps, { getMenuGroupedByCategory, savePromotion, updatePromotion, getLocation })(PromotionForm);
