import React from "react";
import DataTable from "react-data-table-component";
import TimeHelper from "../../../helpers/TimeHelper";
import ViewHelper from "../../../helpers/ViewHelper";
import Checkbox from "../../@vuexy/checkbox/CheckboxesVuexy";
import { Check } from "react-feather";
import DataTableExtensions from 'react-data-table-component-extensions';


const ListAppUsers = (props) => {

  const columns = [
    {
      name: 'ID',
      selector: 'user_id',
      sortable: true,
    },
    {
      name: 'First Name',
      selector: 'first_name',
      sortable: true,
      format: (val) => {
        return val.first_name ? val.first_name : 'No Name';
      }
    },
    {
      name: 'Last Name',
      selector: 'last_name',
      sortable: true,
      format: (val) => {
        return val.last_name ? val.last_name : 'No Name';
      }
    }, {
      name: 'Nickname',
      selector: 'first_name',
      sortable: true,
      minWidth: '200px',
      format: (val) => {
        return val.first_name && val.last_name ? val.first_name + '' + val.last_name : 'No Nick Name';
      }
    }, {
      name: 'Email(Facebook)',
      selector: 'facebook_email',
      minWidth: '300px',
      sortable: true,
      format: (val) => {
        return val.facebook_email ? val.facebook_email : 'Not available';
      }
    }
    , {
      name: 'Email(Self)',
      selector: 'email',
      minWidth: '300px',
      sortable: true,
      format: (val) => {
        return val.email ? val.email : 'Not available';
      }
    }, {
      name: 'Phone Number',
      selector: 'phone_number',
      minWidth: '200px',
      sortable: true,
      format: (val) => {
        return val.phone_number ? val.phone_number : 'Not available';
      }
    }, {
      name: 'DOB',
      selector: 'date_of_birth',
      sortable: true,
      format: (val) => {
        return val.date_of_birth ? val.date_of_birth : 'Not available';
      }
    }, {
      name: 'City',
      selector: 'city',
      sortable: true,
      format: (val) => {
        return val.city ? val.city : 'Not available';
      }
    }, {
      name: 'Country',
      selector: 'country',
      sortable: true,
      format: (val) => {
        return val.country ? val.country : 'Not available';
      }
    }, {
      name: 'Device/OS',
      selector: 'last_used_device',
      minWidth: '300px',
      format: (val) => {
        return val.last_used_device ? val.last_used_device : 'Not available';
      }
    }, {
      name: 'Reg Date',
      selector: 'registration_date',
      minWidth: '300px',
      sortable: true,
      format: (val) => {
        return val.registration_date ? TimeHelper.toFormattedDateTime(val.registration_date) : 'Not available';
      }
    }, {
      name: 'Last App Use Date',
      selector: 'last_app_use_date',
      minWidth: '300px',
      sortable: true,
      format: (val) => {
        return val.last_app_use_date ? TimeHelper.toFormattedDateTime(val.last_app_use_date) : 'Not available';
      }
    }, {
      name: 'Banned',
      selector: 'is_banned',
    }, {
      name: 'Latitue',
      selector: 'latitude',
      format: (val) => {
        return val.latitude ? val.latitude : 'Not available';
      }
    }, {
      name: 'Longitude',
      selector: 'longitude',
      format: (val) => {
        return val.longitude ? val.longitude : 'Not available';
      }
    }, {
      name: 'Gender',
      selector: 'gender',
      format: (val) => {
        return val.gender ? val.gender : 'Not available';
      }
    }, {
      name: 'Score',
      selector: 'score',
      format: (val) => {
        return val.score ? val.score : 'Not available';
      }
    }
  ];

  const renderAppUsers = (users, handleChange) => {
    if (!users || users.length === 0)
      return <h1>No App user found.</h1>;
    const tableData = {
      columns: columns,
      data: users,
      print: false,
      export: false,
    };
    return <DataTableExtensions
      {...tableData}
    >
     <DataTable
        noHeader
        pagination
        paginationRowsPerPageOptions={[25, 50, 100]}
        paginationPerPage={50}
        selectableRows
        selectableRowsComponent={Checkbox}
        selectableRowsComponentProps={{
          color: "primary",
          icon: <Check className="vx-icon" size={12} />,
          label: "",
          size: "sm"
        }}
        onSelectedRowsChange={handleChange}
        columns={columns}
        data={users}
      /></DataTableExtensions>;
  }
      return renderAppUsers(props.users,props.handleChange);

}

      export default ListAppUsers;
