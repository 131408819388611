import React, { Component } from "react";
import { connect } from "react-redux";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import ErrorHandler from "../shared/ErrorHandler";
import { getAllRidersByLocation } from "../../redux/actions/riders";
import "../../assets/scss/pages/page-pricing.scss";
import ListRiders from "./ListRiders";
import { Button } from "reactstrap";
import ConfirmationModal from "../shared/forms/ConfirmationModal";
import LocationService from "../../services/LocationService";
import SelectLocation from "../shared/SelectLocation";
import LocationSelectHeader from "../shared/LocationSelectHeader";
import RiderAssignModal from "./assign/RiderAssignModal";
import SettlementCashModal from "./assign/SettlementCashModal";
import { createRiderPayment } from '../../redux/actions/riders';
import { assign } from "../../redux/actions/staff";

class Riders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      riders: "",
      staff: {
        staff_id: 0,
        staff_location_id: 0,
        staff_group_id: 15,
        user_id: 0,
      },
      location: null,
      selectLocation: false,
      errorMessage: "",
      errorPayment: '',
      errorDialog: {
        show: false,
        rejectCallback: () => { },
        title: "App Users",
        header: "App Users",
      },
      confirmationDialog: {
        show: false,
        acceptCallback: () => { },
        rejectCallback: () => { },
        title: "App Users",
        header: "App Users",
      },
      isAssignModalOpen: false,
      isSettleModalOpen: false,
      earnings: 0,
      cashInHand: 0,
      rider_id: 0,
      rider_name: '',
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    try {
      const location = LocationService.getLocation();
      if (location) {
        await this.refreshData(location.loc_id);
        this.setState({ loading: false });
      } else {
        this.setState({ loading: false, selectLocation: true });
      }
    } catch (e) {
      console.log(e);
      this.setState({ loading: false, error: true });
    }
  }
  async refreshData(loc_id, updateLocation = true) {
    let loc = this.state.location;
    if (updateLocation)
      loc = await LocationService.getLocationFromServer(loc_id);
    await this.updateValue(loc.loc_id, "staff_location_id");
    await this.props.getAllRidersByLocation(loc.loc_id);
    const location = {
      loc_id: loc.loc_id,
      location_status: loc.location_status,
      name: loc.name,
    };
    this.setState({ location });
  }

  handleChange = (state) => {
    let reviews = [];
    for (let i = 0; i < state.selectedRows.length; i++) {
      reviews.push(state.selectedRows[i].review_id);
    }
    this.setState({ reviews: reviews });
  };

  showConfirmationDialog(accept, reject, title, header) {
    const defaultState = {
      show: false,
      acceptCallback: () => { },
      rejectCallback: () => { },
      title: "",
      header: "",
    };

    const confirmationDialog = {
      show: true,
      acceptCallback: async () => {
        if (accept) accept();
        this.setState({ confirmationDialog: defaultState });
      },
      rejectCallback: () => {
        this.setState({ confirmationDialog: defaultState });
        if (reject) reject();
      },
      title: title,
      header: header,
    };
    this.setState({ confirmationDialog });
  }

  showErrorDialog(reject, title, header) {
    const defaultState = {
      show: false,
      rejectCallback: () => { },
      title: "",
      header: "",
    };

    const errorDialog = {
      show: true,
      rejectCallback: () => {
        this.setState({ errorDialog: defaultState });
        if (reject) reject();
      },
      title: title,
      header: header,
    };
    this.setState({ errorDialog });
  }

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  async onSelect(location) {
    this.setState({ loading: true });
    LocationService.setLocation(location);
    await this.refreshData(location.loc_id);
    this.setState({ selectLocation: false, loading: false });
  }

  changeLocation() {
    this.setState({ selectLocation: true });
  }

  async toggleAssignModal() {
    let {
      state: { isAssignModalOpen },
    } = this;
    isAssignModalOpen = !isAssignModalOpen;
    return this.setState({ isAssignModalOpen });
  }

  async toggleSettleModal(data) {
    let {
      state: { isSettleModalOpen },
    } = this;
    isSettleModalOpen = !isSettleModalOpen;
    return this.setState({ isSettleModalOpen, earnings: data.earning, cashInHand: data.total_cash, rider_id: data.rider_id, rider_name: data.rider_name });
  }
  async updateValue(value, type) {
    if (!isNaN(value) && value) value = parseInt(value);
    if (value == 11) {
      await this.setState({ isAdmin: true });
    } else {
      await this.setState({ isAdmin: false });
    }
    let staff = JSON.parse(JSON.stringify(this.state.staff));
    let obj = staff;
    let parts = type.split(".");
    const n = parts.length - 1;
    for (let i = 0; i < n; i++) obj = obj[parts[i]];
    obj[parts[n]] = value;
    await this.setState({ staff });
  }

  async setStaffUser(user) {
    await this.updateValue(user.staff_id, "staff_id");
    await this.updateValue(user.staff.User.user_id, "user_id");
    await this.setState({ errorMessage: "" });
  }

  async saveRider() {
    await this.props.assign(this.state.staff);
    if (this.props.staff.status === "success") {
      await this.toggleAssignModal();
      await this.refreshData(this.state.location.loc_id);
    }
    await this.setState({ errorMessage: this.props.staff.message });
  }

  async savePayment(data) {

    let payment = {
      'rider_id': data.rider_id,
      'loc_id': this.state.location.loc_id,
      'amount_received': data.amount,
      'type': data.settlement_type,
      'status': 1,
      'created_at': new Date(),
      'updated_at': new Date()
    }
    await this.props.createRiderPayment(this.state.location.loc_id, payment);
    if (this.props.payments.status === "success") {
      await this.toggleSettleModal(this.state);
      await this.refreshData(this.state.location.loc_id);
    }
    await this.setState({ errorPayment: this.props.payments.message });
  }


  render() {
    const {
      props: { riders },
    } = this;
    const {
      state: {
        confirmationDialog,
        errorDialog,
        selectLocation,
        location,
        loading,
        earnings,
        cashInHand,
        rider_id,
        rider_name
      },
    } = this;
    console.log(riders);
    const locationName = location ? location.name : "";

    if (selectLocation)
      return (
        <SelectLocation
          onBack={this.toggleLocation.bind(this)}
          onSelect={this.onSelect.bind(this)}
        />
      );

    if (loading) return <SpinnerComponent />;
    return (
      <>
        <div className="display-flex flex-row flex-space-between mb-1 align-items-center">
          <LocationSelectHeader
            text={locationName}
            onIconClick={this.changeLocation.bind(this)}
          />
          <Button
            outline
            className="font-weight-bold custom-padding disabled-2-border btn-sm-font"
            color={"secondary"}
            size="md"
            onClick={() => this.toggleAssignModal()}
          >
            <span className="ml-1 mr-1 d-md-inline">Assign Rider</span>
          </Button>
        </div>

        <div id="pricing-table">
          {/* <Button className='mb-1' color='danger' size='md' onClick={() => this.deleteReviews()}>
            <span className='ml-1 mr-1 d-md-inline'>Delete</span>
          </Button> */}
          {!loading && riders ? (
            <ListRiders
              riders={riders}
              handleChange={this.handleChange.bind(this)}
              toggleModal={this.toggleSettleModal.bind(this)}
            />
          ) : null}
        </div>
        <RiderAssignModal
          location={location}
          error={this.state.errorMessage}
          isOpen={this.state.isAssignModalOpen}
          toggleModal={this.toggleAssignModal.bind(this)}
          setUser={this.setStaffUser.bind(this)}
          save={this.saveRider.bind(this)}
        />
        <SettlementCashModal
          isOpen={this.state.isSettleModalOpen}
          toggleModal={this.toggleSettleModal.bind(this)}
          earnings={earnings}
          cashInHand={cashInHand}
          rider_id={rider_id}
          rider_name={rider_name}
          savePayment={this.savePayment.bind(this)}
        />
        <ConfirmationModal
          header={confirmationDialog.header}
          title={confirmationDialog.title}
          isOpen={confirmationDialog.show}
          reject={confirmationDialog.rejectCallback.bind(this)}
          rejectTitle={"Cancel"}
          accept={confirmationDialog.acceptCallback.bind(this)}
        />
        <ConfirmationModal
          header={errorDialog.header}
          title={errorDialog.title}
          isOpen={errorDialog.show}
          reject={errorDialog.rejectCallback.bind(this)}
          rejectTitle={"OK"}
        />
      </>
    );
    return <ErrorHandler />;
  }
}

function mapStateToProps(state) {
  console.log(state);
  const {
    staff: {
      staff: { data: staff },
    },
    riders: {
      riders: { data: riders, loading: loading },
      payment: {
        data: payments
      }
    },
  } = state;

  return { riders, payments, staff, loading: loading };
}

export default connect(mapStateToProps, { getAllRidersByLocation, createRiderPayment, assign })(
  Riders
);
