import React from 'react';
import { AvForm, AvInput, AvGroup, AvFeedback, AvField } from 'availity-reactstrap-validation';
import { Label, Row, Col, Card, CardBody, CardHeader, Button, ButtonGroup } from 'reactstrap';
import LocationService from '../../../services/LocationService';
import CONSTANTS from '../../../constants';
import { connect } from 'react-redux';
import SpinnerComponent from '../../@vuexy/spinner/Fallback-spinner';
import StaffService from '../../../services/StaffService';
import RolesService from '../../../services/RolesService';

import ConfirmationModal from '../../shared/forms/ConfirmationModal';
import { getAllLocations } from '../../../redux/actions/locations';
import { getRoles, getStaff, assign } from '../../../redux/actions/staff';
import Toggle from 'react-toggle';
import Select from 'react-select'
import AsyncSelect from 'react-select/async';
import _ from 'lodash';
import ViewHelper from "../../../helpers/ViewHelper";


import "../../../assets/scss/plugins/forms/react-select/_react-select.scss"

let roleOptions = [];
let locationsOptions = [];

class RoleAssignForm extends React.Component {
	state = {
		activeStep: 0,
		isOpenSelect: false,
		staff: {
			staff_id: 0,
			staff_location_id: 0,
			staff_group_id: 0,
			user_id: 0,
		},
		location: {
			loc_id: 0,
			name: 'All Venues',
			Currency: {
				currency_code: 'PKR'
			},
			decimal_places: 2,
		},
		loading: false,
		saving: false,
		errorSaving: false,
		errorWallet: false,
		users: [],
		isOpen: false,
		isAdmin: false,
		roles: [],
		searchTerm: '',
		errorDialog: {
			show: false,
			rejectCallback: () => { },
			title: 'App Reports',
			header: 'App Reports'
		},
		confirmationDialog: {
			show: false,
			acceptCallback: () => { },
			rejectCallback: () => { },
			title: 'Wallet',
			header: 'Wallet'
		}
	}

	async getStaffFromId(id) {
		let staff = await StaffService.getStaff(id);
		return staff;
	}

	async componentDidMount() {
		
		const roles = await RolesService.getAllRoles('all');
		await this.setState({ roles: ViewHelper.transformedRoles(roles) });
		const parts = window.location.pathname.split('/');
		await this.props.getStaff(parts[parts.length - 1]);
		await this.updateValue(parts[parts.length - 1], 'staff_id');
		this.setState({ loading: false });
	}


	onBlur() {
		this.filterUpdated();
	}

	toggleLocation() {
		this.setState({ selectLocation: !this.state.selectLocation });
	}

	async onSelect(location) {
		this.setState({ loading: true });
		LocationService.setLocation(location, CONSTANTS.CACHE_KEY_PREFIX_ANALYTICS);
		await this.refreshData(location.loc_id);
		this.setState({ selectLocation: false, loading: false });
	}

	toggleItemsModal() {
		this.setState({ isMenuItemModalOpen: !this.state.isMenuItemModalOpen });
	}

	async updateValue(value, type) {
		if (!isNaN(value) && value)
			value = parseInt(value);
		if (value == 11) {
			await this.setState({ isAdmin: true });
		} else {
			await this.setState({ isAdmin: false });
		}
		let staff = JSON.parse(JSON.stringify(this.state.staff));
		let obj = staff;
		let parts = type.split('.');
		const n = parts.length - 1;
		for (let i = 0; i < n; i++)
			obj = obj[parts[i]];
		obj[parts[n]] = value;
		await this.setState({ staff });
	}

	async setOpen(open) {
		await this.setState({ isOpen: open });
	}

	async setOpenSelect() {
		await this.setState({ isOpenSelect: !this.state.isOpenSelect });
	}

	async saveRoles() {
		await this.updateValue(this.props.staff.User ? this.props.staff.User.user_id : 0, 'user_id')
		await this.props.assign(this.state.staff);
    this.showErrorDialog(async () => {
			const parts = window.location.pathname.split('/');
      if (this.props.staff.status === 'success') {
        this.props.history.push('/staff/assign/'+parts[parts.length - 1]);
      }
    }, this.props.staff.message, 'Alert');
	}

	handleStepChange(activeStep) {
		this.setState({ activeStep });
	}

	showConfirmationDialog(accept, reject, title, header) {
		const defaultState = {
			show: false,
			acceptCallback: () => { },
			rejectCallback: () => { },
			title: '',
			header: ''
		};

		const confirmationDialog = {
			show: true,
			acceptCallback: async () => {
				accept();
				this.setState({ confirmationDialog: defaultState });
			},
			rejectCallback: () => {
				this.setState({ confirmationDialog: defaultState });
				if (reject)
					reject();
			},
			title: title,
			header: header
		}
		this.setState({ confirmationDialog });
	}


	showErrorDialog(reject, title, header) {
		const defaultState = {
			show: false,
			rejectCallback: () => { },
			title: '',
			header: ''
		};

		const errorDialog = {
			show: true,
			rejectCallback: () => {
				this.setState({ errorDialog: defaultState });
				if (reject)
					reject();
			},
			title: title,
			header: header
		}
		this.setState({ errorDialog });
	}

	onConditionChange = (selectedOption) => {
		this.updateValue(selectedOption.value, 'plan_time',);
	}

	handleInputChange = (searchTerm) => {
		this.setState({ searchTerm });
		return searchTerm;
	};

	async loadOptions(term, callback) {
		if (term.length < 3) return callback([]);
		try {
			const locations = await LocationService.getLocations(term, this.props.showOutlets);
			callback(ViewHelper.transformedLocations(locations));
		} catch (e) {
			console.log(e);
			alert('Could not load locations');
		}
	}


	onChange(selected) {
		if (selected) {
			if (this.props.onSelect) this.props.onSelect(selected.location);
			else this.onSelect(selected.location);
		}
	}


	onChangeRole(selected) {
		if (selected) {
			 this.onSelectRole(selected.value);
		}
	}

	onBlur() {
		if (this.props.onBlur) {
			this.props.onBlur();
		}
	}

	async onSelect(location) {
		await this.updateValue(location.loc_id, 'staff_location_id')
	}
	async onSelectRole(role) {
		await this.updateValue(role, 'staff_group_id')
	}

	render() {
		const { props: {
			roles,
			staff,
			locations,
		} } = this;

		const { state: {
			loading,
			errorSaving,
			editMode,
			confirmationDialog,
			errorDialog,
			isAdmin,
		} } = this;


		if (roles) {
			roleOptions = [];
			roles.map((row) => { roleOptions.push({ value: row.staff_group_id, label: row.staff_group_name }) })
		}

		if (loading) return <SpinnerComponent />;
		return (
			<div className='location'>
					<Card>
						<CardHeader className='flex-column align-items-start'>
							{errorSaving ? <span className='danger'>Unable to save staff.</span> : null}
						</CardHeader>
						<CardBody>
							<AvForm >
								<Row>
									<>
										<Col md='12' sm='12'>
											<AvGroup>
												<Label> Staff Email </Label>
												<AvInput name='staff_email' type='text' value={staff ? staff.staff_email : ''} disabled />
												<AvFeedback>Please enter staff email.</AvFeedback>
											</AvGroup>
										</Col>
										<Col md='12' sm='12'>
											<AvGroup>
												<Label> Roles </Label>
												<Select
													defaultValue={this.state.roles[0]}
													name="color"
													options={this.state.roles}
													onChange={this.onChangeRole.bind(this)}
													className='React'
													classNamePrefix='select'
												/>
											</AvGroup>
										</Col>
										{!isAdmin &&
											<Col md='12' sm='12'>
												<AvGroup>
													<Label> Locations </Label>
													<AsyncSelect
														placeholder='Please enter restaurant name'
														autoFocus
														isClearable
														cacheOptions
														onBlur={this.onBlur.bind(this)}
														onChange={this.onChange.bind(this)}
														loadOptions={this.loadOptions.bind(this)}
														onInputChange={this.handleInputChange.bind(this)}
														noOptionsMessage={() => 'No restaurants present.'}
														className='React'
														classNamePrefix='select'
														styles={{
															control: (provided) => { return { ...provided, borderRadius: '0.3rem' } },
															dropdownIndicator: (provided) => { return { ...provided, display: 'none' } },
															indicatorSeparator: (provided) => { return { ...provided, display: 'none', color: '#fff' } },
															input: (provided) => { return { ...provided, color: '#fff' } }
														}}
													/>

												</AvGroup>
											</Col>
										}
									</>
								</Row>
								<Button color='primary' size='md' onClick={() => this.saveRoles()}>
									<span className='ml-1 mr-1 d-md-inline '>Submit</span>
								</Button>
							</AvForm>
						</CardBody>
					</Card>
				<ConfirmationModal header={confirmationDialog.header} title={confirmationDialog.title} isOpen={confirmationDialog.show} reject={confirmationDialog.rejectCallback.bind(this)} rejectTitle={'OK'} />
				<ConfirmationModal header={errorDialog.header} title={errorDialog.title} isOpen={errorDialog.show} reject={errorDialog.rejectCallback.bind(this)} rejectTitle={'OK'} />
			</div>
		)
	}
}

function mapStateToProps(state) {
	const { staff: {
		allroles: {
			data: roles
		},
		staff: {
			data: staff
		}
	},
		locations: {
			location: {
				data: locations, loading: loading
			}
		} } = state;

	return { roles, staff, locations, loading: loading };
}

export default connect(mapStateToProps, { getRoles, getAllLocations, getStaff, assign })(RoleAssignForm);
