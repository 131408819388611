import React from 'react';
import { Badge, Button, Card, CardBody, CardHeader, CardTitle, Collapse } from 'reactstrap';
import { Check, ChevronDown, ChevronUp, Edit2, Plus, Trash } from 'react-feather';

import '../../assets/css/collapsibeCardComponent.scss';
import TimeHelper from '../../helpers/TimeHelper';
import Checkbox from '../@vuexy/checkbox/CheckboxesVuexy';

import '../../assets/scss/plugins/forms/switch/react-toggle.scss';
import 'react-toggle/style.css';
import Toggle from "react-toggle";

class CollapsibleCardComponent extends React.Component {

  render() {
    const { props: {
      item: collapseItem,
      toggleCollapse,
      isOpen,
      toggleStatus,
      toggleCategoryModal,
      toggleMenuModal,
      bodyColor,
      badgeTitle,
      deleteCardItem,
      changeStatusItem,
      updateSelectedModifiers,
      selectedIds,
      isOutlet,
    } } = this;

    const isEnabled = collapseItem.status == 1;

    const isOffTime = collapseItem.available_time == '1' && !(TimeHelper.checkIfTimeIsActive(collapseItem.local_from_time, collapseItem.local_to_time));
    let buttonClasses = 'round font-weight-bold custom-padding mr-1';
    if (isEnabled)
      buttonClasses += ' success-3-border';
    else
      buttonClasses += ' disabled-3-border';

    let rightSize = (toggleStatus ? 1 : 0) + (toggleCategoryModal ? 1 : 0) + (deleteCardItem ? 1 : 0) + (updateSelectedModifiers ? 1 : 0) + 1;
    let leftSize = 10 - rightSize;
    return (
      <div id='collapse' className="vx-collapse collapse-bordered mb-2">
        <Card
          key={collapseItem.id}
          className='border-radius-0-5'
        >
          <CardHeader id='collapse' onClick={(e) =>{ 
            if(e.target.id !== 'collapse'){
              return;
            }
            toggleCollapse(collapseItem.id)}} className='border-radius-0-5' {...this.props.dragHandleProps}>
            <CardTitle id='collapse' className={`lead collapse-title collapsed flex-${leftSize}`}>
              {collapseItem.title}
              {badgeTitle && <Badge id='collapse' className='ml-1 font-small-2 align-top' pill color="primary">{badgeTitle}</Badge>}
              {isOffTime && <Badge id='collapse' className='ml-1 font-small-2 align-top' pill color="primary">Off Time</Badge>}
            </CardTitle>
            <div id='collapse' className={`justify-content-end display-flex flex-${rightSize}`}>
              {toggleStatus &&
                <Button onClick={(e) => {
                  e.stopPropagation();
                  toggleStatus(collapseItem);
                }} outline className={buttonClasses} color={isEnabled ? 'success' : 'secondary'} size='md'>
                  {isEnabled ? 'Enabled' : 'Disabled'}
                </Button>}

              {!isOutlet && toggleCategoryModal && <span id='collapse' tag='small' className='text-bold-500 cursor-pointer mr-1'>
                <Edit2 size={25} onClick={(e) => {
                  e.stopPropagation();
                  toggleCategoryModal(collapseItem);
                }} />
              </span>}
              {!this.props.isPromotion && updateSelectedModifiers &&
                <span tag='small' className='text-bold-500 cursor-pointer mr-1 align-self-center'>
                  <Toggle
                    name={'active'}
                    data-id={0}
                    defaultChecked={collapseItem.status}
                    className='switch-danger'
                    value={collapseItem.status}
                    onChange={(e) => {
                      e.stopPropagation();
                      changeStatusItem(collapseItem)
                    }}
                  />
                </span>}
              {deleteCardItem && <span tag='small' className='text-bold-500 cursor-pointer mr-1 align-self-center'>
                <Trash size={20} className='btn-flat-danger' onClick={(e) => {
                  e.stopPropagation();
                  deleteCardItem(collapseItem);
                }} />
              </span>}
              {updateSelectedModifiers && <Checkbox
                color='primary'
                icon={<Check className='vx-icon' size={16} />}
                defaultChecked={selectedIds.indexOf(collapseItem.id) > -1}
                checked={selectedIds.indexOf(collapseItem.id) > -1}
                value={collapseItem.id}
                onClick={(e) => {
                  e.stopPropagation();
                  updateSelectedModifiers(e.target.value);
                }}
              />}
              <span id='collapse' tag='small' className='text-bold-500 cursor-pointer'>
                {isOpen ? <ChevronUp id='collapse' size={30} /> : <ChevronDown id='collapse' size={30} />}
              </span>
            </div>
          </CardHeader>
          <Collapse
            isOpen={isOpen}
          >
            <CardBody className={bodyColor ? bodyColor : 'menu-dark-bg-color'}>
              {this.props.children}
              {!isOutlet && toggleMenuModal && <Button outline className='font-weight-bold custom-padding disabled-2-border my-1' color={'secondary'} size='md' onClick={() => {
                toggleMenuModal();
              }}>
                <Plus className='mx-2' size={20} /> <span className='ml-1 mr-5'>Add New Item</span>
              </Button>}
            </CardBody>
          </Collapse>
        </Card>
      </div >
    )
  }
}
export default CollapsibleCardComponent;