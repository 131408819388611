import CONSTANTS from '../../../constants';

export function dummyItem(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.MENU.GET_DUMMY_ITEM) {
    return { data: action.data, loading: action.loading};
  }
  return state;
}
export function allModifiers(state = { data: null, loading: true }, action) {
  if (action.type === CONSTANTS.ACTIONS.MENU.GET_ALL_MODIFIERS) {
    return { data: action.data, loading: action.loading};
  }
  return state;
}